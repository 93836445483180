// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';

import ColorConstants from 'constants/ColorConstants'
import PageConstants from 'constants/PageConstants'
import QualCodeActions from 'actions/QualCodeActions'

import CodeTag from './CodeTag'
import CodingActions from '../../actions/CodingActions';
import EditButton from './EditButton'
import SentenceComponent from './SentenceComponent'
import SpeakerComponent from './SpeakerComponent'
import {PARAGRAPH_PADDING} from 'constants/DimensionsConstants'
/*
import {Button} from 'react-bootstrap'
import MemoTag from 'memos/MemoTag'
*/

const MAIN_STYLE = {
  marginLeft:PARAGRAPH_PADDING + "px",
  marginRight:"0px",
  marginTop:"0px",
  marginBottom:"0px",
  padding:"0px"
}

const leftStyle = {
    left:0,
    top:0,
    padding:"0 0 0 15px",
    margin:0,
    width:PARAGRAPH_PADDING + "px",
    position:"absolute",
}

const toolStyle = {
  paddingTop:'5px',
  paddingLeft: '2px',
}

const allStyle = {
  margin:0,
  border:0,
  position:"relative",
  paddingBottom:"20px",
  minHeight: "65px"
  // height:"500px", // this is set set by react
}

class SpeakerParagraphComponent extends React.Component {
  constructor() {
    super();
    this.state = {
      paragraphHover: false,
      showTools: false,
      hoverExcerptId: null,
    }
    this.onClick = this.onClick.bind(this);
    this.tagEnter = this.tagEnter.bind(this);
    this.tagLeave = this.tagLeave.bind(this);
    this.onParagraphHover = this.onParagraphHover.bind(this);
    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
    this.memoClicked = this.memoClicked.bind(this);
    this.onHoverExcerpt = this.onHoverExcerpt.bind(this);
  }

  onHoverExcerpt(excerptId) {
    this.setState({hoverExcerptId: excerptId});
  }

  memoClicked(e) {
    e.preventDefault();
    e.stopPropagation();
    this.props.memoClicked(this.props.codedParagraph.memoedExcerptIds || []);
  }
  
  shouldComponentUpdate(nextProps, nextState)
  {
    if ( nextState.showTools != this.state.showTools ) return true;
    if ( nextState.paragraphHover != this.state.paragraphHover ) return true;
    if ( !nextProps.codedParagraph ) return false;
    if ( !this.props.codedParagraph ) return true;
    if ( JSON.stringify(nextProps.codedParagraph) != JSON.stringify(this.props.codedParagraph)) return true;
    if ( nextProps.hasEditPermission !== this.props.hasEditPermission ) return true;
    if ( this.state.hoverExcerptId !== nextState.hoverExcerptId ) return true;
    return false;
  }

    onMouseEnter(e)
    {
      this.setState({
        showTools: true,
      })
    }

    onMouseLeave(e)
    {
      this.setState({
        showTools: false,
      })
    }


  onParagraphHover(hover)
  {
    if ( this.props.isPrint || !this.props.hasEditPermission ) return;
    this.setState({paragraphHover: hover});
  }

  removeCode(codeID) {
    CodingActions.codeUnPressed({
      code_id: codeID,
      page: PageConstants.TRANSCRIPT_PAGE
    })
  }

  tagEnter(codeID)
  {
    QualCodeActions.addPrimeCode({
      codeID: codeID,
      paragraphIndex: this.props.absoluteIndex
    });
  }

  tagLeave(codeID)
  {
    QualCodeActions.removePrimeCode({
      codeID: codeID,
      paragraphIndex: this.props.absoluteIndex
    })
  }

  onClick(target, event)
  {
    if ( !this.props.hasEditPermission ) return;

    CodingActions.selectWordExcerpt({
      start: this.props.codedParagraph.start,
      end: this.props.codedParagraph.end  
    });  

    document.getSelection().removeAllRanges();
  }

  _createDataIndex(index)
  {
    return '{"index":' + index.toString() + '}'
  }

  render () {
    if ( this.props.codedParagraph.end - this.props.codedParagraph.start <= 0)
      return (<div></div>)

    const mainStyle = {
      ...MAIN_STYLE,
      marginLeft: this.props.marginLeft || MAIN_STYLE.marginLeft,
      marginRight: this.props.marginRight || MAIN_STYLE.marginRight,
    }

    const sentences = this.props.codedParagraph.sentences.map((sentence, index) =>
      <SentenceComponent key={index}
                          codedSentence={sentence}
                          paragraphHover={this.state.paragraphHover}
                          index={index}
                          isPrint={this.props.isPrint}
                          hasEditPermission={this.props.hasEditPermission}
                          onHover={this.onHoverExcerpt}
                          hoverExcerptId={this.state.hoverExcerptId}
                          />
    );

    const labels = [...this.props.codedParagraph.codes.map((code, index) =>
      <CodeTag
        key={index}
        id={(code||{}).id}
        name={(code||{}).name}
        selected={(code||{}).selected}
        tagEnter={this.tagEnter}
        tagLeave={this.tagLeave}
        onCodeRemove={this.removeCode}
        hasEditPermission={this.props.hasEditPermission}
        coders={code.coders}
        />
    )].filter((label) => label !== null);

    var paragraphStyle = {
       lineHeight: "31px"
    };

    const speaker = this.props.codedParagraph.speaker;
    const time = this.props.codedParagraph.time;
    const color = this.props.codedParagraph.color || null;

    return (
      <div style={allStyle}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
        className='paragraph'
        >
        <div style={leftStyle} dataindex={this._createDataIndex(this.props.paragraphIndex)}
          className="numberDiv">
            {!this.props.isPrint &&
              <SpeakerComponent
                initials={speaker ? speaker[0] : null}
                onHover={this.onParagraphHover}
                onClick={this.onClick}
                color={speaker ? color : ColorConstants.ULTRA_LIGHT_GREY}
              />
            }
          <div dataindex={this._createDataIndex(this.props.paragraphIndex)}
            className="numberDiv"
            style={toolStyle}>
            <EditButton
              onClick={this.props.onEditClick}
              show={this.props.canEdit && this.state.showTools}
            />
          </div>
        </div>
        {(speaker || time )&&
          <div style={mainStyle} dataindex={this._createDataIndex(this.props.paragraphIndex)} className="numberDiv">
            <div dataindex={this._createDataIndex(this.props.paragraphIndex)} className="numberDiv">
              <span>
                <b>{speaker}</b>
              </span>
              <span style={{color:ColorConstants.LIGHT_GREY, paddingLeft:speaker ? "10px" : "0px"}}>
                {time}
              </span>
            </div>
          </div>
        }
        <div dataindex={this._createDataIndex(this.props.paragraphIndex)} className="labelDiv" style={mainStyle}>
          <div>
            <p dataindex={this._createDataIndex(this.props.paragraphIndex)} className="paragraphSpan" style={paragraphStyle}>
              {sentences}
            </p>
            <div style={{width:"100%", wordWrap: 'break-word'}}>
              {labels}
            </div>
          </div>
        </div>
      </div>

    );
  }
}

export default SpeakerParagraphComponent;
