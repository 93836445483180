// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';
import {Button, Alert} from 'react-bootstrap'
import {ButtonToolbar, ButtonGroup} from 'react-bootstrap'
import {LinkContainer} from 'react-router-bootstrap'
import QualCodeActions from 'actions/QualCodeActions'
import UserStore from 'stores/UserStore'
import CashierStore from 'stores/CashierStore'
import ColorConstants from 'constants/ColorConstants'
import LoadingButton from './LoadingButton';
import DelveAccountBody from './DelveAccountBody'
import SubscriptionBorder from './SubscriptionBorder'
import couponMapper from 'mappers/CouponMapper'
import couponErrorMapper from 'mappers/CouponErrorMapper'
import trackingUtil from 'utils/TrackingUtil'
import SessionAPI from 'apis/SessionAPI'
import sessionMapper from 'mappers/SessionMapper'
import SubscriptionRedirectComponent from './SubscriptionRedirectComponent';

import CouponAPI from 'apis/CouponAPI'

const queryString = require('query-string');

class SubscriptionDisplay extends React.Component {
  constructor () {
    super();
    this._onChange = this._onChange.bind(this);
    this.state = {
      user: UserStore.getUser(),
      ...CashierStore.getPlan(),
      subscribeError: CashierStore.getSubscribeError(),
    }
    this.subscribe = this.subscribe.bind(this);
  }

  _onChange () {
    this.setState({
      user: UserStore.getUser(),
      ...CashierStore.getPlan(),
      ...CashierStore.getSubscriptions(),
      subscribeError: CashierStore.getSubscribeError(),
    })
  }

  async _createSession() {
    const coupon_id = this._getPromo(this.props);

    this.session = SessionAPI.createSession(
      sessionMapper({coupon_id})
    ).catch((error)=>{return {error}});

    this.yearSession = SessionAPI.createSession(
      sessionMapper({interval: 'year', coupon_id})
    ).catch((error)=>{return {error}});
  }

  _getPromo(props) {
    if ( !props ) return null;
    return props.promo;
  }

  checkPromo(props, prevProps) {
    const prevPromo  = this._getPromo(prevProps);
    const currentPromo = this._getPromo(this.props)
    if ( prevPromo !== currentPromo && !!currentPromo )
    {
      CouponAPI.getPromo(currentPromo).then((coupon)=>{
        const success = couponMapper(currentPromo, coupon);
        if ( !success ) return;
        this.setState({success})
        trackingUtil.updateAttribute('last_promo_code', currentPromo);
      }).catch((e)=>{
        const promoError = couponErrorMapper(currentPromo, e);
        if ( !promoError ) return;
        this.setState({promoError});
      });

      this._createSession()
    }
  }

  componentDidMount () {
    UserStore.addChangeListener(this._onChange);
    CashierStore.addChangeListener(this._onChange);
    this.checkPromo(this.props);
    this._createSession();
  }

  componentWillUnmount () {
    // NOTE: the way the code is currently working, the call back is being called
    // but this is then unmounted. Becauser it's unmounted it unsubscribes.
    // however I guess the callbacks are still in the queue
    // Not sure if there is a better way to design this...
    // App.jsx is causing this to hapen I believe...
    UserStore.removeChangeListener(this._onChange);
    CashierStore.removeChangeListener(this._onChange);
  }

  signOut (e) {
    QualCodeActions.signOut();
  }

  async subscribe () {
    this.setState({
      loading: true
    })
    const interval = this.isYearly(this.props) ? 'year' : 'month';

    // TODO: may need some sort of verification that this was successful...
    const session = await (
      this.isYearly(this.props) ? this.yearSession : this.session)

    if ( session.error ) {
      this.setState({
        stripeError: 'Something went wrong. Please refresh and try again.'
      })
      return
    }

    var stripe = Stripe(STRIPE_PUBLISHABLE_KEY);

    stripe.redirectToCheckout({
      sessionId: session.data.id
    });
  }

  getPlan() {
    const yearly = this.isYearly(this.props);
    const plan = yearly ? this.state.annual_plan : this.state.plan;
    return plan;
  }

  isYearly(props) {
    const parsed = queryString.parse(props.location.search);
    return !!parsed.plan;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.checkPromo(this.props, prevProps);
  }

  getError(state) {
    if ( !!state.promoError && state.promoError.error )
      return state.promoError;

    if ( !!state.stripeError ) {
      return {
        error: true,
        message: state.stripeError
      }
    }

    return state.subscribeError;
  }

  render () {
    const error = this.getError(this.state);

    const yearly = this.isYearly(this.props);
    const plan = this.getPlan() || {};
    const planPrice = plan && plan.amount ? plan.amount : null;
    const discount = this.state.annual_plan &&
                      this.state.annual_plan.discount ?
                      this.state.annual_plan.discount : null;
    const annual_plan_amount = plan.annual_plan_amount || null;

    return (
      <div>
        <style type="text/css">{`
          .btn-left, .btn-left:hover, .btn-left:focus, .btn-left:active {
              background-color: "white";
              color: ${ColorConstants.ACTIVE_BUTTON_BLUE};
              border-color: ${ColorConstants.ACTIVE_BUTTON_BLUE};
          }
          .btn-right, .btn-right:hover, .btn-right:focus, .btn-right:active {
              background-color: ${ColorConstants.ACTIVE_BUTTON_BLUE};
              color: #ffffff;
              border-color: ${ColorConstants.ACTIVE_BUTTON_BLUE};
          }
        `}</style>

      <DelveAccountBody error={error} success={this.state.success}>
          <SubscriptionRedirectComponent/>
            {planPrice &&
              <div>
              <SubscriptionBorder>
                <div style={{marginLeft:105, marginTop: 15, width:400}}>
                  {discount &&
                    <div style={{
                        color: !yearly ? ColorConstants.LIGHT_GREY : ColorConstants.ACTIVE_BUTTON_BLUE,
                        marginLeft: "30px",
                        marginBottom: "5px",
                        fontSize: "0.8em"
                        }}>
                      Save {discount}
                    </div>
                  }
                  <div>
                    <ButtonToolbar>
                      <ButtonGroup bsSize="small">
                        <LinkContainer activeClassName="" to='/subscribe?plan=yearly' style={{cursor:'pointer'}}>
                          <Button bsStyle={!yearly ? "left" : "right"}>Billed Annually</Button>
                        </LinkContainer>
                        <LinkContainer to='/subscribe' style={{cursor:'pointer'}}>
                          <Button bsStyle={!yearly ? "right" : "left"}>Billed Monthly</Button>
                        </LinkContainer>
                      </ButtonGroup>
                    </ButtonToolbar>
                  </div>
                </div>

                <div style={{marginTop:30, marginBottom:15, height:80, textAlign:"center"}}>
                  <h2>${planPrice}</h2>
                  <p style={{fontSize:".9em"}}>per team member per month</p>
                  {annual_plan_amount && <p style={{
                    fontSize:".7em",
                    color: ColorConstants.LIGHT_GREY
                  }}><em>billed as ${annual_plan_amount} annually</em></p>}
                </div>

                <div style={{borderBottom:"1px solid lightGray"}}/>

                <div style={{margin:30}}>
                  <p style={{textAlign:"center", margin:5}}>Unlimited projects, transcripts & codes</p>
                  <p style={{textAlign:"center", margin:5}}>Keep ownership of your data</p>
                  <p style={{textAlign:"center", margin:5}}>Easily export your research</p>
                  <p style={{textAlign:"center", margin:5}}>Customer support</p>
                  <p style={{textAlign:"center", margin:5}}>Cancel anytime</p>
                </div>

                {!!this.state.user.email &&
                  <div style={{marginBottom:30}}>
                    <LoadingButton
                      width={300}
                      centered
                      bsStyle="blue"
                      loading={this.state.loading}
                      onClick={this.subscribe}
                    >
                      Start Your 14 Day Free Trial
                    </LoadingButton>
                  </div>
                }

                <div style={{borderBottom:"1px solid lightGray"}}/>

                <div style={{marginTop:30}}>
                  <div style={{margin:"0 auto", maxWidth: 350}}>
                    <p style={{textAlign:"center", fontSize:".9em"}}>
                      Use Delve for 14 days for free. After the trial, your subscription will automatically start.
                    </p>
                  </div>
                </div>
              </SubscriptionBorder>
              <div style={{textAlign:"center", margin:15}}>
                <p style={{textAlign:"center", fontSize:".9em"}}>
                  Are you a student? <a href="https://intercom.help/delvetool/en/articles/3805420-education-pricing" target="_blank">Learn about Delve's Educational Pricing.</a>
                </p>
              </div>

              <div style={{textAlign:"center"}}>
                <Button bsStyle="link" onClick={this.signOut}>Log Out</Button>
              </div>
          </div>
        }
        </DelveAccountBody>
      </div>
    );
  }
}

export default SubscriptionDisplay;
