function getUser(state, userID) {
  if ( userID === 'bot' ) {
    return {
      name: 'AI Assistant',
      email: null,
      id: 'bot',
      initials: 'AI'
    }
  }

  return (state.entities.users || {})[userID] || null;
}

function userWithInitials(state, userID)
{
  const user = getUser(state, userID);
  let initials = null;

  if ( user && user.initials )
    return user;
  
  if ( user && user.name && user.name.length > 0)
  {
      const nameArray = user.name.split(' ');
      const firstLetter = nameArray[0] ? nameArray[0][0] : "";
      const secondLetter = nameArray[1] ? nameArray[1][0] : "";
      initials = firstLetter + secondLetter;
  }
  else if ( user && user.email )
  {
    initials = user.email[0];
  }

  return {
    ...user,
    initials: initials
  }
}

const nameMapper = (state, user_id) => {
  return userWithInitials(state, user_id)
}

export default nameMapper
