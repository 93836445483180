import React from 'react';
import Markdown from 'markdown-to-jsx';
import CustomLink from './CustomLink'; 

const MyMarkdownComponent = (props) => {
    // Define inline CSS for tables
    const tableStyle = {
        borderCollapse: 'collapse',
        width: '100%', // Adjust table width as needed
    };

    const cellStyle = {
        border: '1px solid #dddddd', // Adjust border color and size as needed
        textAlign: 'left', // Adjust text alignment as needed
        padding: '8px', // Adjust padding as needed
    };

    // Define inline CSS for table headers
    const headerStyle = {
        ...cellStyle, // Inherit cell styles
        backgroundColor: '#f2f2f2', // Optional: Adjust header background color
    };

    const handleLinkClick = (snippetId) => {
        //if (!props.onLinkClicked) return;
        props.onLinkClicked(snippetId); // Call the onLinkClicked prop with the snippetId
    }
        

    return (
        <Markdown
            options={{
                disableParsingRawHTML: true,
                overrides: {
                    table: {
                        props: {
                            style: tableStyle,
                        },
                    },
                    th: {
                        props: {
                            style: headerStyle,
                        },
                    },
                    td: {
                        props: {
                            style: cellStyle,
                        },
                    },
                    img: {
                        component: ({ alt }) => <span>{alt}</span>,
                    },
                    a: {
                        component: CustomLink,
                        props: {
                            onClick: handleLinkClick, // Passing the handler
                        },
                    }
                },
            }}
        >
            {props.children}
        </Markdown>
    );
};

export default MyMarkdownComponent;