import React, {useCallback} from 'react';
import {useDropzone} from 'react-dropzone';
import {Button} from 'react-bootstrap'


function FileDropZone(props) {
  /*
    Open can be used to open the native file picker
  */
  const {acceptedFiles, getRootProps, getInputProps, open} = useDropzone({
    onDrop: props.onDrop,
    noClick: true,
    noKeyboard: true,
    onDragEnter: event => props.isHovering(true),
    onDragLeave: event => props.isHovering(false)
  });

  const files = acceptedFiles.map(file => {
    return <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  }
  );

  return (
    <div style={{height:"100%"}}>
      <div {...getRootProps({className: 'dropzone'})} style={{height:"100%"}}>
        <input {...getInputProps()}/>
        {props.children}
      </div>
    </div>
  );
}


export default FileDropZone
