// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';

class FileLoadingContainer extends React.Component {
  constructor () {
    super();

  }

  render () {

    const files = this.props.files.map((file, index)=>{
      const error = !!file.error;
      const errorMessage = error ? file.errorMessage : null;
      const message = error && errorMessage ? errorMessage :
                            (!!file.loaded ? 'Loaded' : 'Loading');

      return (<div key={index}>
        {file.fileName} - {message}

      </div>)
    });

    return (
      <div style={{height:"500px"}}>
        {files}
      </div>
    );
  }
}

export default FileLoadingContainer;
