// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';
import {Modal, Button, Alert} from 'react-bootstrap'
import QualCodeActions from 'actions/QualCodeActions'
import {FormControl, ControlLabel, FormGroup} from 'react-bootstrap'
import UserAPI from 'apis/UserAPI';

class UpdateEmailModal extends React.Component {
  constructor () {
    super();
    this.state = {
      newEmail: "",
      verifyEmail: "",
      verifyPassword: "",
      message: "",
      messageStatus: null
    };

    this.onNewEmailChange = this.onNewEmailChange.bind(this);
    this.onVerifyEmailChange = this.onVerifyEmailChange.bind(this);
    this.onVerifyPasswordChange = this.onVerifyPasswordChange.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onHide = this.onHide.bind(this);
  }

  onNewEmailChange(e)
  {
    this.setState({
      newEmail: e.target.value
    })
  }

  onSave(e)
  {
    const that = this;
    UserAPI.updateEmail(this.state.newEmail, this.state.verifyEmail, this.state.verifyPassword).then((data)=>{
      that.setState({
        message: 'To finish updating your email address, please check your email and click the link to verify your new email address.',
        messageStatus: 'success',
      })
    }).catch((error)=>{
      console.log(error);
      that.setState({
        message: 'Error updating email address. Please try again.',
        messageStatus: 'danger',
      })
    });
  }

  onVerifyEmailChange(e)
  {
    this.setState({
      verifyEmail: e.target.value
    })
  }

  onVerifyPasswordChange(e)
  {
    this.setState({
      verifyPassword: e.target.value
    })
  }

  onHide(e)
  {
    this.setState({
      newEmail: "",
      verifyPassword: "",
      verifyEmail: "",
      message: "",
      messageStatus: null
    })
    this.props.onHide()
  }

  render () {
    return (
      <div>
        <Modal show={this.props.show} onHide={this.onHide}>
            <Modal.Header closeButton>
              <Modal.Title>Update Email Address</Modal.Title>
            </Modal.Header>

            <Modal.Body>

            {this.state.messageStatus &&
              <Alert bsStyle={this.state.messageStatus}>
                <p>
                  {this.state.message}
                </p>
              </Alert>
              }

              <form>
                <FormGroup controlId="newEmail">
                  <ControlLabel>New Email</ControlLabel>
                  <FormControl
                    componentClass="input"
                    type="text"
                    placeholder=""
                    value={this.state.newEmail}
                    onChange={this.onNewEmailChange}
                  />
                </FormGroup>

                <FormGroup controlId="verifyNewemail">
                  <ControlLabel>Verify New Email</ControlLabel>
                  <FormControl
                    componentClass="input"
                    type="text"
                    placeholder=""
                    value={this.state.verifyEmail}
                    onChange={this.onVerifyEmailChange}
                  />
                </FormGroup>

                <FormGroup controlId="password">
                  <ControlLabel>Password</ControlLabel>
                  <FormControl
                    componentClass="input"
                    type="password"
                    placeholder=""
                    value={this.state.verifyPassword}
                    onChange={this.onVerifyPasswordChange}
                  />
                </FormGroup>
              </form>
            </Modal.Body>
          <Modal.Footer>
            <Button bsStyle="blue" onClick={this.onSave}>Save</Button>
          </Modal.Footer>
      </Modal>
      </div>
    );
  }
}

export default UpdateEmailModal;
