import AppDispatcher from 'dispatcher/AppDispatcher'
var _ = require('underscore');
import BaseStore from 'stores/BaseStore'
import FeatureFlagState from './FeatureFlagState'
import feature_flag_reducer from './FeatureFlagReducer';
import feature_flag_mapper from './FeatureFlagMapper';

const {
  GET_FEATURE_FLAGS_RESULT
} = require('./FeatureFlagConstants');

var FeatureFlagStore = _.extend({}, BaseStore, {
  hasAICoding: () => {
    return feature_flag_mapper(FeatureFlagState.get())
  },
});

// Register callback with AppDispatcher
AppDispatcher.register(function(payload) {
  var action = payload.action;
  switch(action.actionType) {
    case GET_FEATURE_FLAGS_RESULT:
      FeatureFlagState.set(feature_flag_reducer(action, FeatureFlagState.get()))
      break;
    default:
      return true;
  }

  FeatureFlagStore.emitChange();
  return true;
});

export default FeatureFlagStore
