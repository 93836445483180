// app/javascript/conversations/components/conversationsDisplay.jsx

import React, { useState, useEffect, useContext } from 'react';
import RowModal from 'projects/components/RowModal'
import ModalRow from 'projects/components/ModalRow'
import ChatRow from './ChatRow'
import ConversationActions from './ConversationActions';
import ProjectContext from 'contexts/ProjectContext';
import {projectContextToUserObject} from './chat_collaborator_util';

const _ = require('underscore');

const ConversationPickerModal = (props) => {
  const [conversations, setConversations] = useState([])
  const projectContext = useContext(ProjectContext);

  const {show, projectId, onHide} = props;

  useEffect(() => {
    if (projectId && !!show) {
      ConversationActions.getConversations(projectId).then((data)=>{
        setConversations(data.data.conversations || []);
      });  
    }
  }, [show, projectId]);
  const collaborators = projectContextToUserObject(projectContext);

  return (
    <div>
      <RowModal
        bsSize="large"
        show={show}
        onHide={onHide}
        buttonText='New Conversation'
        buttonAction={true ? null : this.addProject}
        header={<h3 style={{marginTop:0}}>All Chats</h3>}
      >
        <ModalRow
          row={[
            <strong>Chat</strong>,
            <strong>Owner</strong>,
            <strong>Created At</strong>]}
        />
        
        {conversations.sort((a, b) => new Date(b.created_at) - new Date(a.created_at)).map((conversation, index) => {
          const user = collaborators[conversation.user_id] || {};
          const user_name = user.name || user.email || null;
          
          return <ChatRow
            key={index} 
            created_at={conversation.created_at}
            name={conversation.first_message || 'No messages'}
            user_name={user_name}
            id={conversation.id}
            project_id={projectId}
            onHide={onHide}/>
        })}
        {conversations.length === 0 &&
          <div style={{textAlign: 'center', fontSize: '1.2em', marginTop: '40px'}}>
              Your chat history will appear here once you start a chat
          </div>
        }
        
      </RowModal>
    </div>)
}

export default ConversationPickerModal;
