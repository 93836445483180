// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';
import {Modal, Button, Alert} from 'react-bootstrap'
import CashierActions from 'actions/CashierActions'
import {FormControl, ControlLabel, FormGroup} from 'react-bootstrap'
import TeamModalMain from './TeamModalMain'
import TeamModalAdd from './TeamModalAdd'
import TeamModalSubscription from './TeamModalSubscription'
import TeamModalDetail from './TeamModalDetail'
import TeamModalRemove from './TeamModalRemove'

import AccountPageConstants from 'constants/AccountPageConstants'

class TeamModal extends React.Component {
  constructor () {
    super();
    this.state = {
      email: null,
      seat_id: null,
    };

    this.onHide = this.onHide.bind(this);
    this.addTeamMember = this.addTeamMember.bind(this);
    this.onAddEmail = this.onAddEmail.bind(this);
    this.addSeat = this.addSeat.bind(this);
    this.memberClick = this.memberClick.bind(this);
    this.removeMember = this.removeMember.bind(this);
    this.confirmRemoveMember = this.confirmRemoveMember.bind(this);
    this.backButtonPressed = this.backButtonPressed.bind(this);
  }

  backButtonPressed(e)
  {
    let page = AccountPageConstants.MAIN_PAGE;
    switch (this.props.page) {
      case AccountPageConstants.MAIN_PAGE: // NOTE: this really should not happen
      case AccountPageConstants.ADD_MEMBERS:
      case AccountPageConstants.MEMBER_DETAIL:
        page = AccountPageConstants.MAIN_PAGE;
      break;
      case AccountPageConstants.PAYMENT:
        page = AccountPageConstants.ADD_MEMBERS;
      break;
      case AccountPageConstants.MEMBER_DELETE:
        page = AccountPageConstants.MEMBER_DETAIL;
      break;
    }

    CashierActions.gotoTeamModal(page);
  }

  removeMember(seatID)
  {
    this.setState({
      seat_id: seatID,
    })

    CashierActions.gotoTeamModal(AccountPageConstants.MEMBER_DELETE);
  }

  confirmRemoveMember(subscriptionID, seatID)
  {
    if ( seatID && subscriptionID)
      CashierActions.removeSeat(subscriptionID, seatID);

      /* HANDLED BY API CALL
    this.setState({
      page: AccountPageConstants.MAIN_PAGE,
      seat_id: null,
    })
    */
  }

  memberClick(seatID)
  {
    this.setState({
      seat_id: seatID,
    })

    CashierActions.gotoTeamModal(AccountPageConstants.MEMBER_DETAIL);
  }

  addSeat(e)
  {
    CashierActions.addSeat(this.props.subscription.id, this.state.email);
    /* HANDLED BY API CALL
    this.setState({
      page: AccountPageConstants.MAIN_PAGE,
    })*/
  }

  onAddEmail(email)
  {
    this.setState({
      email: email,
    })

    CashierActions.gotoTeamModal(AccountPageConstants.PAYMENT);
  }

  addTeamMember(e)
  {
    CashierActions.gotoTeamModal(AccountPageConstants.ADD_MEMBERS);
  }

  onHide(e)
  {
    this.props.onHide();
  }

  render () {
    var page = <div></div>

    switch (this.props.page) {
      case AccountPageConstants.MAIN_PAGE:
        page = <TeamModalMain
                  subscription={this.props.subscription}
                  addTeamMember={this.addTeamMember}
                  memberClick={this.memberClick}
                  />
      break;
      case AccountPageConstants.ADD_MEMBERS:
        page = <TeamModalAdd
                  subscription={this.props.subscription}
                  onAddEmail={this.onAddEmail}
                />
      break;
      case AccountPageConstants.PAYMENT:
        page = <TeamModalSubscription
                  addSeat={this.addSeat}
                  addSeatInfo={this.props.addSeatInfo}
                  subscription={this.props.subscription}
                />
      break;
      case AccountPageConstants.MEMBER_DETAIL:
      {
        const seats = this.props.subscription.seats.filter((seat)=>seat.id==this.state.seat_id);
        const seat = seats.length > 0 ? seats[0] : null;
        page = <TeamModalDetail
                  seat={seat}
                  removeSeat={this.removeMember}
                />
      }
      break;
      case AccountPageConstants.MEMBER_DELETE:
      {
        page = <TeamModalRemove
                onClick={(e)=>this.confirmRemoveMember(this.props.subscription.id, this.state.seat_id)}
                removeSeatInfo={this.props.removeSeatInfo}
                subscription={this.props.subscription}
                />
      }
      break;
    }

    const mainPage = this.props.page == AccountPageConstants.MAIN_PAGE;

    return (
      <div>
        <Modal show={this.props.show} onHide={this.onHide}>
          <Modal.Body>
            <div style={{minHeight:"33px"}}>
              {!mainPage &&
                <Button bsStyle="link" onClick={this.backButtonPressed}>Back</Button>
              }
            </div>
            <div style={{margin:"0px 33px"}}>
              {page}
            </div>
          </Modal.Body>
      </Modal>
      </div>
    );
  }
}

export default TeamModal;
