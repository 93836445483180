var options = { year: 'numeric', month: 'long', day: 'numeric' };
import nameMapper from './NameMapper'

const isCancelled = (subscription, subscriptionState) => {
  subscriptionState = subscriptionState || {};
  if ( !subscriptionState.loaded ) return false;
  if ( !subscription ) return true;
  if ( subscription.status === 'canceled' ) return true;
  return false;
}

const isPastDue = (subscription, subscriptionState) => {
  subscriptionState = subscriptionState || {};
  if ( !subscriptionState.loaded ) return false;
  if ( !subscription ) return false;
  if ( subscription.status === 'past_due' ) return true;
  return false;
}

const subscriptionMapper = (state) => {
  try {
    if ( !state.subscriptionState.loaded )
      return {
        subscriptionState:{
          subscription: null,
          loaded: state.subscriptionState.loaded,
          loading: state.subscriptionState.loading,
        }
      }

    const currentUserID = state.entities.user.id;

    const subscriptions = state.subscriptionState.subscriptions.map((subscriptionID)=>{
      const subscription = state.entities.subscriptions[subscriptionID];
      if ( !subscription ) return null;

      var date = new Date(Date.parse(subscription.current_period_end));
      const plan = state.entities.plans[subscription.plan];
      const amount = plan ? plan.amount/100 : null;
      const total = amount ? amount * subscription.quantity : 0;
      const seatIDs = subscription.seats ? subscription.seats : [];
      const seats = seatIDs.map((seatID)=>{
        const seat = state.entities.seats[seatID] ? state.entities.seats[seatID] : {};
        const user = nameMapper(state, seat.user);
        return {
          ...seat,
          user:user,
        }
      })

      const owner = nameMapper(state, subscription.user_id);
      const plan_id = plan ? plan.id : null;

      return {
        id: subscription.id,
        quantity: subscription.quantity,
        interval: plan && plan.interval ? plan.interval : null,
        amount: amount,
        total: total,
        isOwner: currentUserID == subscription.user_id,
        owner: owner,
        current_period_end: date.toLocaleDateString('en-US', options),
        status:subscription.status,
        cancel_at_period_end:subscription.cancel_at_period_end,
        plan_id,
        seats: subscription.seats ? seats : null,
        isSeparatelyInvoiced: !!plan_id && plan_id.toString() === process.env['SEPARATELY_INVOICED_PLAN_ID']
      }
    }).filter((subscription) => !!subscription);

    // this is assuming the first one is fine
    // you'll want to do active
    var subscription = null;

    if ( subscriptions.length > 0 )
      subscription = subscriptions[0];

    return {
      subscriptionState:{
        subscription: subscription,
        loaded: state.subscriptionState.loaded,
        loading: state.subscriptionState.loading,
        isCancelled: isCancelled(subscription, state.subscriptionState),
        isPastDue: isPastDue(subscription, state.subscriptionState),
        isOwner: (subscription || {}).isOwner
      }
    }
  } catch (e) {
    console.error(e);
    return {subscriptionState:{
      subscription: null,
      loaded: false,
      loading: false,
    }};
  }
}

export default subscriptionMapper
