// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';
import {Modal} from 'react-bootstrap'
import AccountSingleButton from './AccountSingleButton'

function ErrorModal(props) {
  return (
    <div>
      <Modal show={props.show} onHide={props.onHide}>
          <Modal.Body>
            <div style={{margin:"0px 33px"}}>
              <AccountSingleButton
                loading={false}
                header={props.header}
                button={props.button}
                hideTerms
                disabled={false}
                body={props.body}
                onClick={props.onClick}
                />
            </div>
          </Modal.Body>
    </Modal>
    </div>
  );
}

export default ErrorModal;
