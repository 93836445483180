import React from 'react';
import { Popover } from 'react-bootstrap';

function CustomPopover(props) {
  return (
    <Popover
      {...props}
      id={"12345"}
      className="custom-popover"
    >
        {props.children}
    </Popover>
  );
}

export default CustomPopover;