import { normalize, schema } from 'normalizr';
import InitialState from 'constants/InitialState'
var _ = require('underscore');

import QualConstants from 'constants/QualConstants'
import {
  LOADED_STATE,
  LOADING_STATE,
} from 'constants/LoadingConstants'

const BLANK_SEARCH = {
  searchTerm: null,
  results: null
}

function searchReducer(action, state = InitialState)
{
  if ( !action ) return state;

  switch (action.actionType)
  {
    case QualConstants.TRANSCRIPT_SEARCH:
    {
      return {
        ...state,
        search: {
          // NOTE: write over searches from other projects. Does not seem relevant to keep them around.
          [action.data.project_id]:
          {
            ...BLANK_SEARCH,
            ...state.search[action.data.project_id],
            loadState: LOADING_STATE,
            searchTerm: action.data.query,
          }
        }
      }
    }
    break;
    case QualConstants.TRANSCRIPT_SEARCH_RECEIVED:
    {
      return {
        ...state,
        search: {
          // NOTE: write over searches from other projects. Does not seem relevant to keep them around.
          [action.data.project_id]:
          {
            ...BLANK_SEARCH,
            ...state.search[action.data.project_id],
            loadState: LOADED_STATE,
            total: action.data.total,
            results:
              action.data.hits.map((hit)=>{
                return {
                  transcript_id: hit.id,
                  project_id: hit.project_id,
                  results: hit.results,
                  score: hit._score,
                }
              })
          }
        }
      }
    }
    break;
    default:
      return state;
  }
}

export default searchReducer
