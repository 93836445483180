import {
  DELVE_TIME_REGEXP,
  ARROW_TIME_REGEXP,
  BLANK_REGEX
} from 'utils/FormatterRegExp'

export default (transcriptText) => {
  const regex = new RegExp(`^(?:\\d+${BLANK_REGEX}\\n)?(${DELVE_TIME_REGEXP})${ARROW_TIME_REGEXP}(?:\\n)?`, 'gm');
  
  return transcriptText.replace(
    regex,
    "$1 "
  );
}