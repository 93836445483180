import {
  AUTH_FORGOT_PASSWORD_ERROR,
} from 'constants/AuthConstants'

const errorMessageDict = {
  AUTH_FORGOT_PASSWORD_ERROR: "Something went wrong, please reload and try again."
}

const NULL_STATE = {error:{error: false}};

const forgotPasswordMapper = (state) => {
  try {
    const error = state.errorDict[AUTH_FORGOT_PASSWORD_ERROR];
    if ( !error.error ) return NULL_STATE;

    return {
      error:{
        error: true,
        message: error.message ? error.message : errorMessageDict[AUTH_FORGOT_PASSWORD_ERROR],
      }
    }
  } catch (e) {
    console.error(e);
    return NULL_STATE;
  }
}

export default forgotPasswordMapper
