import { normalize, schema } from 'normalizr';
import InitialState from 'constants/InitialState'

// import archive constants
import {
  ARCHIVE_PROJECT,
  ARCHIVE_PROJECT_SUCCESS,
  ARCHIVE_PROJECT_ERROR,
  UNARCHIVE_PROJECT,
  UNARCHIVE_PROJECT_SUCCESS,
  UNARCHIVE_PROJECT_ERROR
} from './ArchiveConstants';

var _ = require('underscore');

function archiveReducer(action, state = InitialState)
{
  if ( !action ) return state;

  switch (action.actionType)
  {
    case ARCHIVE_PROJECT:
    {
      return {
        ...state,
        entities: {
          ...state.entities,
          projects: {
            ...state.entities.projects,
            [action.data.project_id]: {
              ...state.entities.projects[action.data.project_id],
              status: 'archived'
            }
          }
        }
      }
    }
    break
    case UNARCHIVE_PROJECT:
    {
      return {
        ...state,
        entities: {
          ...state.entities,
          projects: {
            ...state.entities.projects,
            [action.data.project_id]: {
              ...state.entities.projects[action.data.project_id],
              status: 'unarchived'
            }
          }
        }
      }
    }
    break
    default:
      return state;
  }
}

export default archiveReducer
