// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';
import Header from './Header'
import ShareButton from './ShareButton'
import matchToProjectID from 'utils/MatchToProjectID'

class ShareHeader extends React.Component {
  constructor () {
    super();
  }

  render () {
    const projectID = matchToProjectID(this.props.match)

    // TODO: Find a better way to align the share buttons with the other headers
    // Probably just hide the drop down option
    return (
      <div style={{width:"100%", display: 'flex'}}>
        <div style={{flex: 1}}>
          <Header name={this.props.name}/>
        </div>
        <div style={{marginRight: '42.5px'}}>
          <ShareButton
            projectID={projectID}
            hasEditPermission={this.props.hasEditPermission}
            subscriptionState={this.props.subscriptionState}
            onShowCollabModal={this.props.onShowCollabModal}
            showCollabModal={this.props.showCollabModal}
          />
        </div>
      </div>
    );
  }
}

export default ShareHeader;
