// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';
import {Button, Alert} from 'react-bootstrap'

class CollabNavigation extends React.Component {
  constructor () {
    super();
  }

  render () {
    return (
      <div>
        <div>
          <div style={{padding: 0, margin: 0, height: 10}} >
            {!!this.props.clickBack &&
              <Button bsStyle='link' style={{
                padding: 0,
                margin: 0}}
                onClick={this.props.clickBack}
                >
                Back
              </Button>
            }
          </div>

          <div style={{width: "100%", marginTop: 0}}>
            <h5 style={{
              overflow: "hidden",
              textOverflow:'ellipsis',
              whiteSpace: 'nowrap',
              textAlign: 'center',
              marginTop: 0
            }}>{this.props.title}</h5>
          </div>
        </div>

        {this.props.shareError && <Alert bsStyle="warning">
          <p>
            {this.props.shareError}
          </p>
        </Alert>
        }

        {this.props.children}
      </div>
    );
  }
}

export default CollabNavigation;
