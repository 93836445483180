import React, { useState } from 'react';
import { Button, FormControl, InputGroup } from 'react-bootstrap';
import FilterComponent from '../projects/components/FilterComponent';

function isDisabled(chatText) {
  return !chatText || chatText.length === 0;
}

const ChatInput = (props) => {
  return (
    <div style={{ border: '1px solid #ddd', margin: '5px 20px 20px 20px', borderRadius: '4px' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', padding: '10px', alignItems: 'center' }}>
        <InputGroup style={{ width: '100%' }}>
          <form onSubmit={props.onSubmit} style={{ display: 'flex', width: '100%' }}>
            <FormControl
              className="ai-chat-input"
              type="text"
              style={{ flexGrow: 1, marginRight: '5px', border: 'none', boxShadow: 'none' }}
              value={props.chatText}
              onChange={(e) => props.setChatText(e.target.value)}
              placeholder='Ask anything about your coded snippets...'
            />
            <Button
              type="submit"
              bsStyle="blue"
              disabled={isDisabled(props.chatText)}>
                Ask
            </Button>
          </form>
        </InputGroup>
      </div>
      <div style={{ padding: '12px', borderTop: '1px solid #ddd'}}>
        <div style={{ fontWeight: 'bold', marginRight: '10px', marginBottom: '8px', fontSize: '.8em' }}>
          Select which snippets you're asking about
        </div>
        <FilterComponent
          dropdownAbove={true}
          projectID={props.projectID}
          hideDescriptors
          codeFilterButtonText="Select Codes"
          codeSubHeader="Ask about snippets in ** of the selected codes."
          transcriptFilterButtonText="Select Transcripts"
          transcriptSubHeader="Ask about snippets in any of the selected transcripts."
          source="chat"
        />
      </div>
    </div>
  );
};

export default ChatInput;
