import React from 'react';
const LEFT_PADDING = 45;

function RowWrapper(props) {
  return (<div style={{float:"left",width:props.dimension}}>
    {props.children}
  </div>);
}

function ModalRow(props) {
  /*
    Open can be used to open the native file picker
  */

  let dimensions = ["50%", "23%", "23%", "4%"];
  if ( props.row.length == 2 )
    dimensions = ["70%", "30%"]

  const elements = props.row.map((rowItem, index)=>{
    const item = rowItem || <br/>
    const dimension = dimensions[index];

    if ( index < props.row.length - 1 )
      return (<RowWrapper dimension={dimension} key={index}>
        {item}
      </RowWrapper>)

    return (<RowWrapper dimension={dimension} key={index}>
      <div style={{float:'right'}}>
          {item}
      </div>
      </RowWrapper>
    );
  })


  return (<div className='modal-row' style={{borderBottom: "1px solid lightGray", padding:0, magin: 0, height:"45px"}}>
            <div style={{padding:`12px ${LEFT_PADDING}px 12px ${LEFT_PADDING}px`}}>
              {elements}
              <div style={{clear:"both"}}></div>
            </div>
          </div>);
}


export default ModalRow
