import projectListMapper from './ProjectListMapper'

const newProjectModalMapper = (state) => {
  try {
    const original = projectListMapper(state);
    const show = !!state.newProject.show;
    const newProjectID = state.newProject.id ? state.newProject.id : null;
    return Object.assign({}, original, {show: show, id: newProjectID})
  } catch (e) {
    console.error(e);
    return {projects:[], show: false, id: null};
  }
}

export default newProjectModalMapper
