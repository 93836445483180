import {
  REFER_PAGE,
  REFER_SENT
} from 'constants/AccountPageConstants'

const REFER_PAGES = [REFER_PAGE, REFER_SENT];

const NULL_STATE = {
  showReferPage: false,
  page: null,
}

const teamMapper = (state) => {
  const page = state.teamModal.page;
  const showReferPage = REFER_PAGES.includes(page);

  try {
    return {
      showReferPage: showReferPage,
      page: showReferPage ? page : null
    }
  } catch (e) {
    console.error(e);
    return NULL_STATE;
  }
}

export default teamMapper
