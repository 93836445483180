
const SNIPPET_PATTERN = /#?Snippet[_ ](\d+)/
const GLOBAL_SNIPPET_PATTERN = new RegExp(SNIPPET_PATTERN.source, 'g');

// this function goes through the content
// it looks for the pattern #Snippet_<id>
// it returns on object of
// {
//     id: {
//        order: the order in which the snippet appears in the content
//        link: the markdown link to the snippet [order](#Snippet_<id>)
//     }
//}
// if a snippet is used twice, only the first order number is recorded
function getSnippetCitation(content) {
    let match;
    let snippetCitations = {};
    let order = 1;
    while ((match = GLOBAL_SNIPPET_PATTERN.exec(content)) !== null) {
        if (!snippetCitations[match[1]]) {
            snippetCitations[match[1]] = {
                order: order,
                link: `[${order}](#Snippet_${match[1]})`
            };
            order++;
        }
    }
    return snippetCitations;
}

function citationCreation(content) {
    const snippetCitations = getSnippetCitation(content);

    // link content should use the link object within the snippetCitations object
    const linkContent = content.replace(GLOBAL_SNIPPET_PATTERN, (match, id) => snippetCitations[id].link);

    // snippetIds as an array in order of order
    const snippetIds = Object.keys(snippetCitations).sort((a, b) => snippetCitations[a].order - snippetCitations[b].order);

    return {linkContent, snippetCitations, snippetIds};
}

function extractSnippetIds(content) {
    let match;
    const snippetIds = new Set();
    while ((match = GLOBAL_SNIPPET_PATTERN.exec(content)) !== null) {
        snippetIds.add(match[1]);
    }
    return [...snippetIds]; // Convert back to array
}

// filter excerpts by content
function filterExcerptsByContent(excerpts, content) {
    const excerptIds = extractSnippetIds(content);
    // filters the object excerpts to only include the ones that are in the message
    return Object.keys(excerpts || {}).reduce((acc, key) => {
      if (excerptIds.includes(key)) {
        acc[key] = excerpts[key];
      } 
      return acc;
    }, {});
}

// gets snipppet id from markdown link that looks like [1](#Snippet_20)
const getSnippetIdFromLink = (href) => {
    if (!href) return null;
    const match = href.match(SNIPPET_PATTERN);
    
    // return snippet id if match found
    return match ? match[1] : null;
}

export default citationCreation
export { extractSnippetIds, getSnippetIdFromLink, filterExcerptsByContent }

