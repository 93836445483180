// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';
import ColorConstants from 'constants/ColorConstants'
import {DropdownButton, MenuItem, Button, Glyphicon} from 'react-bootstrap'
import GlyphBorder from './GlyphBorder'

const BORDER_RADIUS = "5px"

class GlyphDropdown extends React.Component {
  constructor () {
    super();
    this.state = {
      show: false
    }

    this.setShow = this.setShow.bind(this);
  }

  setShow(show) {
    this.setState({
      show
    })
  }

  render () {
    return (
      <GlyphBorder show={this.props.show || this.state.show} backgroundFill={this.state.show} large>
        <div style={{paddingTop:2, paddingRight:0}}>
          <DropdownButton
            bsStyle="link"
            title={<Glyphicon style={{color:ColorConstants.LIGHT_GREY}} glyph='pencil'/>}
            id={1}
            style={{float:"right", width:"100%", height:"100%", padding:"0 4 0 0", margin:0}}
            onClick={()=>this.setShow(true)}
            onBlur={()=>this.setShow(false)}
            noCaret
            pullRight
          >
            <MenuItem eventKey="1" onClick={this.props.seeDetails}>See Details</MenuItem>
            <MenuItem eventKey="2" onClick={this.props.onRename}>Rename</MenuItem>
            <MenuItem className='delete-code' eventKey="3" onClick={this.props.deleteCode}>Delete</MenuItem>
          </DropdownButton>
        </div>
      </GlyphBorder>
    );
  }
}


export default GlyphDropdown;
