// app/javascript/projects/components/ProjectsDisplay.jsx

import React, { useState } from 'react';
import {Button} from 'react-bootstrap'

import ExcerptConstants from 'constants/ExcerptConstants';
import MemoList from './MemoList';
import MemoInput from './MemoInput'
import ModalDivider from 'projects/components/modal/ModalDivider';

function Thread(props) {
  const buttonText = props.memosToSee === 1 ? 
    'See Previous Memo' : `See Previous ${props.memosToSee} Memos`

  return (
    <div className="thread">
      {(props.memos || []).length > 0 &&
        <div>
          <ModalDivider/>
          <div style={{
            marginLeft: ExcerptConstants.PADDING,
            marginRight: ExcerptConstants.PADDING,
            marginBottom: '20px',
            marginTop: '20px',
          }}>
            {props.memosToSee > 0 &&  
              <div style={{
              }}>
                <Button 
                  style={{
                    padding: 0,
                    margin: 0,
                    fontSize: '.8em'
                  }}
                  bsStyle='link'
                  onClick={props.increaseMemoCount}
                >
                  {buttonText}
                </Button>
              </div>
            }
            <div style={{
              marginTop: props.memosToSee > 0 ? '0px' : '25px'
            }}>
              <MemoList
                memos={props.memos} 
                memoExpanded={props.memoExpanded}
                expandedMemoList={props.expandedMemoList}
                hasEditPermission={props.hasEditPermission}
              />
            </div>
          </div>
        </div>
      }
      {props.hasEditPermission && 
        <MemoInput
          incrementAddedMemoCount={props.incrementAddedMemoCount}
          excerpt_id={props.excerpt_id}
          initials={props.initials}
          color={props.userColor}
          askAI={props.askAI}
          featureFlagAICoding={props.featureFlagAICoding}
        />
      }
    </div>
  );
}

export default Thread;