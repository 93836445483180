// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';
import ParagraphContainer from './ParagraphContainer';
import Scrollbars from 'react-custom-scrollbars';
import UserDropdown from './UserDropdown';
import PagerWrapper from './PagerWrapper';
import ShareToCompare from './ShareToCompare';

import {
  TRANSCRIPT_NAVIGATION_PADDING
} from 'constants/DimensionsConstants'

const PADDING = `${TRANSCRIPT_NAVIGATION_PADDING}px`

const tabStyle = {
  paddingLeft: PADDING,
  margin: "0px"
}

class ComparisonViewSolo extends React.Component {
  render () {
    const codedTranscript = this.props.codedTranscript || {paragraphs: []};

    const paragraphItems = codedTranscript.paragraphs.map((paragraph, index) =>
    {
        if ( !paragraph || (paragraph.start === paragraph.end)) return null;

        return (
              <ParagraphContainer codedParagraph={paragraph}
                                  absoluteIndex={paragraph.index}
                                  paragraphIndex={index}
                                  transcriptID={this.props.transcriptID}
                                  canEdit={false}
                                  isPrint={true}
                                  hasEditPermission={false}
                                  marginLeft={PADDING}
                                  marginRight="20px"
                                  id={index}
                                  key={index}
                                  />
        );
      }
    );


    return (
      <div style={{display: 'flex', height:"100%"}}>
        <Scrollbars
          autoHide
          style={{height:"100%", flex:1}}
          ref={(ref)=>{this.scrollbar=ref}}
        >
          <PagerWrapper pageCount={this.props.pageCount} onPage={this.props.onPage}>
            <div style={{paddingTop: '15px', paddingBottom: '15px'}} >
              <div className="coded-by" style={tabStyle}>
                <UserDropdown
                  users={this.props.users}
                  onSelect={this.props.onLeftUserSelect}
                  user={this.props.selectedUsers.leftUser}
                />
              </div>
            </div>
            <div style={{marginTop:'10px'}}>
              <div>
                {paragraphItems}
              </div>
            </div> 
          </PagerWrapper>
        </Scrollbars>
        <div style={{flex: 1}}>
          <ShareToCompare
            onShowCollabModal={this.props.onShowCollabModal}
          />
        </div>
      </div>

    );
  }
}

export default ComparisonViewSolo;
