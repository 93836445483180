// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';
import QualCodeActions from 'actions/QualCodeActions'
import {Button} from 'react-bootstrap'
import DescriptorComponent from './DescriptorComponent'
import { Scrollbars } from 'react-custom-scrollbars';

var _ = require('underscore');


class EditDemographicView extends React.Component {
  constructor () {
    super();
    this.state = {
      createDescriptor: false
    }
    this.addDescriptor = this.addDescriptor.bind(this);
    this.updateDescriptor = this.updateDescriptor.bind(this);
    this.createDescriptor = this.createDescriptor.bind(this);
    this.deleteDescriptor = this.deleteDescriptor.bind(this);
    this.createSelection = this.createSelection.bind(this);
    this.addAndSelectChoice = this.addAndSelectChoice.bind(this);
  }

  addAndSelectChoice(descriptorID, choiceText)
  {
    const transcriptID = this.props.transcriptID;
    QualCodeActions.createMultiChoiceAndSelect(transcriptID, descriptorID, choiceText);
  }

  createSelection(choiceID)
  {
    const transcriptID = this.props.transcriptID;
    if ( !transcriptID ) return;
    QualCodeActions.createMultiChoiceSelection(transcriptID, choiceID);
  }

  updateDescriptor(id, name, newPayload, updatePayload, deletePayload)
  {
    QualCodeActions.updateDescriptor(id,
                                      name,
                                      newPayload,
                                      updatePayload,
                                      deletePayload
                                    );
  }

  createDescriptor(id, name, newPayload, updatePayload, deletePayload)
  {
    const projectID = this.props.projectID;
    if ( projectID )
    {
      QualCodeActions.createDescriptor(projectID, name, newPayload, updatePayload, deletePayload);
      this.setState({
        createDescriptor: false
      })
    }
  }

  deleteDescriptor(descriptorID)
  {
    if ( descriptorID )
    {
      const projectID = this.props.projectID;
      if ( projectID )
        QualCodeActions.deleteDescriptor(projectID, descriptorID);
    }
    this.setState({
      createDescriptor: false
    })
  }

  addDescriptor(e)
  {
    this.setState({
      createDescriptor: true
    })

    this.scrollToBottom();
  }

  scrollToBottom () {
    if ( this.messagesEnd )
    {
      this.messagesEnd.scrollIntoView({ behavior: "smooth" });
    }
  }

  render () {
    const descriptorElements = this.props.descriptors.map((descriptor, index) =>
      {
        return <DescriptorComponent doneEditingCallback={this.updateDescriptor}
                                    key={descriptor.id}
                                    descriptor={descriptor}
                                    deleteCallback={this.deleteDescriptor}
                                    createSelectionCallback={this.createSelection}
                                    addAndSelectChoiceCallback={this.addAndSelectChoice}
                                    />
      });

    return (
        <div style={{padding:"10px", height:"100%"}}>
          <Scrollbars autoHide style={{height:"100%"}}>
            <p>
              Add demographics and descriptors to the transcript in order to filter by it later.
            </p>
              {descriptorElements}

              {this.state.createDescriptor &&
                <DescriptorComponent descriptor={null}
                                      doneEditingCallback={this.createDescriptor}
                                      deleteCallback={this.deleteDescriptor}
                                      addAndSelectChoiceCallback={this.addAndSelectChoice}/>}

              <div style={{height:"200px", width:"10px"}}/>

                <div style={{ float:"left", clear: "both" }}
                     ref={(el) => { this.messagesEnd = el; }}>
                </div>
          </Scrollbars>



            <div style={{
              position: "absolute",
              left: "20px",
              bottom: "40px",
              zIndex: 10,
            }}>
              <Button style={{
                  position: "relative",
                  width: "280px"
                }} bsStyle="blue" onClick={this.addDescriptor}><span style={{margin:"10px 50px"}}>Add Descriptor</span></Button>
            </div>
        </div>
    );
  }
}

export default EditDemographicView;
