// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';
import {Modal, Alert} from 'react-bootstrap'
import HelpLink from './collab/HelpLink';
import CodedTranscriptStore from 'stores/CodedTranscriptStore'
import ProjectsStore from 'stores/ProjectsStore'
import ModalDivider from './modal/ModalDivider';


const PARAGRAPH_STYLE = {
  marginBottom: '20px'
}

// react function for Q&A
function QandA (props) {
  return (
    <div style={PARAGRAPH_STYLE}>
      <h4>
        {props.header}
      </h4>

      <div>
        {props.children}
      </div>
    </div>
  )
}

class InterCoderModal extends React.Component {
  constructor () {
    super()
    this.state = {
      interCoderScore: '...'
    }  
  }

  // caluclate interCoderScore when props.show updates to true
  componentDidUpdate(prevProps) {
    if (this.props.show !== prevProps.show && !!this.props.show) {
      const results = CodedTranscriptStore.calculateInterCoderReliability(this.props.transcriptID)
      const transcript = ProjectsStore.getTranscript(this.props.transcriptID)

      this.setState({
        interCoderScore: results.score,
        numberOfUsers: results.user_count,
        name: (transcript || {}).name || null,
      })
    }
  }

  interCoderNotANumber () {
    return isNaN(this.state.interCoderScore) || !this.state.interCoderScore;
  }

  getScore () {
    // if score is NaN or undefined, return 'No Score'
    if (this.interCoderNotANumber()) {
      return 'No Score'
    }

    return this.state.interCoderScore
  }

  getInterCoderErrorMessage() {
    if (this.interCoderNotANumber()) {
      // if the user count is 1 or 0 explain why there is no score
      if ( this.state.numberOfUsers <= 1 ) {
        return "We are unable to provide an inter-coder reliability score because at least two coders must code this transcript."
      } else {
        // there are not enough overlapping coded segments to calculate intercoder reliability
        return "We are unable to provide an inter-coder reliability score due to limited overlap in transcript coding. Code more to obtain a meaningful score."
      }
    }
  }

  render () {
    const errorMessage = this.getInterCoderErrorMessage()

    return (
      <div>
        <Modal
          show={this.props.show}
          onHide={this.props.onHide}
          bsSize='large'
        >
          <Modal.Body>
            <div style={{padding: '20px 45px'}}>
              <div style={PARAGRAPH_STYLE}>
                {!!errorMessage && 
                  <Alert bsStyle="info">
                    {errorMessage}
                  </Alert>
                }
                <h1>
                  {this.getScore()}
                </h1>
                <h3>
                  Intercoder Reliability Score
                </h3>
                <div style={{}}>
                  For how your team coded {`"${this.state.name}"` || 'this transcript'}
                </div>
              </div>

              <div style={{paddingBottom: '15px'}}>
                <ModalDivider/>
              </div>
              <QandA header="What does this score mean?">
                Your intercoder reliability score is a measure of how similarly your team is applying codes to this transcript.
              </QandA>

              <QandA header="How is this score calculated?">
                The score is calculated using <a target="_blank" rel="noopener noreferrer" href="https://en.wikipedia.org/wiki/Krippendorff%27s_alpha">Krippendorff's Alpha</a>.
                 This is a standard measure of intercoder reliability.
                 The score is calculated on a transcript level and takes into account each person who coded transcript.
                 Anyone who did not code this transcript is not included in the score.
              </QandA>

              <QandA header="What is a good score?">
                The score will range between -1 and 1. 
                A score of 0 is considered no better than chance, and 1 is considered full agreement.
                While there is no hard and fast rule, a score of 0.8 or higher is often considered good.
              </QandA>

              <QandA header="Should I use this score?">
                Not necessarily, not all qualitative researchers believe the use of intercoder reliability 
                should be applied universally to all team projects. While some researchers use this score to 
                determine if their team is coding consistently, it is not an irrefutable measurement. Intercoder reliability 
                tends to be more helpful for deductive methodologies with pre-defined codebooks. However, for 
                inductive methodologies, it can have a suppressive effect on the generation of novel theories and insights.
              </QandA>

              <div style={PARAGRAPH_STYLE}>
                <HelpLink
                  intercomArticle={process.env['INTERCOM_ARTICLE_INTERCODER_RELIABILITY']}
                  glyph="book"
                >
                  Learn more about Delve's Intercoder Reliability Score
                </HelpLink>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default InterCoderModal;
