import InitialState from 'constants/InitialState'

import {
  REFER_START,
  REFER_SEND,
  REFER_DISMISS
} from 'constants/ReferConstants'
import AccountPageConstants from 'constants/AccountPageConstants'

function referReducer(action, state = InitialState)
{
  if ( !action ) return state;

  switch (action.actionType)
  {
    case REFER_START:
    {
      return {
        ...state,
        teamModal: {
          page: AccountPageConstants.REFER_PAGE
        }
      }
    }
    break;
    case REFER_SEND: {
      return {
        ...state,
        teamModal: {
          page: AccountPageConstants.REFER_SENT
        }
      }
    }
    break;
    case REFER_DISMISS: {
      return {
        ...state,
        teamModal: {
          page: null
        }
      }
    }
    break;
    default:
      return state;
  }
}

export default referReducer
