// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';
import {Link} from 'react-router-dom'
import MemoTag from 'memos/MemoTag'

import CodeTag from './CodeTag'

import ColorConstants from 'constants/ColorConstants'
import ExcerptConstants from 'constants/ExcerptConstants'

class ExcerptBody extends React.Component {
  constructor () {
    super();
    this.onClick = this.onClick.bind(this);
    this.onLinkClick = this.onLinkClick.bind(this);
    this.onCodeRemove = this.onCodeRemove.bind(this);
  }

  onCodeRemove(codeID) {
    this.props.onCodeRemove(codeID, this.props.excerpt.id);
  }

  onLinkClick(e)
  {
    if ( this.props.onLinkClick )
      this.props.onLinkClick(this.props.excerpt.id);
  }

  onClick (e)
  {
    this.props.onTextClicked(this.props.excerpt.id);

    if (!e)
      e = window.event;

    //IE9 & Other Browsers
    if (e.stopPropagation) {
      e.stopPropagation();
    }
    //IE8 and Lower
    else {
      e.cancelBubble = true;
    }
  }


  render () {
    var style = {};
    if ( this.props.selected )
      style = {backgroundColor: ColorConstants.HIGHLIGHT_COLOR};
    else if (this.props.hover)
      style = {backgroundColor: ColorConstants.HOVER_COLOR};

    const excerpt = this.props.excerpt ? this.props.excerpt : {};
    const excerptText = excerpt.text ? excerpt.text : "";
    var text = excerptText.split('\n').map((paragraph, index) =>
      <p key={index}><span style={style}>{paragraph}</span></p>
    );

    var codes = this.props.excerpt.codes.map((code, index) =>{
      return <CodeTag
          id={code.id}
          server_id={code.server_id}
          name={code.name}
          codePage={this.props.codePage}
          key={index}
          projectID={this.props.projectID}
          onCodeRemove={this.onCodeRemove}
          selected={this.props.selected}
          hasEditPermission={this.props.hasEditPermission}
          onClick={this.props.onCodeClick}
          coders={code.coders}
          inExcerpt={true}
        />
    })  

    const textDiv = this.props.disabled || !this.props.hasEditPermission ?
                      <div style={{cursor:"default"}}> {text} </div> :
                      <div style={{cursor:"pointer"}}
                        onMouseLeave={this.props.onMouseLeave}
                        onMouseEnter={this.props.onMouseEnter}
                        onClick={this.onClick}
                      > {text} </div>;
    // css styling where all padding is 30 except bottom which is 20


    return (
      <div style={{
        marginTop: ExcerptConstants.PADDING,
        marginBottom: '20px',
        marginLeft: ExcerptConstants.PADDING,
        marginRight: ExcerptConstants.PADDING,
      }}>
        <p style={{fontSize:"0.8em"}}>
          <Link onClick={this.onLinkClick} to={`/transcripts/${this.props.excerpt.transcript_id}?snippet=${this.props.excerpt.server_id}`}>
            <span style={{
              color:ColorConstants.ACTIVE_BUTTON_BLUE,
              display: 'inline-block',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              maxWidth: '90%'
            }}>
              <strong>
                {this.props.excerpt.transcript.name}
              </strong>
            </span>
          </Link>
        </p>
        {textDiv}

        <div style={{wordWrap: 'break-word', display: "flex"}}>
          <div style={{flexGrow: 1, maxWidth: '90%'}}>
            {codes}
          </div>
          
          <div style={{marginTop: "3px", marginLeft: 'auto'}}>
            <MemoTag
              onClick={this.props.expandMemos}
              count={this.props.memoCount}
              show={this.props.showMemoIndicator}
              selected={this.props.selected}
            />
          </div>
        </div>
      </div>
    )
  }
}

export default ExcerptBody;
