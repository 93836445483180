import API from 'apis/API'

var ArchiveApi = {
  // Archive Project
  archiveProject: function(projectId) {
    return API.post('/api/projects/' + projectId + '/project_archives')
  },

  unarchiveProject: function(projectId) {
    return API.delete('/api/projects/' + projectId + '/project_archives/' + projectId)
  }
};

export default ArchiveApi;
