// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';
import {
  ULTRA_LIGHT_GREY,
  LIGHT_GREY,
  ACTIVE_BUTTON_BLUE,
} from 'constants/ColorConstants';

function AtMentionMemo(props) {
  // if props.text starts with @ai, then format with at mention, otherwise just return text
  // @ai is the only at mention we support for now
  if (!props.text.startsWith('@ai_assistant')) {
    return props.text;
  }

  const textWithoutAtMention = props.text.substring(13);

  return (<div><span style={{
    backgroundColor: ULTRA_LIGHT_GREY,
    color: ACTIVE_BUTTON_BLUE,
    borderRadius: '5px',
    padding: '2px',
  }}>@AI Assistant</span> {textWithoutAtMention}</div>)

}

export default AtMentionMemo;
