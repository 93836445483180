// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';
import AccountSingleButton from '../AccountSingleButton'
import CancelConstants from './CancelConstants'
import {Radio, FormGroup} from 'react-bootstrap'
import {
  CANCEL_SUBSCRIPTION
} from 'constants/AccountPageConstants'
const _ = require('underscore')

const _OPTIONS = [
  {
    name: "Finished my research",
    type: CancelConstants.FINISHED
  },
  {
    name: "No research project at this time",
    type: CancelConstants.NO_RESEARCH
  },
  {
    name: "Missing features",
    type: CancelConstants.FEATURES
  },
  {
    name: "Too expensive",
    type: CancelConstants.PRICE
  },
  {
    name: "Technical Issues",
    type: CancelConstants.TECHNICAL
  },
  {
    name: "Need help",
    type: CancelConstants.HELP
  },
  {
    name: "Found another solution",
    type: CancelConstants.OTHER_SOLUTION
  },
  {
    name: "Not right for me",
    type: CancelConstants.NOT_RIGHT
  },
];

const OTHER = {
  name: "Other",
  type: CancelConstants.OTHER
};

const OPTIONS = [..._.shuffle(_OPTIONS), OTHER]

class PauseSurvey extends React.Component {
  constructor () {
    super();
    this.state = {
    };

    this.onChange = this.onChange.bind(this);
  }

  onChange(e) {
    this.props.onChecked(e.target.value)
  }

  render () {
    return (
      <div>
        <AccountSingleButton
          onClick={this.onNext}
          loading={false}
          header="We are sorry to see you go"
          button="Continue"
          hideTerms
          disabled={!this.props.checked}
          onClick={this.props.onNext}
          onSubButton={this.props.onCancel}
          subButton="Nevermind"
          body={<div>
            <p>
                Could you please tell us why you are leaving?
            </p>
            <div style={{textAlign: 'left', padding: '5px'}}>
              <form>
                <FormGroup
                  controlId="churnSurveyId"
                  onChange={this.onChange}
                  >
                  {OPTIONS.map((option)=>{
                    return (
                      <Radio readOnly value={option.type} key={option.type} checked={this.props.checked === option.type}>
                        {option.name}
                      </Radio>
                    )
                  })}
                </FormGroup>
              </form>
            </div>
          </div>}
          />
      </div>
    );
  }
}

export default PauseSurvey;
