export default {
  collapseCode: (state, projectID, codeID, collapseName, collapse) =>{
    const mappedCodeID = state.mappers.codes[codeID];
    const collapseObject = state[collapseName] ? state[collapseName] : {};
    const projectCollapse = collapseObject[projectID] ? collapseObject[projectID] : {};

    return {
      ...state,
      [collapseName]:
      {
        [projectID]:
        {
          ...projectCollapse,
          [mappedCodeID]: !!collapse,
        }
      }
    }
  },
  getCodeObject: (state, projectID, collapseName, newCodeObject) =>{
    var clientCodeBar = {};

    Object.keys(newCodeObject).forEach((codeID)=>{
      const clientCodeID = state.mappers.codes[codeID] ? state.mappers.codes[codeID] : codeID;
      clientCodeBar[clientCodeID] = newCodeObject[codeID];
    });


    return {
      ...state,
      [collapseName]:
      {
        ...state[collapseName],
        [projectID]:clientCodeBar,
      }
    }
  }
}
