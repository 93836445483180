import React, { useState, useRef, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import Excerpt from 'projects/components/Excerpt';

const Citation = ({ citationRef, onClick, citationNumber, excerpt, onTextClicked, selected }) => {
    const thisClick = (e) => {
        if ( e ) {
            e.preventDefault(); // Prevent the default link action
            e.stopPropagation(); // Prevent the event from bubbling up
        }

        onClick()
    }

    return (
        <div ref={citationRef}>
            <Button bsStyle="link" onClick={thisClick}>
                {citationNumber}^
            </Button>
            <Excerpt
                excerpt={excerpt}
                onTextClicked={onTextClicked}
                hasEditPermission={true}
                selected={selected}
            />
        </div>
    )
};

export default Citation;