// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';
import {Button} from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap';
import UserDisplaySectionContainer from './UserDisplaySectionContainer'
import QualCodeActions from 'actions/QualCodeActions';

function DataComponent(props) {
  return (
    <div>
      <UserDisplaySectionContainer header="Data">
        <LinkContainer to='/users/archived'>
          <Button bsStyle='blue' onClick={QualCodeActions.loadProjects}>View archived projects</Button>
        </LinkContainer>
      </UserDisplaySectionContainer>
    </div>
  );
}

export default DataComponent;
