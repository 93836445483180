// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';
import BillingModalGenericBlocker from './BillingModalGenericBlocker'


class BillingModalBlocker extends React.Component {
  constructor () {
    super();
  }

  getText(isSubscriptionOwner, isSeparatelyInvoiced) {
    if ( isSeparatelyInvoiced ) {
      if ( isSubscriptionOwner ) {
        return "Your Delve subscription has expired. Please contact the Delve team to renew it."
      } else {
        return "Your Delve subscription has expired. Contact your team owner to renew it, or start your own subscription."
      }
    } else {
      if ( isSubscriptionOwner ) {
        return "Please renew your subscription to access this project.";
      } else {
        return "Please start your subscription to access this project."
      }
    }

  }

  render () {
    const subscription = this.props.subscription || {};
    const isSeparatelyInvoiced = subscription.isSeparatelyInvoiced;
    const text = this.getText(this.props.isSubscriptionOwner, isSeparatelyInvoiced)

    return (
      <BillingModalGenericBlocker
        onClick={isSeparatelyInvoiced ? null : this.props.onClick}
        text={text}
        header="Delve Subscription Required"
        isSubscriptionOwner={this.props.isSubscriptionOwner}
      />
    );
  }
}

export default BillingModalBlocker;
