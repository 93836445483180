module.exports = {
                    // Get Code Excerpts
                    FILTER_CODE_EXCERPTS: "FILTER_CODE_EXCERPTS",
                    FILTER_CODE_EXCERPTS_RESULT: "FILTER_CODE_EXCERPTS_RESULT",
                    FILTER_CODE_EXCERPTS_ERROR: "FILTER_CODE_EXCERPTS_ERROR",
                    FILTER_CODE_NEXT: "FILTER_CODE_NEXT",
                    // Code report
                    CODE_REPORT: "CODE_REPORT",
                    CODE_REPORT_RESULT: "CODE_REPORT_RESULT",
                    CODE_REPORT_ERROR: "CODE_REPORT_ERROR"
                  }
