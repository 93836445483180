// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';

import NewTranscript from './NewTranscript'
import OnboardingTranscript from './OnboardingTranscript'
import OnboardingActions from 'actions/OnboardingActions'
import TranscriptActions from 'actions/TranscriptActions'
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom'

class NewTranscriptContainer extends React.Component {
  constructor () {
    super();
    this.state = {
      redirect: null,
      cachedProjectID: null,
    };

    this.getStartedClick = this.getStartedClick.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(name, body) {
    const projectID = this.props.projectID;
    if ( ! projectID ) return;

    TranscriptActions.createTranscript({
      project_id:projectID,
      body: body,
      name: name
    }).then((data)=>{
      if ( !data ) return;
      const transcript = data.transcript || {};
      if ( !transcript.id ) return;
      this.setState({
        redirect: transcript.id
      })
    });
  }

  static getDerivedStateFromProps(nextProps, prevState)
  {
    if ( nextProps.projectID != prevState.cachedProjectID )
    {
      return {
        ...prevState,
        cachedProjectID: nextProps.propertyID,
      }
    }

    return null;
  }

  getStartedClick (e)
  {
    OnboardingActions.getStarted();
  }

  render () {
    if ( !this.props.onboardingState.newTranscriptOnboarding )
    {
      if ( !this.props.onboardingState.loaded )
      {
        return (<div></div>)
      }
      else {
        return (
          <div>
            {this.state.redirect &&
              <Redirect to={"/transcripts/" + this.state.redirect} />}
            <NewTranscript
                height={this.props.height}
                isHover={this.props.isHover}
                handleSubmit={this.handleSubmit}/>
          </div>
        );
      }
    }
    else {
      return (<OnboardingTranscript onClick={this.getStartedClick}/>);
    }
  }
}

NewTranscriptContainer.propTypes = {
  projectID: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired
}

export default NewTranscriptContainer;
