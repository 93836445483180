var _ = require('underscore');
import {
  FILES__TOO_BIG,
  FILES__COUNT_LIMIT,
  FILES__TYPE_UNSUPPORTED
} from 'constants/ErrorConstants'

import dragDropMessageMapper from './DragDropMessageMapper'
import dragDropCountMapper from './DragDropCountMapper'

const dragDropMapper = (state) => {
  const files = Object.keys(state.dragDrop.files).
                map((id)=>state.dragDrop.files[id]).
                filter((file)=>!!file);

  const {
    errorFileLength,
    loadedFileLength,
    fileLength,
    loading
  } = dragDropCountMapper(files);

  const messages = dragDropMessageMapper(fileLength, loadedFileLength, errorFileLength) || {};

  const errors = _.uniq(files.map((file)=>{
    const error = file.error || {};
    return error.type || null;
  }));

  const hasTooBig = errors.includes(FILES__TOO_BIG.name);
  const errorType = state.dragDrop.error && state.dragDrop.error.type ? state.dragDrop.error.type : false;

  return {
    show: state.dragDrop.show && files.length > 0 && !state.dragDrop.error,
    hasTooBig: hasTooBig,
    errorParsing: errorType,
    files: files,
    loading: loading,
    ...messages
  }
}

export default dragDropMapper
