function mergeExcerptsReducer(state, normalizedExcerpts)
{
  var newExcerpts = {};
  var newMapper = {};
  normalizedExcerpts = normalizedExcerpts ? normalizedExcerpts : {};

  Object.keys(normalizedExcerpts).map(function(key, index) {
    const newExcerpt = normalizedExcerpts[key];
    const clientID = state.mappers.excerpts[newExcerpt.id] ? state.mappers.excerpts[newExcerpt.id] : newExcerpt.id;
    const originalExcerpt = clientID ? state.entities.excerpts[clientID] : null;

    newExcerpts[clientID] = {
      ...originalExcerpt,
      ...newExcerpt,
      id: clientID,
      server_id: newExcerpt.id,
    }

    newMapper[newExcerpt.id] = newExcerpt.id;
  });

  return {
    entities: {
      excerpts: {
        ...state.entities.excerpts,
        ...newExcerpts
      }
    },
    mappers:
    {
      excerpts: {
        ...newMapper,
        ...state.mappers.excerpts,
      }
    }
  }
}

export default mergeExcerptsReducer
