import React from 'react';
import {Button} from 'react-bootstrap'
import ColorConstants from 'constants/ColorConstants'
import {Link} from 'react-router-dom'
import HelpLink from './collab/HelpLink'

import {
  ACCOUNT_WIDTH
} from 'constants/DimensionsConstants'

const GenericOnboardingMessage = ({header, body, secondBody, buttonText, to, onClick, intercomArticleId, helpArticleText}) => {

  const handleClick = (e) => {
    if (onClick) {
      onClick();
    }
  }

  let button;

  if (to) {
    button = <Link to={to} onClick={handleClick}>
      <Button bsStyle="blue">
        {buttonText}
      </Button>
    </Link>
  } else if (onClick) {
    button = <Button bsStyle="blue" onClick={handleClick}>
      {buttonText}
    </Button>
  }

  return (
    <div style={{textAlign:"center",
                  maxWidth:ACCOUNT_WIDTH,
                  margin:"0px auto",
                  padding:"20px 0px",
                  fontFamily: "Libre Baskerville, serif",
                  color: ColorConstants.LIGHT_GREY}}>
      <h3>
        {header}
      </h3>
      <p style={{fontSize:"1.2em", marginTop:"30px"}}>
        {body}
      </p>
      {secondBody && <p style={{fontSize:"1.2em", marginTop:"30px"}}>
        {secondBody}
      </p>}

      {button &&
        <div style={{marginTop:"30px"}}>
          {button}
        </div>
      }

      {intercomArticleId  &&
        <div style={{marginTop: '30px'}}>
          <HelpLink intercomArticle={intercomArticleId}>
            {helpArticleText}
          </HelpLink>
        </div>      
      }
    </div>
  );
}

export default GenericOnboardingMessage;