// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';
import {
  CODE_LABEL_HEIGHT,
  CODE_LABEL_WIDTH
} from 'constants/DimensionsConstants'
import QualCodeActions from 'actions/QualCodeActions'
import CodeLabelBody from './CodeLabelBody'
import CodeLabelRight from './CodeLabelRight'
import CodeLabelStateConstants from 'constants/CodeLabelStateConstants'

const FULL_HEIGHT = (CODE_LABEL_HEIGHT).toString() + "px";
const MERGE_HEIGHT = (CODE_LABEL_HEIGHT * 2).toString() + "px";

class CodeLabel extends React.Component {
  constructor () {
    super();
    this.state = {
      editableName: "",
      hover: false,
      mergeHover: false
    };
    this.onBlur = this.onBlur.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onRename = this.onRename.bind(this);
    this.seeDetails = this.seeDetails.bind(this);
    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
    this.deleteCode = this.deleteCode.bind(this);
    this.onCollapseClicked = this.onCollapseClicked.bind(this);
    this.onHide = this.onHide.bind(this);
    this.confirmMerge = this.confirmMerge.bind(this);
    this.onMergeHover = this.onMergeHover.bind(this);
  }

  onMergeHover(isOver) {
    this.setState({
      mergeHover: !!isOver
    })

    this.props.onShowMerge(!!isOver ? this.props.id : null)
  }

  onCollapseClicked(e)
  {
    //IE9 & Other Browsers
    e.preventDefault();
    if (e.stopPropagation) {
      e.stopPropagation();
    }
    //IE8 and Lower
    else {
      e.cancelBubble = true;
    }
    this.props.onCollapseClicked(this.props.id, !!this.props.barCollapsed);
  }



  onMouseEnter(e)
  {
    this.setState({
      hover: true
    })
  }

  onMouseLeave(e)
  {
    this.setState({
      hover: false
    })
  }

  seeDetails(e)
  {
    if ( this.props.id )
    {
      QualCodeActions.showCodePageModal(this.props.id);
    }
  }

  onRename (e) {
    window.requestAnimationFrame(() => {
      this.props.onRename();
    })
  }

  onBlur (e) {
    this.props.renameCode(this.props.id, this.state.editableName);
  }

  onFocus(e) {
    this.setState({
      editableName: this.props.name
    })
    e.target.value = '';
    e.target.value = this.props.name;
  }

  onChange(e) {
    this.setState({
      editableName: e.target.value
    })
  }

  _getCodeName (newCode, codeName) {
    return newCode ? "Add Code: " + codeName : codeName;
  }

  deleteCode(e) {
    if ( this.props.id )
      this.props.deleteCode({
        id: this.props.id,
        name: this.props.name
      });
  }

  getCodeState() {
    if (this.state.mergeHover || this.props.showConfirmMerge) {
      if ( this.props.disabled )
        return CodeLabelStateConstants.CODE_MERGING_DISABLED;

      return CodeLabelStateConstants.CODE_MERGING_ENABLED;
    }

    if ( this.props.ghost )
      return CodeLabelStateConstants.CODE_GHOSTED;
    if ( this.props.selected )
      return CodeLabelStateConstants.CODE_SELECTED;
    if ( this.props.disabled )
      return CodeLabelStateConstants.CODE_DISABLED;

    return CodeLabelStateConstants.CODE_ENABLED;
  }

  confirmMerge(e) {
    this.props.confirmMerge();
  }

  onHide() {
    this.props.cancelMerge();
  }

  isCollapsible () {
    return this.props.childrenCount > 0 && !this.props.ghost && !this.props.isSearching;
  }

  render () {
    const codeName = this._getCodeName(this.props.newCode, this.props.name);
    const showCollapseGlyph = this.isCollapsible();

    return (
      // width was + 43 originally, now I changed it to 60. But that was really haphazzard
      <div style={{height:this.state.mergeHover || this.props.showConfirmMerge ?
          MERGE_HEIGHT: FULL_HEIGHT, width:(CODE_LABEL_WIDTH + 45)}}
            onMouseEnter={this.onMouseEnter}
            onMouseLeave={this.onMouseLeave}
        >
          <CodeLabelBody
            fadeIn={this.props.fadeIn}
            name={this.state.mergeHover ? this.props.mergeName : codeName }
            count={this.props.count}
            showMerge={!!this.props.showMerge}
            edit={this.props.renameMode}
            mergeParent={this.state.mergeHover /*replace this with code state*/}
            dragOver={this.props.dragOver /*replace this with code state*/}
            isDragging={this.props.isDragging /*replace this with code state*/}
            showConfirmMerge={this.props.showConfirmMerge /* replace this as well */}
            showCollapseGlyph={showCollapseGlyph}
            barCollapsed={this.props.barCollapsed}
            onClick={() => this.props.onClick({
              id: this.props.id,
              name: this.props.name
            }, this.props.selected)}
            onCollapseClicked={this.onCollapseClicked}
            onBlur={this.onBlur}
            onFocus={this.onFocus}
            onChange={this.onChange}
            editableName={this.state.editableName}
            ghost={this.props.ghost}
            state={this.getCodeState()}
            allowPopover={this.props.allowDropdown}
            id={this.props.id}
            canDropOnParent={this.props.canDropOnParent}
            searchedCodeUnmounted={this.props.searchedCodeUnmounted}
          />

        <div style={{float:"right"}}>
          <CodeLabelRight
            initiateMergeCode={this.props.initiateMergeCode}
            showMerge={!!this.props.showMerge}
            showConfirmMerge={this.props.showConfirmMerge /* replace this as well */}
            confirmMerge={this.confirmMerge}
            onHide={this.onHide}
            onRename={this.onRename}
            onSeeDetails={this.seeDetails}
            deleteCode={this.deleteCode}
            showDropdown={this.state.hover &&
                          !this.props.newCode &&
                          !this.props.ghost &&
                          this.props.allowDropdown
                        }
            codeId={this.props.id || null}
            onDragHover={this.onMergeHover}
            container={this}
            isOverCode={this.props.isOverCode}
            />
        </div>
      </div>
    );
  }
}

export default CodeLabel;
