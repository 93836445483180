var _ = require('underscore');

const pluralFileString = (fileCount) => fileCount == 1 ? 'file' : 'files';


const dragDropCountMapper = (files) => {
    const errorFileLength = files.filter((file)=>!!file.error).length;
    const loadedFileLength = files.filter((file)=>!!file.loaded).length;
    const fileLength = files.length;
    const loading = fileLength !== (errorFileLength + loadedFileLength);
    return {
      errorFileLength: errorFileLength,
      loadedFileLength: loadedFileLength,
      fileLength: fileLength,
      loading: loading
    }
}

export default dragDropCountMapper
