import EventSource from 'eventsource';

class StreamHandler {
    constructor(onDataReceived, onClose) {
        this.data = '';
        this.stream = null;
        this.onDataReceived = onDataReceived;
        this.onClose = onClose;
    }

    startStream(streamUrl, headers) {
        this.stream = new EventSource(streamUrl, {headers});
        this.stream.onmessage = this.handleMessage.bind(this);
        this.stream.onopen = this.handleOpen.bind(this);
        this.stream.onclose = this.handleClose.bind(this);
        this.stream.onerror = this.handleError.bind(this);
    }

    handleMessage(event) {
        // Append data to the array
        this.data = this.data.concat(event.data.replace(/\\n/g, '\n'));
        if (this.onDataReceived) {
            this.onDataReceived(this.data);
        }
    }

    handleOpen(event) {
        console.log('Stream opened');
    }

    closeStream() {
        this.stream.close();
        if (this.onClose) {
            this.onClose();
        }
    }

    handleClose(event) {
        console.log('Stream closed');
        // Perform any cleanup or state updates here
        this.closeStream();
    }

    handleError(error) {
        console.error('Stream error:', error);
        // Handle the error appropriately
        this.closeStream();
    }

    getData() {
        return this.data;
    }
}

export default StreamHandler;
