// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';
import CollabNavigation from './CollabNavigation'
import {Button, Alert} from 'react-bootstrap'
import LoadingButton from '../LoadingButton'
import Disclaimer from '../Disclaimer'



class CollabModalActivate extends React.Component {
  constructor () {
    super();
  }

  render () {
    const subscription = this.props.subscription || {};

    return (
      <CollabNavigation
        title='Invitation Sent'
      >
        <Alert bsStyle="warning">
          <div>
            <strong>
              {this.props.email} can only view this project,
              because they don't have an active subscription.
            </strong>
          </div>
        </Alert>

        <div style={{
          marginTop: '20px',
          marginLeft: '10px',
          marginRight: '10px',
          marginBottom: '40px',
          textAlign: 'center',
          fontSize: '1em'
        }}>
          Add {this.props.email} to your subscription for an
          additional ${subscription.amount} per {subscription.interval || 'month'} to enable them to edit your project.
        </div>

        <div>
          <LoadingButton
            bsStyle="blue"
            width={300}
            loading={this.props.addingSeat}
            onClick={() => this.props.onAddSeat(this.props.email)}
            centered
          >
            Add to your subscription
          </LoadingButton>
        </div>
        <div style={{textAlign: 'center', marginTop:'5px'}}>
          <Button
            bsStyle="link"
            onClick={this.props.onSkip}
            disabled={!!this.props.addingSeat}
          >
            Continue without adding
          </Button>
        </div>
        <div style={{textAlign: 'center', fontSize: '.8em'}}>
          <Disclaimer>
            By adding an additional team member, you agree to be charged a prorated amount for this month starting on your next billing cycle, and to automatically be charged each month with your updated number of team members until you cancel.
          </Disclaimer>
        </div>
      </CollabNavigation>
    );
  }
}

export default CollabModalActivate;
