// LOAD_PROJECT_DETAILS_RESULT: waiting on this

const onboardingMapper = (state, projectID) => {
  const loadingState = state.loadingState.projectDetails[projectID];
  const loaded = loadingState ? loadingState.loaded : false;
  const project = state.entities.projects[projectID] ? state.entities.projects[projectID] : {};
  const transcripts = project.transcripts ? project.transcripts : [];
  const gotStarted = state.onboardingState.getStarted;

  return {
    loaded: loaded,
    newTranscriptOnboarding: loaded && transcripts.length == 0 && !gotStarted,
    otherOnboarding: loaded && transcripts.length == 0
  };
}

export default onboardingMapper;
