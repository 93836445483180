const { convertArrayToCSV } = require('convert-array-to-csv');
import codeListMapper from './CodeListMapper'
const _ = require('underscore');
import exportProjectNameMapper from './ExportProjectNameMapper'

const exportCodesCSVMapper = (state, projectID) => {
  const {codes} = codeListMapper(state, projectID)
  const header = ['Order of Code in List', 'Nested Level', 'Code Name', 'Number of Snippets', 'Code Description', 'Code URL']

  const codesWithoutIds = codes.map((code)=>_.rest(code))

  const {projectName, exportFileName} = exportProjectNameMapper(state, projectID,'Codes');

  return {
    projectName: exportFileName,
    codeText: convertArrayToCSV(codesWithoutIds, {
      separator: ',',
      header: header
    })
  }
}

export default exportCodesCSVMapper
