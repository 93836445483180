// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';
import {Modal, Button, Alert} from 'react-bootstrap'
import PropTypes from 'prop-types';
import CloseModalButton from './CloseModalButton'

const LEFT_PADDING = 45;

class RowModal extends React.Component {
  render () {
    return (
      <div>
        <Modal bsSize={this.props.bsSize} show={this.props.show} onHide={this.props.onHide}>
            <Modal.Body style={{padding: 0, margin: 0}}>

            <div style={{margin:`15px 15px 15px ${LEFT_PADDING}px`}}>
              <div style={{float:"left", marginTop:'25px'}}>
                {this.props.header}
              </div>

              <CloseModalButton onHide={this.props.onHide}/>

              {this.props.errorMessage &&
                <Alert bsStyle='danger' style={{marginRight:25}}>{this.props.errorMessage}</Alert>
              }
            </div>
            <div style={{clear:"both"}}>
              {this.props.children}
            </div>

              <div style={{margin:25, clear:"both"}}>
                <div style={{float:"right", marginRight:20, marginBottom:15}}>
                  { this.props.buttonAction && 
                    <Button onClick={this.props.buttonAction} bsStyle="blue">{this.props.buttonText}</Button>
                  }
                </div>
                <div style={{clear:"both"}}></div>
              </div>
            </Modal.Body>
        </Modal>
      </div>
    );
  }
}

RowModal.propTypes = {
  buttonText: PropTypes.string.isRequired,
  buttonAction: PropTypes.func,
  header: PropTypes.object.isRequired,
  onHide: PropTypes.func.isRequired,
}

RowModal.defaultProps = {}

export default RowModal;
