// This is a helper function
import { normalize, schema } from 'normalizr';

const plan_schema = new schema.Entity('plans');
const plans_schema = new schema.Entity('plans_schema', {
  plans:[plan_schema]
});

const subscription_schema = new schema.Entity('subscriptions', {
  plan: plan_schema
});

function subscriptionReducer(state, action) {
  const normalizedData = normalize(action.data, subscription_schema);
  const subscriptionID = normalizedData.result;
  if ( !subscriptionID ) return state;

  var subscriptions = state.subscriptionState.subscriptions
                          ? state.subscriptionState.subscriptions.slice() : [];

  const subscription = normalizedData.entities.subscriptions[subscriptionID];
  const existingSubscription = state.entities.subscriptions[subscriptionID] ? state.entities.subscriptions[subscriptionID] : {};

  return {
    ...state,
    entities:{
      ...state.entities,
      subscriptions:{
        // TODO: should currently only have one subscription at a time
        // ...state.entities.subscriptions,
        [subscriptionID]:{
          ...existingSubscription,
          ...subscription,
        }
      },
      plans:{
        ...state.entities.plans,
        ...normalizedData.entities.plans,
      }
    },
    subscriptionState:{
      ...state.subscriptionState,
      subscriptions: [subscriptionID]
    }
  }
}


export default subscriptionReducer;
