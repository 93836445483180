import React, { useEffect, useState, useContext } from 'react';
import TranscriptHeader from './TranscriptHeader';
import QualCodeActions from 'actions/QualCodeActions'
import ProjectContext from 'contexts/ProjectContext';
import ExportActions from 'actions/ExportActions'
import ProjectsStore from 'stores/ProjectsStore'
import {BetaModalProvider, useModal} from '../modal/BetaModalProvider';
import UserContext from 'contexts/UserContext';

const TransriptHeaderWithModal = (props) => {
    // get UserContext

    const userContext = useContext(UserContext);
    const {user} = userContext;
    const { openModal, closeModal, isModalOpen } = useModal();

    useEffect(() => {
        // if deductive modal is not shown, we do not need to show the beta modal
        if ( !props.showDeductiveModal ) {
            closeModal();
            return;
        }

        // if deductive modal is shown, we need to check if the user has the feature flag, if not, show the beta modal
        if (user.featureFlagAICoding === false) {
            openModal();
        } else if (user.featureFlagAICoding === true) { // if the user has the feature flag, the beta modal should not be showing
            closeModal()
        }
    }, [user.featureFlagAICoding, props.showDeductiveModal, openModal, closeModal]);

    const aiCodings = (e) => {
        props.setShowDeductiveModal(true);
    }

    const hideAiCodings = (e) => {
        props.setShowDeductiveModal(false);
    }

    return <TranscriptHeader
                {...props}
                isBetaModalOpen={isModalOpen}
                showDeductiveModal={props.showDeductiveModal && !!user.featureFlagAICoding}
                aiCodings={aiCodings}
                hideAiCodings={hideAiCodings}
            />
}

function _parseParams(match)
{
  if ( !match ) return null;
  const params = match.params;
  if ( !params ) return null;

  return Number(params.transcriptID);
}

const TranscriptHeaderContainer = (props) => {
    const [showDeductiveModal, setShowDeductiveModal] = useState(false);
    const projectContext = useContext(ProjectContext);
    const projectID = ((projectContext || {}).project || {}).id;
    const [showModal, setShowModal] = useState(false);
    const [title, setTitle] = useState('');
    const transcriptID = _parseParams(props.match);

    const _getTranscriptFromParams = (propsInput) =>
    {
      const transcriptID = _parseParams(propsInput.match);
      if ( !transcriptID ) return null;
  
      const transcript = ProjectsStore.getTranscript(transcriptID);
      return transcript;
    }
  
    const _loadState = () => {
        const transcript = _getTranscriptFromParams(props);
        if ( !transcript ) return;

        setTitle(transcript.name);
    }

    useEffect(() => {
        ProjectsStore.addChangeListener(_loadState);
        _loadState(props);

        // Cleanup function
        return () => {
            ProjectsStore.removeChangeListener(_loadState);
        };
    }, [props.match.params.transcriptID]); // Only re-run the effect if props.match.params.transcriptID changes


    const printTranscript = () => {
        window.open(`/transcripts/${transcriptID}/print`);
    }

    const exportTranscript = () => {
        ExportActions.exportTranscript(transcriptID);
    }

    const cancelDelete = (e) =>
    {
        setShowModal(false);
    }

    const deleteModalClicked = (e) =>
    {
        setShowModal(true);
    }
    
    const onTitleChange = (title) => {
        if ( title && title.length > 0 )
        {
          QualCodeActions.updateTranscript(transcriptID, title);
        }
    }

    const confirmDelete = () =>
    {
      const firstTranscript = ProjectsStore.getNextTranscript(transcriptID);
      setShowModal(false)
  
      if ( firstTranscript )
      {
        props.history.push(`/transcripts/${firstTranscript}`);
      }
      else
      {
        props.history.push(`/projects/${projectID}/transcripts/new`)
      }
  
      QualCodeActions.deleteTranscript(transcriptID);
    }
  
    return <BetaModalProvider onReject={()=>{setShowDeductiveModal(false)}}>
        <TransriptHeaderWithModal
            setShowDeductiveModal={setShowDeductiveModal}
            showDeductiveModal={showDeductiveModal}
            onTitleChange={onTitleChange}
            confirmDelete={confirmDelete}
            projectID={projectID}
            cancelDelete={cancelDelete}
            deleteModalClicked={deleteModalClicked}
            showModal={showModal}
            exportTranscript={exportTranscript}
            printTranscript={printTranscript}
            title={title}
            transcriptID={transcriptID}
            subscriptionState={props.subscriptionState}
            {...props}
            />
    </BetaModalProvider>
}

export default TranscriptHeaderContainer;