import {
  DELVE_TIME_REGEXP,
  NAME_REGEX,
  BLANK_REGEX
} from 'utils/FormatterRegExp'

export default (transcriptText) => {

  const regex = new RegExp(`^${BLANK_REGEX}(${NAME_REGEX}):${BLANK_REGEX}\\n${BLANK_REGEX}([a-zA-Z]+)`, 'gm');
 
  const withTimestamp = new RegExp(`^${BLANK_REGEX}(${NAME_REGEX})${BLANK_REGEX}\\((${DELVE_TIME_REGEXP})\\)${BLANK_REGEX}:${BLANK_REGEX}\\n${BLANK_REGEX}(\\w*)`, 'gm')

  const onlyTimestamp = new RegExp(`^\\((${DELVE_TIME_REGEXP})\\)${BLANK_REGEX}:${BLANK_REGEX}\\n${BLANK_REGEX}(\\w*)`, 'gm');

  return transcriptText.replace(
    regex,
    "$1: $2"
  ).replace(withTimestamp,
    "$1: $2 $3"
  ).replace(onlyTimestamp,
    "$1 $2"
  );
}