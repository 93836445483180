// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';
import {Modal, Button, Alert} from 'react-bootstrap'
import QualCodeActions from 'actions/QualCodeActions'
import {FormControl, ControlLabel, FormGroup} from 'react-bootstrap'

class PasswordModal extends React.Component {
  constructor () {
    super();
    this.state = {
      currentPassword: "",
      newPassword: "",
      verifyPassword: "",
    };

    this.onCurrentPasswordChange = this.onCurrentPasswordChange.bind(this);
    this.onNewPasswordChange = this.onNewPasswordChange.bind(this);
    this.onVerifyPasswordChange = this.onVerifyPasswordChange.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onHide = this.onHide.bind(this);
  }

  onCurrentPasswordChange(e)
  {
    this.setState({
      currentPassword: e.target.value
    })
  }

  onSave(e)
  {
      QualCodeActions.changePassword(this.state.currentPassword, this.state.newPassword, this.state.verifyPassword);
  }

  onNewPasswordChange(e)
  {
    this.setState({
      newPassword: e.target.value
    })
  }

  onVerifyPasswordChange(e)
  {
    this.setState({
      verifyPassword: e.target.value
    })
  }

  onHide(e)
  {
    QualCodeActions.showPasswordModal(false);
    this.setState({
      currentPassword: "",
      verifyPassword: "",
      newPassword: "",
    })
  }

  render () {
    return (
      <div>
        <Modal show={this.props.passwordState.show} onHide={this.onHide}>
            <Modal.Header closeButton>
              <Modal.Title>Change Password</Modal.Title>
            </Modal.Header>

            <Modal.Body>

            {(this.props.passwordState.message && !this.props.passwordState.success) &&
              <Alert bsStyle="danger">
                <p>
                  {this.props.passwordState.message}
                </p>
              </Alert>
              }

              <form>
                <FormGroup controlId="currentPassword">
                  <ControlLabel>Current Password</ControlLabel>
                  <FormControl
                    componentClass="input"
                    type="password"
                    placeholder=""
                    value={this.state.currentPassword}
                    onChange={this.onCurrentPasswordChange}
                  />
                </FormGroup>

                <FormGroup controlId="newPassword">
                  <ControlLabel>New Password</ControlLabel>
                  <FormControl
                    componentClass="input"
                    type="password"
                    placeholder=""
                    value={this.state.newPassword}
                    onChange={this.onNewPasswordChange}
                  />
                </FormGroup>

                <FormGroup controlId="verifyPassword">
                  <ControlLabel>Verify Password</ControlLabel>
                  <FormControl
                    componentClass="input"
                    type="password"
                    placeholder=""
                    value={this.state.verifyPassword}
                    onChange={this.onVerifyPasswordChange}
                  />
                </FormGroup>
              </form>
            </Modal.Body>
          <Modal.Footer>
            <Button bsStyle="blue" onClick={this.onSave}>Save</Button>
          </Modal.Footer>
      </Modal>
      </div>
    );
  }
}

export default PasswordModal;
