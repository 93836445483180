import React, { useEffect, useState } from 'react';
import AnalysisCodeContainer from './AnalysisCodeContainer';
import AnalysisStore from 'stores/AnalysisStore';

function SnippetsCodeContainer({ projectID, page, height }) {
    const [selectedExcerptId, setSelectedExcerptId] = useState(null);
    const _onChange = () => {
        const excerpt = AnalysisStore.getSelectedExcerpt(projectID);
        setSelectedExcerptId(excerpt ? excerpt.id : null);
    }

    useEffect(() => {
        AnalysisStore.addChangeListener(_onChange);
        return () => {
            AnalysisStore.removeChangeListener(_onChange);
        }
    }, []);

    return (
        <AnalysisCodeContainer
            projectID={projectID}
            page={page}
            height={height}
            selectedExcerptId={selectedExcerptId}
        />
    );
}

export default SnippetsCodeContainer;