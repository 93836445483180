import React from 'react';
import {Link} from 'react-router-dom'
import ModalRow from 'projects/components/ModalRow'
import dateMapper from 'mappers/DateMapper';

const WRAP_STYLE = {
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis"
};

function ChatRow(props) {

  const {project_id, id, name, created_at, onHide, user_name} = props;
  const {dateString} = dateMapper(created_at);
  /*
    Open can be used to open the native file picker
  */
  return <ModalRow
      row={[
        <div
        style={WRAP_STYLE}
        className='chatRow'
        >
          <Link to={`/projects/${project_id}/chat/${id}`} onClick={onHide}>
            {name}
          </Link>
        </div>,
        <div style={WRAP_STYLE}>{user_name || '-'}</div>,
        <div style={WRAP_STYLE}>{dateString || '...'}</div>,
      ]}
    />
}


export default ChatRow
