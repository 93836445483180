module.exports =  {
  ARCHIVE_PROJECT: 'ARCHIVE_PROJECT',
  ARCHIVE_PROJECT_SUCCESS: 'ARCHIVE_PROJECT_SUCCESS',
  ARCHIVE_PROJECT_ERROR: 'ARCHIVE_PROJECT_ERROR',

  // UNARCHIVE_PROJECT
  UNARCHIVE_PROJECT: 'UNARCHIVE_PROJECT',
  UNARCHIVE_PROJECT_SUCCESS: 'UNARCHIVE_PROJECT_SUCCESS',
  UNARCHIVE_PROJECT_ERROR: 'UNARCHIVE_PROJECT_ERROR'
}
