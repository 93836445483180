// Desc: Modal for deleting a transcript

import React from 'react';

import {Modal, Button} from 'react-bootstrap'

function DeleteTranscriptModal(props) {
  return (
    <Modal show={props.showModal} onHide={props.cancelDelete}>
      <Modal.Header>
        <Modal.Title>Are you sure you want to delete this transcript?</Modal.Title>
      </Modal.Header>
    
      <Modal.Body>All text and coded snippets from this transcript will be deleted.</Modal.Body>
      
      <Modal.Footer>
        <Button onClick={props.cancelDelete}>No Cancel</Button>
        <Button onClick={props.confirmDelete} bsStyle="blue">Yes, Delete It</Button>
      </Modal.Footer>
    </Modal>  
  )
}

export default DeleteTranscriptModal;