import InitialState from 'constants/InitialState'
import OptimisticMapperHelper from 'mappers/OptimisticMapperHelper';
import optimisticMemoReducer from './OptimisticMemoReducer';
const _ = require('underscore');

// import archive constants
import {
  CREATE_MEMO,
  CREATE_MEMO_RESULT,
  CREATE_MEMO_ERROR,
  DELETE_MEMO,
  DELETE_MEMO_CABLE
} from './MemoConstants';


function injectMemo(state, excerptId, memo)
{
  const excerpt = OptimisticMapperHelper.getExcerpt(state, excerptId) || {};
  excerptId = OptimisticMapperHelper.getExcerptId(state, excerptId) || excerptId;

  const newMemoIds = _.uniq([...excerpt.memos || [], memo.id]);

  return {
    ...state,
    entities: {
      ...state.entities,
      excerpts: {
        ...state.entities.excerpts,
        [excerptId]: {
          ...excerpt,
          memos: newMemoIds,
        },
      },
      memos: {
        ...state.entities.memos,
        [memo.id]: memo,
      }
    }
  }
}

function memoReducer(action, state = InitialState)
{
  if ( !action ) return state;

  switch (action.actionType)
  {
    case CREATE_MEMO: {
      // update excerpt and memo entities
      const excerptId = action.data.excerpt_id;
      const clientId = action.data.clientId;
      const user_id = action.data.user_id || (state.entities.user || {}).id;

      const memo = {
        id: clientId,
        text: action.data.text,
        user_id,
        created_at: new Date(),
      };

      return injectMemo(state, excerptId, memo);
    }
    case CREATE_MEMO_ERROR: {
      // remove memo with client id
      const clientId = action.data.clientId;

      return {
        ...state,
        entities: {
          ...state.entities,
          memos: _.omit(state.entities.memos, clientId),
        }
      }
    }
    case DELETE_MEMO_CABLE:
    case DELETE_MEMO: {
      // remove memo with client id
      const memoId = action.data.id;

      return {
        ...state,
        entities: {
          ...state.entities,
          memos: _.omit(state.entities.memos, memoId),
        }
      }
    }
    case CREATE_MEMO_RESULT: {
      // update excerpt and memo entities
      const memo = action.data.memo;
      const excerptId = action.data.id;
      const clientId = action.data.clientId;

      let newState = injectMemo(state, excerptId, memo);
      return optimisticMemoReducer(newState, excerptId, clientId);
    }
    default:
      return state;
  }
}

export default memoReducer
