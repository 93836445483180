import {
  AUTH_ACCEPT_INVITE_ERROR,
} from 'constants/AuthConstants'

const DEFAULT_MESSAGE = "Something went wrong, please reload and try again.";

const NULL_STATE = {error:{error: false}, lastInvite:null};

const resetPasswordMapper = (state) => {
  try {
    const lastInvite = state.inviteState.lastInvite;

    const error = state.errorDict[AUTH_ACCEPT_INVITE_ERROR];
    if ( !error.error ) return {
      ...NULL_STATE,
      lastInvite: lastInvite,
      email: state.inviteState.user ? state.inviteState.user.email : null
    }

    return {
      error:{
        error: true,
        message: error.message ? error.message : DEFAULT_MESSAGE,
      },
      lastInvite: lastInvite,
    }
  } catch (e) {
    console.error(e);
    return NULL_STATE;
  }
}

export default resetPasswordMapper
