var Dispatcher = require('flux').Dispatcher;
import * as Sentry from "@sentry/browser";
import {HTTPError, HTTP404Error} from 'apis/HTTPErrors';

// Create dispatcher instance
var AppDispatcher = new Dispatcher();
import AuthConstants from 'constants/AuthConstants'

function logSentryError(error) {
  // if type error catpure exception, otherwise capture message
  try {
    if (error instanceof Error) {
      Sentry.captureException(error);
    } else {
      Sentry.captureMessage(JSON.stringify(error));
    }  
  } catch (e) {}
}

// Convenience method to handle dispatch requests
AppDispatcher.handleAction = function(action) {
  if (action.actionType != AuthConstants.AUTH_UPDATE_TOKEN)
  {
    console.log("App Dispatcher: ")
    console.log(action);
  }

  if ( !!action.error ) {
    logSentryError(action.error);
  }

  if ( !!action.errors ) {
    logSentryError(action.errors[0]);
  }


  this.dispatch({
    source: 'VIEW_ACTION',
    action: action
  });
}

export default AppDispatcher
