// CancelConstants.js

export default {
  FINISHED: "finished",
  NO_RESEARCH: "no_research",
  FEATURES: "features",
  PRICE: "price",
  TECHNICAL: "technical",
  HELP: "help",
  OTHER_SOLUTION: "other_solution",
  NOT_RIGHT: "not_right",
  OTHER: "other",
}
