import { ActionCable, ActionCableProvider } from 'react-actioncable-provider';
import React from 'react';

import AuthStore from 'stores/AuthStore'
import QualCodeActions from 'actions/QualCodeActions'
import MemoActions from 'memos/MemoActions';

import CodingActions from '../../actions/CodingActions';
import ChatActions from 'chat/ChatActions';


class CableLayer extends React.Component {
  constructor(props) {
    super(props);
    this.handleProjectCable = this.handleProjectCable.bind(this);
    this.handleCodeCable = this.handleCodeCable.bind(this);
    this.handleExcerptCable = this.handleExcerptCable.bind(this);
    this.handleTranscriptCable = this.handleTranscriptCable.bind(this);
    this.onDisconnected = this.onDisconnected.bind(this);
    this._onChange = this._onChange.bind(this);
    const cableURL = AuthStore.getCableURL();

    this.state = {
      cableURL: cableURL
    }
    this.cableLayerSet = !!cableURL;
    this.setCableLayer();
  }

  setCableLayer()
  {
    if ( !this.cableLayerSet )
    {
      const cableURL = AuthStore.getCableURL();
      if ( cableURL )
      {
        this.cableLayerSet = true;
        this.setState({
          cableURL: cableURL
        })
      }
    }
  }

  onDisconnected() {
    console.log('disconnected cable');
    if ( CABLE_DISCONNECT_FLAG )
      alert('disconnected from live updates')
  }

  handleProjectCable(response)
  {
    QualCodeActions.projectUpdateCable(response);
  }

  handleMemoCable(response) {
    // delete action 
    if ( response['action'] === 'destroy' ) {
      if ( !!response.memo_id )
        MemoActions.deleteMemoCable(response.memo_id);
    }
  }

  handleCodeCable(response)
  {
    if ( response['action'] == 'update' )
      CodingActions.updateCodeCable(response);
    else if ( response['action'] === 'nest' )
      CodingActions.nestCodeCable(response);
  }

  handleExcerptCable(response)
  {
    CodingActions.addExcerptCable(response);
  }

  handleTranscriptCable(response)
  {
    if ( response['action'] == 'create' )
      QualCodeActions.addTranscriptCable(response);
    else if ( response['action'] == 'update')
      QualCodeActions.updateTranscriptCable(response);
    else if ( response['action'] == 'destroy')
      QualCodeActions.deleteTranscriptCable(response);
    else if ( response['action'] == 'job') {
      ChatActions.updateJob(response)
    }
  }

  _onChange() {
    this.setCableLayer();
  }

  componentDidMount () {
    AuthStore.addChangeListener(this._onChange);
  }

  componentWillUnmount () {
    AuthStore.removeChangeListener(this._onChange);
  }


 render () {
   return (
     <div>
       {this.state.cableURL &&
         <ActionCableProvider url={this.state.cableURL}>
          {
            this.props.projectID &&
            <div>
              <ActionCable
                key={`project_${this.props.projectID}`}
                channel={{ channel: 'ProjectsChannel', project: this.props.projectID }}
                onReceived={this.handleProjectCable}
                onDisconnected={this.onDisconnected}
                onConnected={()=>{console.log('cable connected');}}
                onRejected={()=>{console.log('cable rejected');}}
              />
              <ActionCable
               key={`code_${this.props.projectID}`}
               channel={{ channel: 'CodesChannel', project: this.props.projectID }}
               onReceived={this.handleCodeCable}
              />
              <ActionCable
               key={`excerpt_${this.props.projectID}`}
               channel={{ channel: 'ExcerptsChannel', project: this.props.projectID }}
               onReceived={this.handleExcerptCable}
              />
              <ActionCable
               key={`transcript_${this.props.projectID}`}
               channel={{ channel: 'TranscriptsChannel', project: this.props.projectID }}
               onReceived={this.handleTranscriptCable}
              />
              <ActionCable
                key={`memo_${this.props.projectID}`}
                channel={{ channel: 'MemosChannel', project: this.props.projectID }}
                onReceived={this.handleMemoCable}
              />
            </div>
          }
          </ActionCableProvider>
       }
    </div>
   );
 }
}

export default CableLayer;
