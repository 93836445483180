// NIL means way in the future
const { convertArrayToCSV } = require('convert-array-to-csv');
var _ = require('underscore');
import exportProjectNameMapper from './ExportProjectNameMapper'
import exportExcerptHelper from './ExportExcerptHelper'

import {
  TRANSCRIPT,
  SNIPPET,
  URL,
  MEMOS,
  CODED_BY,
  CODES
} from './ExportConstants'

const HEADER = [TRANSCRIPT, SNIPPET, URL, MEMOS, CODED_BY]

const exportExcerptMapper = (state, filterState, projectID) => {
  const host = window && window.location && window.location.host ? window.location.host : "app.delvetool.com";
  let exportExcerpts;

  let descriptorNames = []

  try {
    const exportObjects = exportExcerptHelper(state, filterState, projectID);
    const excerpts = exportObjects.excerpts;
    descriptorNames = exportObjects.descriptorNames;
    
    exportExcerpts = excerpts.map((excerpt)=>{
      const coders = _.chain(excerpt.codes || []).map(
        (code)=>(code.coders || []).map(
          (coder)=>(coder || {}).name
        )
      )
      .flatten()
      .filter((coder)=>!!coder && coder.length > 0)
      .uniq()
      .value()
      .join('\n');

      return [
        excerpt.start || 0,
        excerpt.transcriptName,
        excerpt.text,
        excerpt.url,
        excerpt.memos,
        coders,
        ...excerpt.choices,
        ...excerpt.codes.map((code)=>code.name || "???" )
      ];
    }).sort(function (a, b) {
      const aName = a[1].toLowerCase();
      const bName = b[1].toLowerCase();
      const localeCompare = aName.localeCompare(bName);
      if ( localeCompare != 0 ) return localeCompare;
      return (a[0] < b[0]) ? -1 : 1;
    }).map((excerptArray) =>
      _.rest(excerptArray)
    );
  } catch (e) {
    console.error(e);
    exportExcerpts = '';
  };

  const {exportFileName} = exportProjectNameMapper(state, projectID, 'Snippets');

  return {
    projectName: exportFileName,
    exportExcerpts: convertArrayToCSV(
      exportExcerpts,
      {
        separator: ',',
        header: [...HEADER, ...descriptorNames, CODES]
      }
    )
  }
}

export default exportExcerptMapper
