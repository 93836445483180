const NULL_STATE = {transcript: null, loaded: false, project: null}

const newestTranscriptMapper = (state, projectID) => {
  try {
    const project = state.entities.projects[projectID];
    if ( !project ) return NULL_STATE;
    if ( !project.transcripts ) return {transcript: null, loaded: false, project: {id: projectID}}

    var maxTranscript = null;

    if ( project.recent_transcript_id )
    {
      maxTranscript = {id: project.recent_transcript_id}
    }
    else {
      for ( var i = 0; i < project.transcripts.length; i++ )
      {
        const transcriptID = project.transcripts[i];
        const transcript = state.entities.transcripts[transcriptID];
        if ( !maxTranscript || transcript.updated_at >= maxTranscript.updated_at )
          maxTranscript = transcript;
      }
    }

    const transcript = maxTranscript ? {id: maxTranscript.id} : null;
    return { transcript: transcript, loaded: true, project: {id: projectID}};
  } catch (e) {
    console.error(e);
    return NULL_STATE;
  }
}

export default newestTranscriptMapper
