import React from 'react';
import {Link} from 'react-router-dom'
import {Glyphicon, Modal, Button, Alert} from 'react-bootstrap'
import {DropdownButton, MenuItem} from 'react-bootstrap'
import ClipLoader from 'react-spinners/ClipLoader';
import ColorConstants from 'constants/ColorConstants'
const LEFT_PADDING = 45;
import ModalRow from './ModalRow'

function FileRow(props) {
  /*
    Open can be used to open the native file picker
  */
  let rightHand = (
    <ClipLoader
                color={ColorConstants.ACTIVE_BUTTON_BLUE}
                sizeUnit={"px"}
                size={19}
                loading={true}/>);

  if ( props.loaded )
    rightHand = <Glyphicon style={{
        fontSize:'1.4em',
        color:ColorConstants.ACTIVE_BUTTON_BLUE,
        width:"21px",
        height:"21px"
      }} glyph="ok"/>

  if ( props.error )
    rightHand = <div style={{color:ColorConstants.ERROR_TEXT}}>{props.errorMessage || 'Something went wrong'}</div>
    //rightHand = <Alert bsStyle="danger" style={{padding:10, margin:0}}>{props.errorMessage || 'Something went wrong'}</Alert>

  return <ModalRow
      row={[
        <div style={{whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        width:"100%",
      }}>
          {props.name}
        </div>,
        rightHand
      ]}
    />
}


export default FileRow
