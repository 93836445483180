// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';
import {Modal, Button} from 'react-bootstrap'
import UserStore from 'stores/UserStore'
import QualCodeActions from 'actions/QualCodeActions'
import termsOfServiceModal from 'mappers/TermsOfServiceMapper'
import TwoOptionModal from './TwoOptionModal';

const ModalState = {
  NORMAL: "NORMAL",
  LOADING: "LOADING",
  DECLINED: "DECLINED",
}

class TermsOfServiceModal extends React.Component {
  constructor () {
    super();
    this.state = Object.assign({}, {
        state: ModalState.NORMAL
      },
      UserStore.getStateWithMapper(termsOfServiceModal)
    );
    this._onChange = this._onChange.bind(this);
    this.acceptTermsOfService = this.acceptTermsOfService.bind(this);
    this.rejectTermsOfService = this.rejectTermsOfService.bind(this);
  }

  signout (e)
  {
    QualCodeActions.signOut();
  }

  acceptTermsOfService (e)
  {
    this.setState({state: ModalState.LOADING});
    QualCodeActions.acceptTermsOfService();
  }

  rejectTermsOfService (e)
  {
    this.setState({state: ModalState.DECLINED});
  }

  _onChange()
  {
    this._loadState(this.props);
  }

  _loadState(props)
  {
    this.setState(UserStore.getStateWithMapper(termsOfServiceModal));
  }

  componentDidMount () {
    UserStore.addChangeListener(this._onChange);
  }

  componentWillUnmount () {
    UserStore.removeChangeListener(this._onChange);
  }

  render () {

    if ( ModalState.DECLINED == this.state.state )
    {
      return (
        <div>
          <Modal show={this.state.show}>
              <Modal.Header>
                <Modal.Title>Terms and Conditions</Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <p>In order to continue using Delve, you will need to accept our <a href="https://delvetool.com/terms" target="_blank">Terms of Service</a> and <a href="https://delvetool.com/privacy-policy" target="_blank">Privacy Policy</a>.</p>
                <p>
                    If you have any questions or would like to discuss the agreements,
                    please send us an email at <a href="mailto:team@twentytonine.com">
                      team@twentytonine.com
                    </a>.
                </p>
              </Modal.Body>

            <Modal.Footer>
              <Button onClick={this.signout} bsStyle="blue">Okay</Button>
            </Modal.Footer>
          </Modal>
        </div>
      );
    }
    else {
      const disabled = this.state.state==ModalState.LOADING;
      return (
        <TwoOptionModal
          show={this.state.show}
          title="Terms and Conditions"
          onNo={this.rejectTermsOfService}
          onYes={this.acceptTermsOfService}
          noText="I Decline"
          yesText="I Agree"
          >
            We have updated our <a href="https://delvetool.com/terms" target="_blank">Delve Terms of Service</a> and <a href="https://delvetool.com/privacy-policy" target="_blank">User Privacy Policy</a>. To continue you must read and agree to the updated agreements.
        </TwoOptionModal>
      );
    }
  }
}

export default TermsOfServiceModal;
