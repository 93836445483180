// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';
import {FormControl, ControlLabel, FormGroup, InputGroup} from 'react-bootstrap'
import {Button} from 'react-bootstrap'
import QualCodeActions from 'actions/QualCodeActions'
import PageParserUtil from 'utils/PageParserUtil'
import { Redirect } from 'react-router-dom'
import newestTranscriptMapper from 'mappers/NewestTranscriptMapper'

// Stores
import ProjectsStore from 'stores/ProjectsStore'

class ProjectBody extends React.Component {
  constructor () {
    super();
    this._onChange = this._onChange.bind(this);
    this.state = ProjectsStore.getStateWithMapper((state)=>newestTranscriptMapper(state, null));
  }

  redirect()
  {
    const page = PageParserUtil.parse(this.props.match, this.props.location);
    const projectID = page.params.projectID;

    this.setState(ProjectsStore.getStateWithMapper((state)=>newestTranscriptMapper(state, projectID)))
  }

  _onChange()
  {
    this.redirect();
  }

  componentDidMount () {
    ProjectsStore.addChangeListener(this._onChange);
    this.redirect();
  }

  componentWillUnmount() {
    ProjectsStore.removeChangeListener(this._onChange);
  }

  render () {
    var redirectAddress = null;
    const project = this.state.project;

    if ( this.state.loaded )
    {
      if ( this.state.transcript )
      {
        redirectAddress = `/transcripts/${this.state.transcript.id}`;
      }
      else if (this.state.project) {
        redirectAddress = `/projects/${this.state.project.id}/transcripts/new`;
      }
    }

    return (
      <div>
        { redirectAddress && <Redirect to={redirectAddress}/> }
      </div>
    );
  }
}

export default ProjectBody;
