// app/javascript/projects/components/ProjectsDisplay.jsx
import React from 'react';
import AccountSingleButton from './AccountSingleButton'

class TeamModalSubscription extends React.Component {
  render () {
    const subscription = this.props.subscription ? this.props.subscription : {};
    const users = subscription.users ? subscription.users : [];
    
    return (
      <AccountSingleButton
        error={this.props.addSeatInfo.error}
        onClick={this.props.addSeat}
        loading={this.props.addSeatInfo.loading}
        header="Review the details"
        body={<p>
          Your team member will be able to start using Delve immediately. You are increasing your subscription by 1 team member.
        </p>}
        button="Confirm Subscription Change"
        disclaimer={`By adding an additional team member, you agree to be charged
          a prorated amount for this ${subscription.interval} starting on your next billing cycle,
          and to automatically be charged each ${subscription.interval} with your updated number of team
          members until you cancel.`}
        />
    );
  }
}

export default TeamModalSubscription;
