import metaSubscriptionMapper from 'mappers/MetaSubscriptionMapper'

import {
  CANCEL_SUBSCRIPTION,
} from 'constants/CashierConstants'

const cancelSubscriptionMapper = (state) => {
  return {
    cancelSubscription:metaSubscriptionMapper(state, CANCEL_SUBSCRIPTION)
  }
}

export default cancelSubscriptionMapper
