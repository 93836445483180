// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';

import CodeStore from 'stores/CodeStore'
import CodedTranscriptStore from 'stores/CodedTranscriptStore';
import QualCodeActions from 'actions/QualCodeActions'

import CodePageContainer from './CodePageContainer'
import CodingActions from '../../actions/CodingActions';

class TranscriptCodePageContainer extends React.Component {
  constructor () {
    super();
    this.state = {
      ...CodeStore.getCodePageModalInfo(),
    };
    this._onChange = this._onChange.bind(this);
    this.hideModal = this.hideModal.bind(this);
  }

  _onChange()
  {
    this._loadState(this.props);
  }

  _loadState(props)
  {
    this.setState({
      ...CodeStore.getCodePageModalInfo(),
    });
  }

  // this works but is a little hacky
  // really _loadState should be updating but it is not
  // this is likely because the transcript name is updated elsewhere
  static getDerivedStateFromProps(props, state)
  {
    return {
      ...state,
      ...CodeStore.getCodePageModalInfo(props.codeID),
    }
  }

  componentDidMount () {
    CodeStore.addChangeListener(this._onChange);
    CodedTranscriptStore.addChangeListener(this._onChange);
    this._loadState(this.props);
  }

  componentWillUnmount () {
    CodeStore.removeChangeListener(this._onChange);
    CodedTranscriptStore.removeChangeListener(this._onChange);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const prevCode = prevState.code || {};
    const thisCode = this.state.code || {};

    if ( prevCode.id != thisCode.id && thisCode.id && this.props.projectID)
    {
      CodingActions.getCode(thisCode.id, this.props.projectID);
    }
  }

  hideModal ()
  {
    QualCodeActions.hideCodePageModal();
  }

  render () {
    return (
      <CodePageContainer
        excerpts={this.state.excerpts}
        transcripts={this.state.transcripts}
        transcriptCount={this.state.transcriptCount}
        code={this.state.code ? this.state.code : {}}
        show={this.state.show}
        hideModal={this.hideModal}
        overlappingCodes={this.state.overlappingCodes}
        canPage={this.state.canPage}
        loading={this.state.loading}
        hasEditPermission={this.props.hasEditPermission}
        user={this.state.user}
      />
    );
  }
}

export default TranscriptCodePageContainer;
