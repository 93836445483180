import CodeStore from 'stores/CodeStore';
import QualAPI from 'utils/QualAPI';
import asyncUtil from 'utils/AsyncUtil';

import AppDispatcher from '../dispatcher/AppDispatcher';
import LocalAPI from 'utils/LocalAPI';
import ProjectsStore from '../stores/ProjectsStore';
import QualConstants from '../constants/QualConstants';
import CodePageConstants from '../constants/CodePageConstants'
import ReportAPI from 'apis/ReportAPI'
import ExcerptAPI from 'apis/ExcerptAPI';

const Joi = require('joi');

function dispatchError(type, payload, data)
{
  const response = payload.response || {};
  const responseData = response.data || {};
  const errors = responseData.errors || [];

  AppDispatcher.handleAction({
    actionType: type,
    data: data || {},
    errors: errors,
  });
}

const _loadCodes = (projectID) => {
  if ( !projectID )
    return;

  const loadState = CodeStore.getLoadState(projectID);
  if ( loadState.loading )
    return;

  AppDispatcher.handleAction({
      actionType: QualConstants.FETCH_CODES,
      data: {project_id: projectID}
  });

  return QualAPI.getCodes(projectID).then(response => {
    AppDispatcher.handleAction({
      actionType: QualConstants.FETCH_CODES_RESULT,
      data: response.data
    })
  })
  .catch(error => {
    AppDispatcher.handleAction({
      actionType: QualConstants.FETCH_CODES_ERROR,
      data: {project_id: projectID},
      error: error
    })
  });
}

var CodingActions = {
  /*****************************\
  *
  * Codes
  *
  \*****************************/
  loadCodes: _loadCodes,
  addCodeAndExcerpt: function(data)
  {
    AppDispatcher.handleAction({
      actionType: QualConstants.ADD_CODE_AND_EXCERPT,
      data: data
    });
  },

  nestCodeCable: (data) =>
  {
    AppDispatcher.handleAction({
      actionType: QualConstants.NEST_CODE_CABLE,
      data: data
    })
  },

  updateCodeCable: function(data)
  {
    AppDispatcher.handleAction({
      actionType: QualConstants.UPDATE_CODE_CABLE,
      data: data.code
    });
  },

  addCode: function(data)
  {
    return QualAPI.addCode(data).then((response) => {
      const data = response.data;

      AppDispatcher.handleAction({
        actionType: QualConstants.CREATE_CODE_RESULT,
        data: data
      });

      return data;
    })
    .catch(function (error) {
      AppDispatcher.handleAction({
        actionType: QualConstants.CREATE_CODE_ERROR,
        data: data,
        error: error
      });
      throw error;
    });
  },

  getCode: async function(codeID)
  {
    AppDispatcher.handleAction({
      actionType: QualConstants.GET_CODE,
      data: {
        code_id: codeID,
      }
    });

    codeID = await asyncUtil.getServerCode(codeID).catch((_)=>{});
    if ( !codeID ) return;

    const response = await QualAPI.getCode(codeID)
    .catch(error => {
      AppDispatcher.handleAction({
        actionType: QualConstants.GET_CODE_ERROR,
        data: error
      });
    });

    if ( !response ) return;

    AppDispatcher.handleAction({
      actionType: QualConstants.GET_CODE_RESULT,
      data: response.data
    });

    AppDispatcher.handleAction({
      actionType: CodePageConstants.FILTER_CODE_EXCERPTS,
      data: {
        codeID
      }
    })

    const excerptResponse = await QualAPI.getCodeExcerpts(codeID)
    .catch((error)=>{
      AppDispatcher.handleAction({
        actionType: CodePageConstants.FILTER_CODE_EXCERPTS_ERROR,
        data: error
      });
    });

    if ( !excerptResponse ) return;

    AppDispatcher.handleAction({
      actionType: CodePageConstants.FILTER_CODE_EXCERPTS_RESULT,
      data: {
        ...excerptResponse.data,
        isFirstCall: true
      }
    });

    AppDispatcher.handleAction({
      actionType: CodePageConstants.CODE_REPORT,
      data: {
        codeID
      }
    });

    const report = await ReportAPI.getCodeReport(codeID).catch((error)=>{
      AppDispatcher.handleAction({
        actionType: CodePageConstants.CODE_REPORT_ERROR,
        data: error
      });
    });

    if (!report) return;

    AppDispatcher.handleAction({
      actionType: CodePageConstants.CODE_REPORT_RESULT,
      data: report.data
    });
  },

  nextCodePage: async function(codeID) {
    codeID = await asyncUtil.getServerCode(codeID).catch((_)=>{});
    if ( !codeID ) return;

    AppDispatcher.handleAction({
      actionType: CodePageConstants.FILTER_CODE_EXCERPTS,
      data: {
        codeID: codeID
      }
    })

    const next = CodeStore.getCodeNext(codeID);
    await QualAPI.getCodeExcerpts(codeID, next).then((excerptResponse)=>{
      AppDispatcher.handleAction({
        actionType: CodePageConstants.FILTER_CODE_EXCERPTS_RESULT,
        data: excerptResponse.data
      });
    }).catch((error)=>{
      AppDispatcher.handleAction({
        actionType: CodePageConstants.FILTER_CODE_EXCERPTS_ERROR,
        data: error
      });
    });

  },

  /*****************************\
  *
  * Excerpts
  *
  \*****************************/
  selectExcerpt: function(data)
  {
    AppDispatcher.handleAction({
      actionType: QualConstants.SELECT_EXCERPT,
      data: data
    });
  },

  selectWordExcerpt: function(data)
  {
    AppDispatcher.handleAction({
      actionType: QualConstants.SELECT_WORD,
      data: data
    });
  },

  deselectExcerpt: function()
  {
    AppDispatcher.handleAction({
      actionType: QualConstants.DESELECT_EXCERPT,
      data: null
    });
  },

  asyncRenameCode: function(codeID, name)
  {
    return QualAPI.renameCode(codeID, name).then(response => {
      AppDispatcher.handleAction({
        actionType: QualConstants.RENAME_CODE_RESULT,
        data: response.data
      });
    })
    .catch(error => {
      console.error(error);
      AppDispatcher.handleAction({
        actionType: QualConstants.RENAME_CODE_ERROR,
        data: error
      });
    });
  },

  renameCode: function(codeID, name)
  {
    AppDispatcher.handleAction({
      actionType: QualConstants.RENAME_CODE,
      data: {
        id: codeID,
        name: name
      }
    });

    return asyncUtil.renameCode(codeID, name);
  },

  asyncDeleteCode: function(serverCodeID)
  {
    return QualAPI.deleteCode(serverCodeID).then(response => {
      AppDispatcher.handleAction({
        actionType: QualConstants.DELETE_CODE_RESULT,
        data: {id: serverCodeID}
      });
    })
    .catch(error => {
      console.error(error);
      AppDispatcher.handleAction({
        actionType: QualConstants.DELETE_CODE_ERROR,
        data: error
      });
    });
  },

  deleteCode: function(clientCodeID)
  {
    AppDispatcher.handleAction({
      actionType: QualConstants.DELETE_CODE,
      data: {
        id: clientCodeID
      }
    });

    asyncUtil.deleteCode(clientCodeID);
  },

  codePressed: function(data)
  {
    AppDispatcher.handleAction({
      actionType: QualConstants.CODE_PRESSED,
      data:data
    });

    const codeId = data.code_id;
    const projectId = data.project_id;
    this.expandParents(projectId, codeId);
  },

  codeUnPressed: function(data)
  {
    AppDispatcher.handleAction({
      actionType: QualConstants.CODE_UNPRESSED,
      data:data
    });

    const codeId = data.code_id;
    const projectId = data.project_id;
    this.expandParents(projectId, codeId);
  },

  removeCodeFromExcerpt: function(codeID, excerptID) {
    asyncUtil.removeCodeFromExcerpt(codeID, excerptID);
    // This used to not be called. Not sure why we are using async util to handle the initial reduction.
    // Also it wasn't dispatching so nothing was updating when this change was made.
    AppDispatcher.handleAction({
      actionType: QualConstants.DELETE_CODE_FROM_EXCERPT,
      data: {
        code_id: codeID,
        excerpt_id: excerptID,
        user_ids: null
      }
    })
  },

  addExcerptCable: function(data)
  {
    AppDispatcher.handleAction({
      actionType: QualConstants.CREATE_EXCERPT_CABLE,
      data: data
    });
  },

  addExcerpt: async function(data)
  {
    const schema = Joi.object().keys({
      start: Joi.number().required(),
      end: Joi.number().required(),
      code_id: Joi.number().required(),
      text: Joi.string().required(),
      transcript_id: Joi.number().required(),
      clientID: Joi.string(),
      user_ids: Joi.array().items(Joi.number())
    });

    const result = schema.validate(data);

    if ( result.error ) {
      dispatchError(QualConstants.CREATE_EXCERPT_ERROR, result.error);
      throw result.error;
    }

    const clientID = data.clientID;
    const transcript_id = data.transcript_id;
    var transcript = ProjectsStore.getEditTranscript(transcript_id).transcript;
    const transcript_version = transcript ? transcript.version : null;

    try {
      const response = await ExcerptAPI.addExcerpt(
        clientID,
        ProjectsStore.getSessionID(),
        data.start,
        data.end,
        data.code_id,
        transcript_id,
        data.text,
        transcript_version,
        data.user_ids
      );

      AppDispatcher.handleAction({
        actionType: QualConstants.CREATE_EXCERPT_RESULT,
        data: response.data
      });
  
      return response.data;  
    } catch (error) {
      dispatchError(
        QualConstants.CREATE_EXCERPT_ERROR,
        error,
        {
            transcriptID: transcript_id,
            clientID,
            codeID: data.code_id
        }
      );

      return {};
    }
  },

  addCodeToExcerpt: function(data)
  {
    if ( data.id && data.code_id)
    {
      ExcerptAPI.addCodeToExcerpt(ProjectsStore.getSessionID(), data.code_id, data.id, data.user_ids).then((data) =>{
        AppDispatcher.handleAction({
          actionType: QualConstants.ADD_CODE_TO_EXCERPT_RESULT,
          data: data
        });
      }).catch(error => {
          console.error(error);
          AppDispatcher.handleAction({
            actionType: QualConstants.ADD_CODE_TO_EXCERPT_ERROR,
            data: {
              code_id: data.code_id,
              excerpt_id: data.id
            },
            error: error
          });
      });
    }
  },

  /****************************\
  *
  * Removing Code From Excerpts
  *
  \****************************/
  deleteCodeFromExcerpt: function(data)
  {
    return QualAPI.deleteCodeFromExcerpt(ProjectsStore.getSessionID(), data.code.id, data.excerpt.id, data.user_ids).then((data) => {
      AppDispatcher.handleAction({
        actionType: QualConstants.DELETE_CODE_FROM_EXCERPT_RESULT,
        data: data
      });
    }).catch(error => {
      console.error(error);
      AppDispatcher.handleAction({
        actionType: QualConstants.DELETE_CODE_FROM_EXCERPT_ERROR,
        data: null,
        error: error
      });
    });
  },

  nestCodes: function(childCodeID, parentCodeID, position)
  {
    AppDispatcher.handleAction({
      actionType: QualConstants.NEST_CODE,
      data: {
        child_code_id: childCodeID,
        parent_id: parentCodeID,
        position
      }
    });
  },

  asyncNestCode: function(childCodeID, parentCodeID, position)
  {
    return QualAPI.nestCode(childCodeID, parentCodeID, position).then((response)=>{
      AppDispatcher.handleAction({
        actionType: QualConstants.NEST_CODE_RESULT,
        data: response.data
      });
    }).catch((error)=>{
      AppDispatcher.handleAction({
        actionType: QualConstants.NEST_CODE_ERROR,
        data: {child_code_id: childCodeID, parent_id: parentCodeID},
        error: error
      });

      const projectID = CodeStore.codeIdToProjectId(childCodeID);
      if ( projectID )
        return _loadCodes(projectID)
    });
  },

  expandParents: function(projectId, codeId)
  {
    if ( !codeId || !projectId ) return;
    const parents = CodeStore.getCodeParents(codeId);

    var parentId;
    for (parentId of parents) {
      this.expandCodeLabel(projectId, parentId);
    }
  },

  // Code bar collapse
  collapseCodeLabel: function(projectID, codeID)
  {
    asyncUtil.getServerCode(codeID).then((serverCodeID)=>{
      LocalAPI.collapseCodeBar(projectID, serverCodeID);
    });

    AppDispatcher.handleAction({
      actionType: QualConstants.COLLAPSE_CODE_BAR,
      data:{
        project_id: projectID,
        code_id: codeID,
      }
    })
  },

  expandCodeLabel: function(projectID, codeID)
  {
    asyncUtil.getServerCode(codeID).then((serverCodeID)=>{
      LocalAPI.expandCodeBar(projectID, serverCodeID);
    });

    AppDispatcher.handleAction({
      actionType: QualConstants.EXPAND_CODE_BAR,
      data:{
        project_id: projectID,
        code_id: codeID,
      }
    })
  },
};

export default CodingActions;
