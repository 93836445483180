import codeListMapper from 'mappers/exports/CodeListMapper'
import ExportMatrix from './ExportMatrix'
import exportProjectNameMapper from './ExportProjectNameMapper'

var _ = require('underscore');

const COL_OFFSET = 3;
const ROW_OFFSET = 2;

const EXPORT_NAME = 'Code x Code Matrix';
const DESCRIPTION = '# of overlaps between codes'

const codesVCodesArray = (state, projectID, projectName, report) => {
  const {codes} = codeListMapper(state, projectID);

  const matrix = new ExportMatrix(codes.length + ROW_OFFSET, codes.length + COL_OFFSET);

  matrix.setCodeRows(codes, ROW_OFFSET, COL_OFFSET);

  const codeNames = codes.map((code)=>code[3]);
  matrix.setRow(codeNames.map(()=>DESCRIPTION), 0, COL_OFFSET);
  matrix.setRow(codeNames, 1, COL_OFFSET);


  if ( report && report.codes ) {
    for ( let codeIndex1 = 0; codeIndex1 < codes.length; codeIndex1++ ) {
      const [code_id_1] = codes[codeIndex1];
      const reportCode1 = report.codes[code_id_1];
      if ( !reportCode1 ) continue;

      for ( let codeIndex2 = codeIndex1 + 1; codeIndex2 < codes.length; codeIndex2++ ) {
        const [code_id_2] = codes[codeIndex2];
        const reportCode2 = reportCode1.codes[code_id_2];
        if ( !reportCode2 ) continue;
        const excerptCount = reportCode2.excerpt_count;
        matrix.setValue(codeIndex1 + ROW_OFFSET, codeIndex2 + COL_OFFSET, excerptCount);
        matrix.setValue(codeIndex2 + ROW_OFFSET, codeIndex1 + COL_OFFSET, excerptCount);
      }
    }
  }

  matrix.setHeaders(projectName, EXPORT_NAME)

  return matrix.toCSV();
}

const exportCodesVCodesMapper = (state, projectID, report) => {
  const {projectName, exportFileName} = exportProjectNameMapper(state, projectID,'CodeXCode');

  const matrix = codesVCodesArray(
    state,
    projectID,
    projectName,
    (report || {}).report
  );

  return {
    projectName: exportFileName,
    matrix
  }
}

export default exportCodesVCodesMapper
