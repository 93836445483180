import AppDispatcher from 'dispatcher/AppDispatcher'
var _ = require('underscore');
import BaseStore from 'stores/BaseStore'
import JobState from './JobState'
import job_mapper from './JobMapper';
import jobReducer from './JobReducer';


const {
  CREATE_JOB,
  CREATE_JOB_RESULT,
  CREATE_JOB_ERROR,
  DESTROY_JOB,
  DESTROY_JOB_RESULT,
  GET_JOB_RESULT,
  UPDATE_JOB_CABLE,
  STOP_JOB,
  STOP_JOB_RESULT,
} = require('./JobConstants');

var JobStore = _.extend({}, BaseStore, {
  getJob: (transcript_id) => {
    return job_mapper(JobState.get(), transcript_id);
  },
});

// Register callback with AppDispatcher
AppDispatcher.register(function(payload) {
  var action = payload.action;
  switch(action.actionType) {
    case CREATE_JOB:
    case CREATE_JOB_RESULT:
    case CREATE_JOB_ERROR:
    case DESTROY_JOB:
    case DESTROY_JOB_RESULT:
    case GET_JOB_RESULT:
    case UPDATE_JOB_CABLE:
    case STOP_JOB:
    case STOP_JOB_RESULT:
      JobState.set(jobReducer(action, JobState.get()))
      break;
    default:
      return true;
  }

  JobStore.emitChange();
  return true;
});

export default JobStore
