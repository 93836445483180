import React, { useState, useEffect } from 'react';
import Question from './Question';
import { Redirect } from 'react-router-dom'; 
import DelveAccountBody from '../projects/components/DelveAccountBody';

import ROLE from './role.json';
import USAGE from './usage.json';
import JOBS from './jobs.json';
import SubscriptionRedirectComponent from 'projects/components/SubscriptionRedirectComponent';
import {Button} from 'react-bootstrap';
import { ACTIVE_BUTTON_BLUE } from '../constants/ColorConstants';

const QUESTIONS = [ROLE, USAGE, JOBS];

const initializeAnswers = () => ({
    [ROLE.type]: null,
    [USAGE.type]: null,
    [JOBS.type]: null
});

const shouldRandomize = (type) => {
    return [ROLE.type, JOBS.type].includes(type)
}

function PQL(props) {
    const [answers, setAnswers] = useState(() => {
        try {
            const savedAnswers = localStorage.getItem('intro_survey_answers');
            return savedAnswers ? JSON.parse(savedAnswers) : initializeAnswers();
        } catch (error) {
            console.error('Error accessing localStorage', error);
            return initializeAnswers();
        }
    });

    useEffect(() => {
        try {
            localStorage.setItem('intro_survey_answers', JSON.stringify(answers));
        } catch (error) {
            console.error('Error accessing localStorage', error);
        }
    }, [answers]);

    const onResponse = (type, value) => {
        setAnswers(prevAnswers => ({ ...prevAnswers, [type]: value }));
    };

    const getAnswer = (type) => answers[type];

    if (!process.env['PQL_FLAG']) {
        return <Redirect to="/subscribe" />;
    }

    const relevantAnswers = Object.keys(answers)
        .filter(key => QUESTIONS.some(question => question.type === key))
        .map(key => answers[key]);

    if (relevantAnswers.every(answer => answer !== null)) {
        return <Redirect to="/subscribe" />;
    }
    
    return (
        <DelveAccountBody
            subHeader="Tell us about you to get the most out of Delve."
        >
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', textAlign: 'left' }}>
                <SubscriptionRedirectComponent />
                <div style={{ textAlign: 'left', padding: '5px' }}>
                    <form>
                        {QUESTIONS.map((question, index) => (
                            <Question
                                key={index}
                                question={question.question}
                                answers={question.answers}
                                type={question.type}
                                onResponse={onResponse}
                                answer={getAnswer(question.type)}
                                randomize={shouldRandomize(question.type)}
                            />
                        ))}
                    </form>

                    <div style={{color: ACTIVE_BUTTON_BLUE}}>
                        * Required Question
                    </div>
                </div>

                <Button bsSize='large' bsStyle='blue' style={{width:'200px', marginTop: '10px'}} disabled>
                    Next
                </Button>
            </div>
        </DelveAccountBody>
    );
}

export default PQL;