import {
  LOAD_TRANSCRIPT_ERROR
} from 'constants/QualConstants'

const NO_ERROR = {error: null}
const NOT_FOUND_ERROR_CODES = [403, 404];


export default (state, id, errorType) => {
  const error = state.errorDict[errorType];

  if ( !error) return NO_ERROR;
  const idError = error[id];
  if ( !idError) return NO_ERROR;

  if ( NOT_FOUND_ERROR_CODES.includes(idError.errorCode)) {
    return {
      error: true,
      errorCode: idError.errorCode
    }
  }

  return NO_ERROR
}
