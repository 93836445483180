let _state = {
  codes: {}
}

var CodePageState = {
  set: function(newState) {
    _state = newState;
  },
  get: function(){
    return _state;
  }
}

export default CodePageState
