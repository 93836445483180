// NIL means way in the future
import excerptMapper from './ExcerptMapper'
const NIL_UPDATED_VALUE = "2020";
import {
  NOT_LOADING_STATE,
} from 'constants/LoadingConstants'


const projectExcerptMapper = (state, projectID) => {
  try {
    const excerpt_ids = state.entities.excerpts ? Object.keys(state.entities.excerpts) : [];
    const excerpts = excerpt_ids.map((excerptID)=>{
      const excerpt = excerptMapper(state, excerptID);
      return excerpt;
    }).filter((excerpt)=>!!excerpt);

    // convert excerpts from an array to an object
    return {excerpts: excerpts.reduce((acc, excerpt) => {
      acc[excerpt.id] = excerpt;
      return acc;
    }, {})};
  } catch (e) {
    console.error(e);
    return {excerpts: {} };
  }
}

export default projectExcerptMapper
