// app/javascript/projects/components/CodeDisplay.jsx

import React from 'react';
import TwoOptionModal from 'projects/components/TwoOptionModal';

function BetaModal(props) {
  return (
    <TwoOptionModal
      show={props.show}
      onNo={props.onNo}
      onYes={props.onYes}
      title={'Delve AI Beta Terms of Use'}
      noText='I Decline'
      yesText='I Agree'
    >
      To use the AI features offered by Delve, you must accept the AI Beta Terms of Use. Please review and agree to these terms by visiting the <a href="https://delvetool.com/beta-terms" target="_blank">Beta Terms page</a>.
    </TwoOptionModal>
  )
}

export default BetaModal;
