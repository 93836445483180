import {
  SUBSCRIBE_ERROR,
  FETCH_SUBSCRIPTIONS_ERROR,
  FETCH_PLANS_ERROR
} from 'constants/CashierConstants'

const defaultMessage = "Something went wrong, please reload and try again.";

const errorMessageDict = {
}

const NO_ERROR = {error: {error: false}}

function getMessage(type)
{
  return errorMessageDict[type] ? errorMessageDict[type] : defaultMessage;
}

function getDefaultError(state, type){
  const error = state.errorDict[type];
  if ( !error.error ) return null;

  return {
    error:{
      error: true,
      message: getMessage(type),
    }
  }
}

function getError(state, type){
  const error = state.errorDict[type];
  if ( !error.error ) return null;

  return {
    error:{
      error: true,
      message: error.message ? error.message : getMessage(type),
    }
  }
}

// TODO: Check if this can be deprecated
const subscribeErrorMapper = (state) => {
  try {
    const subscriptionError = getError(state, SUBSCRIBE_ERROR);
    if ( subscriptionError ) return subscriptionError;

    const fetchSubscriptionError = getError(state, FETCH_SUBSCRIPTIONS_ERROR);
    if ( fetchSubscriptionError ) return fetchSubscriptionError;

    const planError = getDefaultError(state, FETCH_PLANS_ERROR);
    if ( planError ) return planError;

    return NO_ERROR;
  } catch (e) {
    console.error(e);
    return NO_ERROR;
  }
}

export default subscribeErrorMapper
