// app/javascript/projects/components/ProjectsDisplay.jsx

import { DropTarget } from 'react-dnd';
import React from 'react';

import { DragItemTypes } from 'constants/DragConstants';

import CodingActions from '../../actions/CodingActions';
import DimensionsConstants from 'constants/DimensionsConstants'

let updates = 0;
const codeTarget = {
  drop(props, monitor) {
    const item = monitor.getItem();
    const didDrop = monitor.didDrop();

    if ( !didDrop )
    {
      const bottomMargin = props.bottomMargin || {};
      const position = bottomMargin.position || 1;
      const noop = bottomMargin.noop;

      if ( !noop )
        CodingActions.nestCodes(item.codeID, null, position);
    }
  }
}

function collectTarget(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver({ shallow: true }),
  }
}
class BottomMarginDropZone extends React.Component {
  componentDidUpdate (prevProps) {
    if ( prevProps.isOver !== this.props.isOver) {
      this.props.onDragHover(this.props.isOver)
    }
  }

  render () {
    const { connectDropTarget, isOver} = this.props;
    return connectDropTarget(<div style={{
                                position: 'absolute',
                                border: null,
                                top: 50,
                                height: DimensionsConstants.BOTTOM_DROP_ZONE_HEIGHT,
                                zIndex:100,
                                width: "150%",
                              }}>
                            </div>);
  }
}

export default DropTarget(DragItemTypes.CODE_LABEL, codeTarget, collectTarget)(BottomMarginDropZone);
