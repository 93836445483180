// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';
import AccountSingleButton from './AccountSingleButton'
import SessionAPI from 'apis/SessionAPI'


class BillingModalGenericBlocker extends React.Component {
  constructor () {
    super();
    this.state = {
      subscribing: false,
      error: null
    };

    this.onSubscribe = this.onSubscribe.bind(this);
  }

  onSubscribe() {
    this.setState({
      subscribing: true
    });

    SessionAPI.createSessionWithLocation({}).catch((error)=>{
      this.setState({
        error: 'There was an error subscribing. Please refresh and try again.'
      })
    }).then(()=>{
      this.setState({
        subscribing: false
      });
    })
  }

  render () {
    return (
      <div>
        <AccountSingleButton
          onClick={this.props.isSubscriptionOwner ? this.props.onClick : this.onSubscribe}
          loading={this.state.subscribing}
          header={this.props.header}
          button={this.props.isSubscriptionOwner ? "Renew Subscription" : "Start Subscription"}
          body={<p>{this.props.text}</p>}
          hideTerms={true}
          error={{
            error: !!this.state.error,
            message: this.state.error
          }}
        />
      </div>
    );
  }
}

export default BillingModalGenericBlocker;
