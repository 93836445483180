// app/javascript/projects/components/NewTranscript.jsx

import React from 'react';
import {Button} from 'react-bootstrap'
import {FormControl, ControlLabel, FormGroup} from 'react-bootstrap'
import {ButtonToolbar} from 'react-bootstrap'
import {HEADER_HEIGHT} from 'constants/DimensionsConstants'
import ColorConstants from 'constants/ColorConstants'
import {
  CONTEST_MAX_CHARACTERS
} from 'constants/GenericConstants'
import { CONTENT_MAX_WIDTH } from 'constants/DimensionsConstants'
import FileUtil from 'utils/FileUtil'

const PADDING = 30;
const BOTTOM_PADDING = 150;

const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

class NewTranscript extends React.Component {
  constructor () {
    super();
    this.state = {
      transcriptDescription: '',
      transcriptName: '',
      redirect: false
    };

    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleDescriptionChange(event){
    this.setState({transcriptDescription: event.target.value})
  }

  handleNameChange(event){
    this.setState({transcriptName: event.target.value})
  }

  handleSubmit(event) {
    event.preventDefault();
    const { transcriptName, transcriptDescription } = this.state;
    this.props.handleSubmit(transcriptName, transcriptDescription);
  }

  render () {
    const diff = this.state.transcriptDescription.length - CONTEST_MAX_CHARACTERS;
    const tooLong = diff > 0;
    const tooShort = !this.state.transcriptDescription || this.state.transcriptDescription.length === 0;
    const copyAndPasting = this.state.transcriptName.length != '' || this.state.transcriptDescription != '';

    return (
      <div style={{paddingLeft:"30px", paddingTop:PADDING + "px", height:"100%", maxWidth: CONTENT_MAX_WIDTH + "px"}}>
          <form style={{height:"100%"}}>
                     <FormGroup controlId="formtranscriptName">
                       <ControlLabel style={{fontSize:".80em"}}>Transcript Name</ControlLabel>
                       <FormControl
                         componentClass="input"
                         placeholder=""
                         value={this.state.transcriptName}
                         onChange={this.handleNameChange}
                       />
                     </FormGroup>
                     <div style={{
                         position: "relative",
                       }}>
                       <FormGroup controlId="formControlsTextarea" style={{height:this.props.height - BOTTOM_PADDING - PADDING}}>
                         <ControlLabel style={{fontSize:".80em"}}>Add Text</ControlLabel>
                         <FormControl componentClass="textarea"
                             placeholder=""
                             style={{
                               height:this.props.height - BOTTOM_PADDING - PADDING - 20,
                               resize:'none',
                               background:this.props.isHover ? ColorConstants.LIGHT_BLUE : null,
                               borderColor:this.props.isHover ? ColorConstants.ACTIVE_BUTTON_BLUE : null
                             }}
                             value={this.state.transcriptDescription}
                             onChange={this.handleDescriptionChange}/>
                       </FormGroup>
                       {!copyAndPasting &&
                         <div style={{
                             position: "absolute",
                             top: "40%",
                             left: "50%",
                             transform: "translate(-50%, -50%)",
                             textAlign: "center",
                             fontSize: "2.0em",
                             width: "100%",
                             color: ColorConstants.LIGHT_GREY,
                             fontFamily: "Libre Baskerville, serif"
                           }}>
                           {!this.props.isHover &&
                               <div style={{pointerEvents:'none'}}>
                                 <p>
                                  Copy and paste a transcript
                                 </p>
                                 <p>
                                   Or drag and drop {FileUtil.fileTypesOrCopy()} files
                                 </p>
                               </div>
                           }
                           {this.props.isHover  &&
                             <div style={{color:ColorConstants.ACTIVE_BUTTON_BLUE}}>
                               <p>
                                 Drop .txt files here.
                               </p>
                             </div>
                           }
                        </div>
                       }
                     </div>
             <ButtonToolbar>
               {tooLong &&
                 <div style={{float:"right", color:ColorConstants.ERROR_TEXT}}>
                   {`${numberWithCommas(diff)}`} characters over the {`${numberWithCommas(CONTEST_MAX_CHARACTERS) }`} limit
                 </div>
               }
               <Button onClick={(e)=>{this.handleSubmit(e)}} type="submit" bsStyle="blue" disabled={tooLong || tooShort}>
                   Add Transcript
               </Button>
             </ButtonToolbar>

         </form>

      </div>
    );
  }
}

export default NewTranscript;
