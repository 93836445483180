// app/javascript/projects/components/ProjectArchivePage.jsx

import React from 'react';
import FullPageMessage from 'projects/components/FullPageMessage'
import QualCodeActions from 'actions/QualCodeActions'


class ProjectArchivePage extends React.Component {
  
  render () {
    return <FullPageMessage
      header='Project has been archived'
      body={!!this.props.isProjectOwner ? 'Unarchive the project to view it.' : 'The project must be unarchived by the owner to be viewed.'}
      onClick={()=>QualCodeActions.showProjectModal()}
      buttonText='Go back to projects'
      subButtonText='See archived projects'
      subButtonTo={this.props.isProjectOwner ? '/users/archived' : null}
    />
  }
}

export default ProjectArchivePage;
