import AppDispatcher from 'dispatcher/AppDispatcher'
import ShareConstants from 'constants/ShareConstants'
import shareReducer from 'reducers/ShareReducer'
import collaboratorsMapper from 'mappers/CollaboratorsMapper'
import singleCollaboratorsMapper from 'mappers/SingleCollaboratorMapper'
import State from 'stores/State'

var _ = require('underscore');
var EventEmitter = require('events').EventEmitter;

var ShareStore = _.extend({}, EventEmitter.prototype, {
  getCollaborators: function(projectID) {
    return collaboratorsMapper(State.get(), projectID);
  },

  getCollaborator: function(projectID, email) {
    return singleCollaboratorsMapper(State.get(), projectID, email)
  },

  // Emit Change event
  emitChange: function() {
    this.emit('change');
  },

  // Add change listener
  addChangeListener: function(callback) {
    this.on('change', callback);
  },

  // Remove change listener
  removeChangeListener: function(callback) {
    this.removeListener('change', callback);
  }
});

// Register callback with AppDispatcher
AppDispatcher.register(function(payload) {
  var action = payload.action;

  switch(action.actionType) {
    case ShareConstants.FETCH_PROJECT_COLLABORATORS_RESULT:
    case ShareConstants.ADD_COLLABORATOR_RESULT:
    case ShareConstants.REMOVE_COLLABORATOR_RESULT:
    case ShareConstants.FETCH_PROJECT_COLLABORATORS:
    case ShareConstants.ADD_COLLABORATOR:
    case ShareConstants.REMOVE_COLLABORATOR:
    case ShareConstants.ADD_COLLABORATOR_ERROR:
    case ShareConstants.REMOVE_COLLABORATOR_ERROR:
    case ShareConstants.FETCH_PROJECT_COLLABORATORS_ERROR:
    case ShareConstants.UPDATE_COLLABORATOR_RESULT:
    case ShareConstants.UPDATE_COLLABORATOR:
    case ShareConstants.CLEAR_COLLABORATION_ERROR:
      State.set(shareReducer(action, State.get()))
      break
    default:
      return true;
  }

  ShareStore.emitChange();
  return true;
});

export default ShareStore
