import GenericConstants from 'constants/GenericConstants'
const queryString = require('query-string');

function getPage(page, numberOfPages) {
  page = Math.max(page, 1);
  page = Math.min(page, numberOfPages);
  page = page || 1;
  return page;
}

function getParagraphsPerPages(numberOfParagraphs, numberOfPages) {
  return Math.ceil(numberOfParagraphs/numberOfPages);
}

var TranscriptPagingUtil = {
  getNumberOfPages: function(transcriptLength)
  {
    const DEFAULT_NUMBER = 1;
    if ( !transcriptLength ) return DEFAULT_NUMBER;

    if ( transcriptLength > GenericConstants.ORIGINAL_MAX_CHARACTERS ) {
      return Math.ceil(transcriptLength / GenericConstants.PAGE_SIZE);
    }

    return DEFAULT_NUMBER;
  },

  getPageStart: function(page, numberOfPages, numberOfParagraphs) {
    if ( page < 0 ) return 0;
    page = getPage(page, numberOfPages);
    const paragraphsPerPages = getParagraphsPerPages(numberOfParagraphs, numberOfPages);
    return (page - 1)  * paragraphsPerPages;
  },

  getPageEnd: function(page, numberOfPages, numberOfParagraphs) {
    if ( page < 0 ) return numberOfParagraphs;
    page = getPage(page, numberOfPages);
    const paragraphsPerPages = getParagraphsPerPages(numberOfParagraphs, numberOfPages);
    return (page == numberOfPages) ? numberOfParagraphs : page * paragraphsPerPages;
  },

  getPageLocationOfParagraph: function(paragraphIndex, numberOfPages, numberOfParagraphs) {
    paragraphIndex = parseInt(paragraphIndex) || 0;
    if ( paragraphIndex <= 0 ) return 1;
    for (let page = 1; page <= numberOfPages; page++ ) {
      const start = this.getPageStart(page, numberOfPages, numberOfParagraphs);
      const end = this.getPageEnd(page, numberOfPages, numberOfParagraphs);
      if ( paragraphIndex >= start && paragraphIndex < end ) {
        return page;
      }
    }
    return numberOfPages;
  },

  calcQueryParams: function(queryParams, newPage) {
    let parsed = queryString.parse(queryParams);
    parsed['page'] = newPage;
    return `?${queryString.stringify(parsed)}`;
  }
}

export default TranscriptPagingUtil;
