import { normalize, schema } from 'normalizr';
import InitialState from 'constants/InitialState'
import optimisticMemoReducer from 'memos/OptimisticMemoReducer';
import OptimisticMapperHelper from 'mappers/OptimisticMapperHelper';

import {
    CREATE_EXCERPT,
    CREATE_EXCERPT_ERROR,
    CREATE_EXCERPT_RESULT,
    CREATE_EXCERPT_CABLE,
  } from 'constants/QualConstants'


var _ = require('underscore');

/***************************\
*
* Codes Schema
*
\***************************/
const code_schema = new schema.Entity('codes')

/*
* Excerpt Schema
*/

const memo_schema = new schema.Entity('memos')

const excerpt_schema = new schema.Entity('excerpt', {
  codes: [code_schema],
  memos: [memo_schema]
})

const transcript_excerpt_schema = new schema.Entity('transcripts', {
  excerpt: excerpt_schema
})

const get_code_ids = (state, serverCodeID) => {
  return {
    serverCodeID,
    codeID: OptimisticMapperHelper.getCodeId(state, serverCodeID) || serverCodeID
  }
}

const merge_codes = (state, code_ids, codes) => {
  code_ids = code_ids || [];
  return code_ids.reduce((mergedCodes, currentCodeId) => {
    const {serverCodeID, codeID} = get_code_ids(state, currentCodeId);
    const code = (codes || {})[serverCodeID];
    const existingCode = OptimisticMapperHelper.getCode(state, codeID);

    return {
      ...mergedCodes,
      ...(codeID && {[codeID]: {
        ...existingCode,
        ...code,
        id: codeID,
        server_id: serverCodeID,
      }})
    };
  }, state.entities.codes);
}

const get_code_mapper = (state, code_ids) => {
  code_ids = code_ids || [];
  return code_ids.reduce((mergedCodes, currentCodeId) => {
    const {serverCodeID, codeID} = get_code_ids(state, currentCodeId);
    return {
      ...(codeID && {[serverCodeID]: codeID}),
      ...mergedCodes
    };
  }, state.mappers.codes);
}

function createExcerptReducer(action, state = InitialState)
{
  if ( !action ) return null;

  switch (action.actionType)
  {
    case CREATE_EXCERPT_CABLE:
    {
      const session_id = action.data['session_id'];
      if ( session_id == state.session_id ) return state;

      const transcript_excert_code_schema = new schema.Entity('transcripts', {
        excerpt: excerpt_schema,
        codes: [code_schema]
      })

      const normalizedData =  normalize(action.data, transcript_excert_code_schema);

      const clientID = action.data['client_id'];

      // Do not handle a cable if you are the originator. You already have this excerpt.
      if ( clientID && state.mappers.excerpts[clientID] )
        return state;

      const transcriptID = normalizedData.result;
      const serverExcerptID = normalizedData.entities.transcripts[transcriptID].excerpt;
      const excerptID = state.mappers.excerpts[serverExcerptID] ? state.mappers.excerpts[serverExcerptID] : serverExcerptID;
      const existingExcerpt = state.entities.excerpts[excerptID];

      const existingMemos = existingExcerpt && existingExcerpt.memos ? existingExcerpt.memos : [];
      const memos = normalizedData.entities.excerpt[serverExcerptID].memos || [];
      const newMemos = _.uniq([...existingMemos, ...memos]);

      const excerpt = normalizedData.entities.excerpt[serverExcerptID];

      const transcript = state.entities.transcripts[transcriptID];
      var excerptIDs = transcript && transcript.excerpts ? transcript.excerpts.slice() : [];
      if( !excerptIDs.includes(excerptID) && !excerptIDs.includes(serverExcerptID) )
        excerptIDs.push(excerptID);

      const code_ids = normalizedData.entities.transcripts[transcriptID].codes;
      const codes = normalizedData.entities.codes;

      const newState = {
        ...state,
        entities: {
          ...state.entities,
          codes: merge_codes(state, code_ids, codes),
          excerpts: {
            ...state.entities.excerpts,
            [excerptID]: {
              ...existingExcerpt,
              ...excerpt,
              id: excerptID,
              server_id: serverExcerptID,
              memos: newMemos
            }
          },
          transcripts: {
            ...state.entities.transcripts,
            [transcriptID]: {
              ...state.entities.transcripts[transcriptID],
              id: transcriptID,
              excerpts: excerptIDs
            }
          }, 
          memos: {
            ...state.entities.memos,
            ...normalizedData.entities.memos
          }
        },
        mappers:{
          ...state.mappers,
          codes:get_code_mapper(state, code_ids),
          excerpts: {
            [serverExcerptID]: excerptID,
            ...state.mappers.excerpts,
          }
        }
      }

      const clientId = action.data.memo_client_id;
      return optimisticMemoReducer(newState, excerptID, clientId);
    }
    break;
    case CREATE_EXCERPT:
    {
      const codeID = OptimisticMapperHelper.getCodeId(state, action.data.code_id);      
      const transcriptID = action.data.transcript_id;
      const excerpt = action.data.excerpt;

      const excerptID = excerpt.id;
      const existingCode = state.entities.codes[codeID];
      const count = existingCode && existingCode.count ? existingCode.count + 1 : 1;

      const transcript = state.entities.transcripts[transcriptID];
      var excerptIDs = transcript && transcript.excerpts ? transcript.excerpts.slice() : [];
      excerptIDs.push(excerptID);

      return {
        ...state,
        entities: {
          ...state.entities,
          codes: {
            ...state.entities.codes,
            ...(codeID && {[codeID]: {
              ...existingCode,
              count: count
            }})
          },
          excerpts: {
            ...state.entities.excerpts,
            [excerptID]: {
              // This is a bit hacky, usually you don't have the parent stored. But why not actually?
              // An excerpt cannot actually chnage transcripts.
              transcript_id: transcriptID,
              ...excerpt,
              ...(codeID && {
                codings: [
                  {
                    code_id: codeID,
                    user_id: state.entities.user.id,
                    created_at: new Date().toISOString()
                  }
                ]
              })
            }
          },
          transcripts: {
            ...state.entities.transcripts,
            [transcriptID]: {
              ...state.entities.transcripts[transcriptID],
              id: transcriptID,
              excerpts: excerptIDs
            }
          }
        },
        mappers:{
          ...state.mappers,
          excerpts: {
            ...state.mappers.excerpts,
            [excerptID]: excerptID
          }
        }
      }
    }
    break;
    case CREATE_EXCERPT_ERROR:
    {
      const data = action.data || {};
      const {clientID, transcriptID, codeID} = data;

      if ( !clientID ) return state;

      const localCodeID = OptimisticMapperHelper.getCodeId(state, codeID);      
      const existingCode = state.entities.codes[localCodeID];
      const transcript = state.entities.transcripts[transcriptID];

      let newExcerpts = {...state.entities.excerpts};
      delete newExcerpts[clientID];

      const newCode = existingCode ? {
        [localCodeID]: {
          ...existingCode,
          count: existingCode.count ? existingCode.count - 1: 0
        }
      } : null;

      const newTranscript = transcript ? {
        [transcriptID] : {
          ...state.entities.transcripts[transcriptID],
          id: transcriptID,
          excerpts: transcript
                      && transcript.excerpts
                      ? transcript.excerpts.filter((excerpt_id)=>clientID !== excerpt_id)
                      : []
        }
      } : null;

      let newExcerptMappers = {...state.mappers.excerpts};
      delete newExcerptMappers[clientID];


      return {
        ...state,
        entities: {
          ...state.entities,
          codes: {
            ...state.entities.codes,
            ...(!!newCode && newCode)
          },
          excerpts: newExcerpts,
          transcripts: {
            ...state.entities.transcripts,
            ...(!!newTranscript && newTranscript)
          }
        },
        mappers:{
          ...state.mappers,
          excerpts: newExcerptMappers
        }
      }
    }
    break;
    case CREATE_EXCERPT_RESULT:
    {
      // FIXIT: why does this not add the excerpt to the actual transcript?
      const normalizedData =  normalize(action.data, transcript_excerpt_schema);
      const transcriptKey = normalizedData.result;
      const transcript = normalizedData.entities.transcripts[transcriptKey];
      const excerptKey = transcript.excerpt;
      const excerpt = normalizedData.entities.excerpt[excerptKey];
      const excerptClientID = transcript.client_id ? transcript.client_id : excerpt.id;

      return {
        ...state,
        entities: {
          ...state.entities,
          excerpts: {
            ...state.entities.excerpts,
            [excerptClientID]:
            {
              ...normalizedData.entities.excerpt[excerptKey],
              ...state.entities.excerpts[excerptClientID],
              id: excerptClientID,
              server_id: excerptKey
            }
          },
        },
        mappers:{
          ...state.mappers,
          excerpts:{
            ...state.mappers.excerpts,
            [excerptKey]: excerptClientID
          }
        }
      }
    }
    break;
    default:
      return null;
  }
}

export default createExcerptReducer
