import AppDispatcher from 'dispatcher/AppDispatcher'
import AuthConstants from 'constants/AuthConstants'
import CodedTranscriptUtil from 'utils/CodedTranscriptUtil'
import MergeConstants from 'constants/MergeConstants'
import PageConstants from 'constants/PageConstants'
import QualConstants from 'constants/QualConstants'
import MemoConstants from 'memos/MemoConstants'
import State from 'stores/State'
import asyncUtil from 'utils/AsyncUtil'
import descriptorReducer  from 'reducers/DescriptorReducer'
import editParagraphMapper from 'mappers/EditParagraphMapper'
import excerptMapper from 'mappers/ExcerptMapper'
import transcriptMapper from 'mappers/TranscriptMapper'
import botTranscriptExcerptMapper from 'mappers/BotTranscriptExcerptMapper'
import loadedTranscriptMapper from 'mappers/LoadedTranscriptMapper'
import memoPageMapper from 'memos/MemoPageMapper'
import ShareConstants from 'constants/ShareConstants'
import OptimisticMapperHelper from 'mappers/OptimisticMapperHelper'
import intercomMapper from 'mappers/IntercomMapper'
import interCoderMapper from 'mappers/intercoder/InterCoderMapper'

var _ = require('underscore');

var EventEmitter = require('events').EventEmitter;

var _select = null;
var _coders = null;
var _codedTranscriptUtil = null;
var _otherCodedTranscriptUtil = null;

function _setTranscript(transcriptID)
{
  // if transcript does not exist
  if ( !transcriptID && !!_codedTranscriptUtil )
      // and use that transcript
      transcriptID = _codedTranscriptUtil.getTranscriptID();

  // if it didn't have a transcript then return
  if ( !transcriptID ) return;

  // this mapper puts it in the format that it can be consumed, it should be the ground truth
  const transcript = transcriptMapper(State.get(), transcriptID);
  if ( !transcript ) return;

  // only create a new coded transcript util if this is truly a new transcript

  if ( !_codedTranscriptUtil || _codedTranscriptUtil.isNew(transcriptID, transcript.body) )
  {
      _codedTranscriptUtil = new CodedTranscriptUtil(transcriptID, transcript.body);
      _select = null;
  }

  const coders = _getCoders() || [];
  if ( !!coders && coders.length > 1 ) {
    if ( !_otherCodedTranscriptUtil || _otherCodedTranscriptUtil.isNew(transcriptID, transcript.body) ) {
      _otherCodedTranscriptUtil = new CodedTranscriptUtil(transcriptID, transcript.body);
    }

    _otherCodedTranscriptUtil.addExcerpts(transcript.excerpts || [], [coders[1]]);
  } else {
    _otherCodedTranscriptUtil = null;
  }


  _codedTranscriptUtil.addExcerpts(transcript.excerpts || [], coders.length > 0 ? [coders[0]] : null);
}

function normalSelect(excerpt)
{
  return {start: excerpt.start, end: excerpt.end};
}

function _addWordSelect(data)
{
  if ( !_codedTranscriptUtil ) return;
  if ( data )
  {
    _select = normalSelect(data);
  }
  else
    _select = null;
}

function _addSelect(excerpt)
{
  if ( !_codedTranscriptUtil ) return;
  _select = !!excerpt ? normalSelect(excerpt) : null;
}

function _addCodeAndExcerpt(data)
{
  const clientID = asyncUtil.createCode(data.projectId, data.name);

  _createExcerpt({
    code_id: clientID,
    transcriptID: data.transcriptID,
  })
}

function _removeCodeFromExcerpt(data)
{
  const codeID = data.code_id;
  if ( !codeID ) return null;
  if (!_select ) return null;
  if ( !_codedTranscriptUtil ) return null;

  const existingExcerpt = _codedTranscriptUtil.getExcerpt(_select.start, _select.end);
  if ( !existingExcerpt || !existingExcerpt.id)
    return;

  asyncUtil.removeCodeFromExcerpt(codeID, existingExcerpt.id, _getCoders());
}

function _getExcerpt() {
  if (!_select ) return new Error('No select');
  if ( !_codedTranscriptUtil ) return new Error('No coded transcript util');
  return _codedTranscriptUtil.getExcerpt(_select.start, _select.end);
}

function _createExcerpt(data)
{
  const existingExcerpt = _getExcerpt();
  // return null if error returned
  if ( existingExcerpt instanceof Error ) return null;

  if ( existingExcerpt )
  {
    asyncUtil.addCodeToExcerpt(data.code_id, existingExcerpt.id, _getCoders());
  }
  else {
    // TODO: add a code to a new excerpt, the count does not go up
    asyncUtil.createNewExcerpt(data.code_id, data.transcriptID, _select.start, _select.end, _getCoders());
  }
  _setTranscript();
}

function _setPrimeCode(data)
{
  if (!!_codedTranscriptUtil)
    _codedTranscriptUtil.setPrimeCode(data.paragraphIndex, data.codeID);
  if ( !!_otherCodedTranscriptUtil )
    _otherCodedTranscriptUtil.setPrimeCode(data.paragraphIndex, data.codeID);
}

function _removePrimeCode(data)
{
  if (!!_codedTranscriptUtil)
    _codedTranscriptUtil.setPrimeCode(null, null);
  if ( !!_otherCodedTranscriptUtil )
    _otherCodedTranscriptUtil.setPrimeCode(null, null);
}

function _filterCoders(data)
{
  _coders = data;
}

// this is hackier than I'd like
function replaceMe(coders) {
  if ( !coders ) return coders;

  coders = [...coders]
  if ( coders.includes('me') ) {
    const user = intercomMapper(State.get());
    if ( !!user && !!user.user_id )
    {
      const userId = user.user_id.toString();
      if ( !coders.includes(userId) )
        coders.push(userId);

      coders = coders.filter((coder) => coder !== 'me');
    }
  }

  return coders;
}

function _getCoders()
{
  if ( !_coders ) return null;
  return replaceMe(_coders);
}

var CodedTranscriptStore = _.extend({}, EventEmitter.prototype, {
  getBotExcerpts(transcriptID) {
    return botTranscriptExcerptMapper(State.get(), transcriptID) || {};
  },

  isTranscriptLoaded(transcriptID) {
    return loadedTranscriptMapper(State.get(), transcriptID);
  },

  calculateInterCoderReliability(transcriptID) {
    return interCoderMapper(State.get(), transcriptID);
  },

  getOrCreateExcerpt(transcriptID) {
    const existingExcerpt = _getExcerpt();
    // return null if error returned
    if ( existingExcerpt instanceof Error ) return null;

    if ( existingExcerpt )
      return existingExcerpt;

    const results = asyncUtil.createEmptyExcerpt(transcriptID, _select.start, _select.end);
    return {id: results[0]};
  },

  // TODO: put this in a mapper
  getExcerptDetails(excerptID)
  {
    return excerptMapper(State.get(), excerptID);
  },

  getSelect() {
    if ( !_select ) return null;
    return JSON.stringify(_select)
  },

  getLocationWithSearchText(transcriptID, searchText)
  {
    const transcript = transcriptMapper(State.get(), transcriptID);
    if ( !transcript || !transcript.body ) return null;

    const body = transcript.body;
    const loc = body.indexOf(searchText);
    if ( loc >= 0 )
    {
      return {
        start: loc,
        end: loc + searchText.length
      }
    }
    else {
      null
    }
  },

  getParagraphIndexWithLocation(transcriptID, loc)
  {
    if ( !_codedTranscriptUtil ) return null;
    if ( transcriptID != _codedTranscriptUtil.getTranscriptID()) return null;
    if ( !_codedTranscriptUtil ) return null;
    return _codedTranscriptUtil.getParagraphLocation(loc);
  },

  getExcerpts(excerptIDs) {
    return memoPageMapper(State.get(), excerptIDs);
  },

  getSpecificCodedTranscript(codedTranscriptUtil, transcriptID, page) {
    const EMPTY_PROJECT = {paragraphs:[]};
    if ( codedTranscriptUtil == null) return EMPTY_PROJECT;
    if ( codedTranscriptUtil.getTranscriptID() != transcriptID ) return EMPTY_PROJECT;
    return codedTranscriptUtil.getCodedTranscript(State.get(), page, _select);
  },

  getCodedTranscript(transcriptID, page, isOther)
  {
    if ( isOther ) {
      return this.getSpecificCodedTranscript(_otherCodedTranscriptUtil, transcriptID, page);
    } else {
      return this.getSpecificCodedTranscript(_codedTranscriptUtil, transcriptID, page);
    }
  },

  getNumberOfPages(transcriptID)
  {
    const EMPTY_PROJECT = 1;
    if ( _codedTranscriptUtil == null) return EMPTY_PROJECT;
    if ( _codedTranscriptUtil.getTranscriptID() != transcriptID ) return EMPTY_PROJECT;
    return _codedTranscriptUtil.getNumberOfPages();
  },

  getNumberOfParagraphs()
  {
    if ( !_codedTranscriptUtil ) return 0;
    return _codedTranscriptUtil.getNumberOfParagraphs();
  },

  getNumberOfSentences(paragraphIndex)
  {
    if ( !_codedTranscriptUtil ) return 0;
    return _codedTranscriptUtil.getNumberOfSentences(paragraphIndex);
  },

  getSelectedText()
  {
    if (!_select ) return null;
    if (!_codedTranscriptUtil) return null;

    const transcriptID = _codedTranscriptUtil.getTranscriptID();
    const transcript = transcriptMapper(State.get(), transcriptID);
    if ( !transcript || !transcript.body ) return null;
    const body = transcript.body;

    const text = transcript.body.substring(_select.start, _select.end);
    return text;
  },

  getSelectedCodes()
  {
    if (!_select ) return [];
    if (!_codedTranscriptUtil) return [];
    const excerpt = _codedTranscriptUtil.getExcerpt(_select.start, _select.end);
    return (excerpt && excerpt.codes ? excerpt.codes : [])
    .map((code) => (code || {}).id).filter((id) => (!!id))  
  },

  isSelected()
  {
    return (!!_select);
  },

  // this is the get edit state that you should use
  getEditState(transcriptID)
  {
    return editParagraphMapper(State.get(), transcriptID);
  },

  // Emit Change event
  emitChange: function() {
    this.emit('change');
  },

  // Add change listener
  addChangeListener: function(callback) {
    this.on('change', callback);
  },

  // Remove change listener
  removeChangeListener: function(callback) {
    this.removeListener('change', callback);
  }
});

// Register callback with AppDispatcher
AppDispatcher.register(function(payload) {
  var action = payload.action;

  switch(action.actionType) {
    // THIS IS A FULL RE-RENDER
    // TODO: should not be necessary, but for now will force a reset
    case QualConstants.CREATE_EXCERPT_ERROR:
    case QualConstants.ADD_CODE_TO_EXCERPT_ERROR:
      const transcriptID = !!_codedTranscriptUtil ? _codedTranscriptUtil.getTranscriptID() : null;
      State.set(descriptorReducer(action, State.get()));
      _codedTranscriptUtil = null;
      _setTranscript(transcriptID);
    break;
    // note this is just for debugging
    case AuthConstants.AUTH_SIGN_OUT:
    case AuthConstants.AUTH_SIGN_IN_RESPONSE:
    case AuthConstants.AUTH_SIGN_OUT_RESPONSE:
    case AuthConstants.AUTH_SIGN_IN:
      _codedTranscriptUtil = null;
      return true; //TODO: do not need to broadcast, just wiping the data on log out
    break;
    case QualConstants.DEBUG_SET_STATE:
      State.set(action.data);
      return true;
    break
    case QualConstants.LOAD_TRANSCRIPT_RESULTS:
      State.set(descriptorReducer(action, State.get()));
      _setTranscript(action.data.id);
    break;
    case QualConstants.FETCH_CODES_RESULT:
    case QualConstants.NEST_CODE_CABLE:
    case QualConstants.DELETE_CODE:
    case QualConstants.ADD_CODE_TO_EXCERPT_RESULT:
    case QualConstants.CREATE_EXCERPT:
    // TODO: create code cable is probably making set transcript be called too much, could optimize this...
    case QualConstants.CREATE_EXCERPT_CABLE:
    case MergeConstants.MERGE_CODE:
    case MergeConstants.MERGE_CODE_RESULT:
    case MemoConstants.CREATE_MEMO_RESULT:
    case ShareConstants.FETCH_PROJECT_COLLABORATORS_RESULT:
    case QualConstants.DELETE_CODE_FROM_EXCERPT:  
      State.set(descriptorReducer(action, State.get()));
      _setTranscript();
    break;
    case QualConstants.CREATE_EXCERPT_RESULT:
    case QualConstants.EDIT_PARAGRAPH:
    case QualConstants.CANCEL_EDIT_PARAGRAPH:
    case QualConstants.UPDATE_TRANSCRIPT_TEXT:
    case QualConstants.UPDATE_TRANSCRIPT_TEXT_RESULT:
    case QualConstants.UPDATE_TRANSCRIPT_TEXT_ERROR:
    case MergeConstants.MERGE_CODE_INITIATED:
    case MergeConstants.MERGE_CODE_CANCELLED:
      State.set(descriptorReducer(action, State.get()));
    break;
    case QualConstants.ADD_CODE_AND_EXCERPT:
      if ( action.data.page != PageConstants.TRANSCRIPT_PAGE ) return true;
        _addCodeAndExcerpt(action.data);
      break

    case QualConstants.CODE_PRESSED:
      if ( action.data.page != PageConstants.TRANSCRIPT_PAGE ) return true;
        _createExcerpt(action.data);
    break;

    case QualConstants.CODE_UNPRESSED:
      if ( action.data.page != PageConstants.TRANSCRIPT_PAGE ) return true;
      _removeCodeFromExcerpt(action.data);
      _setTranscript();
    break;

    case QualConstants.SELECT_EXCERPT:
      const excerpt = OptimisticMapperHelper.getExcerpt(State.get(), action.data.id);
      _addSelect(excerpt);
    break

    case QualConstants.SELECT_WORD:
      _addWordSelect(action.data);
    break

    case QualConstants.DESELECT_EXCERPT:
      _addSelect(null);
    break

    case QualConstants.ADD_PRIME_CODE:
      _setPrimeCode(action.data);
    break;

    case QualConstants.REMOVE_PRIME_CODE:
      _removePrimeCode(action.data);
    break;

    case QualConstants.FILTER_CODERS:
      _filterCoders((action.data || {}).coders);
      _setTranscript();
    break;

    default:
      return true;
  }

  CodedTranscriptStore.emitChange();
  return true;
});

export default CodedTranscriptStore
