// app/javascript/projects/components/FullPageMessage.jsx

import React from 'react';
import {Button} from 'react-bootstrap'
import {
  ACCOUNT_WIDTH
} from 'constants/DimensionsConstants'
import {LinkContainer} from 'react-router-bootstrap'

class FullPageMessage extends React.Component {
  render () {
    return (<div style={{textAlign:"center", maxWidth:ACCOUNT_WIDTH, margin:"0px auto", padding: "20px 0px", fontFamily: "Libre Baskerville, serif"}}>
      <h3>
        {this.props.header}
      </h3>
      <p style={{fontSize:"1.2em", marginTop:"30px"}}>
        {this.props.body}
      </p>

      <div style={{marginTop:"30px"}}>
        <Button onClick={this.props.onClick} bsStyle="blue" style={{width:"80%"}}>
          {this.props.buttonText}
        </Button>
      </div>

      {this.props.subButtonTo && 
        <div style={{marginTop:"15px"}}>
          <LinkContainer to={this.props.subButtonTo}>
            <Button bsStyle="link">
              {this.props.subButtonText}
            </Button>
          </LinkContainer>
        </div>
      }
    </div>);
  }
}

export default FullPageMessage;
