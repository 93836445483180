// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';
import {DropdownButton, Glyphicon} from 'react-bootstrap'
import ShareButton from './ShareButton'

class HeaderDropdown extends React.Component {
  constructor () {
    super();
  }

  render () {
    return (
      <div style={{float:"right", width: "105px"}}>
          <div style={{float:"left"}}>
            <ShareButton
              projectID={this.props.projectID}
              hasEditPermission={this.props.hasEditPermission}
              subscriptionState={this.props.subscriptionState}
              onShowCollabModal={this.props.onShowCollabModal}
              showCollabModal={this.props.showCollabModal}
            />
          </div>
          <div style={{float:"right"}}>
            <DropdownButton
              bsStyle="link"
              title={<Glyphicon style={{color:"black"}} glyph='option-horizontal'/>}
              id={1}
              style={{float:"right"}}
              noCaret
              pullRight
            >
              {this.props.children}
            </DropdownButton>
        </div>
      </div>
    );
  }
}

export default HeaderDropdown;
