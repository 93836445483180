// app/javascript/projects/components/TopMarginDropZone.jsx
import React from 'react';
import MarginDropZone from './MarginDropZone'

class TopMarginDropZone extends React.Component {
  render () {
    const topMargin = this.props.topMargin || {};
    return <div>
      <div style={{position: "relative"}}>
        <MarginDropZone
          dropPosition={topMargin.position || 1}
          dropCodeId={topMargin.parent_id || null}
          isOverParent={false}
          canDropOnParent={false}
          canDropOnMargin={true}
          depth={0}
          showDeny={false}
          rejectNesting={null}
          top={1}
          lineTop={2}
          noop={!!topMargin.noop}
        />
    </div></div>
  }
}

export default TopMarginDropZone;
