// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';
import {Link} from 'react-router-dom'
import ColorConstants from 'constants/ColorConstants'
import SearchResultUtil from 'utils/SearchResultUtil';


class SearchResult extends React.Component {
  render () {
    const match = this.props.match;
    const urlString = encodeURI(match.replace(/<strong>/g, "").replace(/<\/strong>/g, ""));
    const link = "/transcripts/" + this.props.transcriptID + "?search=" + urlString;
    
    const text = SearchResultUtil.renderHighlightedText(match);

    return (
      <div style={{marginBottom:20}}>
        <Link to={link}>
          <span style={{color:ColorConstants.ACTIVE_BUTTON_BLUE, fontSize:".8em"}}>
            <strong>
              {this.props.name}
            </strong>
          </span>
        </Link>

        <div>
          <p>
            {text}
          </p>
        </div>
      </div>
    );
  }
}

export default SearchResult;
