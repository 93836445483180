var _ = require('underscore');
import excerptMapper from './ExcerptMapper';

const transcriptMapper = (state, transcriptID) => {
  if ( !transcriptID ) return false;
  const transcript = state.entities.transcripts[transcriptID];
  if (!transcript ) return false;

  if ( !transcript.excerpts ) return false;
  if ( !transcript.body ) return false;
  return true;
}

export default transcriptMapper
