// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';
import {Label} from 'react-bootstrap'
import ColorConstants from 'constants/ColorConstants'

const defaultStyle = {"color": ColorConstants.DARK_GREY, "backgroundColor": ColorConstants.ULTRA_LIGHT_GREY, fontWeight:"normal"};
const darkStyle = {"color": ColorConstants.ULTRA_LIGHT_GREY, "backgroundColor": ColorConstants.DARK_GREY, fontWeight:"normal"};
const hoverStyle = {"color": ColorConstants.DARK_GREY, "backgroundColor": ColorConstants.LIGHT_GREY, fontWeight:"normal"};

class CodePill extends React.Component {
  constructor () {
    super();
  }

  render () {
    const style = this.props.dark ? darkStyle :
                    this.props.hover ? hoverStyle : defaultStyle;

    return (
      <span style={{marginRight:0, maxWidth: "100%"}}>
        <Label onClick={this.props.onClick} style={style}>
          <div style={{
            paddingBottom:'2px',
            display: 'inline-block',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            maxWidth: '80%',
            verticalAlign: 'middle'
          }} className='code-pill'>
            {this.props.name}
          </div>
        </Label>
      </span>
    );
  }
}

export default CodePill;
