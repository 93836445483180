// MemoAvatar.jsx

import React from 'react';
import ColorConstants from 'constants/ColorConstants'
import {
  AVATAR_SIZE
} from 'constants/DimensionsConstants';

function MemoAvatar(props) {
  const initial = props.initials ? props.initials.charAt(0) : "U";

  return (
    <div style={{ 
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: AVATAR_SIZE,
      width: AVATAR_SIZE,
      backgroundColor: props.color || ColorConstants.ACTIVE_BUTTON_BLUE,
      color: "white",
      borderRadius: '50%',
      fontSize: '.7em'
      }}>
    <span>{initial}</span>
  </div>
  );
}

export default MemoAvatar;
