// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';
import QualCodeActions from 'actions/QualCodeActions'
import PageParserUtil from 'utils/PageParserUtil'
import DescriptorStore from 'stores/DescriptorStore'
import ProjectsStore from 'stores/ProjectsStore'
import mapToStateProps from 'mappers/DescriptorMapper'
import EditDemographicView from './EditDemographicView'
import ViewOnlyDemographicView from './ViewOnlyDemographicView'

class DemographicsView extends React.Component {
  constructor () {
    super();
    this.state = {
      descriptors:[]
    }
    this._onChange = this._onChange.bind(this);
  }

  _getProjectID(props)
  {
    const transcriptID = this._getTranscriptID(props);
    // TODO: you want to REDUX al lthis not not hack these stores together
    const projectID = ProjectsStore.getProjectIDFromTranscriptID(transcriptID);
    return projectID;
  }

  _getTranscriptID(props)
  {
    const page = PageParserUtil.parse(props.match, props.location);
    return page.params.transcriptID;
  }

  _loadNewState(props)
  {
    const transcriptID = this._getTranscriptID(props);
    const newState = DescriptorStore.getStateWithMapper(
                      (state) => mapToStateProps(state, transcriptID)
    );

    this.setState(newState);
  }

  _loadDemographics(props)
  {
    this._loadNewState(props);
    const page = PageParserUtil.parse(props.match, props.location);
    QualCodeActions.requestDescriptorsForTranscript(page.params.transcriptID);
    QualCodeActions.requestSelections(page.params.transcriptID);
  }

  _onChange () {
    this._loadNewState(this.props);
  }

  componentWillReceiveProps(nextProps) {
    const transcriptID = this._getTranscriptID(this.props);
    const nextTranscriptID = this._getTranscriptID(nextProps);

    if ( transcriptID != nextTranscriptID )
    {
      this._loadDemographics(nextProps);
    }
  }

  componentDidMount () {
    this._loadDemographics(this.props);
    DescriptorStore.addChangeListener(this._onChange);
  }

  componentWillUnmount () {
    DescriptorStore.removeChangeListener(this._onChange);
  }

  scrollToBottom () {
    if ( this.messagesEnd )
    {
      this.messagesEnd.scrollIntoView({ behavior: "smooth" });
    }
  }

  render () {
    if ( this.props.hasEditPermission )
      return (
        <EditDemographicView
          descriptors={this.state.descriptors}
          projectID={this._getProjectID(this.props)}
          transcriptID={this._getTranscriptID(this.props)}
        />
      )
    else
      return (
        <ViewOnlyDemographicView
          descriptors={this.state.descriptors}
          projectID={this._getProjectID(this.props)}
          transcriptID={this._getTranscriptID(this.props)}
        />
      )
  }
}

export default DemographicsView;
