// app/javascript/projects/components/ProjectsDisplay.jsx

import React, { useState }  from 'react';
import ColorConstants from 'constants/ColorConstants'
import DimensionsConstants from 'constants/DimensionsConstants'

const LARGE_BORDER_RADIUS = "5px"
const BORDER_RADIUS = "3px"
const BUTTON_SIZE = "25px"


function GlyphBorder(props) {
  if ( !props.show ) return (<div></div>);

  const [buttonHover, setButtonHover] = useState(false);

  return (
    <div style={{
                  width: props.large ? DimensionsConstants.CODE_LABEL_HEIGHT : BUTTON_SIZE,
                  height: props.large ? DimensionsConstants.CODE_LABEL_HEIGHT : BUTTON_SIZE,
                  backgroundColor: buttonHover || props.backgroundFill ? ColorConstants.ULTRA_LIGHT_GREY : "white",
                  borderRadius: props.large ? LARGE_BORDER_RADIUS : BORDER_RADIUS,
                  }}>
        <div  onMouseEnter={() => setButtonHover(true)}
              onMouseLeave={() => setButtonHover(false)}>
          {props.children}
        </div>
    </div>
  );
}

export default GlyphBorder;
