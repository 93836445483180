// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';
import {Button} from 'react-bootstrap'
import Excerpt from './Excerpt'

const LEFT_PADDING = 45;

function ExcerptListComponent(props) {
  const excerpts = props.excerpts.map((excerpt,index)=>{
    return(
          <Excerpt
            codePage={props.codePage}
            projectID={props.projectID}
            excerpt={excerpt}
            key={index}
            onLinkClick={props.onLinkClick}
            onTextClicked={props.onTextClicked}
            selected={(excerpt || {}).id === props.selectedExcerpt}
            hasEditPermission={props.hasEditPermission}
            onCodeClick={props.onCodeClick}
            />
        );
  });

  return (
    <div style={{padding:`20px ${LEFT_PADDING}px`}}>
      <span style={{fontSize:'.8em', cursor:'default'}}><strong>
        {props.headerText || "Sorted By Most Recent"}
      </strong></span>
      {excerpts}

      {props.canPage &&
        <div style={{textAlign:'center', marginBottom:"20px"}}>
          <Button bsStyle="white"
            onClick={props.showMore}
            disabled={props.loading}
            >Show More</Button>
        </div>
      }
    </div>
  );
}

export default ExcerptListComponent;
