import AppDispatcher from 'dispatcher/AppDispatcher'
import QualConstants from 'constants/QualConstants'
import descriptorReducer  from 'reducers/DescriptorReducer'
import State from 'stores/State'

var _ = require('underscore');
var EventEmitter = require('events').EventEmitter;

var DescriptorStore = _.extend({}, EventEmitter.prototype, {
  getStateWithMapper(mapper)
  {
    return mapper(State.get());
  },

  // Emit Change event
  emitChange: function() {
    this.emit('change');
  },

  // Add change listener
  addChangeListener: function(callback) {
    this.on('change', callback);
  },

  // Remove change listener
  removeChangeListener: function(callback) {
    this.removeListener('change', callback);
  }
});

// Register callback with AppDispatcher
AppDispatcher.register(function(payload) {
  var action = payload.action;

  switch(action.actionType) {
    case QualConstants.FETCH_DESCRIPTORS_RESULT:
    case QualConstants.UPDATE_MULTI_CHOICE_DESCRIPTOR_RESULT:
    case QualConstants.CREATE_MULTI_CHOICE_DESCRIPTOR_RESULT:
    case QualConstants.DESTROY_MULTI_CHOICE_DESCRIPTOR:
    case QualConstants.FETCH_SELECTIONS_RESULT:
    case QualConstants.CREATE_MULTI_CHOICE_SELECTION_RESULT:
    case QualConstants.CREATE_MULTI_CHOICE:
    case QualConstants.CREATE_MULTI_CHOICE_RESULT:
    case QualConstants.FETCH_PROJECT_SELECTIONS_RESULT:
      State.set(descriptorReducer(action, State.get()));
      break
    default:
      return true;
  }

  DescriptorStore.emitChange();
  return true;
});

export default DescriptorStore
