// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';

import CodedTranscriptStore from 'stores/CodedTranscriptStore'

import CodingActions from '../../actions/CodingActions';
import ParagraphContainer from './ParagraphContainer'
import {PARAGRAPH_PADDING} from 'constants/DimensionsConstants'
var _ = require('underscore');


class TranscriptBody extends React.Component {
  constructor () {
    super();
    this.state = {};

    this.onMouseUp = this.onMouseUp.bind(this);
    this.copy = this.copy.bind(this);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
  }

  copy(e)
  {
    const selectedText = CodedTranscriptStore.getSelectedText();

    if ( selectedText )
    {
      // e.clipboardData is initially empty, but we can set it to the
      // data that we want copied onto the clipboard.
      e.clipboardData.setData('text/plain', selectedText);
      //e.clipboardData.setData('text/html', '<b>Hello, world!</b>');
      // This is necessary to prevent the current document selection from
      // being written to the clipboard.
      e.preventDefault();
    }
  }

  componentDidMount () {
    document.addEventListener('copy', this.copy, false);
  }

  componentWillUnmount () {
    document.removeEventListener('copy', this.copy, false)
  }

  _getDataIndex(node) {
    if ( node == null )
      return null;

    if ( !['codeSpan', 'highlightSpan', 'sentenceSpan', 'paragraphSpan', 'labelDiv', 'numberDiv'].includes(node.className))
      return null;

    var dataindex = node.getAttribute('dataindex');
    if ( !dataindex )
      return null;

    return JSON.parse(dataindex)
  }

  _getIndex(node)
  {
    const dataIndex = this._getDataIndex(node);
    if ( !dataIndex ) return null;
    return dataIndex['index'];
  }

  _getExcerptId(node) {
    const dataIndex = this._getDataIndex(node);
    if ( !dataIndex ) return null;
    return dataIndex['excerptId'];
  }

  _getElementWithName(node, name)
  {
    while ( node != null )
    {
      if ( node.className === name)
      {
        return node;
      }

      node = node.parentNode;
    }

    return null;
  }

  _paragraphNumberHash(node)
  {
    var element = this._getElementWithName(node, 'numberDiv');
    var index = this._getIndex(element);
    if ( index == null) return null;
    return {
      codeIndex: 0,
      highlightIndex: 0,
      sentenceIndex: 0,
      paragraphIndex: index
    }
  }

  _tagHash(node)
  {
    var element = this._getElementWithName(node, 'labelDiv');
    var index = this._getIndex(element);
    if ( index == null) return null;
    return {
      codeIndex: -1,
      highlightIndex: -1,
      sentenceIndex: -1,
      paragraphIndex: index
    }
  }

  _elementHash(codeSpan)
  {
    var returnHash = {};
    let excerptId = null;

    var index = this._getIndex(codeSpan);
    if ( index == null ) return null;
    returnHash['codeIndex'] = index;
    returnHash['excerptId'] = returnHash['excerptId'] || this._getExcerptId(codeSpan);

    var highlightSpan = codeSpan.parentNode;
    index = this._getIndex(highlightSpan);
    if ( index == null) return null;
    returnHash['highlightIndex'] = index;
    returnHash['excerptId'] = returnHash['excerptId'] || this._getExcerptId(highlightSpan);


    var sentenceSpan = highlightSpan.parentNode;
    index = this._getIndex(sentenceSpan);
    returnHash['sentenceIndex'] = index;
    returnHash['excerptId'] = returnHash['excerptId'] || this._getExcerptId(sentenceSpan);

    if ( index == null ) return null;

    var paragraphSpan = sentenceSpan.parentNode;
    index = this._getIndex(paragraphSpan);
    if ( index == null ) return null;
    returnHash['paragraphIndex'] = index;
    returnHash['excerptId'] = returnHash['excerptId'] || this._getExcerptId(paragraphSpan);
    return returnHash;
  }

  _getSentenceSpan(hash)
  {
    var paragraph = this.props.codedTranscript.paragraphs[hash['paragraphIndex']];

    if ( !paragraph ) return null;

    var sentence = paragraph.sentences[hash['sentenceIndex']];

    if ( !sentence ) return null;

    return {
      start: sentence.start,
      end: sentence.end
    }
  }

  _getSpan(hash, offset)
  {
    var paragraph = this.props.codedTranscript.paragraphs[hash['paragraphIndex']];

    if ( hash['sentenceIndex'] < 0)
      return paragraph.end;

    var sentence = paragraph.sentences[hash['sentenceIndex']];

    var start = sentence.start;


    var highlightElements = sentence.selectElements;

    if (!highlightElements) return null;

    for ( var highlightIndex = 0; highlightIndex < hash['highlightIndex']; highlightIndex++)
    {
      var highlightElement = highlightElements[highlightIndex];
      var codeElements = highlightElement.elements;

      for ( var codeIndex = 0; codeIndex < codeElements.length; codeIndex++ )
      {
        var codeElement = codeElements[codeIndex];
        if (codeElement)
          start += codeElement.text.length;
      }
    }

    var lastHighlightedElement = highlightElements[hash['highlightIndex']]; // WARNING: it seems like highlightElements is not always available: https://qual-production.sentry.io/issues/3833151140/?project=1309285&query=is%3Aunresolved&referrer=issue-stream&stream_index=1
    var codeElements = lastHighlightedElement.elements;

    for ( var codeIndex = 0; codeIndex < hash['codeIndex']; codeIndex++ )
    {
      var codeElement = codeElements[codeIndex];
      start += codeElement.text.length;
    }

    start += offset;
    return start;
  }

  _isClick(startHash, endHash, startOffset, endOffset) {
    if ( startOffset != endOffset )
      return false;

    const values = ['codeIndex', 'highlightIndex', 'sentenceIndex', 'paragraphIndex'];
    startHash = startHash || {};
    endHash = endHash || {};

    let i = 0;
    for (i = 0; i < values.length; i++) {
      if ( startHash[values[i]] != endHash[values[i]] )
        return false;
    }

    return true;
  }

  _getSpanFromSelect(startHash, endHash, startOffset, endOffset)
  {
    if ( !startHash || !endHash ) return null;

    if ( _.isEqual(startHash, endHash) && startOffset === endOffset)
    { 
      const excerptId = startHash['excerptId'];
      if ( !!excerptId ) {
        return {
          excerptId
        }
      }
      else 
        return this._getSentenceSpan(startHash)
    }
    else
    {
      var start = this._getSpan(startHash, startOffset);
      var end = this._getSpan(endHash, endOffset);

      if ( start == null || end == null) return null;

      return {
        start: start,
        end: end
      }
    }
  }

  onMouseUp(event, endOffset)
  {
    if ( !!this.props.isPrint ) return;

    if ( !this.props.hasEditPermission ) {
      CodingActions.deselectExcerpt();
      return
    };

    var s = window.getSelection();

    if ( !s || s.rangeCount == 0 )
      return;

    var startRange = s.getRangeAt(0);
    var endRange = s.getRangeAt(s.rangeCount - 1);

    var startNode = startRange.startContainer.parentNode;
    var endNode = endRange.endContainer.parentNode;

    var startHash = this._elementHash(startNode);
    var endHash = this._elementHash(endNode);

    var startOffset = startRange.startOffset;
    var endOffset = endRange.endOffset;

    if ( startHash == null )
    {
      startHash = this._tagHash(startNode);
      if ( startHash == null)
      {
        startHash = this._paragraphNumberHash(startNode);
        startOffset = 0;
      }
    }
    if ( endHash == null )
    {
      endHash = this._tagHash(endNode);
      if ( endHash == null )
      {
        endHash = this._paragraphNumberHash(endNode);
        endOffset = 0;
      }
    }

    var excerpt = this._getSpanFromSelect(startHash, endHash, startOffset, endOffset);

    // This figures out if the user ended on a sentence
    // If we clicked on a sentence, great, we should deselect anything
    // we are either highlighting a selection or clicking on a sentence
    const _target = !!event && event.target || {};
    const _targetName = _target.className || null;

    // this calculates whether text is selected
    // If text is selected someone is highlighting text, and it doesn't matter
    // where the user ended. We'll want to select something
    // If they did not select something, this was simply a click.
    const isClick = this._isClick(startHash, endHash, startOffset, endOffset);

    if (typeof _targetName !== 'string' && !(_targetName instanceof String))
      return;

    // If the user clicked on a pill, we are going to ignore the click
    const pillClasses = ['code-x', 'code-pill', 'add-memo'];

    const didLandOnPill = _targetName && pillClasses.some((className)=>_targetName.includes(className));
    if ( didLandOnPill && isClick )
      return;

    const didClickOnSentence = _targetName == 'codeSpan';

    if ( excerpt == null || (isClick && !didClickOnSentence) ) {
      CodingActions.deselectExcerpt();
      return
    };

    if ( !!excerpt.excerptId ) {
      CodingActions.selectExcerpt({
        id: excerpt.excerptId
      });
    } else {
      document.getSelection().removeAllRanges();
      CodingActions.selectWordExcerpt({
        ...excerpt
      });  
    }
  }

  render () {
    if ( !!this.props.initParagraphRefs ) this.props.initParagraphRefs();
    const setParagraphRef = this.props.setParagraphRef || (() => {});

    const paragraphItems = this.props.codedTranscript.paragraphs.map((paragraph, index) =>
      {
        if ( !paragraph || (paragraph.start === paragraph.end)) return null;

        return (
          <div key={paragraph.index}
                ref={(pc)=>{setParagraphRef(index, pc)}}>
            <ParagraphContainer codedParagraph={paragraph}
                                absoluteIndex={paragraph.index}
                                paragraphIndex={index}
                                transcriptID={this.props.transcriptID}
                                canEdit={
                                  !this.props.isPrint &&
                                  !!this.props.hasEditPermission
                                }
                                isPrint={!!this.props.isPrint}
                                hasEditPermission={!!this.props.hasEditPermission}
                              />
          </div>
        );
      }
    );

    const paragraphPadding = `${PARAGRAPH_PADDING}px`

    return (
      <div
        style={{margin:0, padding:"20px 10px"}}
        onMouseUp={this.onMouseUp}>
        <div ref={this.props.bodyRef}>
          {this.props.title &&
            <div style={{paddingLeft:paragraphPadding}}>
              <h3>{this.props.title}</h3>
            </div>
          }
          {paragraphItems}
          {this.props.isPrint && paragraphItems.length > 0 &&
            <div style={{paddingLeft:paragraphPadding}}>
              <hr style={{boderWidth:'0px'}}/>
              Created with the <a href="http://www.delvetool.com">Delve Qualitative Analysis Tool </a>
            </div>
          }
        </div>
      </div>
    );
  }
}

export default TranscriptBody;
