import React from 'react';
var SearchResultUtil = {
  renderHighlightedText: function(match)
  {
    if ( !match ) return null;
    const parts = match.split(/<strong>|<\/strong>/);
  
    return parts.map((part, index) => {
      if (index % 2 === 0) {
        return part; // This will be the regular, non-bolded text
      } else {
        return <strong key={index}>{part}</strong>; // This will be the bolded text
      }
    });
  },
}

export default SearchResultUtil;
