import React, { createContext, useState, useContext } from 'react';
import DeleteMemoModal from 'memos/DeleteMemoModal';
const ModalContext = createContext();
import MemoActions from 'memos/MemoActions'
import { withToastManager } from 'react-toast-notifications';

const onDelete = (memoData, closeModal, toastManager) => {
  MemoActions.deleteMemo(memoData).catch((error)=>{
    toastManager.add(
      'Error deleting memo. Refresh and try again.', {
      appearance: 'error',
      autoDismiss: true,
      autoDismissTimeout: 7000
    })
  });
  closeModal();
}

export const useModal = () => useContext(ModalContext);

export const MemoModalProvider = withToastManager((props) => {
  const { children } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [memoData, setMemoData] = useState(null);

  const openModal = (data) => {
    setIsModalOpen(true);
    setMemoData(data);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setMemoData(null);
  };

  return (
    <ModalContext.Provider value={{ openModal, isModalOpen }}>
      {children} {/* This renders the parts of your app wrapped by the provider */}
      <DeleteMemoModal
        show={isModalOpen}
        onCancel={closeModal}
        onDelete={() => onDelete(memoData, closeModal, props.toastManager)}
      />
    </ModalContext.Provider>
  );
});
