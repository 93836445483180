// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';
import QualCodeActions from 'actions/QualCodeActions'
import { Scrollbars } from 'react-custom-scrollbars';
import ColorConstants from 'constants/ColorConstants'

var _ = require('underscore');


class ViewOnlyDemographicView extends React.Component {
  constructor () {
    super();
    this.state = {
    }
  }

  render () {
    const descriptorElements = this.props.descriptors.map((descriptor, index) =>
      {
        const selectedMultiChoices = (descriptor.selected_multi_choice || {}).choice;
        return <div style={{margin:'25px 15px'}}>
          <p><strong>{descriptor.name}</strong></p>
          {selectedMultiChoices ?
              <p>
                {selectedMultiChoices}
              </p> :
              <p style={{color:ColorConstants.LIGHT_GREY}}>
                <em>Non specified</em>
              </p>
          }
        </div>
      });

    return (
        <div style={{padding:"10px", height:"100%"}}>
          <Scrollbars autoHide style={{height:"100%"}}>
              {descriptorElements}
              <div style={{height:"200px", width:"10px"}}/>

                <div style={{ float:"left", clear: "both" }}
                     ref={(el) => { this.messagesEnd = el; }}>
                </div>
          </Scrollbars>
        </div>
    );
  }
}

export default ViewOnlyDemographicView;
