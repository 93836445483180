import {HTTPError} from 'apis/HTTPErrors';
import InitialState from 'constants/InitialState';

const DEFAULT_ERROR_MESSAGE = "Something went wrong. Please refresh and try again.";

var errorUtil = {
  createErrorStateWithMessage: function(state, message, defaultDismiss) {
    return {
      ...state,
      error: {
        error: true,
        message: message,
        defaultDismiss: !!defaultDismiss
      }
    }
  },

  clearError: function(state) {
    return {
      ...state,
      errorDict: InitialState.errorDict,
      error: {
        error: false,
        message: null
      }
    }
  },

  createDefaultMessage(state)
  {
    return this.createErrorStateWithMessage(state, DEFAULT_ERROR_MESSAGE);
  },

  checkHTTPError(state, error, actionType, id) {
    if ( error instanceof HTTPError ) {
      return {
        ...state,
        errorDict:{
          ...state.errorDict,
          [actionType]: {
            [id]: {
              errorCode: error.status,
              message: null,
              error: true
            }
          }
        }
      }
    }

    return this.createDefaultMessage(state)
  },


  clearErrorType(state, actionType) {
      return {
        ...state,
        errorDict:{
          ...state.errorDict,
          [actionType]: {}
        }
      }
  },

  createError(errorConstantObject) {
    errorConstantObject = errorConstantObject || {};
    let error = new Error(errorConstantObject.message || 'Unknown Delve Error');
    error.name = errorConstantObject.name || 'DelveErrorUnknown';
    return error;
  },
}

export default errorUtil
