// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';
import RowModal from './RowModal'
import ModalRow from './ModalRow'
import FileErrorModal from './FileErrorModal'
import ColorConstants from 'constants/ColorConstants'
import GenericConstants from 'constants/GenericConstants'
import { withRouter } from 'react-router';
import FileRow from './FileRow'
import ProjectsStore from 'stores/ProjectsStore'
import QualCodeActions from 'actions/QualCodeActions'
import {
  FILES__COUNT_LIMIT,
  FILES__TYPE_UNSUPPORTED
} from 'constants/ErrorConstants'

class FileLoadingModal extends React.Component {
  constructor () {
    super();

    this.state = {
      ...ProjectsStore.getFiles(),
    };

    this._onChange = this._onChange.bind(this);
    this.onHide = this.onHide.bind(this);
    this.onClear = this.onClear.bind(this);
    this.beforeUnloadFunction = this.beforeUnloadFunction.bind(this);
  }

  onClear(e) {
    QualCodeActions.clearFileUpload();
  }

  onHide (e)
  {
    QualCodeActions.dismissFile(true);
  }

  beforeUnloadFunction(e) {
    if ( !this.state )
      return;

    if( !this.state.loading ) {
      return;
    }
    e.preventDefault();
    e.returnValue = 'Files have not finished uploading';
    return 'Files have not finished uploading';
  }

  componentDidMount () {
    ProjectsStore.addChangeListener(this._onChange);
    window.addEventListener("beforeunload", this.beforeUnloadFunction);
  }

  componentWillUnmount () {
    ProjectsStore.removeChangeListener(this._onChange);
  }

  _onChange()
  {
    this.setState({
      ...ProjectsStore.getFiles()
    })
  }

//errorMessage=
  render () {
    let errorMessage = null;
    if ( this.state.hasTooBig || this.state.errorMessage ) {
      errorMessage = this.state.errorMessage;
      if ( this.state.hasTooBig )
        errorMessage = `${errorMessage}. Files need to be under ${GenericConstants.CONTEST_MAX_CHARACTERS/1000},000 characters.`
    }

    return (
      <div>
        <RowModal
          show={this.state.show}
          onHide={this.onHide}
          buttonText='Done'
          buttonAction={this.onHide}
          header={<h4 style={{marginTop:0}}>{this.state.message}</h4>}
          errorMessage={errorMessage}
          >
          {this.state.files.map((file, index)=>{
            const hasError = !!file.error
            return <FileRow
              name={file.fileName}
              loaded={!!file.loaded}
              error={hasError}
              errorMessage={hasError ? file.error.message : null}
              file={file}
              key={index}/>
          })}
        </RowModal>
        <FileErrorModal
          type={this.state.errorParsing}
          onClear={this.onClear}
          />
      </div>
    );
  }
}

export default withRouter(FileLoadingModal);
