// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';
import {LinkContainer} from 'react-router-bootstrap'

function SideBarButton(props) {
  if ( !props.isOnUserPage ) {
    return (<div
        style={props.linkStyle}
        className={props.className}
      >
      <LinkContainer to={props.to}>
        <div>
          {props.children}
        </div>
      </LinkContainer>
    </div>)
  } else {
    return (
      <div
        onClick={props.onClick}
        style={props.linkStyle}
        className={props.className}
      >
        {props.children}
      </div>
    )
  }
}

export default SideBarButton;
