const ROOT_KEY = 'ROOT';

const getParent = (state, nodeID) =>
{
  const code = state.entities.codes[state.mappers.codes[nodeID]];
  if ( code )
    if ( code.parent_id )
      return code.parent_id;

  return ROOT_KEY;
}

export default getParent;
