// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';
import AccountSingleButton from './AccountSingleButton'

class TeamModalRemove extends React.Component {
  render () {
    const subscription = this.props.subscription ? this.props.subscription : {};
    return (
      <AccountSingleButton
        error={this.props.removeSeatInfo.error}
        onClick={this.props.onClick}
        loading={this.props.removeSeatInfo.loading}
        header="Review the details"
        button="Confirm Subscription Change"
        disclaimer={`By removing a team member, you agree to receive a
          prorated credit to go toward your next ${subscription.interval}'s subscription
          and you agree to automatically be charged each ${subscription.interval}
          with your updated number of team members until you cancel.`}
        body={<p>You are reducing your subscription by 1 team member.</p>}
        />
    );
  }
}

export default TeamModalRemove;
