// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';
import CodedTranscriptStore from 'stores/CodedTranscriptStore'
import QualCodeActions from 'actions/QualCodeActions'
import SpeakerParagraphComponent from './SpeakerParagraphComponent'

var _ = require('underscore');

class ParagraphContainer extends React.Component {
  constructor() {
    super();
    this.onStartEditing = this.onStartEditing.bind(this);
    this.onDoneEditing = this.onDoneEditing.bind(this);
  }

  onStartEditing(e)
  {
    QualCodeActions.editParagraph(this.props.transcriptID, this.props.absoluteIndex)
    if ( e.stopPropagation )
      e.stopPropagation();
  }

  onDoneEditing(newText)
  {
    QualCodeActions.doneEditParagraph(this.props.transcriptID, this.props.absoluteIndex, newText);
  }

  render () {
    const characterStart = this.props.codedParagraph.start === 0 ? 1 : this.props.codedParagraph.start;

    return (<SpeakerParagraphComponent   codedParagraph={this.props.codedParagraph}
                                paragraphIndex={this.props.paragraphIndex}
                                absoluteIndex={this.props.absoluteIndex}
                                paragraphNumber={characterStart}
                                onEditClick={this.onStartEditing}
                                canEdit={this.props.canEdit}
                                hasEditPermission={this.props.hasEditPermission}
                                isPrint={this.props.isPrint}
                                marginLeft={this.props.marginLeft}
                                marginRight={this.props.marginRight}
                              />);
  }
}

export default ParagraphContainer;
