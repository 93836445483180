// ExcerptMapper.js
import memoMapper from './MemoMapper';
import collaboratorsMapper from './CollaboratorsMapper';
import transcriptIDToProjectIDMapper from './TranscriptIDToProjectIDMapper';
import OptimisticMapperHelper from './OptimisticMapperHelper';
import FallbackNameMapper from './FallbackNameMapper';
const _ = require('underscore');

const excerptMapper = (state, excerptID) => {
  var excerpt = OptimisticMapperHelper.getExcerpt(state, excerptID);
  if ( !excerpt ) return null;

  excerpt = {...excerpt};
  const transcript = state.entities.transcripts[excerpt.transcript_id] ? state.entities.transcripts[excerpt.transcript_id] : {};

  const projectID = transcriptIDToProjectIDMapper(state, excerpt.transcript_id);
  
  const collaborators = collaboratorsMapper(state, projectID);
  const userColors = collaborators.userColors;
  
  const memos = _.chain((excerpt.memos || [])).map((memo_id)=>
    memoMapper(state, memo_id, userColors)
  ).filter((memo)=>!!memo)
  .sortBy((memo)=>memo.created_at).value()

  // group by code_id
  
  const codes = _.chain(excerpt.codings)
  .groupBy((coding)=>OptimisticMapperHelper.getCodeId(state, coding.code_id)).map((codings, code_id)=>{   
    const code = OptimisticMapperHelper.getCode(state, code_id);
    if (!code) return code;

    return {
      id: code.id,
      name: code.name,
      count: code.count,
      ...(code.server_id && {server_id: code.server_id}),
      // dedupe coders by coder id
      coders: _.chain(codings).map((coding)=>{
        const user = FallbackNameMapper(state, coding.user_id) || {};

        if ( !coding.user_id ) return null;
  
        return {
          id: coding.user_id,
          name: user.name,
          time: coding.created_at
        }
      }).filter((coder)=>!!coder)
      .uniq((coder)=>coder.id)
      .sortBy((coder)=>coder.time).value()
    }
  }).filter((code)=>{return !!code}).sort(
    (a,b) => {
      const nameA = ((a || {}).name || '').toLowerCase();
      const nameB = ((b || {}).name || '').toLowerCase();
      return nameA.localeCompare(nameB);
    }
  ).value()

  delete excerpt['codings'];

  return {
    ...excerpt,
    transcript:{
      id: transcript.id,
      name: transcript.name ? transcript.name : "Untitled",
      length: transcript.body ? transcript.body.length : 0,
    },
    codes,
    memos
  }
}

export default excerptMapper
