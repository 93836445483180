// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';
import {Glyphicon, Modal, Button} from 'react-bootstrap'
import Textarea from 'react-textarea-autosize';
import ColorConstants from 'constants/ColorConstants'
import LoadingButton from './LoadingButton'
import {Alert} from 'react-bootstrap'
import {VERSION_MISMATCH} from 'constants/VersioningErrorConstants'

const LEFT_PADDING = 45;

const LABEL_STYLE = {color: "white", backgroundColor: ColorConstants.DARK_GREY}

class EditTranscriptModal extends React.Component {
  constructor () {
    super();
    this.state = {
      text: null,
    };
    this.onHide = this.onHide.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onTextChange = this.onTextChange.bind(this);
    this.refreshPage = this.refreshPage.bind(this);
  }

  refreshPage(e)
  {
     window.location.reload();
  }

  static getDerivedStateFromProps(props, state)
  {
    if ( !state.originalText || state.originalText != props.text)
    {
      const text = props.text ? props.text : "";
      return {
        originalText: text,
        text:text,
      }
    }

    return null;
  }

  onTextChange(event){
    this.setState({text: event.target.value})
  }

  onHide (e)
  {
    this.props.hideModal();
  }

  onSave (e)
  {
    this.props.onSave(this.state.text);
  }

  createErrorAlert(intro, button)
  {
    return(
      <Alert bsStyle="warning" style={{textAlign:"center"}}>
        {intro}<Button bsStyle="link" style={{padding:0}} onClick={this.refreshPage}>
          {button}
        </Button>
      </Alert>
    )
  }

  errorWarning(errorCode)
  {
    if ( !errorCode )
      return <div></div>

    if ( errorCode == VERSION_MISMATCH )
      return this.createErrorAlert("Someone else has edited this transcript. To avoid overwriting those changes, ",
        "  please refresh this page."
      );
    else
      return this.createErrorAlert("Something went wrong. ",
        " Please refresh this page and try again."
      );
  }

  render () {
    const errorComponent = this.errorWarning(this.props.errorCode);

    return (
      <div>
        <Modal show={this.props.show} onHide={this.onHide} animation={false}>
            <Modal.Body style={{padding: 0, margin: 0}}>
              <div style={{padding:`15px ${LEFT_PADDING}px 10px ${LEFT_PADDING}px`}}>
                <div style={{float:"right"}}>
                  <Glyphicon onClick={this.onHide} style={{color:ColorConstants.LIGHT_GREY, cursor:"pointer"}} glyph='remove'/>
                </div>
                <div style={{clear:"both"}}/>
                <div>
                  <h4 style={{marginTop:0, paddingTop:0}}>
                    Edit Text
                  </h4>
                </div>
                {errorComponent}
                <div style={{marginTop: 10, width: "100%"}}>
                  <Textarea placeholder=""
                            onChange={this.onTextChange}
                            value={this.state.text}
                            style={{  width: "100%",
                                      resize:'none',
                                      padding:'20px 30px',
                                      borderColor:"#d7d7d7",
                                      borderRadius: "5px",
                                      outline: "none",
                                  }}
                  />
                </div>
              </div>
            <div style={{float:"right", marginTop:"0px", marginRight:"45px", marginBottom:"20px"}}>
              <LoadingButton bsStyle="blue" loading={!!this.props.saving} onClick={this.onSave} disabled={!!this.props.errorCode}>
                {!!this.props.errorCode ? "Cannot Save Changes" : "Save Changes"}
              </LoadingButton>
            </div>
            <div style={{clear:"both"}}/>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default EditTranscriptModal;
