// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';
import {MenuItem, DropdownButton} from 'react-bootstrap'

function UserDropdown(props) {
  const menuItems = props.users.map((user, index) => {
    return (
      <MenuItem
        eventKey={`user-${index}`}
        onClick={(event)=>{props.onSelect(user.id)}}
        key={`user-${index}`}
      >
        {user.name}
      </MenuItem>
    )
  })

  const user = props.user;
  const name = user ? user.name : 'Select User';

  return (<DropdownButton
    title={`Coded By: ${name}`}
    id={`user-dropdown`}
    bsStyle='white'>
      <MenuItem header>
        Coded By
      </MenuItem>
      {menuItems}
    </DropdownButton>)
}

export default UserDropdown;
