import QualCodeActions from './QualCodeActions'
import FilterActions from './FilterActions'
import ProjectsStore from 'stores/ProjectsStore'
import AnalysisStore from 'stores/AnalysisStore'
import CodeStore from 'stores/CodeStore'
import Exporter from 'utils/Exporter'
import ReportAPI from 'apis/ReportAPI'
import trackingUtil from 'utils/TrackingUtil'
import textFileExporter from 'utils/TextFileExporter'
import csvFileExporter from 'utils/CSVFileExporter'



var ExportActions = {
  /*****************************\
  *
  * Exporting to Word
  *
  \*****************************/
  exportToWord: function(projectId)
  {
    FilterActions._pageThroughExcerpts(projectId).then((result)=>{
      const exportObject = CodeStore.getCodesForExport(projectId);
      const exporter = new Exporter(exportObject.projectName);
      exporter.export(exportObject.projectName, exportObject.fileName, exportObject.codes);
      trackingUtil.logEvent('export-codebook');
    })
  },

  exportCodesToCSV: function(projectId)
  {
    const exportObject = CodeStore.getCodesForCSVExport(projectId);
    csvFileExporter(exportObject.projectName, exportObject.codeText);
    trackingUtil.logEvent('export-codebook-csv');
  },

  exportTranscript: function(transcriptId) {
    return QualCodeActions.loadTranscript(transcriptId).then((result)=>{
      const transcript = ProjectsStore.getTranscript(transcriptId) || {};
      const name = (transcript.name || 'Untitled');
      textFileExporter(name, transcript.body);
      trackingUtil.logEvent('export-transcript');
    });
  },

  exportSnippets: function(projectId) {
    const project = ProjectsStore.getProject(projectId);
    if ( !project ) return;
    return FilterActions.pageThroughExcerptsWithFilters(projectId).then((result)=>{
      const exportResults = AnalysisStore.getExportExcerptMapper(projectId);
      if ( !exportResults ) return;
      csvFileExporter(exportResults.projectName, exportResults.exportExcerpts);
      trackingUtil.logEvent('export-snippets')
    });
  },

  exportCodings: function(projectId) {
    const project = ProjectsStore.getProject(projectId);
    if ( !project ) return;
    return FilterActions.pageThroughExcerptsWithFilters(projectId).then((result)=>{
      const exportResults = AnalysisStore.getExportCodingsMapper(projectId);
      if ( !exportResults ) return;
      csvFileExporter(exportResults.projectName, exportResults.exportExcerpts);
      trackingUtil.logEvent('export-codings')
    });
  },


  exportCodesVCodesMatrix: function(projectId) {
    ReportAPI.getCodesVCodesReport(projectId).then((results)=>{
      const exportResults = AnalysisStore.getCodesVCodesMatrix(
        projectId,
        results.data
      );
      if ( !exportResults ) return;
      csvFileExporter(exportResults.projectName, exportResults.matrix);
      trackingUtil.logEvent('export-codes-v-codes');
    });
  },

  exportCodesVTranscriptsMatrix: function(projectId) {
    ReportAPI.getTranscriptCodesReport(projectId).then((results)=>{
      const exportResults = AnalysisStore.getCodesVTranscriptsMatrix(
        projectId,
        results.data
      );
      if ( !exportResults ) return;
      csvFileExporter(exportResults.projectName, exportResults.matrix);
      trackingUtil.logEvent('export-codes-v-transcripts')
    });
  },

  exportCodesVDescriptorMatrix: function(projectId) {
    ReportAPI.getDescriptorVCodesReport(projectId).then((results)=>{
      const exportResults = AnalysisStore.getCodesVDescriptorMatrix(
        projectId,
        results.data
      );
      if ( !exportResults ) return;
      csvFileExporter(exportResults.projectName, exportResults.matrix);
      trackingUtil.logEvent('export-codes-v-descriptor')
    });
  },

  printTranscript: function(handlePrint) {
    handlePrint();
    trackingUtil.logEvent('export-transcript-pdf')
  }
}

export default ExportActions
