import OptimisticMapperHelper from '../mappers/OptimisticMapperHelper';
var _ = require('underscore');

const alphabeticalSort = (a,b) => {
  var nameA = a && a.name ? a.name.toUpperCase() : ''; // ignore upper and lowercase
  var nameB = b && b.name ? b.name.toUpperCase() : ''; // ignore upper and lowercase
  return nameA.localeCompare(nameB);
}

const coderSort = (a,b) => {
  // selected codes go first
  if ( !!a.selected !== !!b.selected ) return a.selected ? -1 : 1;

  const timeA = a && a.time ? a.time : '';
  const timeB = b && b.time ? b.time : '';
  return timeB.localeCompare(timeA);
};


function getCodes(state, excerpts, selectedExcerpt, selectedCodes){
  var codes = _.chain(excerpts ||  []).map((excerpt)=>{
      const isSelectedExcerpt = !!selectedExcerpt && excerpt.id === selectedExcerpt.id;

      return excerpt.codes.map((code)=>{
        const updatedCode = OptimisticMapperHelper.getCode(state, code.id);
        if ( !updatedCode ) return null;

        return {
          ...updatedCode,
          selected: selectedCodes.includes(code.id),
          coders: (code.coders || []).map((coder)=>{
            return {
              ...coder,
              selected: isSelectedExcerpt
            }
          })
        }
      }).filter((code)=>{return !!code})
    }
  ).flatten().flatten().value();

  const flattened = _.chain(codes)
  .groupBy((c)=>c.id)
  .mapObject((codes, id)=>{
    return codes.reduce((acc, code)=>{
      const coders = _.chain([
        ...acc.coders || [],
        ...code.coders || []
      ]).groupBy((c)=>c.id).mapObject((userCodings, userId)=>{
        // return the userCoding with the most recent userCodings
        const selectedCodings = userCodings.filter((c)=>c.selected);
        const possilbeCodings = selectedCodings.length > 0 ? selectedCodings : userCodings;
        return _.max(possilbeCodings, (c)=>Date.parse(c.time || 0));
      }).values().value();

      return {
        ...acc,
        ...code,
        coders: coders.sort(coderSort)
      }
    }, {})
  }).values().value().sort(alphabeticalSort)
  
  return flattened;
}


export default getCodes;
