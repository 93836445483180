//TODO: Add a Unit Test
export default (promo, coupon) => {
  if ( !coupon || !promo ) return null;
  const data = coupon.data || {};
  const percentOff = data.percent_off;
  const monthsOff = data.duration_in_months;

  if ( !percentOff || !monthsOff ) return null;
  const message = `OFFER ${promo} | Start free trial to get ${percentOff}% off your first ${monthsOff} months of Delve`
  return {
    message
  }
}
