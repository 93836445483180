var _ = require('underscore');

function reduceObjectArray(arr) {
    return _.reduce(arr, function(memo, obj) {
        _.each(obj, function(value, key) {
            if (memo[key]) {
                memo[key] += value;
            } else {
                memo[key] = value;
            }
        });
        return memo;
    }, {});
}

function computeRatio(obj) {
    let sumSquares = 0;
    let sum = 0;

    Object.values(obj).forEach(value => {
        sumSquares += Math.pow(value, 2);
        sum += value;
    });

    return sumSquares / Math.pow(sum, 2);
}

function calculatePE(overlappingExcerpts) {
    // for each excerpt
    const codeSize = overlappingExcerpts.map((excerpt) => {
        // return the code count multiplied by the size (end - start)
        return _.chain(excerpt.code_count).mapObject((value, key) => {
            return value * (excerpt.end - excerpt.start);
        }).value()
    })

    // sum the code sizes across all excerpts
    const codeSizeSum = reduceObjectArray(codeSize);
    const ratio = computeRatio(codeSizeSum);
    return ratio;
}

export default calculatePE;