// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import ColorConstants from 'constants/ColorConstants'

function PageLoadingComponent(props) {
  return (<div style={{position:"relative", height:"100%"}}>
    <div style={{position:"absolute",
                  top:"30%",
                  width:"100%",
                    textAlign:"center"
                  }}>
            <ClipLoader
              color={ColorConstants.ACTIVE_BUTTON_BLUE}
              sizeUnit={"px"}
              size={19}
              loading={true}/>
        </div>
  </div>);
}

export default PageLoadingComponent;
