import AppDispatcher from 'dispatcher/AppDispatcher'
import NetworkConstants from 'constants/NetworkConstants';
import QualConstants from 'constants/QualConstants'

var ErrorActions = {
  /***************************\
  *
  * Error Actions
  *
  \***************************/
  networkError: function(error)
  {
    AppDispatcher.handleAction({
      actionType: NetworkConstants.NETWORK_ERROR,
      data: {},
      error: error
    });
  },
  clearError: function()
  {
    AppDispatcher.handleAction({
      actionType: QualConstants.CLEAR_ERROR,
      data: {}
    });
  },
}

export default ErrorActions
