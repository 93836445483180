import {
  TRANSCRIPT_PAGE,
  CODED_BY_ME_PAGE,
  DEMOGRAPHICS_PAGE,
  COMPARISON_PAGE
} from 'constants/PageConstants'

const transcriptPages = [
  TRANSCRIPT_PAGE,
  CODED_BY_ME_PAGE,
  DEMOGRAPHICS_PAGE,
  COMPARISON_PAGE
]

const queryString = require('query-string');

function addParamsToUrlObject(location, params) {
  const parsed = queryString.parse(location.search);
  const newParams = {
    ...parsed,
    ...params
  }
  const newSearch = queryString.stringify(newParams);
  return {
    pathname: location.pathname,
    search: newSearch
  }
}

var UrlUtil = {
  getTranscriptUrl: function(page, params)
  {
    const { transcriptID, location } = params || {};
    const { search } = location || {};

    if ( transcriptPages.includes(page) && !transcriptID )
      return '/transcripts';

    switch (page) {
      case CODED_BY_ME_PAGE:
        return `/transcripts/${transcriptID}/user/me`;
      case DEMOGRAPHICS_PAGE:
        return `/transcripts/${transcriptID}/demographics`;
      case COMPARISON_PAGE:
        // keep left and right if they are in search
        const { left, right } = queryString.parse(search);
        const newSearch = queryString.stringify({ left, right });
        return `/transcripts/${transcriptID}/compare${newSearch ? `?${newSearch}` : ''}`;
      default:
        return `/transcripts/${transcriptID}`;
    }
  },
  addParamsToUrlObject: addParamsToUrlObject,
  addParamsToUrl(url, params) {
    const urlObject = addParamsToUrlObject({
      pathname: url,
      search: ''
    }, params);

    return `${urlObject.pathname}?${urlObject.search}`;
  },
  setPage: function(page, location) {
    const parsed = queryString.parse(location.search);

    const queryParams = [
      parsed.left ? `left=${parsed.left}` : null,
      parsed.right ? `right=${parsed.right}` : null,
      `page=${page}`
    ].filter((param) => !!param).join('&');
    
    return `${location.pathname}?${queryParams}`;
  },
  getPageNumber: function(location) {
    const parsed = queryString.parse(location.search);
    return !!parsed.page && parseInt(parsed.page) ? parseInt(parsed.page) : 1;
  }
}

export default UrlUtil;
