// app/javascript/projects/components/ProjectsDisplay.jsx

import { DropTarget } from 'react-dnd';
import React from 'react';

import { DragItemTypes } from 'constants/DragConstants';

import CodingActions from '../../actions/CodingActions';
import DragCodeLabel from './DragCodeLabel'
import MarginDropZone from './MarginDropZone'

let updates = 0;
const codeTarget = {
  drop(props, monitor) {
    const item = monitor.getItem();
    const didDrop = monitor.didDrop();

    if ( !didDrop && !props.ghost) {
      if ( !!props.canDropOnParent )
        CodingActions.nestCodes(item.codeID, props.id, 1);
      else
        props.rejectNesting();
    }
  }
}

function collectTarget(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver({ shallow: true }),
  }
}

class CodeLabelDropZone extends React.Component {
  constructor () {
    super();
    this.state = {
      renameMode: false
    }

    this.onRename = this.onRename.bind(this)
    this.renameCode = this.renameCode.bind(this);
  }

  renameCode (id, name) {
    this.props.renameCode(id, name);
    this.setState({
      renameMode: false
    });
  }

  onRename () {
    this.setState({
      renameMode: true
    })
  }

  render () {
    const { connectDropTarget, isOver} = this.props;

    const margin = (this.props.level - 1) * 30 + "px";
    const nestMargin = (this.props.level) * 30 + "px";
    const marginMargin = (this.props.marginDepth - 1) * 30 + "px"

      return connectDropTarget(
        <div>
          <div style={{paddingBottom:"12px", marginLeft: margin}}>
            <DragCodeLabel
                initiateMergeCode={this.props.initiateMergeCode}
                fadeIn={this.props.fadeIn}
                childrenCount={this.props.childrenCount}
                newCode={this.props.newCode}
                barCollapsed={this.props.barCollapsed}
                showMerge={!!this.props.showMerge}
                count={this.props.count}
                position={this.props.position}
                parentId={this.props.parentId}
                name={true ? this.props.name : `${this.props.position} - ${this.props.name}`}
                mergeName={this.props.mergeName}
                id={this.props.id}
                mergeParent={this.props.mergeParent}
                showConfirmMerge={this.props.showConfirmMerge}
                      // TODO: this should be included in codes, this logic is extra
                selected={this.props.selected}
                onClick={this.state.renameMode ? ()=>{} : this.props.onClick}
                disabled={this.props.disabled}
                deleteCode={this.props.deleteCode}
                dragOver={isOver}
                startDrag={this.props.startDrag}
                endDrag={this.props.endDrag}
                ghost={this.props.ghost}
                draggingEnabled={!this.props.ghost && this.props.draggingEnabled && !this.state.renameMode}
                onCollapseClicked={this.props.onCollapseClicked}
                cancelMerge={this.props.cancelMerge}
                confirmMerge={this.props.confirmMerge}
                onShowMerge={this.props.onShowMerge}
                allowDropdown={this.props.allowDropdown}
                renameCode={this.renameCode}
                onRename={this.onRename}
                renameMode={this.state.renameMode}
                canDropOnParent={this.props.canDropOnParent}
                searchedCodeUnmounted={this.props.searchedCodeUnmounted}
            />
        </div>
        {this.props.isDragging && <MarginDropZone
          dropPosition={this.props.marginDropPositon}
          dropCodeId={this.props.marginDropCodeId}
          isOverParent={isOver || !!this.props.isOverBottom}
          canDropOnParent={this.props.canDropOnParent}
          canDropOnMargin={this.props.canDropOnMargin}
          depth={isOver ? this.props.level : this.props.marginDepth - 1}
          ghost={!!this.props.ghost}
          rejectNesting={this.props.rejectNesting}
          noop={this.props.marginNoOp}
        />}
      </div>
      );
  }
}

export default DropTarget(DragItemTypes.CODE_LABEL, codeTarget, collectTarget)(CodeLabelDropZone);
