// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';
import CollabModal from '../CollabModal'
import ShareStore from 'stores/ShareStore'
import ProjectsStore from 'stores/ProjectsStore'
import {Button} from 'react-bootstrap'
import CollabActions from 'actions/CollabActions'
import PopoverButton from './PopoverButton'


class ShareButton extends React.Component {
  constructor () {
    super();
    this.state = {
      ...ShareStore.getCollaborators(null),
      project: {}
    };

    this.showCollabModal = this.showCollabModal.bind(this);
    this.hideCollabModal = this.hideCollabModal.bind(this);
    this._onChange = this._onChange.bind(this);
  }

  _onChange()
  {
    this.setState({
      ...ShareStore.getCollaborators(this.props.projectID),
      project: ProjectsStore.getProject(this.props.projectID),
    });
  }

  showCollabModal(e)
  {
    if ( !this.props.projectID ) return;
    CollabActions.getCollabList(this.props.projectID);

    this.props.onShowCollabModal(true)
    this.setState({
      ...ShareStore.getCollaborators(this.props.projectID)
    });
  }

  hideCollabModal(e)
  {
    this.props.onShowCollabModal(false)
  }

  componentDidMount () {
    ShareStore.addChangeListener(this._onChange);
    ProjectsStore.addChangeListener(this._onChange);
  }

  componentWillUnmount () {
    ShareStore.removeChangeListener(this._onChange);
    ProjectsStore.removeChangeListener(this._onChange);
  }

  render () {
    const button = this.props.hasEditPermission ? <Button
      bsStyle="white"
      onClick={this.showCollabModal}
      disabled={false}
    >
      Share
    </Button> : <PopoverButton
      name="Share"
      onClick={this.showCollabModal}
      disabled={true}
    >
      You can only view this project. Request edit access from the project owner in order to edit and share.
    </PopoverButton>

    return (
      <div className='shareButton'>
        {!!this.props.hasEditPermission && <CollabModal
          show={this.props.showCollabModal}
          projectID={this.props.projectID}
          name={(this.state.project || {}).name}
          hideCollabModal={this.hideCollabModal}
          collaborators={this.state.collaborators}
          owner={this.state.owner}
          shareError={this.state.shareError}
          seatError={this.state.seatError}
          myRole={this.state.myRole}
          subscription={(this.props.subscriptionState || {}).subscription}
        />}
        {button}
      </div>
    );
  }
}

export default ShareButton;
