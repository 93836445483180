module.exports =  {
  // parse files
  PARSE_FILES: "PARSE_FILES",
  PARSE_FILES_ERROR:"PARSE_FILES_ERROR",
  PARSED_FILE: "PARSED_FILE",
  PARSED_FILE_ERROR: "PARSED_FILE_ERROR",
  PARSE_FILES_HIDE: "PARSE_FILES_HIDE",
  PARSE_FILES_SHOW: "PARSE_FILES_SHOW",
  PARSE_FILE_CLEAR: "PARSE_FILE_CLEAR"
}
