var _ = require('underscore');

const editTranscriptMapper = (state, transcriptID, editTranscriptState) => {
  const emptyState = {
    transcript: {},
    editTranscriptState:{
    }
  }

  if ( !transcriptID ) return emptyState;
  const transcript = state.entities.transcripts[transcriptID];
  if (!transcript ) return emptyState;

  return {
    ...emptyState,
    transcript:{
      id: transcriptID,
      body: transcript.body,
      name: transcript.name,
      version: transcript.version,
    },
  }
}

export default editTranscriptMapper
