import { IntercomAPI } from 'react-intercom';

var trackingUtil = {
  logEvent: function logEvent(eventName, params)
  {
    if ( !eventName ) return;
    
    if ( params )
    {
      IntercomAPI('trackEvent', eventName, params);
    }
    else {
      IntercomAPI('trackEvent', eventName);
    }
  },

  updateAttribute: (attribute, value) => {
    if ( !!attribute && !!value ) {
      IntercomAPI('update', {[attribute]: value})
    }
  }
}

export default trackingUtil
