// memoMapper.js

const {
  FEATURE_FLAG_AI_CODING
} = require('./FeatureFlagConstants');

const feature_flag_mapper = (state) => {
  if (!state.feature_flags) return null;
  if ( !state.loaded ) return null;
  
  return state.feature_flags.includes(FEATURE_FLAG_AI_CODING)
}

export default feature_flag_mapper
