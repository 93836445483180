import CodingConstants from 'constants/CodingConstants'
import ExcerptMathUtil from 'utils/ExcerptMathUtil'
var _ = require('underscore');

const DEFAULT_STYLE = CodingConstants.NORMAL;

class SentenceClass {
  constructor(text, start, excerptId, excerptIds){
    this.text = text;
    this.start = start;
    this.end = start + text.length;
    this._excerptId = excerptId;
    this._excerptIds = excerptIds;
  }

  _createSelects(select)
  {
    var original = {
      start: this.start,
      end: this.end,
      label: DEFAULT_STYLE
    };

    if ( !select ) return [original];

    var selectResults = ExcerptMathUtil.subtract(original, [{
      start: select.start,
      end: select.end,
      label: CodingConstants.SELECTED
    }]);

    return selectResults;
  }

  _createElements(originalElement, primeCode, excerpts)
  {
    var primifiedExcerpts = (excerpts || []).
      filter((excerpt)=>
      // inside excerpt and has to have codes
        excerpt.end > this.start && excerpt.start < this.end && !!excerpt.codes && excerpt.codes.length > 0
      ).map((excerpt) =>{
        const containsPrime = primeCode ? excerpt.codes.map((code)=>code.id).includes(primeCode): false;
        return {
          start: excerpt.start,
          end: excerpt.end,
          prime: containsPrime
        }
      });

    var unionedExcerpts = ExcerptMathUtil.union(primifiedExcerpts).map((excerpt) =>{
      return {
        start: excerpt.start,
        end: excerpt.end,
        label: excerpt.prime ? CodingConstants.SUPERCODED: CodingConstants.CODED
      }
    });

    var excerptMathResult = ExcerptMathUtil.subtract(originalElement, unionedExcerpts);

    return excerptMathResult.map((phrase)=>{
      return {
        style:phrase.label,
        text:this.text.substring(phrase.start - this.start, phrase.end - this.start)
      }
    })
  }

  _createSelectedElements(primeCode, select, excerpts){
    var selectedResults = this._createSelects(select);

    return selectedResults.map((selectUnit)=>{
      return {
        style: selectUnit.label,
        elements: this._createElements({
            start: selectUnit.start,
            end: selectUnit.end,
            label: CodingConstants.UNCODED
          }, primeCode, excerpts
        )
      }
    })
  }

  getCodedFormat(primeCode, select, excerpts){
    return {
      start:this.start,
      end: this.end,
      excerptId: this._excerptId,
      excerptIds: this._excerptIds,
      selectElements: this._createSelectedElements(primeCode, select, excerpts)
    }
  }
}

export default SentenceClass;
