var hashString = (input_string) => {
  var hash = 0;
  if ( !input_string) return hash;
  if (input_string.length == 0) {
      return hash;
  }
  for (var i = 0; i < input_string.length; i++) {
      var char = input_string.charCodeAt(i);
      hash = ((hash<<5)-hash)+char;
      hash = hash & hash; // Convert to 32bit integer
  }
  return hash;
}

export default hashString
