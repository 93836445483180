// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';
import ReactDOM from 'react-dom';
import {Glyphicon, Modal, Button, Alert} from 'react-bootstrap'
import {DropdownButton, MenuItem} from 'react-bootstrap'
import {FormGroup, FormControl} from 'react-bootstrap'
import ColorConstants from 'constants/ColorConstants'
import CollabDropdown from './CollabDropdown'
import CollabNavigation from './CollabNavigation'

import CollabActions from 'actions/CollabActions'

const PADDING = 30;
const PADDING_RIGHT = 30;
class CollabInviteWrapper extends React.Component {
  constructor () {
    super();
    this.nextPage = this.nextPage.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.addCollaboratorClick = this.addCollaboratorClick.bind(this);
  }

  addCollaboratorClick(e) {
    if ( this.emailInput )
      ReactDOM.findDOMNode(this.emailInput).focus()

    this.props.nextPage();
  }

  onSubmit(e) {
    e.preventDefault();

    if ( !this.props.collabText )
      return;

    if ( this.emailInput )
      ReactDOM.findDOMNode(this.emailInput).blur()
    this.props.onSubmit();
  }

  nextPage(e) {
    this.props.nextPage()
    e.preventDefault();
  }

  render () {
    return (
      <CollabNavigation
        clickBack={this.props.isInvite ? this.props.clickBack : null}
        title={`Share '${this.props.name || 'Untitled Project'}'`}
        shareError={this.props.shareError || this.props.seatError}
      >
        <FormGroup>
          <form onSubmit={this.onSubmit}>

          <div style={{clear:"both"}}>
            <div style={{clear:"both"}}>
              <div style={{width:"67%", float:"left", paddingLeft:PADDING}}>
                    <FormControl
                      type="email"
                      value={this.props.collabText}
                      onChange={this.props.collabTextChange}
                      placeholder="Enter email address"
                      style={{width:"100%", padding:5}}
                      onClick={this.props.isInvite ? null : this.nextPage}
                      ref={tag => (this.emailInput = tag)}
                    />
              </div>

              <div style={{width:"30%", float:"left", paddingLeft:PADDING_RIGHT}}>
                {!this.props.isInvite ?
                  <Button
                    onClick={this.addCollaboratorClick}
                    bsStyle="blue"
                    style={{width: 90}}
                    >
                      Invite
                  </Button>
                  :
                  <CollabDropdown
                    newCollaborator={true}
                    role={this.props.collabRole}
                    updateCollaborator={this.props.collabRoleChange}
                  />
                }

              </div>
              <div style={{clear:"both"}}></div>
            </div>

            {this.props.children}
          </div>
          </form>
        </FormGroup>
      </CollabNavigation>
    );
  }
}

export default CollabInviteWrapper;
