import SentenceClass from 'utils/SentenceClass'
var _ = require('underscore');

class SentenceBuilder {
  constructor(end, start, introText, bodyText){
    this.end = end;
    this.start = start;
    this.introText = introText;
    this.bodyText = bodyText;
  }

  splitSentence(text) {
    return text.match( /([^\.!\?]+[\.!\?]+|[^\.!\?]+$|^$|[\.!\?]+)/g);
  }

  constructSentences(excerpts) {
    // Split the body text up with the text before, inside, and after the first excerpt, put it in an array
    const introLength = this.introText ? this.introText.length : 0;

    let bodyTextArray = [{
      start: 0,
      end: this.end - this.start - introLength,
      text: this.bodyText,
      type: 'text'
    }]

    // Sort Excerpt by start, and filter out the ones that do not have memos or codes
    excerpts = excerpts.sort((a, b) => {
      if ( a.start < b.start ) return -1;
      if ( a.start > b.start ) return 1;
      if ( a.end > b.end ) return -1;
      if ( a.end < b.end ) return 1;
      return 0;
    }).filter(
      (excerpt) => 
        (excerpt.memos || []).length > 0 || 
        (excerpt.codes || []).length > 0
    );
    
    for ( var i = 0; i < excerpts.length; i++ ) {
      const excerpt = excerpts[i];
      const bodyExcerptStart = excerpt.start - introLength - this.start;
      const bodyExcerptEnd = excerpt.end - introLength - this.start;

      bodyTextArray = _.flatten(
        bodyTextArray.map(
          (bodyTextElement) => {
            
            const fullyOutsideBodyText = bodyExcerptStart >= bodyTextElement.end || bodyExcerptEnd <= bodyTextElement.start;
            if ( fullyOutsideBodyText ) return bodyTextElement;

            const excerptStart = Math.max(bodyExcerptStart, bodyTextElement.start);
            const excerptEnd = Math.min(bodyExcerptEnd, bodyTextElement.end);

            return [
              {
                start: bodyTextElement.start,
                end: excerptStart,
                text: bodyTextElement.text.slice(
                  0,
                  excerptStart - bodyTextElement.start),
                type: bodyTextElement.type,
                excerptIds: bodyTextElement.excerptIds,
                excerptId: bodyTextElement.excerptId
              },
              {
                start: excerptStart,
                end: excerptEnd,
                text: bodyTextElement.text.slice(
                  excerptStart - bodyTextElement.start,
                  excerptEnd - bodyTextElement.start
                ),
                excerptId: excerpt.id || bodyTextElement.excerptId,
                excerptIds: (bodyTextElement.excerptIds || []).concat([excerpt.id]).filter((id) => !!id),
                type: 'excerpt'
              },
              {
                start: excerptEnd,
                end: bodyTextElement.end,
                text: bodyTextElement.text.slice(bodyExcerptEnd - bodyTextElement.start),
                type: bodyTextElement.type,
                excerptId: bodyTextElement.excerptId,
                excerptIds: bodyTextElement.excerptIds,
              }
            ].filter((bodyTextElement) => bodyTextElement.start !== bodyTextElement.end)
        })
      );

      //console.log(JSON.stringify(bodyTextArray, null, 2));
    }

    // apply split sentences to each of the body text array elements
    return _.chain(bodyTextArray).map((bodyTextElement) => {
      if ( bodyTextElement.type === 'excerpt' ) return bodyTextElement;

      const splitSentences = this.splitSentence(bodyTextElement.text);
      const splitSentencesWithStartAndEnd = splitSentences.map((sentence, index) => {
        const start = index === 0 ? bodyTextElement.start : bodyTextElement.start + splitSentences.slice(0, index).join('').length;
        const end = start + sentence.length;
        return {
          start,
          end,
          text: sentence,
          type: 'text'
        }
      })

      return splitSentencesWithStartAndEnd;
    }).flatten().map(((bodyTextElement) => {
      return new SentenceClass(bodyTextElement.text, bodyTextElement.start + this.start + introLength, bodyTextElement.excerptId, bodyTextElement.excerptIds);
    })).value();
  }
}

export default SentenceBuilder;
