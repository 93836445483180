// app/javascript/quotes/components/App.jsx
import React from 'react'
import {
  BrowserRouter as Router,
  Route
} from 'react-router-dom'


import { DndProvider } from 'react-dnd'
import {HTML5Backend} from 'react-dnd-html5-backend';
import { ToastProvider } from 'react-toast-notifications';

import AppBody from './AppBody'
import * as Sentry from '@sentry/browser';
import DelveBootstrapStyles from './DelveBootstrapStyles'
import ReactGA from 'react-ga';
import ErrorBoundary from './ErrorBoundary'
import SubscriberWrapper from './SubscriberWrapper'

ReactGA.initialize(GOOGLE_ANALYTICS_ID, {gaOptions: {
    allowLinker: true
}});

ReactGA.ga('require', 'linker');
ReactGA.ga('linker:autoLink', ['delvetool.com'] );

import 'sanitize.css'

Sentry.init({
  dsn: SENTRY_DNS
});

class App extends React.Component {
  constructor() {
    super();
    this.state = {}
  }

  render ()
  {
    return (
      <div>
        <ErrorBoundary>
          <DndProvider backend={HTML5Backend}>
            <ToastProvider>
              <DelveBootstrapStyles/>
              <Router>
                <SubscriberWrapper>
                  <AppBody/>
                </SubscriberWrapper>
              </Router>
            </ToastProvider>
          </DndProvider>
        </ErrorBoundary>
      </div>
    );
  }
}

export default App
