export default (state, transcriptID, descriptorID) => {
  const selectionIndices =
        transcriptID && state.transcriptToSelection[transcriptID] &&
        state.transcriptToSelection[transcriptID].selections ?
        state.transcriptToSelection[transcriptID].selections : [];


  const descriptorToSelection = selectionIndices.reduce((obj, key)=> {
    const selection = state.entities.selections[key];
    if (selection)
      obj[selection.multi_choice_descriptor_id] = selection;
    return obj;
  }, {});

  const descriptorSelection = descriptorToSelection[descriptorID];
  return descriptorSelection &&
                         state.entities.multi_choices ?
                         state.entities.multi_choices[descriptorSelection.multi_choice_id]
                         : null;
}
