module.exports =  {
  FILES__TOO_BIG: {
    name: 'FileSizeTooBig',
    message: 'File size too big'
  },
  FILES__TYPE_UNSUPPORTED: {
    name: 'UnsupportedFileType',
    message: 'File Type Not Supported'
  },
  FILES__COUNT_LIMIT: {
    name: 'FileCountLimit',
    message: 'Too many files'
  }
}
