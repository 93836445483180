import {
  AUTH_NEW_PASSWORD_ERROR,
  AUTH_NEW_PASSWORD_RESPONSE,
  AUTH_CHANGE_PASSWORD_MODAL,
  AUTH_SIGN_OUT_RESPONSE,
  AUTH_SIGN_IN,
  AUTH_SIGN_IN_RESPONSE,
  AUTH_SIGN_IN_ERROR,
  AUTH_SIGN_UP,
  AUTH_SIGN_UP_RESPONSE,
  AUTH_SIGN_UP_ERROR,
  AUTH_RESET_PASSWORD_ERROR,
  AUTH_RESET_PASSWORD_RESPONSE,
  AUTH_FORGOT_PASSWORD_ERROR,
  AUTH_FORGOT_PASSWORD_RESPONSE,
  AUTH_ACCEPT_INVITE_RESPONSE,
  AUTH_ACCEPT_INVITE_ERROR,
} from 'constants/AuthConstants'

import InitialState from 'constants/InitialState'

var _ = require('underscore');

function getAuthErrorFromPayload(payload) {
  if ( payload && payload.errors && payload.errors.length > 0)
    return payload.errors[0];
  else if ( payload && payload.error && typeof payload.error === 'string' )
    return payload.error
}

function getAuthError(action) {
  return getAuthErrorFromPayload(action.data);
}

function authReducer(action, state = InitialState)
{
  if ( !action ) return state;

  switch (action.actionType)
  {
    case AUTH_SIGN_OUT_RESPONSE:
    {
      return InitialState;
    }
    break;
    case AUTH_NEW_PASSWORD_ERROR:
    {
      return {
          ...state,
          passwordState: {
            ...state.passwordState,
            success: false,
            message: action.data.errors.full_messages[0]
          }
      }
    }
    break;
    case AUTH_NEW_PASSWORD_RESPONSE:
    {
      return {
        ...state,
        passwordState: {
          show: false,
          success: true,
          message: action.data.message,
        }
      }
    }
    break;
    case AUTH_CHANGE_PASSWORD_MODAL:
    {
      if ( action.data.show )
      {
        return {
          ...state,
          passwordState: {
            show: true
          }
        }
      }
      else {
        return {
          ...state,
          passwordState: {
            ...state.passwordState,
            show: false
          }
        }
      }
    }
    break;
    case AUTH_SIGN_UP_RESPONSE:
    case AUTH_SIGN_UP:
    {
      return {
        ...state,
        signupState: {
          show: false,
          message: null,
        }
      }
    }
    break;
    case AUTH_SIGN_UP_ERROR:
    {
      let errorMessage = null;
      if ( action.data.errors && 'full_messages' in action.data.errors )
      {
        errorMessage = action.data.errors.full_messages[0];
      }

      errorMessage = errorMessage || getAuthError(action) || "Problems signing up, please try again";

      return {
        ...state,
        signupState: {
          show: true,
          message: errorMessage,
        }
      }
    }
    break;
    case AUTH_SIGN_IN_ERROR:
    {
      var errorMessage = getAuthError(action) || "Problems logging in, please try again";

      return {
        ...state,
        loginState: {
          error:{
            show: true,
            message: errorMessage,
          }
        }
      }
    }
    break;
    case AUTH_SIGN_IN_RESPONSE:
    case AUTH_SIGN_IN:
    {
      return {
        ...state,
        loginState: {
          error: {
            show: false
          }
        }
      }
    }
    break;
    case AUTH_FORGOT_PASSWORD_RESPONSE:
    {
      return {
        ...state,
        errorDict:{
          ...state.errorDict,
          [AUTH_FORGOT_PASSWORD_ERROR]:{},
        }
      }
    }
    break;
    case AUTH_RESET_PASSWORD_RESPONSE:
    {
      const message = action.data.message;
      var d = new Date();
      return {
        ...state,
        resetState:{
          lastReset: d.getTime(),
          success:{
            message:message,
          }
        },
        errorDict:{
          ...state.errorDict,
          [AUTH_RESET_PASSWORD_ERROR]:{},
        }
      }
    }
    break;
    case AUTH_RESET_PASSWORD_ERROR:
    {
      let message = null;
      if ( action.errors && action.errors.errors )
      {
        const errors = action.errors.errors;
        if ( errors.full_messages && errors.full_messages.length > 0)
        {
          message = errors.full_messages[0];
        }
        else if ( errors.length > 0 )
        {
          const error = errors[0];
          if ( error.detail )
            message = error.detail;
          else
            message = error;
        }
      }

      message = message || getAuthErrorFromPayload(action.errors) || null;

      return {
        ...state,
        resetState:{
          ...state.resetState,
          success:{
            message:null,
          }
        },
        errorDict: {
          ...state.errorDict,
          [AUTH_RESET_PASSWORD_ERROR]:{
            error: true,
            message: message
          }
        }
      }
    }
    break;
    case AUTH_ACCEPT_INVITE_RESPONSE:
    {
      var d = new Date();
      return {
        ...state,
        inviteState:{
          lastInvite: d.getTime(),
          success:true,
          user:action.data.user,
        },
        errorDict:{
          ...state.errorDict,
          [AUTH_ACCEPT_INVITE_ERROR]:{},
        }
      }
    }
    break;
    case AUTH_ACCEPT_INVITE_ERROR:
    {
      let message = null;
      if ( action.errors && action.errors.errors )
      {
        const errors = action.errors.errors;
        if ( errors.length > 0 )
        {
          message = errors[0];
        }
      }

      return {
        ...state,
        inviteState:{
          ...state.inviteState,
          success:false,
        },
        errorDict: {
          ...state.errorDict,
          [AUTH_ACCEPT_INVITE_ERROR]:{
            error: true,
            message: message
          }
        }
      }
    }
    break;
    case AUTH_FORGOT_PASSWORD_ERROR:
    {

      let message = getAuthErrorFromPayload(action.errors) || null;

      return {
        ...state,
        errorDict: {
          ...state.errorDict,
          [AUTH_FORGOT_PASSWORD_ERROR]:{
            error: true,
            message: message
          }
        }
      }
    }
    break;
    default:
      return state;
  }
}

export default authReducer
