// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';
import AccountSingleButton from '../AccountSingleButton'
import {FormControl, FormGroup} from 'react-bootstrap'
import CancelConstants from './CancelConstants'


const FEEDBACK = "Let us know if you have any feedback on your experience using Delve."
const DETAILS = "Let us know If you have any details to share.";

const TYPE_TO_COPY = {
  [CancelConstants.FINISHED]: {
    header: "Congratulations on finishing your research!",
    subheader: "How was your experience using Delve? Let us know if you have any feedback."
  },
  [CancelConstants.NO_RESEARCH]: {
    header: "Are you planning on using Delve for a future research project?",
    subheader: "Let us know if you have any feedback on your experience trying Delve. You are welcome back to Delve In the future!"
  },
  [CancelConstants.FEATURES]: {
    header: "What features were you expecting?",
    subheader: FEEDBACK
  },
  [CancelConstants.PRICE]: {
    header: "What tool will you be using instead?",
    subheader: "We would love to hear about the alternatives you are considering."
  },
  [CancelConstants.TECHNICAL]: {
    header: "What issue did you run into?",
    subheader: DETAILS
  },
  [CancelConstants.HELP]: {
    header: "What do you need help with?",
    subheader: DETAILS
  },
  [CancelConstants.OTHER_SOLUTION]: {
    header: "What solution are you using instead?",
    subheader: "Let us know if you have any feedback on why you didn’t choose to use Delve."
  },
  [CancelConstants.NOT_RIGHT]: {
    header: "What were you looking for?",
    subheader: FEEDBACK
  },
  [CancelConstants.OTHER]: {
    header: "Could you please tell us why you're leaving?",
    subheader: FEEDBACK
  }
}

class CancelFeedback extends React.Component {
  constructor () {
    super();
  }

  getHeader(type) {
    return TYPE_TO_COPY[type].header || "";
  }

  getSubheader(type) {
    return TYPE_TO_COPY[type].subheader || "";
  }

  render () {
    return (
      <div>
        <AccountSingleButton
          onClick={this.props.onNext}
          loading={false}
          header={this.getHeader(this.props.checked)}
          button="Continue"
          hideTerms
          onSubButton={this.props.onCancel}
          subButton="Nevermind"
          disabled={ !this.props.feedback && this.props.feedback.length === 0 }
          body={<div>
            <p>
              {this.getSubheader(this.props.checked)}
            </p>
            <form>
              <FormGroup controlId="feedbackId">
                <FormControl
                  componentClass="textarea"
                  type="textarea"
                  placeholder=""
                  value={this.props.feedback}
                  onChange={this.props.onFeedbackChange}
                  style={{height:"150px"}}
                />
              </FormGroup>
            </form>
          </div>}
          />
      </div>
    );
  }
}
export default CancelFeedback;
