import {
  DELVE_TIME_REGEXP,
  NAME_REGEX,
  BLANK_REGEX
} from 'utils/FormatterRegExp'

export default (transcriptText) => {
  const regex = new RegExp(`^(${NAME_REGEX})[[ |\\t]+(${DELVE_TIME_REGEXP})${BLANK_REGEX}\\n`, 'gm');
  
  return transcriptText.replace(
    regex,
    "$1: $2 "
  );
}