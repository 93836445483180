// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';
import QualCodeActions from 'actions/QualCodeActions'
import AuthStore from 'stores/AuthStore'
import {Redirect} from 'react-router-dom'
import TokenDisplay from './TokenDisplay'

class AcceptInviteDisplay extends React.Component {
  constructor () {
    super();
    this._onChange = this._onChange.bind(this);
    this.shouldRedirect = this.shouldRedirect.bind(this);
    const inviteState = AuthStore.getAcceptInviteState();
    this.state = {
      ...inviteState,
      initialLastInvite: inviteState.lastInvite,
    }
  }

  _onChange () {
    this.setState({
      ...AuthStore.getAcceptInviteState()
    })
  }

  componentDidMount () {
    AuthStore.addChangeListener(this._onChange);
  }

  componentWillUnmount () {
    AuthStore.removeChangeListener(this._onChange);
  }

  acceptInvite(token, password, confirmPassword, name)
  {
    QualCodeActions.acceptInvite(token, password, confirmPassword, name);
  }

  shouldRedirect () {
    return this.state.initialLastInvite != this.state.lastInvite;
  }

  redirectURL (email) {
    if ( email )
    {
      const emailURI = encodeURIComponent(email);
      return `/sign_in?email=${emailURI}`
    }
    else {
      return `/sign_in`
    }
  }

  render () {
    return (
      <div>
        {this.shouldRedirect() && <Redirect to={this.redirectURL(this.state.email)}/>}
        <TokenDisplay
          header="Get started by setting up your account"
          error={this.state.error}
          tokenAction={this.acceptInvite}
          buttonText="Continue"
          terms
          name
        />
      </div>
    );
  }
}

export default AcceptInviteDisplay;
