// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';
import UserSquare from './UserSquare'

function SpeakerComponent(props) {
  return (
    <div
      onClick={props.onClick}
      onMouseEnter={() => props.onHover(true)}
      onMouseLeave={() => props.onHover(false)}>
      <UserSquare
        initials={props.initials}
        color={props.color}
        />
    </div>
  );
}

export default SpeakerComponent;
