var _ = require('underscore');

const transcriptIDToProjectIDMapper = (state, transcriptID) => {
  if ( !transcriptID ) return null;

  for ( var projectIndex in state.entities.projects)
  {
    var project = state.entities.projects[projectIndex];
    if ( project.transcripts )
    {
      if ( _.indexOf(project.transcripts, transcriptID) >= 0)
        return projectIndex;
    }
  }
  return null;
}

export default transcriptIDToProjectIDMapper
