import {
  DELVE_TIME_REGEXP,
  ARROW_TIME_REGEXP,
  BLANK_REGEX,
  NAME_REGEX
} from 'utils/FormatterRegExp'

export default (transcriptText) => {
  const regex = new RegExp(`^${BLANK_REGEX}(${DELVE_TIME_REGEXP})${ARROW_TIME_REGEXP}\\n${BLANK_REGEX}(${NAME_REGEX})${BLANK_REGEX}\\n${BLANK_REGEX}(\\w*)`, 'gm');
  
  return transcriptText.replace(
    regex,
    "$2: $1 $3"
  );
}