import AppDispatcher from 'dispatcher/AppDispatcher'
import MemoAPI from './MemoAPI'
import MemoConstants from './MemoConstants';
import asyncCodePromises from 'utils/AsyncCodePromises';
import { v1 as uuidv1 } from 'uuid';

function dispatchError(type, payload, data)
{
  const response = payload.response ? payload.response : {};
  const responseData = response.data ? response.data : {};
  const errors = responseData.errors ? responseData.errors : [];

  AppDispatcher.handleAction({
    actionType: type,
    data,
    errors: errors,
  })
}

var MemoActions = {
  /***********************\
  *
  * Memo Actions
  *
  \************************/

  createMemo: async function(excerpt_id, text)
  {    
    const clientId = uuidv1();

    excerpt_id = await asyncCodePromises.getExcerpt(excerpt_id);
    // This could have a delay if the excerpt_id was just created
    // You could make it more async friendly if it went beforehand

    AppDispatcher.handleAction({
      actionType: MemoConstants.CREATE_MEMO,
      data: {
        excerpt_id,
        text,
        clientId
      }
    })

    return MemoAPI.createMemo(excerpt_id, text, clientId)
    .then((result) => {
      AppDispatcher.handleAction({
        actionType: MemoConstants.CREATE_MEMO_RESULT,
        data: {
          ...result.data,
          clientId
        }
      })
      return result.data;
    })
    .catch(function (error) {
      dispatchError(MemoConstants.CREATE_MEMO_ERROR, error, {clientId});
      throw error;
    });
  },
  deleteMemo(memo_id) {
    if ( !memo_id ) return;
    AppDispatcher.handleAction({
      actionType: MemoConstants.DELETE_MEMO,
      data: {
        id: memo_id
      }
    })

    return MemoAPI.deleteMemo(memo_id)
    .then((result) => {
      AppDispatcher.handleAction({
        actionType: MemoConstants.DELETE_MEMO_RESULT,
        data: {
          id: memo_id
        }
      })
      return result.data;
    }).catch(function (error) {
      dispatchError(MemoConstants.DELETE_MEMO_ERROR, error, {id: memo_id});
      throw error;
    });
  },
  deleteMemoCable(memo_id) {
    if ( !memo_id ) return;
    AppDispatcher.handleAction({
      actionType: MemoConstants.DELETE_MEMO_CABLE,
      data: {
        id: memo_id
      }
    })
  }
}

export default MemoActions
