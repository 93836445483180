import AppDispatcher from 'dispatcher/AppDispatcher'
var _ = require('underscore');
import BaseStore from 'stores/BaseStore'
import {
  SELECT_CHAT_EXCERPT,
} from './ChatConstants';
import ChatState from './ChatState'

var ChatStore = _.extend({}, BaseStore, {
  getSelectedExcerptId: () => {
    return ChatState.get().selected_excerpt_id;
  },
});

// Register callback with AppDispatcher
AppDispatcher.register(function(payload) {
  var action = payload.action;
  switch(action.actionType) {
    case SELECT_CHAT_EXCERPT:
      const data = action.data || {};
      
      ChatState.set({
        selected_excerpt_id: data.selected_excerpt_id
      })
      break;
    default:
      return true;
  }

  ChatStore.emitChange();
  return true;
});

export default ChatStore
