const _ = require('underscore')
import collaboratorsMapper from './CollaboratorsMapper'

const singleCollaboratorMapper = (state, projectID, email) => {
  const collaborators = (
    collaboratorsMapper(state, projectID) || {}
  ).collaborators || [];

  return collaborators.find((collaborator) => collaborator.email === email) || null;
}

export default singleCollaboratorMapper
