import _ from 'underscore'

export const projectContextToUserObject = (projectContext) => {
    const collaboratorArray = [
        ...projectContext.collaborators || [],
        projectContext.owner || {}
    ]

    return _.indexBy(collaboratorArray, 'id');
}
