import API from './API'
import sessionMapper from 'mappers/SessionMapper'

export default {
  // Create Session
  createSession: function(params) {
    return API.post(`/api/sessions`,
      {
        session: params
      }
    );
  },

  createSessionWithLocation: function(params) {
    const location = window ? window.location : {};

    return this.createSession(sessionMapper({
      success_url: location.href,
      cancel_url: location.href
    })).then((session)=>{
      const stripe = Stripe(STRIPE_PUBLISHABLE_KEY);
      stripe.redirectToCheckout({
        sessionId: session.data.id
      });
    })
  },

  createCustomerPortalSession: async function(return_url) {
    const host = window && window.location && window.location.host ? window.location.host : "app.delvetool.com";
    const https = host.includes('localhost') ? 'http' : 'https'
    const base_url = `${https}://${host}`

    return API.post(`/api/customer_portal_sessions`,
      {
        customer_portal_session: {
          return_url: `${base_url}${return_url}`
        }
      }
    ).then((response)=>{
      return response.data;
    });
  },
};
