// app/javascript/projects/components/ProjectsDisplay.jsx

import React, { useState } from 'react';
import { Label } from 'react-bootstrap'
import {
  LIGHT_GREY,
  ULTRA_LIGHT_GREY,
} from 'constants/ColorConstants';

const HOVER_STYLE = {
  color: LIGHT_GREY,
  backgroundColor: ULTRA_LIGHT_GREY,
  cursor: "pointer",
  fontSize:"1em",
};

const DEFAULT_STYLE = {
  ...HOVER_STYLE,
  backgroundColor: "white",
};

function HoverX(props) {
  const [isHovered, setIsHovered] = useState(false);

  function isHovering(hover) {
    setIsHovered(hover);
    if ( props.isHovering ) props.isHovering(hover);
  }

  if ( !props.show ) return null;

  return (
    <Label
      className={props.className}
      style={isHovered ? HOVER_STYLE : DEFAULT_STYLE}
      onClick={props.onClick}
      onMouseEnter={() => isHovering(true)}
      onMouseLeave={() => isHovering(false)}
    >
      x
    </Label>
  );
}

export default HoverX;
