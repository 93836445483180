// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';
import {LinkContainer} from 'react-router-bootstrap'
import urlUtil from 'utils/UrlUtil';

const TRANSCRIPT_PADDING = "6px"

const linkStyle = {
  paddingLeft:"30px",
  paddingTop:TRANSCRIPT_PADDING,
  cursor: "pointer",
}

const selectedStyle = {
  ...linkStyle,
  color: "white"
}

function TranscriptLink(props) {
  const link = urlUtil.getTranscriptUrl(
    props.page,
    {
      transcriptID: props.transcriptID,
      location: props.location,
    }
  );

  return (<div
    key={props.transcriptID}
    style={props.selected ? selectedStyle : linkStyle}
    className="transcriptLink"
  >
    <LinkContainer to={link} key={props.transcriptID}>
      <div>
        {props.children}
      </div>
    </LinkContainer>
  </div>
  );
}

export default TranscriptLink;
