// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';
import UserDisplayDivider from './UserDisplayDivider'

const LEFT_MARGIN = "30px"

const divStyle = {margin:LEFT_MARGIN}

function UserDisplaySectionContainer(props) {
  return (
    <div>
      <UserDisplayDivider/>
      <div style={divStyle}>
        <h4>{props.header}</h4>
        {props.children}
      </div>
    </div>
  )
}

export default UserDisplaySectionContainer;