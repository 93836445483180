import InitialState from 'constants/InitialState'
import projectShareResultReducer from './project_shares/ProjectShareResultReducer'
import projectShareReducer from './project_shares/ProjectShareReducer'
var _ = require('underscore');
import {HTTPError} from 'apis/HTTPErrors';

import {
  FETCH_PROJECT_COLLABORATORS_RESULT,
  ADD_COLLABORATOR_RESULT,
  REMOVE_COLLABORATOR_RESULT,
  UPDATE_COLLABORATOR_RESULT,
  UPDATE_COLLABORATOR,
  UPDATE_COLLABORATOR_ERROR,
  FETCH_PROJECT_COLLABORATORS,
  ADD_COLLABORATOR,
  REMOVE_COLLABORATOR,
  ADD_COLLABORATOR_ERROR,
  REMOVE_COLLABORATOR_ERROR,
  FETCH_PROJECT_COLLABORATORS_ERROR,
  CLEAR_COLLABORATION_ERROR
} from 'constants/ShareConstants'

function shareReducer(action, state = InitialState)
{
  if ( !action ) return state;

  switch (action.actionType)
  {
    case REMOVE_COLLABORATOR_ERROR:
    case FETCH_PROJECT_COLLABORATORS_ERROR:
    case UPDATE_COLLABORATOR_ERROR:
    {
      return {
        ...state,
        shareState:{
          show: true,
          message: "Something went wrong. Please refresh and try again."
        }
      }
    }
    break;
    case ADD_COLLABORATOR_ERROR:
    {
      var message = `Could not add ${action.data.email}`;
      let error = action.error;

      if ( !!error && error instanceof HTTPError ) {
        error = error.originalError;
      }

      if ( error.response &&
            error.response.data &&
            error.response.data.errors &&
            error.response.data.errors.length > 0)
      {
        error = error.response.data.errors[0];
        if ( error.code == "user_does_not_exist")
        {
          message = `${action.data.email} is not currently a Delve user. They need an account to have shared projects.`
        }
        else if (error.code == "user_already_collaborator") {
          message = `${action.data.email} is already shared on this project.`
        }
      }

      return {
        ...state,
        shareState:{
          show: true,
          message: message
        }
      }
    }
    break;
    case FETCH_PROJECT_COLLABORATORS:
    case ADD_COLLABORATOR:
    case REMOVE_COLLABORATOR:
    case CLEAR_COLLABORATION_ERROR:
    {
      return {
        ...state,
        shareState:{
          show: false,
          message: null
        }
      }
    }
    break;
    case FETCH_PROJECT_COLLABORATORS_RESULT:
    case ADD_COLLABORATOR_RESULT:
    case REMOVE_COLLABORATOR_RESULT:
    case UPDATE_COLLABORATOR_RESULT:
    {
      return projectShareResultReducer(action, state)
    }
    break;
    case UPDATE_COLLABORATOR: {
      return projectShareReducer(action, state)
    }
    break;
    default:
      return state;
  }
}

export default shareReducer
