// app/javascript/projects/components/FiltersDropdown.jsx

import React from 'react';
import ColorConstants from 'constants/ColorConstants'
import {ButtonToolbar, Button, Checkbox} from 'react-bootstrap';
import FilterCodeForm from './FilterCodeForm'
import CodeOpComponent from './analysis/CodeOpComponent'
import FilterButton from './analysis/FilterButton'
import { Scrollbars } from 'react-custom-scrollbars';
import {
  OP_DEFAULT
} from 'constants/OpConstants'

var _ = require('underscore')

const CONTENT_BUFFER = 200;
const CHECKBOX_BUFFER = 125;

const dropDownStyle = {
  position: "relative",
  display: "inlineBlock",
  backgroundColor: "yellow",
}

const dropDownContentStyle = {
  //position: "fixed",
  position: "absolute",
  borderColor: ColorConstants.LIGHT_GREY,
  backgroundColor: "white",
  minWidth: "160px",
  width: "577px",
  marginTop: "40px",
  boxShadow: "2px 2px 2px 2px rgba(0,0,0,0.1)",
  borderRadius: "3px",
  zIndex: 100,
}

class FiltersDropdown extends React.Component {
  constructor () {
    super();
    this.state = { width: 0, height: 0, shown:false, searchText:""};
    this.currentFilteredText = null;
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.onClick = this.onClick.bind(this);
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.filterText = this.filterText.bind(this);
    this.onChecked = this.onChecked.bind(this);
    this.onDoneButton = this.onDoneButton.bind(this);
  }

  setWrapperRef(node) {
     this.wrapperRef = node;
   }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({shown:false})
    }
  }

  componentDidMount () {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.updateWindowDimensions);
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  onClick(e) {
    this.setState({
      shown: !this.state.shown
    })
  }

  onDoneButton(e) {
    this.setState({
      shown: false
    })

    if ( this.props.applyFilter ) this.props.applyFilter();
  }

  filterText(text) {
    this.setState({
      searchText: text
    });
  }

  onChecked(codeID, checked)
  {
    if ( codeID )
    {
      this.filterText("");
      this.props.onChecked(codeID, checked);
    }
  }

  _getCodeName(code)
  {
    if ( code.count > 0 )
      return code.name + " (" + code.count + ")";
    else
      return code.name;
  }

  _createCheckboxFromCodes(codes)
  {
    const lowerSearchText = this.state.searchText.toLowerCase();
    return codes.map((code, index) => {
      const included = code.name.toLowerCase().includes(lowerSearchText);

      return (<div key={code.id}>
                {included &&
                  <Checkbox
                          checked={!!code.checked}
                          onChange={() => this.onChecked(code.server_id, code.checked)}
                          >
                          {this._getCodeName(code)}
                  </Checkbox>
                }

                <div style={{marginLeft:"30px"}}>
                  {this._createCheckboxFromCodes(code.children)}
                </div>
            </div>);
    });
  }

  render () {
    const contentStyleDynamic = {
      ...dropDownContentStyle,
      height:this.state.height - CONTENT_BUFFER,
      display: this.state.shown ? 'block' : 'none',
      ...this.props.dropdownAbove ? {
        bottom: "100%"
      } : {
        top: "100%"
      }
    }

    var firstChecklist = [];
    var secondChecklist = [];

    if ( this.props.codes )
    {
      var codes = this.props.codes;
      var codeLength = codes.length;
      var middle = (codeLength + 1)/2;
      var firstCodes = codes.slice(0, middle);
      var secondCodes = codes.slice(middle, codeLength);
      firstChecklist = this._createCheckboxFromCodes(firstCodes);
      secondChecklist = this._createCheckboxFromCodes(secondCodes);
    }

    return (
      <div>
            <div style={dropDownStyle}>
              <FilterButton
                op={this.props.op || OP_DEFAULT}
                filters={this.props.checkedCodeNames}
                name={this.props.name}
                onClick={this.onClick}
              />
              <div style={contentStyleDynamic} ref={this.setWrapperRef}>
                <div style={{paddingLeft:"32px", width:"100%", height:"100%"}}>

                  <div style={{width:"100%"}}>
                    <FilterCodeForm
                      filterText={this.filterText}
                      searchText={this.state.searchText}
                      placeholder="Search Codes"
                      />
                  </div>

                  <div style={{
                      color:ColorConstants.LIGHT_GREY,
                      clear:"both",
                      display:"block",
                      paddingTop: "10px"
                    }}>
                    <CodeOpComponent
                      op={this.props.op}
                      anyClick={this.props.anyClick}
                      allClick={this.props.allClick}
                      text={this.props.subHeader}
                    />
                  </div>

                  <Scrollbars autoHide style={{width: "100%", height: this.state.height - CONTENT_BUFFER - CHECKBOX_BUFFER}}>
                    <div style={{clear:"both", display:"block"}}>
                      <div style={{width: "50%", height: "100%", float:"left"}}>
                        {firstChecklist}
                      </div>

                      <div style={{width: "50%", height: "100%", float:"right"}}>
                        {secondChecklist}
                      </div>
                    </div>
                  </Scrollbars>
                  <div style={{float:"right", paddingBottom: 40, paddingRight:20}}>
                    <ButtonToolbar>
                      <Button bsStyle="white" onClick={this.props.onClearChecked}>Clear</Button>
                      <Button bsStyle="blue" onClick={this.onDoneButton}>
                        {this.props.filterButtonText || "Apply Filters"}
                      </Button>
                    </ButtonToolbar>

                  </div>
                </div>
              </div>
            </div>
      </div>
    );
  }
}

export default FiltersDropdown;
