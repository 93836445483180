// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';
import BillingModalGenericBlocker from './BillingModalGenericBlocker'


class BillingModalProjectBlocker extends React.Component {
  constructor () {
    super();
  }

  render () {
    const text = this.props.isSubscriptionOwner ?
      "Please renew your subscription to create a new project." :
      "Please start your subscription to create a new project."

    return (
      <BillingModalGenericBlocker
        onClick={this.props.onClick}
        text={text}
        header="Delve Subscription Required"
        isSubscriptionOwner={this.props.isSubscriptionOwner}
      />
    );
  }
}

export default BillingModalProjectBlocker;
