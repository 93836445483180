import React from 'react';
import { getSnippetIdFromLink } from './CitationCreation';

const CustomLink = ({ children, href, onClick }) => {
    const snippetId = getSnippetIdFromLink(href);

    if (!!snippetId) {        
        const handleClick = (e) => {
            if ( e ) {
                e.preventDefault(); // Prevent the default link action
                e.stopPropagation(); // Prevent the event from bubbling up
            }
            if (snippetId) {
                onClick(snippetId); // Call the onClick prop with the snippetId
            }
        };

        return (
            <a href={href} onClick={handleClick}>
                {children}
            </a>
        );
    } else {
        return <span>{children}</span>
    }
};

export default CustomLink;