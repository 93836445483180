// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';
import {Button, Alert} from 'react-bootstrap'
import {Row, Grid, Col} from 'react-bootstrap'
import ColorConstants from 'constants/ColorConstants'
import {Link} from 'react-router-dom'



class DelveAccountBody extends React.Component {
  render () {
    const error = this.props.error ? this.props.error : {};

    return (
      <div>
        <div style={{ margin: "0 auto", fontFamily: "Libre Baskerville, serif"}} >
          <div style={{marginTop:40}}>
            <a href="https://www.delvetool.com" style={{textDecoration: "none"}} target="_blank">
              <h1 style={{textAlign:"center", color:ColorConstants.ACTIVE_BUTTON_BLUE, fontSize:"3.0em"}}><b>Delve</b></h1>
            </a>
          </div>

          {this.props.subHeader &&
            <p style={{textAlign:"center", marginTop:30}}>{this.props.subHeader}</p>
          }
          <div style={{marginTop:10, marginBottom: 10}}>
            <div style={{width: "500px", margin: "0 auto"}}>
              <div style={{margin:30}}>
                {error.error &&
                  <Alert bsStyle="warning" style={{textAlign:"center"}}>
                      {error.message}
                  </Alert>
                }
                {this.props.success && this.props.success.message &&
                  <Alert bsStyle="success" style={{textAlign:"center"}}>
                    <p>
                      {this.props.success.message}
                    </p>
                  </Alert>
                }
              </div>
            </div>
          </div>

          <div style={{marginTop:30}}>
            {this.props.children}
          </div>

          <div style={{
            textAlign:"center",
            position:'absolute',
            width:"500px",
            bottom:"10px",
            right:"25%",
            left:"50%",
            marginLeft:"-250px"
          }}>
            {this.props.login &&
              <p>Have an account? <Link to={`/sign_in`}>Login</Link></p>
            }
            {this.props.remember &&
              <p>Remember your password? <Link to={`/sign_in`}>Login</Link></p>
            }
            {this.props.reset && !this.props.signUp &&
              <div style={{textAlign: "left", width:"100%", marginBottom: "10px"}}>
                <Grid style={{width: "80%", marginLeft:'50px', marginTop:0}}>
                  <Row className="show-grid" style={{textAlign: 'center', marginTop: 0}}>
                    <Col xs={5} md={5} lg={5} style={{textAlign: 'right'}}>
                      <Link to={`/sign_up`}>Create an Account</Link>
                    </Col>
                    <Col xs={2} md={2} lg={2} style={{textAlign: 'center'}}> | </Col>
                    <Col xs={5} md={5} lg={5} style={{textAlign: 'left'}}>
                      <Link to={`/forgot`}>Can't log in?</Link>
                    </Col>
                  </Row>
                </Grid>
              </div>
            }
            {this.props.reset && this.props.signUp &&
                <p>
                  <Link style={{margin:30}} to={`/forgot`}>Forgot password?</Link>
                  <Link style={{margin:30}} to={`/sign_up`}>Sign Up for Delve</Link>
                </p>
            }

          </div>
        </div>
      </div>

    );
  }
}

export default DelveAccountBody;
