import FileParser from "./FileParser"

import transcriptCleaner from 'utils/TranscriptCleanerUtil'

class FileParserTxt extends FileParser {
  onload(reader, fileName, resolve, reject) {
    const binaryStr = reader.result
    resolve({
      body: transcriptCleaner(reader.result),
      name: fileName,
      fileType: 'txt'
    });
  }

  readAs(reader) {
    reader.readAsText(this._file);
  }
}

export default FileParserTxt;
