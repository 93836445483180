// NIL means way in the future
import filteredExcerptMapper from  'mappers/FilteredExcerptMapper'
var _ = require('underscore');
import transcriptDescriptorToChoice from '../descriptors/TranscriptDescriptorToChoice'

import projectToDescriptorMapper from '../descriptors/ProjectToDescriptorMapper'
const HEADER = ['Transcript', 'Snippet', 'URL', 'Memos', 'Coders']

const convertZTimestampToLocal = (timestamp) => {
  const date = new Date(timestamp);
  const localTime = date.toLocaleString();
  return localTime;
};

const memoString = (memos) => {
  return (memos || []).filter((memo)=>!!memo).map((memo)=>
    `${memo.name}: (${convertZTimestampToLocal(memo.created_at)}) ${memo.text}`
  ).join('\n')
};

const exportExcerptMapper = (state, filterState, projectID) => {
  const host = window && window.location && window.location.host ? window.location.host : "app.delvetool.com";
  let exportExcerpts;

  const {descriptors} = projectToDescriptorMapper(state, projectID);
  const descriptorNames = descriptors.map((descripor)=>descripor.name);


  const excerpts = filteredExcerptMapper(state, filterState, projectID);
  exportExcerpts = excerpts.excerpts.map((excerpt)=>{
    const transcriptID = excerpt.transcript.id;

    const choices = descriptors.map((descriptor)=>{
        const choice = transcriptDescriptorToChoice(state, transcriptID, descriptor.id) || {};
        return choice.choice || '';
    })

    const memos = memoString(excerpt.memos);

    return {
      start: excerpt.start || 0,
      end: excerpt.end || 1,
      transcriptName: excerpt.transcript.name || "???",
      text: excerpt && excerpt.text || "???",
      url: excerpt && excerpt.server_id && excerpt.transcript.id ? `${host}/transcripts/${excerpt.transcript_id}?snippet=${excerpt.server_id}` : ``,
      memos,
      codes: excerpt.codes || [],
      choices,
    }
  })

  return {
    excerpts: exportExcerpts,
    descriptorNames
  };
}

export default exportExcerptMapper
