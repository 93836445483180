import dateMapper from './DateMapper';

var CUT_OFF_DATE = new Date(2018, 6, 20);

const projectListMapper = (state) => {
  try {
      const projects = state.projectsList.projects.map((projectID) => {
      const {date: date, dateString: dateString} = dateMapper(state.entities.projects[projectID]['updated_at']);

      var project = {
        ...state.entities.projects[projectID],
        owner: state.entities.users[state.entities.projects[projectID].owner],
        isOwner: state.entities.user['id'] === state.entities.projects[projectID].owner,
        updated_at: date > CUT_OFF_DATE ? dateString : '...',
      }
      delete project['transcripts'];
      delete project['codes'];
      delete project['recent_transcript_id']
      return project;
    }).filter((project) => !!project.isOwner || project.status !== 'archived')
    .sort((projectA, projectB)=>{
      const dateA = new Date(Date.parse(state.entities.projects[projectA.id]['updated_at']));
      const dateB = new Date(Date.parse(state.entities.projects[projectB.id]['updated_at']));
      return dateB - dateA;
    });

    const loaded = state.projectsList.loaded ? true : false;

    return {projects: projects, loaded: loaded};
  } catch (e) {
    console.error(e);
    return {projects:[], loaded: false};
  }
}

export default projectListMapper
