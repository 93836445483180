// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';
import PauseSurvey from './cancel/PauseSurvey'
import CancelFeedback from './cancel/CancelFeedback'
import Cancel from './cancel/Cancel'
import QualCodeActions from 'actions/QualCodeActions'
import CashierActions from 'actions/CashierActions'
import CancelConstants from './cancel/CancelConstants'
import ContactUs from './cancel/ContactUs'

import {
  PLAN_AND_BILLING
} from 'constants/AccountPageConstants'


const SURVEY = 'SURVEY';
const FEEDBACK = 'FEEDBACK';
const HELP = 'HELP'
const CANCEL = 'CANCEL';

const HELP_CHOICES = [CancelConstants.TECHNICAL, CancelConstants.HELP]

class BillingModalPauseFeedback extends React.Component {
  constructor () {
    super();

    this.state = {
      checked: null,
      page: SURVEY,
      feedback: ""
    }

    this.onChecked = this.onChecked.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
    this.onFeedbackChange = this.onFeedbackChange.bind(this);
    this.submitFeedback = this.submitFeedback.bind(this);
    this.backButtonPressed = this.backButtonPressed.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.needsHelp = this.needsHelp.bind(this);
  }

  // on load we need to setHandleBack to backButtonPressed
  componentDidMount() {
    this.props.setHandleBack(this.backButtonPressed);
  }
  
  needsHelp(choice) {
    return HELP_CHOICES.includes(choice);
  }

  backButtonPressed() {
    let page = null;
    switch (this.state.page) {
      case SURVEY:
      break;
      case FEEDBACK:
        page = SURVEY;
      break;
      case HELP:
        page = FEEDBACK;
      break;
      case CANCEL:
        if (this.needsHelp(this.state.checked))
          page = HELP;
        else
          page = FEEDBACK;
      break;
    }

    this.onPageChange(page);
  }

  submitFeedback () {
    QualCodeActions.sendFeedback({
      message: this.state.feedback,
      choice: this.state.checked
    })

    this.onPageChange(this.nextPageAfterFeedback());
  }

  onChecked (checked) {
    this.setState({
      checked
    })
  }

  onFeedbackChange(e)
  {
    this.setState({
      feedback: e.target.value
    })
  }

  onCancel() {
    this.onPageChange(null);
  }

  nextPageAfterFeedback() {
    if ( this.needsHelp(this.state.checked))
      return HELP;
    else
      return CANCEL;
  }

  onPageChange(page) {
    if ( !!page ) {
      this.setState({
        page
      })
      return;
    }

    CashierActions.gotoTeamModal(PLAN_AND_BILLING);
  }

  renderPage(page) {
    if ( page === SURVEY) {
      return (
        <div>
          <PauseSurvey
            checked={this.state.checked}
            onChecked={this.onChecked}
            onNext={()=>this.onPageChange(FEEDBACK)}
            onCancel={this.onCancel}
          />
        </div>
      );
    } else if (page === FEEDBACK){
      return <div>
        <CancelFeedback
          checked={this.state.checked}
          feedback={this.state.feedback}
          onFeedbackChange={this.onFeedbackChange}
          onNext={this.submitFeedback}
          onCancel={this.onCancel}
        />
      </div>
    } else if ( page === HELP )  {
      return <ContactUs
          onNext={()=>this.onPageChange(CANCEL)}
        />
    }
    else {
      return <div>
        <Cancel
          subscription={this.props.subscription}
          cancelSubscriptionInfo={this.props.cancelSubscriptionInfo}
        />
      </div>
    }
  }

  render () {
    const page = this.renderPage(this.state.page)
    return(
      page
    )
  }
}

export default BillingModalPauseFeedback;
