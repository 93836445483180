// app/javascript/projects/components/ProjectsDisplay.jsx
import React from 'react';
import {MenuItem, DropdownButton} from 'react-bootstrap'
import ColorConstants from 'constants/ColorConstants'
import UserCircle from './UserCircle'
import {LinkContainer} from 'react-router-bootstrap'
import QualCodeActions from 'actions/QualCodeActions'
import ReferActions from 'actions/ReferActions'

class AccountFooter extends React.Component {
  constructor()
  {
    super();
    this.logout = this.logout.bind(this);
  }

  logout(e)
  {
    QualCodeActions.signOut();
  }

  // NOTE: This functionality was removed
  // Keeping it for now because it could be refactored
  referAFriend(e) {
    ReferActions.referAFriend();
  }

  render () {
    return (<div style={{borderTop:"1px solid lightGray", padding:"0px 10px 10px 10px", backgroundColor:ColorConstants.BLACK}}>
      <DropdownButton style={{width:"100%", padding:0, margin:0}} bsStyle="link" noCaret dropup title={
          <div style={{width:"100%"}}>
            <div style={{float:"left"}}>
              <UserCircle initials={this.props.initials}/>
            </div>
            <div style={{float:"left", marginBottom:0, marginTop:20, marginLeft:10, color:ColorConstants.ULTRA_LIGHT_GREY, width:150}}>
              <p style={{textAlign:"left", whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis"}}>{this.props.name}</p>
            </div>
            <div style={{clear:"both"}}/>
          </div>
        } id="accountDropUp">
        <LinkContainer to='/users'>
          <MenuItem eventKey="1" >Account</MenuItem>
        </LinkContainer>
        <MenuItem onClick={this.logout} eventKey="2">Log Out</MenuItem>
      </DropdownButton>
    </div>
    );
  }
}

export default AccountFooter;
