// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';
import CollabRow from './CollabRow'
import CollabActions from 'actions/CollabActions'
import HelpLink from './HelpLink'

class CollabModalTeam extends React.Component {
  constructor () {
    super();

    this.removeCollaborator = this.removeCollaborator.bind(this);
    this.updateCollaborator = this.updateCollaborator.bind(this);
    this.nextPage = this.nextPage.bind(this);
  }

  removeCollaborator(e, collaboratorID)
  {
    e.preventDefault();
    CollabActions.removeCollaborator(this.props.projectID, collaboratorID);
  }

  updateCollaborator(collaboratorID, role)
  {
    CollabActions.updateCollaborator(this.props.projectID, collaboratorID, role)
  }

  collabTextChange(e)
  {
    this.setState({
      collabText: e.target.value
    })
  }

  collabRoleChange(role) {
    this.setState({
      collabRole: role
    })
  }

  nextPage(e) {
    this.props.nextPage()
    e.preventDefault();
  }

  render () {
    const collaboratorInfo = this.props.collaborators ? this.props.collaborators : [];
    const collaborators = collaboratorInfo.map((collaborator)=>{
      return <CollabRow
        key={collaborator.id}
        collaborator={collaborator}
        removeCollaborator={this.removeCollaborator}
        updateCollaborator={this.updateCollaborator}
        myRole={this.props.myRole}
        isSubscriptionOwner={this.props.isSubscriptionOwner}
        onActivateSubscription={this.props.onActivateSubscription}
      />
    })

    return (
      <div style={{marginTop: '10px'}}>
          {this.props.owner &&
            <div style={{padding:'15px 30px'}}>
              <div style={{width:"68%", float:"left"}}>
                {this.props.owner.name}
                </div>
                <div style={{width:"32%", float:"left", paddingLeft:'40px'}}>
                  Owner
                </div>
              <div style={{clear:"both"}}></div>
            </div>
          }
          {collaborators}

          <div style={{display:'flex', marginTop: '20px', marginLeft: "30px"}}>
            {process.env['INTERCOM_ARTICLE_SHARING'] && 
              <div style={{marginRight: '10px'}}>
                <HelpLink intercomArticle={process.env['INTERCOM_ARTICLE_SHARING']}>
                  How does sharing work?
                </HelpLink>
              </div>
            }
            {process.env['INTERCOM_ARTICLE_COLLABORATION'] &&
              <div style={{marginLeft: '10px'}}>
                <HelpLink
                  intercomArticle={process.env['INTERCOM_ARTICLE_COLLABORATION']}
                  glyph="book"
                >
                  Learn about collaboration
                </HelpLink>
              </div>
            }
          </div>
      </div>
    );
  }
}

export default CollabModalTeam;
