// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';
import {Label} from 'react-bootstrap'
import ColorConstants from 'constants/ColorConstants'


const HOVER_STYLE = {
  "color": ColorConstants.DARK_GREY,
   "backgroundColor": ColorConstants.LIGHT_GREY,
   fontWeight:"normal",
   cursor:"pointer"
 };

function CodeX(props) {
  return <Label
    className='code-x'
    style={HOVER_STYLE}
    onClick={props.onClick}>
      x
  </Label>
}

export default CodeX;
