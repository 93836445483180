import { Document, Packer, Paragraph, TextRun } from 'docx'
import { saveAs } from 'file-saver';

const LINE_SPACING = 240*1.15;
const LARGE_BOUNDARY = 480;
const SMALL_BOUNDARY = 120;

class Exporter {
  constructor(title){
    this.doc = new Document(
      {
        title: title
      }
    );
    this.doc.Styles.createParagraphStyle('Heading1', 'Heading 1')
     .basedOn("Normal")
     .next("Normal")
     .quickFormat()
     .size(25 * 2)
     .spacing({after: 120, before: 120});
     //.bold()

     this.doc.Styles.createParagraphStyle('Heading2', 'Heading 2')
      .basedOn("Normal")
      .next("Normal")
      .quickFormat()
      .size(18 * 2)
      .spacing({after: 120, before: 120});

    this.doc.Styles.createParagraphStyle('Heading3', 'Heading 3')
     .basedOn("Normal")
     .next("Normal")
     .quickFormat()
     .size(14 * 2)
     .spacing({after: 120, before: 120});


  }

  addTitle(title)
  {
    if (!title) return;

    let paragraph = new Paragraph(title);
    paragraph.title();
    this.doc.addParagraph(paragraph);
    this.doc.createParagraph();
  }

  addCodeLink(url, text, depth)
  {
    let link = this.doc.createHyperlink(url, text);
    let paragraph = new Paragraph();
    paragraph.addHyperLink(link);
    if ( depth == 0)
      paragraph.heading1();
    else if ( depth == 1)
      paragraph.heading2();
    else
      paragraph.heading3();

    this.doc.addParagraph(paragraph);
  }

  getSpacing(i, arrayLength)
  {
    if ( i == arrayLength - 1) {
      return {after: LARGE_BOUNDARY, line: LINE_SPACING, before: SMALL_BOUNDARY};
    }
    else if ( i == 0 ) {
      return {after: SMALL_BOUNDARY, line: LINE_SPACING, before: SMALL_BOUNDARY};
    }
    else {
      return {after: SMALL_BOUNDARY, line: LINE_SPACING, before: SMALL_BOUNDARY};
    }
  }

  addCodeDescription(textArray, depth)
  {
    if ( !textArray || textArray.length == 0)
    {
        this.doc.createParagraph();
        return;
    }

    for (var i = 0; i < textArray.length; i++) {
      let text = textArray[i];
      this.doc.createParagraph(text).spacing(this.getSpacing(i, textArray.length));
    }
  }

  addSnippet(url, header, textArray)
  {
    if ( !textArray || textArray.length == 0 ) return;

    let paragraph = new Paragraph();
    const link = this.doc.createHyperlink(url, header);
    if ( link.root && link.root.length >= 2)
    {
      const textRun = link.root[1];
      if ( textRun && textRun.bold )
        textRun.bold();
    }
    paragraph.addHyperLink(link);
    paragraph.indent({left:480, hanging: 0});
    this.doc.addParagraph(paragraph);

    for (var i = 0; i < textArray.length; i++) {
      let text = textArray[i];
      this.doc.createParagraph(text).spacing(this.getSpacing(i, textArray.length)).indent({left:480, hanging: 0})
    }
  }

  writeCode(code, depth)
  {
    this.addCodeLink(code.url, `${code.name} (${code.count})`, depth);
    this.addCodeDescription(code.synthesisArray, depth)

    for (var excerpt of code.excerpts)
    {
      this.addSnippet(
        excerpt.url,
        `${excerpt.transcript_name}`,
        excerpt.textArray
      )
    }
  }

  createFooter()
  {
    const textRun = new TextRun("Created with Delve (delvetool.com)");
    textRun.color('#b7b7b7');
    let paragraph = new Paragraph();
    paragraph.addRun(textRun);
    this.doc.Footer.addParagraph(paragraph);
  }

  recursiveWriteCodes(codes, depth)
  {
    for (var code of codes) {
      this.writeCode(code, depth)
      const children = code.children ? code.children : [];
      this.recursiveWriteCodes(children, depth + 1);
    }
  }

  export(projectName, fileName, codes)
  {
    this.addTitle(projectName)
    this.recursiveWriteCodes(codes, 0);
    this.createFooter();

    const packer = new Packer();
    packer.toBlob(this.doc).then(blob => {
        saveAs(blob, fileName);
    });
  }
}

export default Exporter;
