// NIL means way in the future
import codeListMapper from 'mappers/exports/CodeListMapper'
import projectDetailsMapper from 'mappers/ProjectDetailsMapper'
import ExportMatrix from './ExportMatrix'
import exportProjectNameMapper from './ExportProjectNameMapper'

var _ = require('underscore');

const getRowCode = (codeIndex) => codeIndex + 1;
const getColCode = (codeIndex) => codeIndex + 2;

const getTranscripts = (state, projectID) => {
  const projectDetails = projectDetailsMapper(state, projectID) || {};
  return projectDetails.transcripts || [];
}

const getTranscriptHash = (state, projectID) => {
  return getTranscripts(state, projectID)
  .map((t)=>{
    return {
      [t.id]: t.name
    }
  }).reduce((a, b)=>{
    return {...a, ...b}
  }, {});
}

const getTranscriptList = (state, projectID) => {
  return getTranscripts(state, projectID)
  .map((t)=>{
    return {
      id: t.id,
      name: t.name
    }
  });
}

const COL_OFFSET = 3;
const ROW_OFFSET = 2;

const TRANSCRIPT_OFFSET = 1;
const TRANSCRIPT_DESCRIPTION_OFFSET = TRANSCRIPT_OFFSET - 1;

const TRANSCRIPT_DESCRIPTION = '# of snippets in this transcript'

const EXPORT_NAME = 'Code x Transcript Matrix';

const codesVTranscriptsArray = (state, projectID, projectName, report) => {
  const {codes} = codeListMapper(state, projectID);
  const transcriptHash = getTranscriptHash(state, projectID);
  const transcriptList = getTranscriptList(state, projectID)

  const matrix = new ExportMatrix(codes.length + ROW_OFFSET, transcriptList.length + COL_OFFSET);

  const transcriptNames = transcriptList.map((t)=>t.name);

  matrix.setCodeRows(codes, ROW_OFFSET, COL_OFFSET);
  matrix.setRow(transcriptNames.map(()=>TRANSCRIPT_DESCRIPTION), TRANSCRIPT_DESCRIPTION_OFFSET, COL_OFFSET);
  matrix.setRow(transcriptNames, TRANSCRIPT_OFFSET, COL_OFFSET);

  if ( report && report.transcripts ) {
    for ( let transcriptIndex = 0; transcriptIndex < transcriptList.length; transcriptIndex ++ ) {
      const {id} = transcriptList[transcriptIndex];
      const reportTranscript = report.transcripts[id];
      if ( !reportTranscript ) continue;
      for ( let codeIndex = 0; codeIndex < codes.length; codeIndex++ ) {
        const [code_id] = codes[codeIndex];
        const reportCode = reportTranscript.codes[code_id];
        if ( !reportCode ) continue
        const excerptCount = reportCode.excerpt_count;
        matrix.setValue(codeIndex + ROW_OFFSET, transcriptIndex + COL_OFFSET, excerptCount);
      }
    }
  }

  matrix.setHeaders(projectName, EXPORT_NAME)
  return matrix.toCSV();
}

const exportCodesVTranscriptsMapper = (state, projectID, report) => {
  const {projectName, exportFileName} = exportProjectNameMapper(state, projectID, 'CodeXTranscript');

  const matrix = codesVTranscriptsArray(state, projectID, projectName, report.report);

  return {
    projectName: exportFileName,
    matrix
  }
}

export default exportCodesVTranscriptsMapper
