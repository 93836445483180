// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';
import TranscriptCodeContainer from './TranscriptCodeContainer'
import SnippetsCodeContainer from './SnippetsCodeContainer';
import ChatCodeContainer from 'chat/ChatCodeContainer';
import {
  Switch,
  Route,
} from 'react-router-dom'


class QualRightSideBar extends React.Component {
  constructor () {
    super();
  }

  getCodeContainer(CodeContainer) {
    return (props) => {
        return (
          <CodeContainer
            projectID={this.props.projectID}
            transcriptID={this.props.transcriptID}
            page={this.props.page}
            height={this.props.height}
            {...props}
          />
        );
    }
  }

  render () {
    if ( !this.props.hasEditPermission )
      return <h3></h3>

    return (
      <div style={{height:"100%"}}>
          <Switch>
            <Route
              path='/projects/:projectID/analysis'
              render={this.getCodeContainer(SnippetsCodeContainer)}
              />
            <Route
              path='/projects/:projectID/chat'
              render={this.getCodeContainer(ChatCodeContainer)}
            />
            <Route
              path='/projects/:projectID/search'
              render={() => <h3></h3>}
              />
            <Route
              path='/projects/:projectID'
              render={() => <h3></h3>}
              />
            <Route
              path='/projects/:projectID/transcripts/new'
              render={() => <h3></h3>}
              />
            <Route
              path='/transcripts/:transcriptID/demographics'
              render={() => <div></div>}
            />
            <Route
              path='/users/'
              render={() => <h3></h3>}
            />
            <Route
              path='/transcripts/:transcriptID/edit'
              render={() => <h3></h3>}
            />
            <Route
              path='/transcripts/:transcriptID'
              render={this.getCodeContainer(TranscriptCodeContainer)}
              />
          </Switch>
      </div>
    );
  }
}

export default QualRightSideBar;
