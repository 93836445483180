// app/javascript/projects/components/ProjectsDisplay.jsx
import React from 'react';
import {Button, Alert} from 'react-bootstrap'
import CashierActions from 'actions/CashierActions'
import BillingModalMainBilling from './BillingModalMainBilling'
import ModalDivider from './modal/ModalDivider'
import ModalComponent from './modal/ModalComponent'
import SessionAPI from '../..//apis/SessionAPI'

import {
  ANNUAL_SUBSCRIPTION,
} from 'constants/AccountPageConstants'

class BillingModalMain extends React.Component {
  constructor () {
    super();
    this.state = {
    };

    this.customerPortalSession = this.customerPortalSession.bind(this);
  }

  upgradeSubscription(e) {
    CashierActions.gotoTeamModal(ANNUAL_SUBSCRIPTION);
  }

  async customerPortalSession(e) {
    this.setState({
      disableCustomerPortalSession: true
    })
    const session = await SessionAPI.createCustomerPortalSession('/users')
    .catch((error)=>{
      this.setState({
        error: {
          error: true,
          message: 'Something went wrong. Please refresh and try again.'
        }
      })
    })

    if ( !session || !session.url ) return
    window.location = session.url;
  }

  _getErrorMessage() {
    if ( !!this.props.error && !!this.props.error.error ) {
      return this.props.error.message;
    }

    if ( !!this.state.error && !!this.state.error.error ) {
      return this.state.error.message;;
    }
  }

  render () {
    const upgradeInfo = this.props.upgradeInfo || {};
    const savings = upgradeInfo.upgrade_savings || 0;
    const subscription = this.props.subscription || {};
    const interval = subscription.interval;
    const isMonthly = interval == 'month';
    const errorMessage = this._getErrorMessage();

    return (
      <div>

        { !!errorMessage &&
          <Alert bsStyle="warning">{errorMessage}</Alert>
        }

        <BillingModalMainBilling
          subscription={this.props.subscription}
        />


        <ModalDivider/>


        <ModalComponent>
          <h4>Billing Schedule</h4>
          {isMonthly && <p>You're paying monthly.</p>}
          {!isMonthly && <p>You're paying annually.</p>}

          {!!savings && isMonthly &&
            <div>
              <p>Save ${savings}/year by paying annually.</p>
              <Button onClick={this.upgradeSubscription} bsStyle="white">Switch to Annual Billing</Button>
            </div>
          }
        </ModalComponent>

        <ModalComponent footer>
          <h4>Payment Method and Invoices</h4>
          <div>
            <p>
              Update credit card information and view invoices.
            </p>

              <Button
                disabled={!!this.state.disableCustomerPortalSession}
                onClick={this.customerPortalSession}
                bsStyle="white"
              >
                View Payment Portal
              </Button>

          </div>
        </ModalComponent>
      </div>
    );
  }
}

export default BillingModalMain;
