import AppDispatcher from 'dispatcher/AppDispatcher';
import QualAPI from 'utils/QualAPI';
import UserStore from 'stores/UserStore'
import ShareConstants from 'constants/ShareConstants';

var CollabActions = {
  clearCollaborationError: function() {
    AppDispatcher.handleAction({
      actionType: ShareConstants.CLEAR_COLLABORATION_ERROR
    })
  },
  // COLLABORATION
  getCollabList: function(projectID)
  {
    AppDispatcher.handleAction({
      actionType: ShareConstants.FETCH_PROJECT_COLLABORATORS,
      data: {
        project_id: projectID,
      }
    })

    return QualAPI.getCollabList(projectID).then((response)=>{
      AppDispatcher.handleAction({
        actionType: ShareConstants.FETCH_PROJECT_COLLABORATORS_RESULT,
        data: response.data
      })
      return response.data;
    }).catch((error)=>{
      AppDispatcher.handleAction({
        actionType: ShareConstants.FETCH_PROJECT_COLLABORATORS_ERROR,
        data: {
          project_id: projectID
        },
        error: error
      })
    });
  },

  addCollborator: function(project_id, email, role)
  {
    AppDispatcher.handleAction({
      actionType: ShareConstants.ADD_COLLABORATOR,
      data: {
        project_id: project_id,
        email,
        role
      }
    })

    return QualAPI.addCollborator(project_id, email, role).then((response)=>{
      AppDispatcher.handleAction({
        actionType: ShareConstants.ADD_COLLABORATOR_RESULT,
        data: response.data
      })

      return response.data;
    }).catch((error)=>{
      AppDispatcher.handleAction({
        actionType: ShareConstants.ADD_COLLABORATOR_ERROR,
        data: {
          project_id: project_id,
          email: email
        },
        error: error
      })

      return {error};
    });
  },

  removeCollaborator: function(project_id, collaborator_id)
  {
    AppDispatcher.handleAction({
      actionType: ShareConstants.REMOVE_COLLABORATOR,
      data: {
        project_id: project_id,
        collaborator_id: collaborator_id
      }
    })

    return QualAPI.removeCollaborator(project_id, collaborator_id).then((response)=>{
      AppDispatcher.handleAction({
        actionType: ShareConstants.REMOVE_COLLABORATOR_RESULT,
        data: response.data
      })
    }).catch((error)=>{
      AppDispatcher.handleAction({
        actionType: ShareConstants.REMOVE_COLLABORATOR_ERROR,
        data: {
          project_id: project_id,
          collaborator_id: collaborator_id
        },
        error: error
      })
    });
  },

  leaveProject: async function(project_id)
  {
    const userId = UserStore.getUserId();
    if ( project_id && userId )
      return this.removeCollaborator(project_id, userId);

    return Promise.resolve();
  },

  updateCollaborator: function(project_id, collaborator_id, role)
  {
    AppDispatcher.handleAction({
      actionType: ShareConstants.UPDATE_COLLABORATOR,
      data: {
        project_id,
        collaborator_id,
        role
      }
    })

    QualAPI.updateCollaborator(project_id, collaborator_id, role).then((response)=>{
      AppDispatcher.handleAction({
        actionType: ShareConstants.UPDATE_COLLABORATOR_RESULT,
        data: response.data
      })
    }).catch((error)=>{
      AppDispatcher.handleAction({
        actionType: ShareConstants.UPDATE_COLLABORATOR_ERROR,
        data: {
          project_id,
          collaborator_id,
          role // this may need to pass the original role
        },
        error: error
      })
    });
  },
}

export default CollabActions
