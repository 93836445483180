import React from 'react';
import OnboardingActions from 'actions/OnboardingActions'
import GenericOnboardingMessage from './GenericOnboardingMessage';

const OnboardingMessage = ({
  header,
  body,
  secondBody,
  projectID,
  hasEditPermission,
  helpArticleText,
  intercomArticleId
}) => {

  const onClick = () => {
    OnboardingActions.getStarted();
  }

  return (
    <GenericOnboardingMessage
      header={header}
      body={body}
      secondBody={secondBody}
      buttonText="Get Started by Adding a Transcript"
      to={projectID && !!hasEditPermission ? `/projects/${projectID}/transcripts/new` : null}
      onClick={projectID && !!hasEditPermission ? onClick : null}
      helpArticleText={helpArticleText}
      intercomArticleId={intercomArticleId}
    />
  );
}

export default OnboardingMessage;