var _ = require('underscore');
import transcriptIDToProjectIDMapper from './TranscriptIDToProjectIDMapper'
import projectToDescriptorMapper from './descriptors/ProjectToDescriptorMapper'
import transcriptDescriptorToChoice from './descriptors/TranscriptDescriptorToChoice'

const mapStateToProps = (state, transcriptID) => {
  try {
    var projectID = transcriptIDToProjectIDMapper(state, transcriptID);
    if ( !projectID ) return {descriptors:[]};

    const {descriptors} = projectToDescriptorMapper(state, projectID);

    const filteredDescriptors = descriptors.map((descriptor)=>{
      const selectedChoice = transcriptDescriptorToChoice(state, transcriptID, descriptor.id)
      return {
        ...descriptor,
        selected_multi_choice: selectedChoice ? selectedChoice : null
      }
    })


    return {descriptors: filteredDescriptors};
  } catch (e) {
    console.error(e);
    return {descriptors:[]};
  }
}

export default mapStateToProps
