const ROOT_KEY = 'ROOT';
import getDepth from 'mappers/nest/DepthMapper'
import getParent from 'mappers/nest/ParentMapper'

function getLevel(state, nodeID, level)
{
  if ( level >= 3 ) return level;
  const code = state.entities.codes[state.mappers.codes[nodeID]];

  if ( code )
  {
    level += 1;
    const parentID = code.parent_id;
    if ( parentID )
    {
      return getLevel(state, parentID, level);
    }
  }

  return level;
}

function getNewParent(state, parentMapper, ghostCodeID, ghostParentID)
{
  const depth = getDepth(parentMapper, ghostCodeID, 0);
  const level = getLevel(state, ghostParentID, 0)

  if ( depth == 3 )
  {
    return ROOT_KEY;
  }
  else if (depth + level == 4) {
    return getParent(state, ghostParentID);
  }
  else if ( depth + level == 5 )
  {
    return getParent(state, getParent(state, ghostParentID));
  }

  return ghostParentID;
}

const codeParentMapper = (state, parentMapper, ghostCodeID, ghostParentID) => {
    ghostParentID = ghostParentID ? ghostParentID : ROOT_KEY;
    if ( ghostParentID  == ROOT_KEY ) return ghostParentID;
    return getNewParent(state, parentMapper, ghostCodeID, ghostParentID);
}

export default codeParentMapper
