// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';
import ColorConstants from 'constants/ColorConstants'

const WIDTH = "400px"

function Disclaimer(props) {
  return (<div style={{color:ColorConstants.LIGHT_GREY}}>
            <p style={{width:"100%", marginTop:"30px"}}>
              {props.children}
            </p>

            {!props.hideTerms &&
              <p style={{width:"100%", marginTop:"30px", marginBottom:"30px"}}>
                You agree to the <a href="https://delvetool.com/terms" target="_blank" rel="noopener noreferrer">Delve Terms of Service</a> and <a href="https://delvetool.com/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>.
              </p>
            }
          </div>)
}

export default Disclaimer;
