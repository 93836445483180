
var timeUtil = {
  getDisplayTime: function(timestamp, includeNow) {
    if ( !timestamp ) {
      return "Just Now"
    }
    
    const date = new Date(timestamp);
    const now = new Date();
    const diff = now - date;
    const diffInMinutes = diff / 1000 / 60;
    const diffInSeconds = diff / 1000;
    const daysAgo = Math.floor(diffInMinutes / 1440);

    // If less than 3 seconds ago, return just now
    if ( diffInSeconds <= 60 ) {
      return 'Just Now'
    }
    // if less than 1 minute ago, return in seconds
    /*
    else if ( diffInMinutes < 1 ) {
      return `${Math.floor(diffInSeconds)} seconds ago`
    } */
    else if ( diffInMinutes < 2 ) {
      return '1 minute ago'
    }
    // If less than 60 minutes ago, return minutes ago
    else if ( diffInMinutes < 60 ) {
      return `${Math.floor(diffInMinutes)} minutes ago`
    }
    // If less than 2 hours ago return 1 hour ago
    else if ( diffInMinutes < 120 ) {
      return '1 hour ago'
    }
    // If less than 24 hours ago return hours ago
    else if ( diffInMinutes < 1440 ) {
      return `${Math.floor(diffInMinutes / 60)} hours ago`
    }
    // if it took place yesterday, return yesterday
    else if ( daysAgo <= 1 ) {
      return '1 day ago'
    }
    // if diff in days is less than 7, return days ago
    else if ( daysAgo <= 7 ) {
      // calculate how many days ago it was
      return `${daysAgo} days ago`
    }
    // if the date was this calendar year, do not include the year, just Jan, 6
    else if ( date.getFullYear() === now.getFullYear() ) {
      const dateString = date.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
      return includeNow ? `on ${dateString}` : dateString;
    }
    // else return the date with the year
    else {
      const dateString = date.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' });
      return includeNow ? `on ${dateString}` : dateString;
    }
  }
}

export default timeUtil
