import { normalize, schema } from 'normalizr';
const _ = require('underscore');
import {
        // FILTER
        FILTER_EXCERPTS,
        FILTER_EXCERPTS_RESULT,
        FILTER_CODE_UNCHECKED,
        FILTER_CODE_CLEAR,
        FILTER_CHECKED,
        FILTER_UNCHECKED,
        FILTER_CLEAR,
        TRANSCRIPT_CHECKED,
        TRANSCRIPT_UNCHECKED,
        TRANSCRIPT_CLEAR,
        CODE_OP_CHANGED,
        FILTER_REPORT_RESPONSE,
        FILTER_NEXT,
        SORT_CHANGED,
} from 'constants/FilterConstants'

// SORT CONSTANTS
import {
  MEMOED,
} from 'constants/SortConstants'

import {
  DELETE_CODE_RESULT,
  DELETE_TRANSCRIPT_RESULT,
  DESTROY_MULTI_CHOICE_DESCRIPTOR_RESULT,
  UPDATE_MULTI_CHOICE_DESCRIPTOR_RESULT
} from 'constants/QualConstants'

const EMPTY_FILTER = {
  descriptors: {},
  transcripts: [],
  codes: []
}

import {
  FILTER_CODE_CHECKED
} from 'constants/FilterConstants'


const _getProjects = (state) => state.projects || {};

const _getProject = (state, projectID) => {
  const projects = _getProjects(state);
  return projects[projectID] || {};
}

const _getFilter = (project) => (project || {}).filters || EMPTY_FILTER

const _getFilters = (state, projectID) => {
  const project = _getProject(state, projectID);
  return _getFilter(project)
}

const _setProject = (state, projectID, project)  => {
  return {
    ...state,
    projects: {
      ..._getProjects(state),
      [projectID]: project
    }
  }
}

const _setFilter = (state, projectID, filters) => _setProject(
  state,
  projectID,
  {
    ..._getProject(state, projectID),
    filters
  }
)

const _setChoices = (state, projectID, descriptorID, choices) => {
  const filters = _getFilters(state, projectID);

  return _setFilter(state, projectID, {
      ...filters,
      descriptors: {
        ...filters.descriptors,
        [descriptorID]: choices
      }
  });
};

const _setOp = (state, projectID, op)=> {
  return _setFilter(state, projectID,
    {
      ..._getFilters(state, projectID),
      op
    }
  );
}

const _setCodes = (state, projectID, codes)=> {
  return _setFilter(state, projectID,
    {
      ..._getFilters(state, projectID),
      codes
    }
  );
}

const _setTranscripts = (state, projectID, transcripts)=> {
  return _setFilter(state, projectID,
    {
      ..._getFilters(state, projectID),
      transcripts
    }
  );
}

function filterReducer(action, state = {})
{
  if ( !action ) return state;

  switch (action.actionType)
  {
    case SORT_CHANGED:
    {
      const projectID = action.data.projectID;
      const sort = action.data.sort;
      const project = _getProject(state, projectID);

      return _setFilter(state, projectID,
        {
          ..._getFilters(state, projectID),
          sort: sort === MEMOED ? sort : null
        }
      );
    }
    break
    case FILTER_EXCERPTS:
      const data = action.data || {};
      const filterUUID = data.filterUUID;
      const projectID = data.projectID;
      if ( !filterUUID ) return state;
      const project = _getProject(state, projectID);

      return _setProject(state, projectID, {
        ...project,
        uuid: filterUUID,
        excerpts: [],
        loading: true,
        counts: {}
      })
    break;
    case FILTER_NEXT:
    {
      const data = action.data || {};
      const projectID = data.projectID;
      const project = _getProject(state, projectID);
      return _setProject(state, projectID, {
        ...project,
        loading: true
      });
    }
    break;
    case FILTER_EXCERPTS_RESULT:
    {
      const projectID = action.data.projectID;
      const uuid = action.data.filterUUID;
      const next = action.data.next;
      const count = action.data.count;

      const code_schema = new schema.Entity('codes');
      const excerpts_schema = new schema.Entity('excerpts', {
        codes: [code_schema]
      })
      const projectSchema = new schema.Entity('project', {
        excerpts: [excerpts_schema]
      })

      var normalizedData = normalize(action.data, projectSchema);

      const newExcerpts = normalizedData.entities.project.undefined.excerpts || [];
      const projectFilters = state.projects || {};
      const project = projectFilters[projectID] || {};
      const currentUuid = project.uuid;

      if ( !!currentUuid && currentUuid !== uuid )
        return state;

      const existingExcerpts = project.excerpts || [];

      return {
        ...state,
        projects: {
          ...projectFilters,
          [projectID]:{
            ...project,
            uuid,
            next: next || null,
            count,
            excerpts: _.uniq([...existingExcerpts, ...newExcerpts]),
            loading: false
          }
        }
      }
    }
    break;
    case FILTER_REPORT_RESPONSE:
    {
      const projectID = action.data.project_id;
      const counts = action.data.report;
      const projects = _getProjects(state);
      const project = _getProject(state, projectID);

      const currentUuid = project.uuid;
      const uuid = action.data.filterUUID;

      if ( !!currentUuid && currentUuid !== uuid )
        return state;

      return {
        ...state,
        projects: {
          ...projects,
          [projectID]:{
            ...project,
            counts
          }
        }
      }
    }
    break;
    case DELETE_CODE_RESULT: {
      const codeId = action.data.id;
      if ( !state || !state.projects ) return state;
      return {
        ...state,
        projects: _.mapObject(state.projects || {}, (project, projectId)=>{
          const filters = _getFilter(project);
          const codes = filters.codes || [];
          return {
            ...project,
            filters: {
              ...filters,
              codes: codes.filter((id)=> id !== codeId )
            }
          }
        })
      }
    }
    break;
    case DESTROY_MULTI_CHOICE_DESCRIPTOR_RESULT: {
      const descriptorId = action.data.id;
      if ( !state || !state.projects ) return state;

      return {
        ...state,
        projects: _.mapObject(state.projects || {}, (project, projectId)=>{
          const filters = _getFilter(project);
          return {
            ...project,
            filters: {
              ...filters,
              descriptors: {
                ...(filters.descriptors || {}),
                [descriptorId]: []
              }
            }
          }
        })
      }
    }
    break;
    case UPDATE_MULTI_CHOICE_DESCRIPTOR_RESULT: {
      const descriptorId = action.data.id;
      const availableChoices = (action.data.multi_choices || []).map((choice)=>choice.id)
      if ( !state || !state.projects ) return state;

      return {
        ...state,
        projects: _.mapObject(state.projects || {}, (project, projectId)=>{
          const filters = _getFilter(project);
          const descriptors = filters.descriptors || {};
          const choices = descriptors[descriptorId];
          if ( !choices ) return project;

          return {
            ...project,
            filters: {
              ...filters,
              descriptors: {
                ...(filters.descriptors || {}),
                [descriptorId]: choices.filter((choice_id)=>availableChoices.includes(choice_id))
              }
            }
          }
        })
      }
    }
    break;
    case DELETE_TRANSCRIPT_RESULT: {
      const transcriptId = action.data.id;
      if ( !state || !state.projects ) return state;
      return {
        ...state,
        projects: _.mapObject(state.projects || {}, (project, projectId)=>{
          const filters = _getFilter(project);
          const transcripts = filters.transcripts || [];
          return {
            ...project,
            filters: {
              ...filters,
              transcripts: transcripts.filter((id)=> id !== transcriptId )
            }
          }
        })
      }
    }
    break;
    case CODE_OP_CHANGED:
    {
      const {op, projectID} = action.data;
      return _setOp(state, projectID, op);
    }
    break
    case FILTER_CODE_CHECKED:
    {
      const {codeID, projectID} = action.data;
      const filters = _getFilters(state, projectID);

      var codes = filters.codes.slice();
      if ( !codes.includes(codeID) )
      {
        codes.push(codeID);
      }

      return _setCodes(state, projectID, codes)
    }
    break
    case FILTER_CODE_UNCHECKED:
    {
      const {codeID, projectID} = action.data;
      const filters = _getFilters(state, projectID);

      const codes = filters.codes.filter((code_id)=>code_id !== codeID);
      return _setCodes(state, projectID, codes)
    }
    break
    case FILTER_CODE_CLEAR:
    {
      const projectID = action.data.projectID;
      return _setCodes(state, projectID, []);
    }
    break
    case FILTER_CHECKED:
    {
      const {descriptorID, choiceID, projectID} = action.data;
      if ( !descriptorID || !choiceID || !projectID) return state;

      const filters = _getFilters(state, projectID);

      var choices = filters.descriptors[descriptorID];
      choices = choices ? choices.slice() : [];
      if ( !choices.includes(choiceID) )
        choices.push(choiceID);

      return _setChoices(state, projectID, descriptorID, choices)
    }
    break;
    case FILTER_UNCHECKED:
    {
      const {descriptorID, choiceID, projectID} = action.data;
      if ( !descriptorID || !choiceID || !projectID) return state;

      const filters = _getFilters(state, projectID);

      const choices = (filters.descriptors[descriptorID] || [])
      .filter((choice_id)=>choice_id !== choiceID);

      return _setChoices(state, projectID, descriptorID, choices);
    }
    break;
    case FILTER_CLEAR:
    {
      const {descriptorID, projectID} = action.data;
      if ( !descriptorID || !projectID) return state;
      return _setChoices(state, projectID, descriptorID, []);
    }
    break;
    case TRANSCRIPT_CHECKED:
    {
      const {transcriptID, projectID} = action.data;
      const filters = _getFilters(state, projectID);
      const transcripts = filters.transcripts || [];
      const newTranscripts = transcripts.concat(
        transcripts.includes(transcriptID) ?
        [] : [transcriptID]
      )
      return _setTranscripts(state, projectID, newTranscripts);
    }
    break
    case TRANSCRIPT_UNCHECKED:
    {
      const {transcriptID, projectID} = action.data;
      const filters = _getFilters(state, projectID);
      const transcripts = (filters.transcripts || []).filter((id)=> id !== transcriptID);
      return _setTranscripts(state, projectID, transcripts);
    }
    break
    case TRANSCRIPT_CLEAR:
    {
      const {projectID} = action.data;
      return _setTranscripts(state, projectID, []);
    }
    break
  }

  return state;
}

export default filterReducer
