const projectToDescriptorMapper = (state, projectID) => {
  try {
    const nullState = {descriptors:[]};
    if ( !projectID ) nullState;
    if ( !state.projectToDescriptor[projectID]) return nullState;
    const descriptorIndices = state.projectToDescriptor[projectID].descriptors;
    const descriptors = state.entities.descriptors;
    const choices = state.entities.multi_choices;

    const filteredDescriptors = descriptorIndices.map((index)=>{
      var descriptor = descriptors[index];

      var multi_choices = descriptor.multi_choices.map((multi_choice_index)=>{
        return choices[multi_choice_index] || {};
      });

      return {
        ...descriptor,
        multi_choices
      }
    });

    return {descriptors: filteredDescriptors};
  } catch (e) {
    console.error(e);
    return {descriptors:[]};
  }
}

export default projectToDescriptorMapper
