// NIL means way in the future
import exportNameMapper from './ExportNameMapper'

const exportProjectNameMapper = (state, projectID, postFix) => {
  const project = state.entities.projects[projectID] ? state.entities.projects[projectID] : {};
  const projectName = project.name || 'Delve Project';
  const exportFileName = exportNameMapper(projectName, postFix)
  return {projectName, exportFileName};
}

export default exportProjectNameMapper
