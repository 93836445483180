import API from 'apis/API'

export default {
  // search transcript
  saveResponse: async function(question_type, answer_type)
  {
    return API.post(`/api/survey_responses`, {
        question_type,
        answer_type
      }
    );
  }
};
