import API from 'apis/API'

export default {
  getFeatureFlags: async function() {
    return API.get(`/api/user_feature_flags`);
  },

  createFeatureFlag: async function(feature) {
    return API.post(`/api/user_feature_flags`, {feature});
  }
};

