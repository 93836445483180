import {
  NOT_LOADING_STATE,
} from 'constants/LoadingConstants'

import {
  MAIN_PAGE,
  ADD_MEMBERS,
  PAYMENT,
  MEMBER_DETAIL,
  MEMBER_DELETE,
  PLAN_AND_BILLING,
  PAUSE_FEEDBACK,
  CANCEL_SUBSCRIPTION,
  UNCANCEL_SUBSCRIPTION,
  ANNUAL_SUBSCRIPTION
} from 'constants/AccountPageConstants'

const TEAM_MODAL_PAGES = [MAIN_PAGE, ADD_MEMBERS, PAYMENT, MEMBER_DETAIL, MEMBER_DELETE];
const BILLING_MODAL_PAGES = [PLAN_AND_BILLING, PAUSE_FEEDBACK, CANCEL_SUBSCRIPTION, UNCANCEL_SUBSCRIPTION, ANNUAL_SUBSCRIPTION];

const NULL_STATE = {
  modalState: {
    showBillingModal: false,
    showTeamModal: false,
    page: null,
  }
}

const teamMapper = (state) => {
  const page = state.teamModal.page;
  const showBillingModal = BILLING_MODAL_PAGES.includes(page);
  const showTeamModal = TEAM_MODAL_PAGES.includes(page);

  try {
    return {
      modalState:{
        showBillingModal: !!page && BILLING_MODAL_PAGES.includes(page),
        showTeamModal: !!page && TEAM_MODAL_PAGES.includes(page),
        page: showBillingModal || showTeamModal ? page : null
      }
    }
  } catch (e) {
    console.error(e);
    return NULL_STATE;
  }
}

export default teamMapper
