import {
  NOT_LOADING_STATE,
} from 'constants/LoadingConstants'

const searchMapper = (state, projectID) => {
  try {
    const search = state.search[projectID] ? state.search[projectID] : {};
    var results = search.results ? search.results : [];
    var count = results.map((result)=>result.results.length).reduce((obj, count)=>{
      return obj + count;
    }, 0)

    const searchTerm = search.searchTerm ? search.searchTerm : "";

    return {
      ...search,
      loadState: search.loadState ? search.loadState : NOT_LOADING_STATE,
      searchTerm: searchTerm,
      count: count,
      total: search.total || undefined,
      results: results.map((result)=>{
        var transcript = state.entities.transcripts[result.transcript_id];
        var name = transcript ? transcript.name : null;
        return {
          ...result,
          name: name ? name : "Untitled"
        }
      })
    }
  } catch (e) {
    console.error(e);
    return {};
  }
}

export default searchMapper
