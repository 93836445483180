// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';
import ReactToPrint, { PrintContextConsumer } from 'react-to-print';

import TranscriptBody from './TranscriptBody'
import CodedTranscriptStore from 'stores/CodedTranscriptStore'
import CodeStore from 'stores/CodeStore'
import ProjectsStore from 'stores/ProjectsStore'
import ExportActions from 'actions/ExportActions'
import {Button} from 'react-bootstrap'
import { Redirect } from 'react-router-dom'
import exportNameMapper from 'mappers/exports/ExportNameMapper'

class TranscriptPrintContainer extends React.Component {
  constructor () {
    super();
    this.state = {
      codedTranscript: CodedTranscriptStore.getCodedTranscript(null),
    };
    this._onChange = this._onChange.bind(this);
    this.bodyRef = this.bodyRef.bind(this);
    this.print = this.print.bind(this);
  }

  _loadState (props) {
    this.setState({
        codedTranscript: CodedTranscriptStore.getCodedTranscript(props.transcriptID, -1),
        transcript: ProjectsStore.getTranscript(props.transcriptID)
      });
  }

  _onChange () {
    this._loadState(this.props);
  }

  componentDidMount () {
    CodedTranscriptStore.addChangeListener(this._onChange);
    CodeStore.addChangeListener(this._onChange);
    this._loadState(this.props);
  }

  componentWillUnmount () {
    CodedTranscriptStore.removeChangeListener(this._onChange);
    CodeStore.removeChangeListener(this._onChange);
  }

  componentWillReceiveProps (nextProps) {
    const nextTranscriptID = nextProps.transcriptID;
    const thisTranscriptID = this.props.transcriptID;

    if ( nextTranscriptID != thisTranscriptID )
    {
      this._loadState(nextProps);
    }
  }

  bodyRef (el) {
    this.componentRef = el;
  }

  print (handlePrint) {
    ExportActions.printTranscript(handlePrint);
  }

  render () {
    if (
      (this.props &&
      this.props.projectID &&
      this.props.project &&
      this.props.project.role === 'view_only')
      || (
        this.props.isSubscriptionActive === false &&
        this.props.projectID )
     ) {
      return (<Redirect to={`/projects/${this.props.projectID}/codes`}/>)
    }

    const transcriptName = (this.state.transcript || {}).name;
    return (
      <div style={{height:"100%"}}>
        <ReactToPrint
          content={() => this.componentRef}
          documentTitle={exportNameMapper(transcriptName, null, 'Untitled')}
        >
          <PrintContextConsumer>
            {({ handlePrint }) => {
              /*if ( this.state.codedTranscript.paragraphs.length > 0 ) {
                handlePrint()
                // for auto printing
              }*/
              return (
              <div>
                <div style={{
                  marginRigth: "20px",
                  marginTop: "20px",
                  float: 'right'
                }}>
                  <Button bsStyle="blue" onClick={()=>this.print(handlePrint)}>Export PDF</Button>
                </div>
                <div style={{clear:"both"}}></div>
              </div>)
            }}
          </PrintContextConsumer>
        </ReactToPrint>
        <TranscriptBody
          transcriptID={this.props.transcriptID}
          codedTranscript={this.state.codedTranscript}
          isPrint={true}
          bodyRef={this.bodyRef}
          title={transcriptName}
        />
      </div>
      );
  }
}

export default TranscriptPrintContainer;
