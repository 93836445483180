// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';
import MemoActions from 'memos/MemoActions'
import { withToastManager } from 'react-toast-notifications';
import AvatarInput from './AvatarInput';

function MemoInput(props) {
  async function addMemo(memo) {
    props.incrementAddedMemoCount()
    return MemoActions.createMemo(props.excerpt_id, memo).then((response)=>{
      // if memo starts with @ai then it is a request to the AI, we will want to stream in that case
      if (memo.startsWith('@ai_assistant')) {
        props.askAI(memo);
      }
    });
  }

  function addMemoErrorHandler() {
    props.toastManager.add(
      'Error saving memo. Refresh and try again.', {
      appearance: 'error',
      autoDismiss: true,
      autoDismissTimeout: 7000
    })
    props.incrementAddedMemoCount(0)
  }
  
  return (
    <AvatarInput
      addInput={addMemo}
      handleError={addMemoErrorHandler}
      initials={props.initials}
      color={props.color}
      featureFlagAICoding={props.featureFlagAICoding}
    />
  );
}

export default withToastManager(MemoInput); 