// app/javascript/projects/components/ProjectsDisplay.jsx

import { DropTarget } from 'react-dnd';
import React from 'react';

import { DragItemTypes } from 'constants/DragConstants';

import CodingActions from '../../actions/CodingActions';
import DimensionsConstants from 'constants/DimensionsConstants'
import ColorConstants from 'constants/ColorConstants'

let updates = 0;

const _isNewPosition = (item, props) => {
  if ( (props.dropCodeId || null) !== (item.parentId || null) ) return true;
  if ( props.dropPosition !== item.position ) return true;
  return false;
}

const codeTarget = {
  drop(props, monitor) {
    const item = monitor.getItem();
    const didDrop = monitor.didDrop();

    if ( !didDrop )
    {
      if ( !!props.canDropOnMargin ) {
        if ( !props.noop )
          CodingActions.nestCodes(item.codeID, props.dropCodeId, props.dropPosition);
      }

      else if ( !!props.rejectNesting && !props.ghost ) {
        props.rejectNesting();
      }
    }
  }
}

function collectTarget(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver({ shallow: true }),
  }
}

const ALLOW = 'ALLOWED';
const DENY = 'DENY';
const HIDE = 'HIDE';

function getState(props) {
  const { isOver, isOverParent, canDropOnParent, canDropOnMargin, ghost} = props;
  if ( isOverParent ) {
    if ( !!canDropOnParent )
      return ALLOW;
    return !ghost ? DENY : HIDE
  }
  else if ( isOver ) {
    if ( !!canDropOnMargin )
      return ALLOW;
    return !ghost ? DENY : HIDE
  }

  return HIDE;
}

function isAllowed(props) {
  switch (getState(props)) {
    case DENY:
      return false
    default:
      return true;
  }
}

function getColor(props) {
  switch (getState(props)) {
    case ALLOW:
      return ColorConstants.GHOST_COLOR
    case DENY:
      return null;
    default:
      return null;
  }
}

class MarginDropZone extends React.Component {
  render () {
    const { connectDropTarget, isOver} = this.props;
    var border = "1px solid black";
    const depthValue = this.props.depth * 30;
    const marginMargin = depthValue + "px"

    return connectDropTarget(<div style={{position: 'absolute',
                                border: null,
                                top: this.props.top || 40,
                                height: this.props.height || 11,
                                width: "150%"
                              }}>
                              <div>
                                <div
                                  style={{
                                  marginTop: this.props.lineTop || 3,
                                  height: 5,
                                  width: "100%",
                                  width: DimensionsConstants.CODE_LABEL_WIDTH,
                                  marginLeft: marginMargin,
                                  backgroundColor: getColor(this.props),
                                  float: 'left'
                                  }}
                                />
                              </div>
                              <div style={{clear: 'both'}}></div>
                            </div>);
  }
}

export default DropTarget(DragItemTypes.CODE_LABEL, codeTarget, collectTarget)(MarginDropZone);
