import InitialState from 'constants/InitialState'
import {
    SHOW_CODE_PAGE_MODAL,
    HIDE_CODE_PAGE_MODAL,
} from 'constants/QualConstants'

var _ = require('underscore');

const setCodePageModal = (state, codeID) => {
  return {
    ...state,
    codePageModal:{
      show:!!codeID,
      codeID: codeID,
    }
  }
}

function codePageShowReducer(action, state = InitialState)
{
  if ( !action ) return null;

  switch (action.actionType) {
    case SHOW_CODE_PAGE_MODAL:
      const codeID = action.data.code_id;
      if ( !codeID ) return state;
      return setCodePageModal(state, codeID);
    case HIDE_CODE_PAGE_MODAL:
      return setCodePageModal(state, null);
  }
}

export default codePageShowReducer
