var _ = require('underscore');
import {
  CREATE_EXCERPT_ERROR,
} from 'constants/QualConstants'

import {
  CODING_VERSION_MISMATCH
} from 'constants/VersioningErrorConstants'

const EMPTY_STATE = {
  error: false,
};


// This is what you need to use to save the pragraph

const codingVersionMapper = (state) => {
  const errorDict = state.errorDict[CREATE_EXCERPT_ERROR] ? state.errorDict[CREATE_EXCERPT_ERROR] : {};
  const errorCode = errorDict.errorCode;
  if ( !errorCode ) return EMPTY_STATE;

  if ( errorCode != CODING_VERSION_MISMATCH ) return EMPTY_STATE;
  
  return {
    error: true,
  }
}

export default codingVersionMapper
