import React from "react";

import CodePill from './CodePill'
import {LinkContainer} from 'react-router-bootstrap'
import QualCodeActions from 'actions/QualCodeActions'


function CodePillLink(props) {

  function onCodeClick(e)
  {
    if ( e ) {
      e.preventDefault(); // Prevent the default link action
      e.stopPropagation(); // Prevent the event from bubbling up
    }
    
    const {id, onClick} = props;
    if ( !!onClick ) onClick();

    if ( id ) QualCodeActions.showCodePageModal(id);
  }

  if ( props.codePage )
  {
    const to = props.server_id ? `/projects/${props.projectID}/codes/${props.server_id}` :
                                  `/projects/${props.projectID}/codes/`;

    return(
      <LinkContainer to={to} style={{cursor:'pointer', paddingRight:0}}>
        <span style={{paddingRight:0}}>
            <CodePill
              name={props.name}
              hover={props.selected}
            />
        </span>
      </LinkContainer>
    );
  }
  else {
    return(
      <span onClick={onCodeClick} style={{cursor:'pointer', paddingRight:0, maxWidth: "100%"}}>
        <CodePill
          name={props.name}
          hover={props.selected}
        />
      </span>
    );
  }
}

export default CodePillLink;
