import React from 'react';
import EditTranscriptModal from './EditTranscriptModal'
import QualCodeActions from 'actions/QualCodeActions'

class EditTranscriptContainer extends React.Component {
  constructor () {
    super();
    this.state = {};
    this.onCancel = this.onCancel.bind(this);
    this.onSave = this.onSave.bind(this);
  }

  onCancel (e)
  {
    if ( !this.props.saving )
    {
      QualCodeActions.cancelEditParagraph();
    }
  }

  onSave (newText)
  {
    if ( this.props.text == newText )
    {
      this.onCancel(null);
    }
    else if ( !isNaN(this.props.transcriptID) && !isNaN(this.props.paragraphID) )
    {
      QualCodeActions.doneEditParagraph(this.props.transcriptID, this.props.paragraphID, newText);
    }
  }

  render () {
    const error = this.props.error ? this.props.error : {};
    const errorCode = error.error ?
                        (error.errorCode ? error.errorCode : "other") :
                        null;

    return (
      <EditTranscriptModal
        show={this.props.show}
        text={this.props.text}
        saving={false}
        hideModal={this.onCancel}
        onSave={this.onSave}
        saving={this.props.saving}
        errorCode={errorCode}
      />
    );
  }
}

export default EditTranscriptContainer;
