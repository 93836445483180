// app/javascript/quotes/components/App.jsx
import React from 'react'
import {
  Switch,
  Route,
  Redirect,
} from 'react-router-dom'

const queryString = require('query-string');

import NetworkLayer from './NetworkLayer'
import LoginDisplay from './LoginDisplay'
import SignUpDisplay from './SignUpDisplay'
import ResetDisplay from './ResetDisplay'
import SuccessDisplay from './SuccessDisplay'
import AcceptInviteDisplay from './AcceptInviteDisplay'
import ForgotDisplay from './ForgotDisplay'
import Intercom from 'react-intercom';
import InitializeAmplitude from './initialize_amplitude';
import { withRouter } from 'react-router';
import PQL from 'pql/PQL';

class AppBody extends React.Component {
  constructor() {
    super();
    this.state = {}
    this.getAppBody = this.getAppBody.bind(this);
    this.checkPromo = this.checkPromo.bind(this);
  }

  _getPromo(props) {
    if ( !props ) return null;
    const parsed = queryString.parse(props.location.search);
    return parsed.promo;
  }

  checkPromo(props, prevProps) {
    const currentPromo = this._getPromo(this.props)
    if ( !!currentPromo && this.state.promo !== currentPromo ) {
      this.setState({
        promo: currentPromo
      })
    }
  }

  componentDidMount () {
    this.checkPromo(this.props);
  }

  componentDidUpdate () {
    this.checkPromo(this.props)
  }

  getAppBody() {
    if ( !this.props.isLoggedIn )
    {
      return (
        <div>
          {<InitializeAmplitude apiKey={AMPLITUDE_ID}/>}
          <Intercom appID={INTERCOM_ID} custom_launcher_selector='#delve_help_link'/>
          <Switch>
            <Route
              path='/reset'
              component={ResetDisplay}
            />
            <Route
              path='/forgot'
              component={ForgotDisplay}
            />
            <Route
              path='/sign_up'
              component={SignUpDisplay}
            />
            <Route
              path='/sign_in'
              component={LoginDisplay}
            />
            <Route
              path='/invite'
              component={AcceptInviteDisplay}
            />
            <Route path="/" render={() => (
                <Redirect to="/sign_in"/>
            )}/>
          </Switch>
        </div>
      );
    }
    else
    {
      return (
        <div>
          {<InitializeAmplitude apiKey={AMPLITUDE_ID} userId={(this.props.user || {}).user_id}/>}
          <Intercom appID={INTERCOM_ID} {...this.props.user} custom_launcher_selector='#delve_help_link'/>
          <div>
            <Switch>
              <Route
                path='/sign_up'
                render={() => (
                    <Redirect to="intro_survey"/>
                )}/>
              />
              <Route
                path='/reset'
                component={ResetDisplay}
              />
              <Route
                path='/forgot'
                component={ForgotDisplay}
              />
              <Route
                exact
                path='/welcome'
                component={SuccessDisplay}
              />
              <Route
                exact
                path='/intro_survey'
                component={PQL}
              />
              <Route
                exact
                path='/subscribe'
                render={(props) => (
                  <NetworkLayer {...props}
                    promo={this.state.promo}
                  />
                )}
              />
              <Route
                exact
                path='/'
                component={NetworkLayer}
              />
              <Route
                path='/projects/:projectID/codes/:codeID'
                component={NetworkLayer}
                exact
                />
              <Route
                path='/projects/:projectID'
                component={NetworkLayer}
                />
              <Route
                exact
                path='/projects'
                component={NetworkLayer}
              />
              <Route
                path='/projects/'
                component={NetworkLayer}
                />
              <Route
                path='/projects/:projectID/chat/:conversationID'
                component={NetworkLayer}
              />
              <Route
                path='/transcripts/:transcriptID'
                component={NetworkLayer}
              />
              <Route
                path='/transcripts/'
                component={NetworkLayer}
                />
              <Route
                path='/codes/:codeID'
                component={NetworkLayer}
              />
              <Route
                path='/users/'
                component={NetworkLayer}
                />
              <Route path="/" render={() => (
                  <Redirect to="/"/>
                )}/>
            </Switch>
          </div>
        </div>
      );
    }
  }

  render ()
  {
    return (
      <div>
        {this.getAppBody()}
      </div>
    );
  }
}

export default withRouter(AppBody)
