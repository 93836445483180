// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';

import AnalysisStore from 'stores/AnalysisStore'
import PageConstants from 'constants/PageConstants'

import CodeDisplay from './CodeDisplay'
import CodingActions from '../../actions/CodingActions';

class AnalysisCodeContainer extends React.Component {
  constructor () {
    super();

    this.state = {
      selectedCodes:[],
    };

    this._onChange = this._onChange.bind(this);
    this.onClick = this.onClick.bind(this);
    this.addNewCode = this.addNewCode.bind(this);
  }

  componentDidMount () {
    AnalysisStore.addChangeListener(this._onChange);
    this._loadState(this.props);
  }

  componentWillUnmount () {
    AnalysisStore.removeChangeListener(this._onChange);
  }

  addNewCode(code)
  {
    if ( this.props.projectID )
    {
      var excerptId = this._getSelectedExcerpt();


      CodingActions.addCodeAndExcerpt(
        {
          name: code.name,
          projectId: this.props.projectID,
          page: PageConstants.ANALYSIS_PAGE,
          excerpt_id: excerptId
        }
      );
    }
  }

  _onChange() {
    this._loadState(this.props);
  }

  _isDisabled() {
    return !this.props.selectedExcerptId;
  }

  onClick(code, selected)
  {
    var excerptId = this._getSelectedExcerpt();

    if ( !this._isDisabled() )
    {
      if ( !selected )
      {
        CodingActions.codePressed({
          code_id: code.id,
          page: PageConstants.ANALYSIS_PAGE,
          project_id: this.props.projectID,
          excerpt_id: excerptId
        })
      }
      else {
        CodingActions.codeUnPressed({
          code_id:code.id,
          page: PageConstants.ANALYSIS_PAGE,
          project_id: this.props.projectID,
          excerpt_id: excerptId
        })
      }
    } else {
      CodingActions.expandParents(
        this.props.projectID,
        code.id
      )
    }
  }

  _getSelectedCodes(excerptId)
  {
    return AnalysisStore.getExcerptCodes(excerptId || null)
  }

  _getSelectedExcerpt()
  {
    return this.props.selectedExcerptId;
  }

  _loadState (props) {
    this.setState({
      selectedCodes: this._getSelectedCodes(props.selectedExcerptId)
    });
  }

  componentWillReceiveProps (nextProps) {
    if ( nextProps.projectID != this.props.projectID || nextProps.selectedExcerptId != this.props.selectedExcerptId)
    {
      this._loadState(nextProps);
    }
  }

  render () {
    return (
      <CodeDisplay
        disabled={this._isDisabled()}
        selectedCodes={this.state.selectedCodes}
        onClick={this.onClick}
        addNewCode={this.addNewCode}
        projectID={this.props.projectID}
        height={this.props.height}
        select={this.props.selectedExcerptId}
      />
    );
  }
}

export default AnalysisCodeContainer;
