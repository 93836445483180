import AppDispatcher from 'dispatcher/AppDispatcher'
import FilterState from 'stores/FilterState'
import FilterActions from 'actions/FilterActions'
import filterReducer from 'reducers/FilterReducer'
import FilterConstants from 'constants/FilterConstants'
import QualConstants from 'constants/QualConstants'
var EventEmitter = require('events').EventEmitter;
import filterMapper from 'mappers/FilterMapper'
const _ = require('underscore');

const delayedFilter = _.throttle(
  (projectID, source)=>FilterActions.filterByCodes(projectID, source),
  1000
);

var FilterStore = _.extend({}, EventEmitter.prototype, {
  // Emit Change event
  emitChange: function() {
    this.emit('change');
  },

  // Add change listener
  addChangeListener: function(callback) {
    this.on('change', callback);
  },

  // Remove change listener
  removeChangeListener: function(callback) {
    this.removeListener('change', callback);
  },

  getState: () => FilterState.get(),

  getFilters: function(projectID){
    return filterMapper(this.getState(), projectID);
  },
});

// Register callback with AppDispatcher
AppDispatcher.register(function(payload) {
  var action = payload.action;

  switch(action.actionType) {
    // REDUX VERSION
    case FilterConstants.FILTER_EXCERPTS_RESULT:
    case FilterConstants.FILTER_EXCERPTS:
    case FilterConstants.FILTER_REPORT_RESPONSE:
    case QualConstants.DELETE_CODE_RESULT:
    case QualConstants.DELETE_TRANSCRIPT_RESULT:
    case QualConstants.UPDATE_MULTI_CHOICE_DESCRIPTOR_RESULT:
    case QualConstants.DESTROY_MULTI_CHOICE_DESCRIPTOR_RESULT:
    case FilterConstants.FILTER_NEXT:
    case FilterConstants.SORT_CHANGED:
      FilterState.set(filterReducer(action, FilterState.get()));
    break;
    // all events that should trigger a filter
    case FilterConstants.FILTER_CODE_CHECKED:
    case FilterConstants.FILTER_CODE_UNCHECKED:
    case FilterConstants.FILTER_CODE_CLEAR:
    case FilterConstants.FILTER_CHECKED:
    case FilterConstants.FILTER_UNCHECKED:
    case FilterConstants.FILTER_CLEAR:
    case FilterConstants.TRANSCRIPT_CHECKED:
    case FilterConstants.TRANSCRIPT_UNCHECKED:
    case FilterConstants.TRANSCRIPT_CLEAR:
    case FilterConstants.CODE_OP_CHANGED:
    {
      FilterState.set(filterReducer(action, FilterState.get()));
      const data = action.data || {};
      const projectID = data.projectID;
      const source = data.source || null;
      

      setTimeout(function() { // Run after dispatcher has finished
        delayedFilter(projectID, source);
      }, 0);
    }
    break;
    default:
      return true;
  }

  FilterStore.emitChange();
  return true;
});

export default FilterStore
