module.exports =  {
                    ADD_CODE_AND_EXCERPT: "ADD_CODE_AND_EXCERPT",
                    // CREATE code
                    CREATE_CODE: "CREATE_CODE",
                    CREATE_CODE_RESULT: "CREATE_CODE_RESULT",
                    CREATE_CODE_ERROR: "CREATE_CODE_ERROR",
                    // GET code
                    GET_CODE: "GET_CODE",
                    GET_CODE_RESULT: "GET_CODE_RESULT",
                    GET_CODE_ERROR: "GET_CODE_ERROR",
                    // FETCH codes
                    FETCH_CODES: "FETCH_CODES",
                    FETCH_CODES_RESULT: "FETCH_CODES_RESULT",
                    FETCH_CODES_ERROR: "FETCH_CODES_ERROR",
                    SELECT_EXCERPT: "SELECT_EXCERPT",
                    SELECT_WORD: "SELECT_WORD",
                    DESELECT_EXCERPT: "DESELECT_EXCERPT",
                    // after an excerpt is added
                    CREATE_EXCERPT: "CREATE_EXCERPT",
                    CREATE_EXCERPT_RESULT: "CREATE_EXCERPT_RESULT",
                    CREATE_EXCERPT_ERROR: "CREATE_EXCERPT_ERROR",
                    CREATE_EXCERPT_CABLE: "CREATE_EXCERPT_CABLE",
                    // code presses
                    CODE_PRESSED: "CODE_PRESSED",
                    CODE_UNPRESSED: "CODE_UNPRESSED",
                    // LOAD_TRANSCRIPT
                    LOAD_TRANSCRIPT: "LOAD_TRANSCRIPT",
                    LOAD_TRANSCRIPT_RESULTS: "LOAD_TRANSCRIPT_RESULTS",
                    LOAD_TRANSCRIPT_ERROR: "LOAD_TRANSCRIPT_ERROR",
                    LOADED_PROJECTS: "LOADED_PROJECTS",
                    LOAD_PROJECTS_ERROR: "LOAD_PROJECTS_ERROR",

                    // LOAD_PROJECT_DETAILS
                    LOAD_PROJECT_DETAILS: "LOAD_PROJECT_DETAILS",
                    LOAD_PROJECT_DETAILS_RESULT: "LOAD_PROJECT_DETAILS_RESULT",
                    LOAD_PROJECT_DETAILS_ERROR: "LOAD_PROJECT_DETAILS_ERROR",
                    // CREATE project
                    NEW_PROJECT: "NEW_PROJECT",
                    NEW_PROJECT_RESULT: "NEW_PROJECT_RESULT",
                    NEW_PROJECT_ERROR: "NEW_PROJECT_ERROR",
                    // UPDATE project name
                    UPDATE_PROJECT_NAME: "UPDATE_PROJECT_NAME",
                    UPDATE_PROJECT_NAME_RESULT: "UPDATE_PROJECT_NAME_RESULT",
                    UPDATE_PROJECT_NAME_ERROR: "UPDATE_TRANSCRIPT_ERROR",
                    // PROJECT CABLE
                    PROJECT_CABLE: "PROJECT_CABLE",
                    // analysis edit excerpt
                    ANALYIS_EDIT_EXCERPT: "ANALYIS_EDIT_EXCERPT",
                    // after code added to existing excerpt
                    ADD_CODE_TO_EXCERPT: "ADD_CODE_TO_EXCERPT",
                    ADD_CODE_TO_EXCERPT_RESULT: "ADD_CODE_TO_EXCERPT_RESULT",
                    ADD_CODE_TO_EXCERPT_ERROR: "ADD_CODE_TO_EXCERPT_ERROR",
                    // code deleted
                    DELETE_CODE_FROM_EXCERPT: "DELETE_CODE_FROM_EXCERPT",
                    DELETE_CODE_FROM_EXCERPT_RESULT: "DELETE_CODE_FROM_EXCERPT_RESULT",
                    DELETE_CODE_FROM_EXCERPT_ERROR: "DELETE_CODE_FROM_EXCERPT_ERROR",
                    // FETCH EXCERPTS
                    FETCH_EXCERPTS: "FETCH_EXCERPTS",
                    FETCH_EXCERPTS_RESULT: "FETCH_EXCERPTS_RESULT",
                    FETCH_EXCERPTS_ERROR: "FETCH_EXCERPTS_ERROR",
                    // SEARCH
                    TRANSCRIPT_SEARCH: "TRANSCRIPT_SEARCH",
                    TRANSCRIPT_SEARCH_RECEIVED: "TRANSCRIPT_SEARCH_RECEIVED",
                    TRANSCRIPT_SEARCH_RECEIVED_ERROR: "TRANSCRIPT_SEARCH_RECEIVED_ERROR",
                    // CREATE transcript
                    TRANSCRIPT_CREATE: "TRANSCRIPT_CREATE",
                    TRANSCRIPT_CREATE_RESULT: "TRANSCRIPT_CREATE_RESULT",
                    TRANSCRIPT_CREATE_ERROR: "TRANSCRIPT_CREATE_ERROR",
                    TRANSCRIPT_CREATE_CABLE: "TRANSCRIPT_CREATE_CABLE",
                    ADD_PRIME_CODE: "ADD_PRIME_CODE",
                    REMOVE_PRIME_CODE: "REMOVE_PRIME_CODE",
                    ANALYSIS_EXCERPT_CLICKED: "ANALYSIS_EXCERPT_CLICKED",
                    // descriptor code
                    FETCH_DESCRIPTORS: "FETCH_DESCRIPTORS",
                    FETCH_DESCRIPTORS_RESULT: "FETCH_DESCRIPTORS_RESULT",
                    FETCH_DESCRIPTORS_ERROR: "FETCH_DESCRIPTORS_ERROR",
                    // new multi_choice
                    CREATE_MULTI_CHOICE: "CREATE_MULTI_CHOICE",
                    CREATE_MULTI_CHOICE_RESULT: "CREATE_MULTI_CHOICE_RESULT",
                    CREATE_MULTI_CHOICE_ERROR: "CREATE_MULTI_CHOICE_ERROR",
                    // update multi_choice_descriptor
                    UPDATE_MULTI_CHOICE_DESCRIPTOR: "UPDATE_MULTI_CHOICE_DESCRIPTOR",
                    UPDATE_MULTI_CHOICE_DESCRIPTOR_RESULT: "UPDATE_MULTI_CHOICE_DESCRIPTOR_RESULT",
                    UPDATE_MULTI_CHOICE_DESCRIPTOR_ERROR: "UPDATE_MULTI_CHOICE_DESCRIPTOR_ERROR",
                    // CREATE_MULTI_CHOICE_DESCRIPTOR
                    CREATE_MULTI_CHOICE_DESCRIPTOR: "CREATE_MULTI_CHOICE_DESCRIPTOR",
                    CREATE_MULTI_CHOICE_DESCRIPTOR_RESULT: "CREATE_MULTI_CHOICE_DESCRIPTOR_RESULT",
                    CREATE_MULTI_CHOICE_DESCRIPTOR_ERROR: "CREATE_MULTI_CHOICE_DESCRIPTOR_ERROR",
                    // DESTROY_MULTI_CHOICE_DESCRIPTOR
                    DESTROY_MULTI_CHOICE_DESCRIPTOR: "DESTROY_MULTI_CHOICE_DESCRIPTOR",
                    DESTROY_MULTI_CHOICE_DESCRIPTOR_RESULT: "DESTROY_MULTI_CHOICE_DESCRIPTOR_RESULT",
                    DESTROY_MULTI_CHOICE_DESCRIPTOR_ERROR: "DESTROY_MULTI_CHOICE_DESCRIPTOR_ERROR",
                    // fetch selections
                    FETCH_SELECTIONS: "FETCH_SELECTIONS",
                    FETCH_SELECTIONS_RESULT: "FETCH_SELECTIONS_RESULT",
                    FETCH_SELECTIONS_ERROR: "FETCH_SELECTIONS_ERROR",
                    // FETCH PROJECT SELECTIONS
                    FETCH_PROJECT_SELECTIONS: "FETCH_PROJECT_SELECTIONS",
                    FETCH_PROJECT_SELECTIONS_RESULT: "FETCH_PROJECT_SELECTIONS_RESULT",
                    FETCH_PROJECT_SELECTIONS_ERROR: "FETCH_PROJECT_SELECTIONS_ERROR",
                    // CREATE selection
                    CREATE_MULTI_CHOICE_SELECTION: "CREATE_MULTI_CHOICE_SELECTION",
                    CREATE_MULTI_CHOICE_SELECTION_RESULT: "CREATE_MULTI_CHOICE_SELECTION_RESULT",
                    CREATE_MULTI_CHOICE_SELECTION_ERROR: "CREATE_MULTI_CHOICE_SELECTION_ERROR",
                    // DELETE selections
                    DELETE_MULTI_CHOICE_SELECTION: "DELETE_MULTI_CHOICE_SELECTION",
                    DELETE_MULTI_CHOICE_SELECTION_RESULT: "DELETE_MULTI_CHOICE_SELECTION_RESULT",
                    DELETE_MULTI_CHOICE_SELECTION_ERROR: "DELETE_MULTI_CHOICE_SELECTION_ERROR",
                    // DELETE transcripts
                    DELETE_TRANSCRIPT: "DELETE_TRANSCRIPT",
                    DELETE_TRANSCRIPT_RESULT: "DELETE_TRANSCRIPT_RESULT",
                    DELETE_TRANSCRIPT_ERROR: "DELETE_TRANSCRIPT_ERROR",
                    DELETE_TRANSCRIPT_CABLE: "DELETE_TRANSCRIPT_CABLE",
                    // UPDATE transcript
                    UPDATE_TRANSCRIPT: "UDPATE_TRANSCRIPT",
                    UPDATE_TRANSCRIPT_RESULT: "UPDATE_TRANSCRIPT_RESULT",
                    UPDATE_TRANSCRIPT_ERROR: "UPDATE_TRANSCRIPT_ERROR",
                    UPDATE_TRANSCRIPT_CABLE: "UPDATE_TRANSCRIPT_CABLE",

                    // Update Transcript
                    UPDATE_TRANSCRIPT_TEXT: "UDPATE_TRANSCRIPT_TEXT",
                    UPDATE_TRANSCRIPT_TEXT_RESULT: "UPDATE_TRANSCRIPT_TEXT_RESULT",
                    UPDATE_TRANSCRIPT_TEXT_ERROR: "UPDATE_TRANSCRIPT_TEXT_ERROR",
                    // DELETE codes
                    DELETE_CODE: "DELETE_CODE",
                    DELETE_CODE_RESULT: "DELETE_CODE_RESULT",
                    DELETE_CODE_ERROR: "DELETE_CODE_ERROR",
                    // CABLE UPDATE
                    CREATE_CODE_CABLE: "CREATE_CODE_CABLE",
                    UPDATE_CODE_CABLE: "UPDATE_CODE_CABLE",
                    DELETE_CODE_CABLE: "DELETE_CODE_CABLE",
                    NEST_CODE_CABLE: "NEST_CODE_CABLE",
                    // RENAME code
                    RENAME_CODE: "RENAME_CODE",
                    RENAME_CODE_RESULT: "RENAME_CODE_RESULT",
                    RENAME_CODE_ERROR: "RENAME_CODE_ERROR",
                    // FETCH user
                    FETCH_USER: "FETCH_USER",
                    FETCH_USER_RESULT: "FETCH_USER_RESULT",
                    FETCH_USER_ERROR: "FETCH_USER_ERROR",
                    // NEW PROJECT
                    SHOW_NEW_PROJECT_MODAL: "SHOW_NEW_PROJECT_MODAL",
                    HIDE_NEW_PROJECT_MODAL: "HIDE_NEW_PROJECT_MODAL",
                    // ACCEPT TERMS OF SERVICE
                    ACCEPT_TERMS_OF_SERVICE: "ACCEPT_TERMS_OF_SERVICE",
                    ACCEPT_TERMS_OF_SERVICE_RESULT: "ACCEPT_TERMS_OF_SERVICE_RESULT",
                    ACCEPT_TERMS_OF_SERVICE_ERROR: "ACCEPT_TERMS_OF_SERVICE_ERROR",
                    // NEST CODE
                    NEST_CODE: "NEST_CODE",
                    NEST_CODE_RESULT: "NEST_CODE_RESULT",
                    NEST_CODE_ERROR: "NEST_CODE_ERROR",
                    // SET State (note for debugonly)
                    DEBUG_SET_STATE: "DEBUG_SET_STATE",
                    // Code Page Modal
                    SHOW_CODE_PAGE_MODAL: "SHOW_CODE_PAGE_MODAL",
                    HIDE_CODE_PAGE_MODAL: "HIDE_CODE_PAGE_MODAL",
                    // update code synnthesis
                    UPDATE_CODE_SYNTHESIS: "UPDATE_CODE_SYNTHESIS",
                    UPDATE_CODE_SYNTHESIS_RESULT: "UPDATE_CODE_SYNTHESIS_RESULT",
                    UPDATE_CODE_SYNTHESIS_ERROR: "UPDATE_CODE_SYNTHESIS_ERROR",
                    // collapse codes
                    COLLAPSE_CODE: "COLLAPSE_CODE",
                    EXPAND_CODE: "EXPAND_CODE",
                    CODE_BOOK: "CODE_BOOK",
                    // collapse code labels
                    COLLAPSE_CODE_BAR: "COLLAPSE_CODE_BAR",
                    EXPAND_CODE_BAR: "EXPAND_CODE_BAR",
                    CODE_BAR: "CODE_BAR",
                    // edit paragraph
                    EDIT_PARAGRAPH: "EDIT_PARAGRAPH",
                    CANCEL_EDIT_PARAGRAPH: "CANCEL_EDIT_PARAGRAPH",
                    // CLEAR ERROR
                    CLEAR_ERROR: "CLEAR_ERROR",
                    // FILTER_CODERS
                    FILTER_CODERS: "FILTER_CODERS",
                  }
