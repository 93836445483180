// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';
import ProjectsStore from 'stores/ProjectsStore'
import QualCodeActions from 'actions/QualCodeActions'
import newProjectModalMapper from  'mappers/NewProjectModalMapper'
import RowModal from './RowModal'
import ModalRow from './ModalRow'
import ProjectRow from './ProjectRow'
import { withRouter } from 'react-router';
import { withToastManager } from 'react-toast-notifications';


class ProjectPickerModal extends React.Component {
  constructor () {
    super();
    this.state = {
      ...ProjectsStore.getStateWithMapper(newProjectModalMapper)
    };
    this._onChange = this._onChange.bind(this);
    this.addProject = this.addProject.bind(this);
    this.onHide = this.onHide.bind(this);
    this.duplicateProject = this.duplicateProject.bind(this);
    this.errorArchivingProject = this.errorArchivingProject.bind(this);
  }

  errorArchivingProject (archive) {
    QualCodeActions.hideProjectModal();
    QualCodeActions.loadProjects();

    if ( this.isArchived() ) {
      this.props.history.push('/users');
    }

    this.props.toastManager.add(
      archive ? 'Error Archiving Project - Please Refresh and Try Again' :
      'Error Unarchiving Project - Please Refresh and Try Again', {
        appearance: 'error',
      }
    );
  }

  isArchived() {
    return this.props.location.pathname.includes('archived');
  }

  onHide (e)
  {
    if ( this.isArchived() ) {
      this.props.history.push('/users');
    } else {
      QualCodeActions.hideProjectModal();
    }
  }

  addProject ()
  {
    if ( !this.props.subscriptionState || !this.props.subscriptionState.loaded) return;
    if ( !!this.props.subscriptionState.isCancelled || !!this.props.subscriptionState.isPastDue )
    {
      QualCodeActions.hideProjectModal();
      this.props.onCanceledNewProject();
    } else {
      QualCodeActions.hideProjectModal();
      this.props.history.push('/projects/new')
    }
  }

  duplicateProject (projectID)
  {
    if ( !this.props.subscriptionState || !this.props.subscriptionState.loaded) return;
    if ( !!this.props.subscriptionState.isCancelled || !!this.props.subscriptionState.isPastDue)
    {
      QualCodeActions.hideProjectModal();
      this.props.onCanceledNewProject();
      return;
    }
    
    QualCodeActions.hideProjectModal();

    const result = this.props.toastManager.add(
      'Copying Codes Into New Project', {
        appearance: 'info'
     });

    QualCodeActions.duplicateProject(projectID).then((data)=>{
      this.props.history.push(`/projects/${data.id}/analysis`)

      this.props.toastManager.update(
        result,
        {
          content: 'New Project Created!',
          appearance: 'success',
          autoDismiss: true,
          autoDismissTimeout: 10000
       });
    }).catch((error)=>{
      this.props.toastManager.update(
        result, {
          content: 'Error Creating New Project. Contact Us For Help.',
          appearance: 'error',
          autoDismiss: true,
          autoDismissTimeout: 10000
       });
    })
  }

  _onChange()
  {
    this._loadState(this.props);
  }

  _loadState(props)
  {
    this.setState({
        ...ProjectsStore.getStateWithMapper(newProjectModalMapper),
      }
    );
  }

  componentDidMount () {
    ProjectsStore.addChangeListener(this._onChange);
  }

  componentWillUnmount () {
    ProjectsStore.removeChangeListener(this._onChange);
  }

  render () {
    const isArchived = this.isArchived();

    const projects = this.state.projects.filter((project) =>
      (project.status === 'archived') === isArchived
    );

    return (
      <div>
        <RowModal
          bsSize="large"
          show={this.state.show || isArchived}
          onHide={this.onHide}
          buttonText='New Project'
          buttonAction={isArchived ? null : this.addProject}
          header={<h3 style={{marginTop:0}}>{isArchived ? 'Archived Projects' : 'Projects'}</h3>}
        >
          <ModalRow
            row={[
              <strong>Project</strong>,
              <strong>Owner</strong>,
              <strong>Last Edited</strong>,
              ' ']}
          />

          {projects.map((project,index)=>{
            return <ProjectRow
                project={project}
                key={index}
                onHide={this.onHide}
                duplicateProject={this.duplicateProject}
                errorArchivingProject={this.errorArchivingProject}
              />
          })}

          {projects.length === 0 &&
            <div style={{textAlign: 'center', fontSize: '1.2em', marginTop: '40px'}}>
              {
                isArchived ? 
                  "There are no archived projects at this time." :
                  "There are no projects at this time."
              }
            </div>
          }
          
        </RowModal>
      </div>
    );
  }
}

export default withRouter(withToastManager(ProjectPickerModal));
