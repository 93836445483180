const getCodeId = (state, codeId) => {
  if ( !codeId ) return codeId;
  return state.mappers.codes[codeId];
}

const getExcerptId = (state, excerptId) => {
  if ( !excerptId ) return excerptId;
  return state.mappers.excerpts[excerptId];
}

var OptimisticMapperHelper = {
  /***********************\
  *
  * Refer
  *
  \************************/
  getCodeId,
  getCodeIdOrOriginal: function(state, codeId) {
    return getCodeId(state, codeId) || codeId;
  },
  getCode: function(state, codeId) {
    if ( !codeId ) return null;
    return state.entities.codes[getCodeId(state, codeId)] || null;
  },

  getCodeServerId: function(state, codeId) {
    return getCode(state, codeId).server_id;
  },

  getExcerptId,
  getExcerptIdOrOriginal: function(state, excerptId) {
    return getExcerptId(state, excerptId) || excerptId;
  },
  getExcerpt: function(state, excerptId ) {
    return state.entities.excerpts[getExcerptId(state, excerptId)];
  },

  getExcerptServerId: function (state, excerptId) {
    return self.getExcerpt(state, excerptId).server_id;
  }
}

export default OptimisticMapperHelper
