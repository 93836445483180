import OtterAIFormatter from "./formatter/OtterAIFormatter";
import SubtitleFormatter from "./formatter/SubtitleFormatter";
import RevFormatter from "./formatter/RevFormatter";
import TeamFormatter from "./formatter/TeamFormatter";
import TranscriptCleanerUtil from "./TranscriptCleanerUtil";
import DiscussIOFormatter from "./formatter/DiscussIOFormatter";

class TranscriptFormatter {
  constructor(){
  }

  run(transcriptText) {
    if ( !process.env.FORMAT_TRANSCRIPT_FLAG ) return transcriptText;
    
    let newStr = transcriptText;
    const formatters = [
      TranscriptCleanerUtil,
      OtterAIFormatter,
      TeamFormatter,
      SubtitleFormatter,
      RevFormatter,
      DiscussIOFormatter
    ]

    formatters.forEach((formatter)=>{
      newStr = formatter(newStr);
    });

    return newStr;
  }
}

export default TranscriptFormatter;
