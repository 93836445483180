module.exports =  {
                    LOADING_STATE: {
                      loading: true,
                      loaded: false,
                    },
                    LOADED_STATE: {
                      loading: false,
                      loaded: true,
                    },
                    NOT_LOADING_STATE: {
                      loading: false,
                      loaded: false,
                    },
                    LOADING_DICT_DEFAULT:{
                      loading: false,
                      last_loaded: null,
                    },
                    LOADING_DICT_LOADED:(()=>{
                      const d = new Date();
                      return {
                        loading: false,
                        last_loaded:d.getTime(),
                      }
                    }),
                  }
