const _ = require('underscore');
import excerptMapper from 'mappers/ExcerptMapper'
import optimisticMapperHelper  from 'mappers/OptimisticMapperHelper'

const memoPageMapper = (state, excerptIds) => {
  const excerpts = _.chain(excerptIds || []).filter((id)=>!!id)
  .map((excerptID)=>optimisticMapperHelper.getExcerptId(state, excerptID))
  .uniq()
  .map((excerptID)=>{
    return excerptMapper(state, excerptID)
  }).filter((e)=>!!e)
  .sort((a,b)=>(b.updated_at || '').localeCompare(a.updated_at || ''))
  .value();

  return {
    excerpts 
  }
}

export default memoPageMapper
