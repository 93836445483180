
import {
  FILES__TYPE_UNSUPPORTED,
} from 'constants/ErrorConstants'
import FileParserTxt from './FileParserTxt'
import FileParserDocx from './FileParserDocx'
import FileParserPdf from './FileParserPdf'

import ErrorUtil from 'utils/ErrorUtil'

export default (file) => {
  if ( !file ) throw new Error('Empty File');
  if ( !file.name ) throw new Error('No File Name');

  const fileName = file.name.toLowerCase();

  if ( fileName.endsWith('.txt') ) return new FileParserTxt(file);
  if ( fileName.endsWith('.docx') ) return new FileParserDocx(file);
  if ( fileName.endsWith('.pdf') ) return new FileParserPdf(file);

  throw ErrorUtil.createError(FILES__TYPE_UNSUPPORTED);
};
