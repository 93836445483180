// app/javascript/projects/components/ProjectsDisplay.jsx

import {
  Switch,
  Route,
} from 'react-router-dom'
import React from 'react';
import {Button} from 'react-bootstrap'
import ProjectContext from 'contexts/ProjectContext';
import LogAPI from 'apis/LogAPI';
import { MemoModalProvider } from './modal/MemoModalProvider';
import {
  TRANSCRIPT_NAVIGATION_PADDING
} from 'constants/DimensionsConstants'

import trackingUtil from 'utils/TrackingUtil'
import UrlUtil from 'utils/UrlUtil'
import TranscriptDropdown from './transcript_nav/TranscriptDropdown';

import {
  TRANSCRIPT_TITLE,
  DEMOGRAPHICS_TITLE,
  CODED_BY_ME_TITLE,
  COMPARISON_TITLE,
} from 'constants/TranscriptNavigationConstants'

import {
  TRANSCRIPT_PAGE,
  DEMOGRAPHICS_PAGE,
  CODED_BY_ME_PAGE,
  COMPARISON_PAGE,
} from 'constants/PageConstants'
import CodedTranscriptStore from 'stores/CodedTranscriptStore'

import CodingActions from '../../actions/CodingActions';
import DemographicsView from './DemographicsView'
import TranscriptBodyContainer from './TranscriptBodyContainer'
import TranscriptCodePageContainer from './TranscriptCodePageContainer'
import ComparisonViewContainer from './ComparisonViewContainer'

const tabStyle = {
    padding: `20px 20px 0px ${TRANSCRIPT_NAVIGATION_PADDING}px`,
    margin: "0px"
}

class TranscriptNav extends React.Component {
  static contextType = ProjectContext;

  constructor () {
    super();
    this.state = {
      height: 300,
      pageCount: CodedTranscriptStore.getNumberOfPages(null),
      transcriptLoaded: false,
      showInterCoderReliability: false,
    };

    this._handleWindowResize = this._handleWindowResize.bind(this);
    this._onChange = this._onChange.bind(this);
    this.getNavigationTitle = this.getNavigationTitle.bind(this);
    this.onNavigationClick = this.onNavigationClick.bind(this);
    this.gotoDemographics = this.gotoDemographics.bind(this);
    this.gotoCompare = this.gotoCompare.bind(this);
    this.gotoCodedByMe = this.gotoCodedByMe.bind(this);
    this.setInterCoderReliability = this.setInterCoderReliability.bind(this);
  }

  setInterCoderReliability(showInterCoderReliability) {
    if ( !!showInterCoderReliability ) {
      LogAPI.logEvent('view-inter-coder-reliability')
    }

    this.setState({showInterCoderReliability});
  }

  gotoTranscript(queryParams) {
    this.props.history.push(`${this.props.location.pathname}${queryParams}`)
  }

  _onChange () {
    this.setState({
      pageCount: CodedTranscriptStore.getNumberOfPages(this.props.transcriptID),
      transcriptLoaded: CodedTranscriptStore.isTranscriptLoaded(this.props.transcriptID)
    });

    this._handleWindowResize();
  }

  componentDidUpdate(prevProps) {
    // Typical usage, don't forget to compare the props
    if (this.props.transcriptID !== prevProps.transcriptID) {
      this.setState({transcriptLoaded: CodedTranscriptStore.isTranscriptLoaded(this.props.transcriptID)});
    }
  }
  renderWithPageInfo(Component, otherProps)
  {
    return (props) => {
        return (
          <Component
            projectID={this.props.projectID}
            transcriptID={this.props.transcriptID}
            page={this.props.page}
            hasEditPermission={this.props.hasEditPermission}
            pageCount={this.state.pageCount}
            onShowCollabModal={this.props.onShowCollabModal}
            {...props}
            {...(otherProps || {})}
          />
        );
    }
  }

  _handleWindowResize()
  {
    var height = 300;
    if ( this.tabDiv )
    {
      height = window.innerHeight  - this.tabDiv.clientHeight - 50;
    }

    this.setState({height: height})
  }

  componentDidMount () {
    window.addEventListener('resize', this._handleWindowResize);
    this._handleWindowResize();
    CodedTranscriptStore.addChangeListener(this._onChange);
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this._handleWindowResize);
    CodedTranscriptStore.removeChangeListener(this._onChange);
  }

  gotoUrl(url) {
    this.props.history.push(url)
  }

  gotoCompare() {
    CodingActions.deselectExcerpt();
    const url = this.compareUrl();
    this.gotoUrl(url);
    trackingUtil.logEvent('navigate-to-coding-comparison')
  }

  gotoCodedByMe() {
    this.onNavigationClick('user/me')
    trackingUtil.logEvent('navigate-to-coded-by-me')
  }

  onNavigationClick(transcriptView) {
    // get the page parameter from the url
    const page = UrlUtil.getPageNumber(this.props.location)
    
    let url = transcriptView ? 
      `/transcripts/${this.props.transcriptID}/${transcriptView}` : 
      `/transcripts/${this.props.transcriptID}` 

    // append page to url
    if ( !!page && page > 1 ) {
      url = `${url}?page=${page}`
    }

    this.gotoUrl(url)
  }

  gotoDemographics(event) {
    const url = `/transcripts/${this.props.transcriptID}/demographics`
    this.gotoUrl(url)
  }

  getNavigationTitle() {
    switch (this.props.page) {
      case TRANSCRIPT_PAGE:
        return TRANSCRIPT_TITLE;
      case CODED_BY_ME_PAGE:
        return CODED_BY_ME_TITLE;
      case DEMOGRAPHICS_PAGE:
        return DEMOGRAPHICS_TITLE;
      case COMPARISON_PAGE:
        return COMPARISON_TITLE;
      default:
        return TRANSCRIPT_TITLE;
    }
  }

  hasCollaborators() {
    const data = this.context;
    const collaborators = data.collaborators || [];
    const collaboratorsLoaded = data.collaboratorsLoaded;
    return collaborators.length > 0 || !collaboratorsLoaded;
  }

  compareUrl() {
    const data = this.context;
    const collaborators = data.collaborators || [];
    const owner = data.owner || {};
    const ownerId = owner.id;

    const page = UrlUtil.getPageNumber(this.props.location);
    const collaboratorsLoaded = data.collaboratorsLoaded;
    let collaboratorId = (collaborators[0] || {}).id;

    collaboratorId = !collaboratorId && !!collaboratorsLoaded ? 'none' : collaboratorId;

    // url should match this but should drop left or right if it does not exist
    // const url = `compare?left=${ownerId}&right=${collaboratorId}`;

    return UrlUtil.addParamsToUrl(
      `/transcripts/${this.props.transcriptID}/compare`,
      {
        ...(ownerId && {left: ownerId}),
        ...(collaboratorId && {right: collaboratorId}),
        ...(page && page > 1 && {page: page}),
      }
    )
  }
  
  render () {
    return (
      <div style={{height: "100%"}} >
        <MemoModalProvider>
          <TranscriptCodePageContainer
            projectID={this.props.projectID}
            hasEditPermission={this.props.hasEditPermission}
          />
        </MemoModalProvider>
        <div style={tabStyle} ref={(div) => { this.tabDiv = div; }} >
          <TranscriptDropdown
            title={this.getNavigationTitle()}
            onNavigationClick={this.onNavigationClick}
            gotoDemographics={this.gotoDemographics}
            gotoCompare={this.gotoCompare}
            gotoCodedByMe={this.gotoCodedByMe}
            showGotoCodedByMe={this.hasCollaborators()}
          />

          <Switch>
            <Route
              path='/transcripts/:transcriptID/compare'
              // render a button
              render={() => {return <Button
                disabled={!this.state.transcriptLoaded}
                bsStyle="link"
                onClick={() => {this.setInterCoderReliability(true)}}
              >Inter Coder Reliability</Button>}}
            />
          </Switch>             
        </div>

        <div style={{height: this.state.height.toString() + "px"}}>
          <Switch>
            <Route
              exact
              path='/transcripts/:transcriptID'
              render={this.renderWithPageInfo(TranscriptBodyContainer)}
            />
            <Route
              path='/transcripts/:transcriptID/demographics'
              render={this.renderWithPageInfo(DemographicsView)}
            />
            <Route
              path='/transcripts/:transcriptID/compare'
              render={this.renderWithPageInfo(ComparisonViewContainer, {
                showInterCoderReliability: this.state.showInterCoderReliability,
                onHideInterCoderReliability: () => {this.setInterCoderReliability(false)},
              })}
            />
            <Route
              exact
              path='/transcripts/:transcriptID/user/me'
              render={this.renderWithPageInfo(TranscriptBodyContainer)}
            />
          </Switch>
        </div>
      </div>);
  }
}

export default TranscriptNav;
