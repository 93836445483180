module.exports =  {
  MAIN_PAGE: "MAIN_PAGE",
  ADD_MEMBERS: "ADD_MEMBERS",
  PAYMENT: "PAYMENT",
  MEMBER_DETAIL: "MEMBER_DETAIL",
  MEMBER_DELETE: "MEMBER_DELETE",
  PLAN_AND_BILLING: "PLAN_AND_BILLING",
  PAUSE_FEEDBACK: "PAUSE_FEEDBACK",
  CANCEL_SUBSCRIPTION: "CANCEL_SUBSCRIPTION",
  UNCANCEL_SUBSCRIPTION: "UNCANCEL_SUBSCRIPTION",
  ANNUAL_SUBSCRIPTION: "ANNUAL_SUBSCRIPTION",
  SUBSCRIPTION_PAST_DUE: "SUBSCRIPTION_PAST_DUE",
  REFER_PAGE: "REFER_PAGE",
  REFER_SENT: "REFER_SENT",
  BLOCKER: "BLOCKER",
  PROJECT_BLOCKER: "PROJECT_BLOCKER"
}
