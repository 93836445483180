import speakerMapper from 'mappers/SpeakerMapper'
import SentenceBuilder from './SentenceBuilder';
import GetCodes from './CodesFromParagraph'

var _ = require('underscore');

class ParagraphClass {
  constructor(text, start, paragraphIndex){
    this.paragraphIndex = paragraphIndex;
    this.text = text;
    this.start = start;
    this.end = this.start + text.length;
    this._sentences = [];
    this.speaker = null;
    this.time = null;
    this.introText = null;
    this.bodyText = text;
    this._excerpts = [];
    const timeAndSpeaker = speakerMapper(text);

    if ( timeAndSpeaker ) {
      this.speaker = timeAndSpeaker.name;
      this.time = timeAndSpeaker.time;
      this.introText = timeAndSpeaker.introText;
      this.bodyText = timeAndSpeaker.bodyText;
    }

    this.constructSentences([]);
  }

  constructSentences(excerpts) {
    const sentencebuilder = new SentenceBuilder(this.end, this.start, this.introText, this.bodyText);
    this._sentences = sentencebuilder.constructSentences(excerpts);
  }

  getIndex() {
    return this.paragraphIndex
  }

  getSpeaker() {
    return this.speaker;
  }

  getTime() {
    return this.time;
  }

  getStart()
  {
    return this.start;
  }

  getEnd()
  {
    return this.end;
  }

  getNumberOfSentences()
  {
    return this._sentences.length;
  }

  _addExcerptToParagraph(excerpt) {
    const excerptInsideParagraph = !(excerpt.end <= this.start || excerpt.start >= this.end);
    if ( !excerptInsideParagraph ) return;

    this._excerpts = this._excerpts.filter((existingExcerpt)=> existingExcerpt.id !== excerpt.id)
    this._excerpts.push(excerpt);
  }

  addExcerpts(excerpts)
  {
    // everything about this is super hacky, and it would be good to clean it up.
    excerpts.forEach((excerpt)=>{
      this._addExcerptToParagraph(excerpt);
    })

    this.constructSentences(this._excerpts);
  }

  _getExcerpt(select) {
    if ( !select || (!select.start & !select.end )) return null;

    return _.chain(this._excerpts || [])
      .filter((excerpt) => excerpt.start === select.start && excerpt.end === select.end)
      .sortBy((excerpt) => -1 * (excerpt.codes || []).length)
      .first()
      .value();
  }

  _getSelectedCodes(selectedExcerpt) {
    selectedExcerpt = selectedExcerpt || {};
    return (selectedExcerpt.codes || []).map((code)=>code.id);
  }

  getMemoedExcerptIds() { 
    return _.chain(this._excerpts || [])
      .filter((excerpt)=>excerpt.memos && excerpt.memos.length > 0)
      .map((excerpt)=>(excerpt || {}).id)
      .filter((id)=>!!id)
      .value();
  }

  hasMemo(){
    return this.getMemoedExcerptIds().length > 0;
  }

  getCodedSentences(speakerColorHash, state, select, primeCode){
    speakerColorHash = speakerColorHash || {};
    const isSelectInsideParagraph = !!select && !(select.end <= this.start || select.start >= this.end);
    let selectedExcerpt = null;

    if ( isSelectInsideParagraph )
      selectedExcerpt = this._getExcerpt(select);

    return {
      start: this.start,
      end: this.end,
      text: this.bodyText,
      speaker: this.getSpeaker(),
      color: speakerColorHash[this.getSpeaker()],
      time: this.getTime(),
      codes: GetCodes(state, this._excerpts, selectedExcerpt, this._getSelectedCodes(selectedExcerpt)),
      index: this.getIndex(),
      sentences:this._sentences.map((sentence)=>
        sentence.getCodedFormat(
          primeCode,
          select,
          this._excerpts
        )
      ),
      hasMemos: false /*this.hasMemo()*/,
      memoedExcerptIds: [] /*this.getMemoedExcerptIds()*/,
      selected: isSelectInsideParagraph,
    }
  }
}

export default ParagraphClass;
