// app/javascript/projects/components/OnboardingMessage.jsx

import React from 'react';
import OnboardingMessage from './OnboardingMessage';

function OnboardingMessageWrapper({onboardingState, header, body, secondBody, projectID, children, hasEditPermission, helpArticleText, intercomArticleId}) {
  return (onboardingState.loaded && onboardingState.otherOnboarding) ? 
    <OnboardingMessage
      header={header}
      body={body}
      secondBody={secondBody}
      projectID={projectID}
      hasEditPermission={hasEditPermission}
      helpArticleText={helpArticleText}
      intercomArticleId={intercomArticleId}
    /> : 
    children;
}

export default OnboardingMessageWrapper;
