import metaSubscriptionMapper from 'mappers/MetaSubscriptionMapper'
import {
  UNCANCEL_SUBSCRIPTION
} from 'constants/CashierConstants'

const uncancelSubscriptionMapper = (state) => {
  return {
    uncancelSubscription:metaSubscriptionMapper(state, UNCANCEL_SUBSCRIPTION)
  }
}

export default uncancelSubscriptionMapper
