import { normalize, schema } from 'normalizr';
import InitialState from 'constants/InitialState'
import subscriptionReducer from './SubscriptionReducer'
import LoadingConstants from 'constants/LoadingConstants'
import AccountPageConstants from 'constants/AccountPageConstants'


import {
  FETCH_UPGRADES,
  FETCH_UPGRADES_RESULT,
  FETCH_UPGRADES_ERROR,
  CREATE_UPGRADE,
  CREATE_UPGRADE_RESULT,
  CREATE_UPGRADE_ERROR
} from 'constants/UpgradeConstants'

const plan_schema = new schema.Entity('plans');
const plans_schema = new schema.Entity('plans_schema', {
  plans:[plan_schema]
});

function upgradeReducer(action, state = InitialState)
{
  if ( !action ) return state;

  switch (action.actionType)
  {
    case FETCH_UPGRADES:
    case FETCH_UPGRADES_ERROR:
    {
      return {
        ...state,
        entities:{
          ...state.entities,
          upgrades:{}
        }
      }
    }
    break;
    case FETCH_UPGRADES_RESULT: {
      const normalizedData = normalize(action.data, plans_schema);
      const plans = normalizedData.entities.plans ? normalizedData.entities.plans : {};
      return {
        ...state,
        entities:{
          ...state.entities,
          upgrades:plans
        }
      }
      return state;
    }
    break;
    case CREATE_UPGRADE_RESULT: {
      const subscriptionState = subscriptionReducer(state, action);
      return {
        ...subscriptionState,
        teamModal:{
          page:AccountPageConstants.PLAN_AND_BILLING,
        },
        errorDict:{
          ...subscriptionState.errorDict,
          [CREATE_UPGRADE_ERROR]:{},
        },
        loadingDict:{
          ...subscriptionState.loadingDict,
          [CREATE_UPGRADE]:LoadingConstants.LOADING_DICT_LOADED(),
        }
      }
    }
    break;
    default:
      return state;
  }
}

export default upgradeReducer
