// app/javascript/quotes/components/App.jsx
import React from 'react'

import AuthStore from 'stores/AuthStore'
import UserStore from 'stores/UserStore'
import intercomMapper from 'mappers/IntercomMapper'
import GoogleAnalytics from './GoogleAnalytics'


class SubscriberWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoggedIn: AuthStore.isLoggedIn(),
      user: {}
    }

    this._onChange = this._onChange.bind(this);
  }

  _onChange () {
    this.setState({
      isLoggedIn: AuthStore.isLoggedIn(),
      user: UserStore.getStateWithMapper(intercomMapper),
    })
  }

  componentDidMount () {
    AuthStore.addChangeListener(this._onChange);
    UserStore.addChangeListener(this._onChange);
    this._onChange();
  }

  componentWillUnmount () {
    AuthStore.removeChangeListener(this._onChange);
    UserStore.removeChangeListener(this._onChange);
  }

  render ()
  {
    return (
      <div>
        <GoogleAnalytics userID={this.state.user.user_id}/>
        {React.cloneElement(this.props.children, {
          ...this.props,
          user: this.state.user,
          isLoggedIn: this.state.isLoggedIn
        })}
      </div>
    );
  }
}

export default SubscriberWrapper
