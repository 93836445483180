// app/javascript/projects/components/CodeDisplay.jsx

import {Modal, Button} from 'react-bootstrap'
import React from 'react';

function TwoOptionModal({show, title, children, onNo, onYes, noText, yesText}) {
  return (<Modal style={{ top: '50px', zIndex: 999999999  }} show={show} onHide={onNo}>
            <Modal.Header>
              <Modal.Title>{title}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              {children}
            </Modal.Body>

            <Modal.Footer>
              <Button onClick={onNo} bsStyle="white">{noText}</Button>
              <Button onClick={onYes} bsStyle="blue">{yesText}</Button>
            </Modal.Footer>
          </Modal>
        )
}

export default TwoOptionModal;
