import emailNameMapper from './EmailNameMapper';


const fallbackNameMapper = (state, user_id) => {
  const user = emailNameMapper(state, user_id);
  return {
    ...user,
    name: user.name || 'Unknown User'
  }
}

export default fallbackNameMapper
