const _ = require('underscore');
import OptimisticMapperHelper from '../OptimisticMapperHelper';

// I want an array of start and end points
// each start and end point should have the code counts of what appear in those ranges
// excerpts have an start and end parameters that should be used to construct this array
// Let's say I have the excerpts:
// [
//   {
//    id: 1,
//    start: 0,
//    end: 10
//    codings: [
//    {
//      code_id: 1,
//      user_id: 1 
//    }
//  ]
//   },
//   {
//    id: 2,
//    start: 0,
//    end: 20
//    codings: [
//    {
//      code_id: 1,
//      user_id: 2
//    },
//    {
//      code_id: 2,
//      user_id: 3
//    },
//   ]
//   },
//   {
//    id: 3,
//    start: 15,
//    end: 25
//   codings: [
//    {
//      code_id: 2,
//      user_id: 1
//    },
//   ]
//   }
// ]
// I want the function to create
// for example: 
// [
//   { 
//     start: 0,
  //     end: 10,
  //     code_count: {
  //       1: 2,
  //       2: 1
  //   },
  //  { 
  //     start: 10,
  //     end: 15,
  //    code_count: {
  //      1: 1,
  //      2: 1
  //    }
  //   },
  //   {
  //     start: 15,
  //     end: 20,
  //     code_count: {
  //       1: 1,
  //       2: 2
  //    },
  //   {
  //     start: 20,
  //     end: 25,
  //     code_count: {
  //       2: 1
  //     }
  //   }
  // ]

// NOTE: this could be made more effecient but going to keep it for now
function overlappingExcerptMapper(state, excerpts) {
  let points = [];

  // Step 1: Find all unique points
  excerpts.forEach(excerpt => {
      if (!points.includes(excerpt.start)) points.push(excerpt.start);
      if (!points.includes(excerpt.end)) points.push(excerpt.end);
  });

  points.sort((a, b) => a - b);  // Step 2: Sort points in ascending order

  let userCodes = {};

  // Step 4: Calculate codeCounts for each range
  excerpts.forEach(excerpt => {
      const startIdx = points.indexOf(excerpt.start);
      const endIdx = points.indexOf(excerpt.end);
      excerpt.codings
      .filter(coding => !!coding.user_id)
      .forEach(coding => {
        for (let i = startIdx; i < endIdx; i++) {
            if ( !userCodes[i] ) userCodes[i] = {};
            if ( !userCodes[i][coding.user_id] ) userCodes[i][coding.user_id] = [];
            const code_id = OptimisticMapperHelper.getCodeId(state, coding.code_id);
            if ( !userCodes[i][coding.user_id].includes(code_id) ) 
                userCodes[i][coding.user_id].push(code_id); 
        }
      });
  });

  let codeCounts = {};
  for (let i = 0; i < points.length - 1; i++) {
    // it looks like this { '1': [ 1 ], '2': [ 1 ] }
    // I want to grab all the arrays and flatten them
    if ( !userCodes[i] ) continue;
    // if only one user coded this range, then we don't need to do anything
    if ( Object.keys(userCodes[i]).length <= 1 ) continue;

    const joinedCodes = _.mapObject(userCodes[i], (value, key) => {
        if ( value.length <= 1 ) return value[0];
        return value.sort().join('|');
    });

    let userCodeIds = _.flatten(Object.keys(joinedCodes).map(user_id => joinedCodes[user_id]));
    
    userCodeIds.forEach(code_id => {
        if ( !codeCounts[code_id] ) codeCounts[code_id] = {};
        codeCounts[code_id][i] = codeCounts[code_id][i] + 1 || 1;
    })
  }


  // Step 5: Generate output array
  let output = [];
  for (let i = 0; i < points.length - 1; i++) {
      let rangeCodeCounts = {};
      Object.keys(codeCounts).forEach(code_id => {
          if (codeCounts[code_id][i] > 0) rangeCodeCounts[code_id] = codeCounts[code_id][i];
      });
      output.push({
          start: points[i],
          end: points[i + 1],
          code_count: rangeCodeCounts
      });
  }

  // filter output where code_count is empty
    output = output.filter(range => Object.keys(range.code_count).length > 0);

  return output;
}



export default overlappingExcerptMapper;
