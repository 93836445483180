var _ = require('underscore');
import dragDropMessageMapper from './DragDropMessageMapper'
import dragDropCountMapper from './DragDropCountMapper'

const dragDropStatusMapper = (state) => {
  const files = Object.keys(state.dragDrop.files).
                map((id)=>state.dragDrop.files[id]).
                filter((file)=>!!file);

  if ( files.length == 0 ) return null;
  const {
    errorFileLength,
    loadedFileLength,
    fileLength,
    loading
  } = dragDropCountMapper(files);

  const messages = dragDropMessageMapper(fileLength, loadedFileLength, errorFileLength);
  if ( !messages ) return null;

  return {
    error: errorFileLength > 0,
    done: !loading,
    ...messages
  }
}

export default dragDropStatusMapper
