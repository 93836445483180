import PageConstants from 'constants/PageConstants'
import matchToProjectID from './MatchToProjectID'

var PageParserUtil = {
  getPageEnum: function(url)
  {
    if ( !url ) return null;

    if ( url == "/" )
      return PageConstants.ROOT_PAGE;
    else if (url.includes("transcripts/new"))
      return PageConstants.NEW_TRANSCRIPT_PAGE;
    else if (url.includes("chat"))
      return PageConstants.CHAT_PAGE;
    else if (url.includes("edit"))
      return PageConstants.EDIT_TRANSCRIPT_PAGE;
    else if ( url.includes("subscribe") )
      return PageConstants.SUBSCRIBE_PAGE;
    else if ( url.includes("search") )
      return PageConstants.SEARCH_PAGE;
    else if (url.includes("analysis"))
      return PageConstants.ANALYSIS_PAGE;
    else if (url.includes("codes"))
      return PageConstants.CODE_BOOK_PAGE;
    else if (url.includes("project"))
      return PageConstants.PROJECT_PAGE;
    else if (url.includes("demographics"))
      return PageConstants.DEMOGRAPHICS_PAGE;
    else if (url.includes("transcripts")) {
      if ( url.includes("user") )
        return PageConstants.CODED_BY_ME_PAGE;
      else if ( url.includes("compare") )
        return PageConstants.COMPARISON_PAGE;
     
      return PageConstants.TRANSCRIPT_PAGE;
    }
    else if (url.includes("users"))
      return PageConstants.USER_PAGE;

    return null;
  },

  isEqual: function(pageA, pageB)
  {
    if (!pageA || !pageB )
      return pageA == pageB;

    if ( pageA.page != pageB.page )
      return false;
    if ( pageA.params.transcriptID != pageB.params.transcriptID )
      return false;
    if ( pageA.params.projectID != pageB.params.projectID )
      return false;
    if ( pageA.params.codeID != pageB.params.codeID )
      return false;

    return true;
  },

  parse: function(match, location)
  {
    if ( !match ) return null;
    var pageEnum = location ? PageParserUtil.getPageEnum(location.pathname) :
                              PageParserUtil.getPageEnum(match.url);

    if (!pageEnum) return null;

    switch (pageEnum) {
      case PageConstants.USER_PAGE:
      case PageConstants.ROOT_PAGE:
      case PageConstants.SUBSCRIBE_PAGE:
        return {page:pageEnum, params:{}};
      case PageConstants.CODE_BOOK_PAGE:
      {
        let projectID = Number(matchToProjectID(match));
        let codeID = Number(match.params.codeID);
        if (isNaN(projectID)) projectID = null;
        if (isNaN(codeID)) codeID = null;

        const codeBookEnum = codeID ? PageConstants.CODE_BOOK_CODE_PAGE : pageEnum;
        return {page:codeBookEnum, params:{projectID: projectID, codeID:codeID}};
      }
      case PageConstants.SEARCH_PAGE:
      case PageConstants.ANALYSIS_PAGE:
      case PageConstants.PROJECT_PAGE:
      case PageConstants.NEW_TRANSCRIPT_PAGE:
      case PageConstants.CHAT_PAGE: // TODO: this will not have a projectID in the future
        var projectID = Number(matchToProjectID(match));
        if (isNaN(projectID)) projectID = null;
        return {page:pageEnum, params:{projectID: projectID}};
      case PageConstants.DEMOGRAPHICS_PAGE:
      case PageConstants.TRANSCRIPT_PAGE:
      case PageConstants.EDIT_TRANSCRIPT_PAGE:
      case PageConstants.CODED_BY_ME_PAGE:
      case PageConstants.COMPARISON_PAGE:
        var transcriptID = Number(match.params.transcriptID);
        if (isNaN(transcriptID)) transcriptID = null;
        return {page:pageEnum, params:{transcriptID:transcriptID}};
      default:
        return null;
      }
  }
}

export default PageParserUtil;
