import CodingConstants from 'constants/CodingConstants'

var ExcerptMathUtil = {
  nonZero: function(excerpt){
    return excerpt.end - excerpt.start > 0;
  },

  _subtract: function(original, subtractor) {
    var returnArray = [];
    var leftover = null;

    const firstSubtract = {
      start: original.start,
      end: Math.min(original.end, Math.max(subtractor.start, original.start)),
      label: original.label
    }

    if ( this.nonZero(firstSubtract) ) returnArray.push(firstSubtract);

    const cutSubtractor = {
      start: Math.max(subtractor.start, original.start),
      end: Math.min(subtractor.end, original.end),
      label: subtractor.label
    }

    if ( this.nonZero(cutSubtractor) ) returnArray.push(cutSubtractor);

    const lastSubtract = {
      start: Math.max(Math.min(subtractor.end, original.end), original.start),
      end: original.end,
      label: original.label
    }

    if ( this.nonZero(lastSubtract) )
    {
      leftover = lastSubtract;
    }

    return [returnArray, leftover];
  },

  _recursiveSubtract: function(completed, leftover, subtractors)
  {
    if ( subtractors.length == 0 || leftover == null)
    {
      if ( leftover )
        completed.push(leftover);

      return completed
    }

    const nextSubtractor = subtractors[0];
    const results = this._subtract(leftover, nextSubtractor);
    completed = completed.concat(results[0]);
    leftover = results[1];
    subtractors = subtractors.slice(1,subtractors.length);

    return this._recursiveSubtract(completed, leftover, subtractors);
  },

  subtract: function(original, subtractors) {
    var results = this._recursiveSubtract([], original, subtractors);
    return results;
  },

  // overlap has a funny side effect.
  // if they are of the same prime class, they are touching if they are next
  // to each other
  // HOWEVER
  // if they are not next to each other, they do in fact need to overlap
  // in order to overlap

  _overlap: function(excerptA, excerptB) {
    if ( excerptA.prime == excerptB.prime )
    {
      // b.start inside of a
      // a.start <= b.start <= a.end
      if ( excerptA.start <= excerptB.start && excerptB.start <= excerptA.end )
        return true;

      // b.end inside of a
      // a.start <= b.end <= a.end
      if ( excerptA.start <= excerptB.end && excerptB.end <= excerptA.end )
        return true;

      // b spans a
      // b.start < a.start && a.end < b.end
      if ( excerptB.start < excerptA.start && excerptA.end < excertB.end )
        return true;

      return false;
    }
    else {
      // b.start inside of a
      // a.start <= b.start < a.end
      if ( excerptA.start <= excerptB.start && excerptB.start < excerptA.end )
        return true;

      // b.end inside of a
      // a.start < b.end < a.end
      if ( excerptA.start < excerptB.end && excerptB.end < excerptA.end )
        return true;

      // b spans a
      // b.start < a.start && a.end <= b.end
      if ( excerptB.start < excerptA.start && excerptA.end <= excerptB.end )
        return true;

      return false;
    }
  },

  _primeSubtract: function(original, primeSubtractor) {
    var returnArray = [];
    var leftover = null;

    const firstSubtract = {
      start: original.start,
      end: Math.min(original.end, Math.max(primeSubtractor.start, original.start)),
    }

    if ( this.nonZero(firstSubtract) ) returnArray.push(firstSubtract);

    const lastSubtract = {
      start: Math.max(Math.min(primeSubtractor.end, original.end), original.start),
      end: original.end,
    }

    if ( this.nonZero(lastSubtract) ) returnArray.push(lastSubtract);

    returnArray.push(primeSubtractor);
    return returnArray;
  },


  _union: function(excerptA, excerptB)
  {
    if ( !excerptA.prime && !excerptB.prime )
    {
      return [{
        start: Math.min(excerptA.start, excerptB.start),
        end: Math.max(excerptA.end, excerptB.end)
      }]
    }
    else if ( excerptA.prime && excerptB.prime )
    {
      return [{
        start: Math.min(excerptA.start, excerptB.start),
        end: Math.max(excerptA.end, excerptB.end),
        prime: excerptA.prime
      }]
    }
    else if ( excerptA.prime )
    {
      return this._primeSubtract(excerptB, excerptA);
    }
    else { // excerptB.prime
      return this._primeSubtract(excerptA, excerptB);
    }
  },

  _recursiveUnion: function(completed, current, next)
  {
    // drop any current which is of zero length
    if ( current && !this.nonZero(current)){
      return this._recursiveUnion(completed, null, next);
    }

    // if there are no more segments, you are done
    if ( next.length == 0 )
    {
      if ( current ) completed.push(current);
        return completed;
    }

    // if there is no current, grab one
    if ( !current )
    {
      return this._recursiveUnion(completed, next[0], next.slice(1, next.length));
    }

    // if the two segments do not overlap, you are done with current
    // add it to completed
    if ( !this._overlap(current, next[0]))
    {
      completed.push(current);
      return this._recursiveUnion(completed, null, next);
    }

    var unionAnswer = this._union(current, next[0]);

    // you just combined two
    // add it to current, and remove the next you just added
    if ( unionAnswer.length == 1 )
    {
      return this._recursiveUnion(completed, unionAnswer[0], next.slice(1, next.length));
    }
    // you combined two and got two
    // this happens when one is prime and the other is not
    // add the first to current
    // adn the next to next
    else if (unionAnswer.length > 1)
    {
      next = next.slice(1, next.length);
      next = next.concat(unionAnswer);
      this.sortStart(next);
      return this._recursiveUnion(completed, null, next);
    }
    else
    {
      throw "This should only return 1 or 2";
    }
  },

  union: function(excerpts){
    this.sortStart(excerpts);
    var results = this._recursiveUnion([], null, excerpts);
    return results;
  },

  // this is in place
  sortStart: function(excerpts) {
    excerpts.sort(function(a, b){
      if ( a.start != b.start )
        return a.start - b.start;

      const aPrime = a.prime ? 1: 0;
      const bPrime = b.prime ? 1: 0;
      return bPrime - aPrime;
    });
  }
}

export default ExcerptMathUtil;
