// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';
import ExcerptConstants from 'constants/ExcerptConstants'

class SubscriptionBorder extends React.Component {
  render () {
    return (
      <div style={{marginTop:40}}>
        <div style={{
            margin: "0 auto",
            width:500,
            border: ExcerptConstants.BORDER,
            padding: "0px 30px 30px 30px",
            boxShadow: ExcerptConstants.BOX_SHADOW,
            borderRadius: ExcerptConstants.BORDER_RADIUS,
          }}>
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default SubscriptionBorder;
