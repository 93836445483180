// app/javascript/projects/components/CodeBookH1.jsx

import React from 'react';
import {FormControl, ControlLabel, FormGroup, InputGroup} from 'react-bootstrap'
import QualCodeActions from 'actions/QualCodeActions'
import {Link} from 'react-router-dom'
import ColorConstants from 'constants/ColorConstants'
import CodeBookH from './CodeBookH'
import CodeBookH2 from './CodeBookH2'
import {CONTENT_MAX_WIDTH} from 'constants/DimensionsConstants'

class CodeBookH1 extends React.Component {
  constructor () {
    super();
    this.state = {}
    this.onCollapseClicked = this.onCollapseClicked.bind(this);
  }

  renderChildren(codes)
  {
    codes = codes && !this.props.code.collapsed ? codes : [];
    return codes.map((code, index) =>{
      return <CodeBookH2
        key={code.id}
        code={code}
        projectID={this.props.projectID}
        hasEditPermission={this.props.hasEditPermission}
      />
    });
  }

  onCollapseClicked(e)
  {
    this.props.onCollapseClicked(this.props.code.id, this.props.code.collapsed);
  }

  startRecursiveRender(code)
  {
    const childComponents = this.renderChildren(code.children);
    return (
      <div style={{marginTop: 20}}>
        <CodeBookH code={code}
                  parent={true}
                  projectID={this.props.projectID}
                  onCollapseClicked={this.onCollapseClicked}
                  collapsed={!!this.props.code.collapsed}
                  hasEditPermission={this.props.hasEditPermission}
                  />
        {childComponents}
      </div>
    );
  }

  render () {
    const codeEntries = this.startRecursiveRender(this.props.code);

    return (
      <div style={{paddingBottom: 0, paddingTop:0, margin: 0, width: "100%", maxWidth: CONTENT_MAX_WIDTH}}>
        {codeEntries}
      </div>
    );
  }
}

export default CodeBookH1;
