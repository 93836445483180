import { normalize, schema } from 'normalizr';
var _ = require('underscore');

const user_schema = new schema.Entity('users');

const share_schema = new schema.Entity('projects', {
  owner: user_schema,
  collaborators: [user_schema]
});

export default (action, state) =>
{
  var normalizedData = normalize(action.data, share_schema);
  const projectID = normalizedData.result;
  const normalizedProjects = _.mapObject(normalizedData.entities.projects, (project)=>{
    const collaborators = project.collaborators.map((collaborator)=>{
      return {
        id: collaborator,
        role: normalizedData.entities.users[collaborator].role
      }
    })

    return {
      ...project,
      collaborators
    }
  })

  const normalizedUsers = _.mapObject(normalizedData.entities.users, (user)=>{
    delete user.role;
    return user
  })
  return {
    ...state,
    shareState:{
      show: false,
      message: null,
    },
    entities:{
      ...state.entities,
      users:{
        ...state.entities.users,
        ...normalizedUsers,
      },
      projects:{
        ...state.entities.projects,
        [projectID]:
        {
          ...state.entities.projects[projectID],
          ...normalizedProjects[projectID],
        }
      }
    }
  }
}
