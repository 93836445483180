const _ = require('underscore')
const AvatarColorConstants = require('constants/AvatarColorConstants')
import EmailNameMapper from './EmailNameMapper'
import {
  ADD_SEAT_ERROR,
} from 'constants/CashierConstants'

const collaboratorsMapper = (state, projectID) => {
  const EMPTY_COLLABORATORS = {userColors: {}, collaborators: null, owner: null, shareError: state.shareState.show ? state.shareState.message : null}

  try {
    const project = state.entities.projects[projectID] || {};
    if (!project) return EMPTY_COLLABORATORS;

    const collaboratorIDs = project.collaborators || [];

    const me = state.entities.user;

    const collaborators = _.chain(collaboratorIDs).map((collaborator)=>{
      const id = collaborator.id
      const user = EmailNameMapper(state, id);

      if ( !user || !user.name ) return null;

      return {
        ...user,
        isMe: me && id == me.id,
        role: collaborator.role
      }
    }).filter((user)=>user).sortBy((collaborator)=>(
      collaborator.name || collaborator.email).toLowerCase()
    )
    .value();

    let owner = EmailNameMapper(state, project.owner);
    owner = !!owner && !!owner.name ? owner : null;

    const errorDict = state.errorDict || {};
    const createSeatError = errorDict[ADD_SEAT_ERROR] || {};

    const userColors = _.chain([...collaborators, owner])
    .filter((user)=>!!user && !!user.name).sortBy(
      (user)=>{
        return (user.name || user.email).toLowerCase()
      }
    ).map((user, index)=>({
      id: user.id,
      color: AvatarColorConstants[index % AvatarColorConstants.length]
    })).indexBy('id').value();

    return {
      collaboratorsLoaded: !!project.collaborators,
      owner: owner,
      collaborators: collaborators,
      myRole: project.role,
      shareError: state.shareState.show ? state.shareState.message : null,
      seatError: createSeatError.error ? "An error occurred adding the user to your subscription." : null,
      userColors
    }
  } catch (e) {
    console.error(e);
    return EMPTY_COLLABORATORS;
  }
}

export default collaboratorsMapper
