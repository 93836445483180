import {
  NOT_LOADING_STATE
} from 'constants/LoadingConstants'
import CashierConstants from 'constants/CashierConstants'
import InitialState from 'constants/InitialState'
import MergeConstants from 'constants/MergeConstants'
import QualConstants from 'constants/QualConstants'
import FilterConstants from 'constants/FilterConstants'
import NetworkConstants from 'constants/NetworkConstants';
import CodePageConstants from 'constants/CodePageConstants';

import errorUtil from 'utils/ErrorUtil'


function errorReducer(action, state = InitialState)
{
  if ( !action ) return state;

  switch (action.actionType)
  {
    case QualConstants.TRANSCRIPT_SEARCH_RECEIVED_ERROR:
    {
      const searchErrorState = errorUtil.createDefaultMessage(state);
      return {
        ...searchErrorState,
        search: {
          [action.data.project_id]:
          {
            ...state.search[action.data.project_id],
            loadingState: NOT_LOADING_STATE,
          }
        }
      }
    }
    break;
    case QualConstants.CREATE_EXCERPT_ERROR:
    {
      const errors = action.errors ? action.errors : [];
      const error = errors[0] ? errors[0] : {};
      const code = error.code;

      return {
        ...state,
        editTranscript: {
          ...state.editTranscript,
          saving: false,
        },
        errorDict:{
          ...state.errorDict,
          [action.actionType]:{
            errorCode:error.code ? error.code : null,
            message: null,
            error: true,
          }
        }
      }
    }
    break;
    case QualConstants.TRANSCRIPT_CREATE_ERROR:
    {
      if ( action.error && action.error.error )
        return errorUtil.createErrorStateWithMessage(state, action.error.error);
      else
        return errorUtil.createDefaultMessage(state);
    }
    break;
    case QualConstants.LOAD_PROJECT_DETAILS_ERROR:
    {
      const projectID = action.data.project_id;

      const newState = errorUtil.checkHTTPError(
        state,
        action.error,
        QualConstants.LOAD_PROJECT_DETAILS_ERROR,
        projectID
      );

      return {
        ...newState,
        loadingState: {
          ...newState.loadingState,
          projectDetails: {
            ...newState.loadingState.projectDetails,
            [projectID]: NOT_LOADING_STATE,
          }
        }
      }
    }
    break;
    case QualConstants.FETCH_CODES_ERROR:
    {
      const projectID = action.data.project_id;

      const newState = errorUtil.checkHTTPError(
        state,
        action.error,
        QualConstants.LOAD_PROJECT_DETAILS_ERROR,
        projectID
      );

      return {
        ...newState,
        loadingState: {
          ...newState.loadingState,
          codes: {
            ...newState.loadingState.codes,
            [projectID]: NOT_LOADING_STATE,
          }
        }
      }
      break;
    }
    case NetworkConstants.NETWORK_ERROR:
    {
      return {
        ...state,
        errorDict:{
          ...state.errorDict,
          [action.actionType]:{
            message: 'Not connected. Changes may not be saved. Try reloading this page.',
            error: true,
          }
        }
      }
      break;
    }
    case QualConstants.NEW_PROJECT_ERROR:
    case QualConstants.UPDATE_PROJECT_NAME_ERROR:
    case QualConstants.CREATE_MULTI_CHOICE_ERROR:
    case QualConstants.UPDATE_MULTI_CHOICE_DESCRIPTOR_ERROR:
    case QualConstants.CREATE_MULTI_CHOICE_DESCRIPTOR_ERROR:
    case QualConstants.DESTROY_MULTI_CHOICE_DESCRIPTOR_ERROR:
    case QualConstants.CREATE_MULTI_CHOICE_SELECTION_ERROR:
    case QualConstants.DELETE_MULTI_CHOICE_SELECTION_ERROR:
    case QualConstants.DELETE_TRANSCRIPT_ERROR:
    case QualConstants.UPDATE_TRANSCRIPT_ERROR:
    case QualConstants.DELETE_CODE_ERROR:
    case QualConstants.RENAME_CODE_ERROR:
    case QualConstants.FETCH_USER_ERROR:
    case QualConstants.ACCEPT_TERMS_OF_SERVICE_ERROR:
    case QualConstants.ADD_CODE_TO_EXCERPT_ERROR:
    case QualConstants.DELETE_CODE_FROM_EXCERPT_ERROR:
    case QualConstants.CREATE_CODE_ERROR:
    case QualConstants.NEST_CODE_ERROR:
    case CashierConstants.FETCH_SUBSCRIPTIONS_ERROR:
    case QualConstants.UPDATE_TRANSCRIPT_TEXT_ERROR:
    case MergeConstants.MERGE_CODE_ERROR:
    case QualConstants.GET_CODE_ERROR:
    case CodePageConstants.CODE_REPORT_ERROR:
    case CodePageConstants.FILTER_CODE_EXCERPTS_ERROR:
      return errorUtil.createDefaultMessage(state);
    break;
    case QualConstants.LOAD_TRANSCRIPT_ERROR:
    case QualConstants.FETCH_SELECTIONS_ERROR:
    {
      const transcriptId = (action.data || {}).transcript_id;
      return errorUtil.checkHTTPError(
        state,
        action.error,
        QualConstants.LOAD_TRANSCRIPT_ERROR,
        transcriptId
      );
    }
    break;
    case QualConstants.FETCH_PROJECT_SELECTIONS_ERROR:
    case QualConstants.FETCH_DESCRIPTORS_ERROR:
    case FilterConstants.FILTER_EXCERPTS_ERROR:
    case FilterConstants.FILTER_REPORT_ERROR:
    {
      return errorUtil.checkHTTPError(
        state,
        action.error,
        QualConstants.LOAD_PROJECT_DETAILS_ERROR,
        (action.data || {}).project_id
      );
    }
    break;
    case QualConstants.LOAD_PROJECT_DETAILS:
      return errorUtil.clearErrorType(state, QualConstants.LOAD_PROJECT_DETAILS_ERROR);
    break;
    case QualConstants.LOAD_TRANSCRIPT:
      return errorUtil.clearErrorType(state, QualConstants.LOAD_TRANSCRIPT_ERROR);
    break;
    case QualConstants.CLEAR_ERROR:
      return errorUtil.clearError(state);
    break;
    default:
      return state;
  }
}

export default errorReducer
