// app/javascript/projects/components/ProjectsDisplay.jsx

import { OverlayTrigger, Popover } from 'react-bootstrap';
import React from 'react';

import { CODE_LABEL_HEIGHT } from 'constants/DimensionsConstants';

const HEIGHT = CODE_LABEL_HEIGHT.toString() + "px"

const textStyle = {
  textAlignVertical: "center",
  paddingLeft: "0px",
  margin: "0",
  height: HEIGHT,
  display: "inline-block",
  maxWidth: "70%",
  lineHeight: HEIGHT,
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  float:"left"
};

const numberStyle = {
  height: HEIGHT,
  lineHeight: HEIGHT,
  whiteSpace: "nowrap",
  maxWidth: "30%"
}

const inputStyle = {
  textAlignVertical: "center",
  paddingLeft: "0px",
  margin: "0",
  borderStyle:"none",
  outline:"none",
  height: HEIGHT,
  background: "transparent",
  lineHeight: HEIGHT,
  width: "80%"
};

class CodeLabelText extends React.Component {
  constructor() {
    super();
    this.onKeyUp = this.onKeyUp.bind(this);
  }

  saveCodeName(e) {
    if ( document.activeElement )
      document.activeElement.blur();
  }

  onKeyUp(e) {
    if ( e.key === 'Enter' )
      this.saveCodeName()
  }

  getMaxWidth(count) {
    if ( !count )
      return "80%"
    else if ( count < 10)
      return "72%"
    else if ( count < 100)
      return "68%"
    else if ( count < 1000)
      return "65%"

    return "62%"
  }

  getTextStyle() {
    return {
      ...textStyle,
      maxWidth: this.getMaxWidth(this.props.count),
      //pointerEvents: "none",
      WebkitTouchCallout: "none", /* iOS Safari */
      WebkitUserSelect: "none", /* Safari */
      KhtmlUserSelect: "none", /* Konqueror HTML */
      MozUserSelect: "none", /* Old versions of Firefox */
      msUserSelect: "none", /* Internet Explorer/Edge */
      UserSelect: "none"
    }
  }

  render () {
    const tooltip = (
      <Popover id={this.props.id || this.props.name}>
        {this.props.name}
      </Popover>
    );

    const label = this.props.edit?
        <input style={inputStyle}
              value={this.props.editableName}
              onBlur={this.props.onBlur}
              onFocus={this.props.onFocus}
              onChange={this.props.onChange}
              ref={(input) => {this.input=input;if (this.input) this.input.focus()}}
              onKeyUp={this.onKeyUp}
              />
            : this.props.ghost ?
            <span style={textStyle}>
              {""}
            </span>
            :
            !!this.props.name && this.props.name.length > 20 && !!this.props.allowPopover ?
            <OverlayTrigger delayShow={500}
              delayHide={0}
              placement="left"
              overlay={tooltip}
              >
              <span style={this.getTextStyle()}>
                {this.props.name}
              </span>
            </OverlayTrigger> :
            <span style={this.getTextStyle()}>
              {this.props.name}
            </span>;

    const countText = !!this.props.count &&
                      this.props.count > 0 &&
                      !this.props.edit &&
                      !this.props.ghost &&
      <span style={numberStyle}>&nbsp;({this.props.count})</span>;

    return (
            <div style={{
                whiteSpace: "nowrap"
              }}>
              {label}{countText}
            </div>
    );
  }
}

export default CodeLabelText;
