// NIL means way in the future
import excerptMapper from './ExcerptMapper'
const NIL_UPDATED_VALUE = "2020";
import {
  NOT_LOADING_STATE,
} from 'constants/LoadingConstants'


const filteredExcerptMapper = (state, filterState, projectID) => {
  try {
    const projects = filterState.projects || {};
    const project = projects[projectID] || {};
    const filteredExcerpts = project.excerpts || [];
    const next = project.next;
    const count = project.count;
    const excerpts = filteredExcerpts.map((excerptID)=>{
      const excerpt = excerptMapper(state, excerptID);
      return excerpt;
    }).filter((excerpt)=>!!excerpt);

    return {excerpts: excerpts, loadingState: {
      loading: !!project.loading,
      loaded: true,
      showMore: !!next && excerpts.length > 0,
      count
    }};
  } catch (e) {
    console.error(e);
    return {excerpts: [], loadingState:NOT_LOADING_STATE };
  }
}

export default filteredExcerptMapper
