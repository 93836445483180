// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';
import newProjectModalMapper from  'mappers/NewProjectModalMapper'
import PageLoadingComponent from './PageLoadingComponent'

import {
  Redirect
} from 'react-router-dom'

import QualCodeActions from 'actions/QualCodeActions'
import ProjectsStore from 'stores/ProjectsStore'
var _ = require('underscore');

class ProjectNew extends React.Component {
  constructor () {
    super();
    this.state = ProjectsStore.getStateWithMapper(newProjectModalMapper);
    this._onChange = this._onChange.bind(this);
  }

  _onChange(){
    this.setState(ProjectsStore.getStateWithMapper(newProjectModalMapper));
  }

  componentDidMount () {
    ProjectsStore.addChangeListener(this._onChange);
    this.createNewProject()
  }

  createNewProject() {
    const subscriptionState = this.props.subscriptionState || {};
    if ( !subscriptionState.loaded ) return;
    
    if ( subscriptionState.isCancelled ) {
      this.setState({
        redirectUsersPage: true
      })
    } else {
      setTimeout(function() { // Run after dispatcher has finished
        QualCodeActions.newProject("", null);
      }, 0);
    }
  }


  componentDidUpdate(prevProps, prevState, snapshot) {
    const subscriptionState = this.props.subscriptionState || {};
    const prevSubscriptionState = prevProps.subscriptionState || {};
    if ( !subscriptionState.loaded ) return;
    if ( subscriptionState.loaded === prevSubscriptionState.loaded ) return;
    this.createNewProject();
  }

  componentWillUnmount () {
    ProjectsStore.removeChangeListener(this._onChange);
  }

  render () {
    if ( !!this.state.redirectUsersPage ) {
      return (
        <div>
          <Redirect to={"/users"} />
        </div>
      )
    }
    else if ( this.state.id )
    {
      return (
        <div>
          <Redirect to={"/projects/" + this.state.id} />
        </div>
      )
    }
    else {
      return <PageLoadingComponent/>
    }
  }
}

export default ProjectNew;
