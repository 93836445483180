import API from 'apis/API'

var ChatAPI = {
  // create memo
  createMemoChat: async function(excerpt_id) {
    return API.post(`/api/excerpts/${excerpt_id}/memo_chats`);
  },
  createAICoding: async function(transcriptId) {
    return API.post(`/api/transcripts/${transcriptId}/ai_codings`);
  },
  getAICoding: async function(transcriptId) {
    return API.get(`/api/transcripts/${transcriptId}/ai_codings`);
  },
  destroyAICoding: async function(jobId) {
    return API.delete(`/api/ai_codings/${jobId}`);
  },
  stopAICoding: async function(jobId) {
    return API.put(`/api/ai_codings/${jobId}/cancel`);
  },
};

export default ChatAPI
