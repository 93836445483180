import React from 'react';
import GenericOnboardingMessage from 'projects/components/GenericOnboardingMessage';

const SearchInfoScreen = ({  }) => {
  return (
    <GenericOnboardingMessage
      header="Find what you're looking for"
      body='Search words or phrases across all transcripts in your project.'
      intercomArticleId={process.env['INTERCOM_ARTICLE_SEARCH']}
      helpArticleText="Learn more about search"
    />
  );
}

export default SearchInfoScreen;