// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';

// Stores
import CodeStore from 'stores/CodeStore'
import CodedTranscriptStore from 'stores/CodedTranscriptStore'
import ProjectsStore from 'stores/ProjectsStore'
import OnboardingMessageWrapper from './OnboardingMessageWrapper';

import CodeBook from './CodeBook'

class CodeBookContainer extends React.Component {
  constructor () {
    super();
    this.state = {
      codes: [],
    }

    this._onChange = this._onChange.bind(this);
  }

  _loadState(props)
  {
    const results = CodeStore.getCodes(props.projectID);
    this.setState({
      codes: results,
    });
  }

  _onChange () {
    this._loadState(this.props);
  }

  componentDidMount () {
    CodeStore.addChangeListener(this._onChange);
    CodedTranscriptStore.addChangeListener(this._onChange);
    ProjectsStore.addChangeListener(this._onChange);
    this._loadState(this.props);
  }

  componentWillUnmount() {
    CodeStore.removeChangeListener(this._onChange);
    CodedTranscriptStore.removeChangeListener(this._onChange);
    ProjectsStore.removeChangeListener(this._onChange);
  }

  componentWillReceiveProps (nextProps) {
    if ( nextProps.projectID != this.props.projectID )
    {
      this._loadState(nextProps);
    }
  }

  render () {
    return <OnboardingMessageWrapper
              onboardingState={this.props.onboardingState}
              header= {this.props.hasEditPermission ? "See codes and summarize findings" : "Empty Project"}
              body= {this.props.hasEditPermission ? "Codes will appear here after you add them on the transcript page." : "Codes and transcripts will appear here after they are added."}
              secondBody= {this.props.hasEditPermission ? "Add descriptions for each code and see a high level overview of research." : null}
              projectID={this.props.projectID}
              hasEditPermission={this.props.hasEditPermission}
            >
                <CodeBook
                  codes={this.state.codes}
                  height={this.props.height}
                  projectID={this.props.projectID}
                  codeID={this.props.codeID}
                  hasEditPermission={this.props.hasEditPermission}
                />
            </OnboardingMessageWrapper>
  }

}

export default CodeBookContainer;
