const sessionMapper = (params) => {
  params = params || {};
  const {interval, coupon_id} = params;
  const host = window && window.location && window.location.host ? window.location.host : "app.delvetool.com";
  const https = host.includes('localhost') ? 'http' : 'https'
  const base_url = `${https}://${host}`

  const couponString = coupon_id ? `promo=${coupon_id}` : null;
  const yearlyUrlParameters = ['plan=yearly', couponString].filter((str)=>!!str).join('&');

  const cancel_url = !couponString ? `${base_url}/subscribe` : `${base_url}/subscribe?${couponString}`
  const yearly_cancel_url = !yearlyUrlParameters ? `${base_url}/subscribe` : `${base_url}/subscribe?${yearlyUrlParameters}`
  const success_url = `${base_url}/welcome`
  return {
    cancel_url: params.cancel_url || (interval === 'year' ? yearly_cancel_url : cancel_url),
    success_url: params.success_url || success_url ,
    ...(interval === 'year' && {interval}),
    ...(!!coupon_id && {coupon_id})
  }
}

export default sessionMapper;
