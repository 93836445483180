// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';

import QualCodeActions from 'actions/QualCodeActions'

import CodePageModal from './CodePageModal'
import CodingActions from '../../actions/CodingActions';
import { useModal } from './modal/MemoModalProvider';

const CodePageContainer = (props) => {
  const showMore = () => {
    const codeID = (props.code || {}).id;
    if (codeID) {
      CodingActions.nextCodePage(codeID);
    }
  };

  const saveCodeName = (codeName) => {
    if (codeName) {
      CodingActions.renameCode(props.code.id, codeName);
    }
  }

  const saveText = (synthesisText) => {
    if (props.code && props.code.id) {
      QualCodeActions.updateCodeSynthesis(props.code.id, synthesisText);
    } else if (props.hideModal) {
      props.hideModal();
    }
  };

  const { isModalOpen } = useModal();

  return (
    <CodePageModal
      codePage={!!props.codePage}
      projectID={props.projectID}
      excerpts={props.excerpts}
      transcripts={props.transcripts}
      code={props.code || {}}
      show={props.show}
      transcriptCount={props.transcriptCount}
      saveText={saveText}
      saveCodeName={saveCodeName}
      hideModal={props.hideModal}
      overlappingCodes={props.overlappingCodes}
      canPage={props.canPage}
      loading={props.loading}
      showMore={showMore}
      hasEditPermission={props.hasEditPermission}
      user={props.user}
      isModalOpen={isModalOpen}
    />
  );
}

export default CodePageContainer;
