// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';
import {FormControl, ControlLabel, FormGroup, InputGroup} from 'react-bootstrap'
import ReactDOM from 'react-dom';

 class FilterCodeForm extends React.Component {
  constructor () {
    super();
    this.handleNewCodeTextChange = this.handleNewCodeTextChange.bind(this);
    this.onKeyUp = this.onKeyUp.bind(this);
    this.myInp = null;
    this.focus = this.focus.bind(this);
  }

  handleNewCodeTextChange(event){
    this.props.filterText(event.target.value);
  }

  focus() {
    if ( !!this.myInp ) {
      ReactDOM.findDOMNode(this.myInp).focus();
    }
  }

  componentWillReceiveProps(nextProps)
  {
    if ( !!nextProps.select && nextProps.select !== this.props.select )
    {
      const that = this;
      setTimeout(function() { // Run after dispatcher has finished
        that.focus()
      }, 0);
    }
  }

  onKeyUp(event) {
    if ( event.key === 'Enter' && this.props.onEnter ) {
      this.props.onEnter();
    }
  }

  render () {
    return (
        <div>
          <FormGroup bsSize="small">
            <InputGroup bsSize="small">
              <FormControl type="text" bsSize="small"
                  value={this.props.searchText}
                  onChange={this.handleNewCodeTextChange}
                  placeholder={this.props.placeholder || "Search or Add Codes"}
                  onKeyUp={this.onKeyUp}
                  ref={(ip) => this.myInp = ip}
              />
            </InputGroup>
          </FormGroup>
      </div>
    );
  }
}

export default FilterCodeForm;
