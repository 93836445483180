//TODO: add a unit test
import {HTTPError} from 'apis/HTTPErrors';

export default (promo, e) => {
  if ( e instanceof HTTPError ) {
    e = e.originalError;
  }

  const response = (e || {}).response || {};
  const data = response.data || {};
  const errors = data.errors || [];
  const error = errors[0] || {};
  const detail = error.detail;
  if ( !detail )
    return null;

  return {
    error: true,
    message: `${promo} ${detail}`
  }
}
