import AppDispatcher from 'dispatcher/AppDispatcher';
import ArchiveApi from 'project_archives/ArchiveAPI';
import ArchiveConstants from './ArchiveConstants';


var ArchiveActions = {
  archiveProject: function(projectId) {
    AppDispatcher.handleAction({
      actionType: ArchiveConstants.ARCHIVE_PROJECT,
      data: {
        project_id: projectId
      }
    });

    return ArchiveApi.archiveProject(projectId).then((response) => {
      AppDispatcher.handleAction({
        actionType: ArchiveConstants.ARCHIVE_PROJECT_SUCCESS,
        data: response.data
      });
      return response.data;
    }).catch((error) => {
      AppDispatcher.handleAction({
        actionType: ArchiveConstants.ARCHIVE_PROJECT_ERROR,
        data: {
          project_id: projectId
        },
        error: error
      })
      throw error;
    })
  },
  unarchiveProject: function(projectId) {
    AppDispatcher.handleAction({
      actionType: ArchiveConstants.UNARCHIVE_PROJECT,
      data: {
        project_id: projectId
      }
    });

    return ArchiveApi.unarchiveProject(projectId).then((response) => {
      AppDispatcher.handleAction({
        actionType: ArchiveConstants.UNARCHIVE_PROJECT_SUCCESS,
        data: response.data
      });
      return response.data;
    }).catch((error) => {
      AppDispatcher.handleAction({
        actionType: ArchiveConstants.UNARCHIVE_PROJECT_ERROR,
        data: {
          project_id: projectId
        },
        error: error
      })
      throw error;
    })
  }
}

export default ArchiveActions
