// app/javascript/projects/components/BottomMarginDropZone.jsx
import React from 'react';
import BottomMarginDropZone from './BottomMarginDropZone'

class BottomCode extends React.Component {
  constructor () {
    super();

    this.state = {
      isOverBottom: false
    }

    this.onDragHover = this.onDragHover.bind(this);
  }

  onDragHover(isOverBottom) {
    this.setState({
      isOverBottom
    })
  }

  render () {
    return (
      <div>
        {!this.state.isOverBottom ?
          this.props.children
          :
          React.cloneElement(this.props.children, {
            isOverBottom: true,
            canDropOnParent: true,
            marginDepth: 1
          })
         }
        <BottomMarginDropZone
          dropPosition={this.props.dropPosition || 1}
          dropCodeId={null}
          onDragHover={this.onDragHover}
          bottomMargin={this.props.bottomMargin}
        />
      </div>
    )
  }
}

export default BottomCode;
