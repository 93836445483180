var _ = require('underscore');
import excerptMapper from 'mappers/ExcerptMapper'
import optimisticMapperHelper  from 'mappers/OptimisticMapperHelper'

const codeMapper = (state, codeID) => {
  if ( !codeID )
    return {code: {}, excerpts: []};

  codeID = optimisticMapperHelper.getCodeId(state, codeID);
  const code = optimisticMapperHelper.getCode(state, codeID);

  const excerpts = _.keys(state.entities.excerpts).map((excerptID)=>{
    return state.entities.excerpts[excerptID];
  }).filter((excerpt)=>{
    if ( !excerpt || !excerpt.codings || excerpt.codings.length == 0 ) return false;
    return excerpt.codings.map((coding)=>state.mappers.codes[coding.code_id]).includes(codeID);
  }).map((excerpt)=>{
    return excerptMapper(state, excerpt.id)
  }).filter((e)=>!!e)
  .sort((a,b)=>(b.updated_at || '').localeCompare(a.updated_at || ''));

  return {
    code: code,
    excerpts: excerpts
  };
}

export default codeMapper
