import React from 'react';
import ProjectsStore from 'stores/ProjectsStore'
import ShareStore from 'stores/ShareStore'
import UserStore from 'stores/UserStore'
import FeatureFlagStore from 'feature_flags/FeatureFlagStore';
import UserContext from 'contexts/UserContext';
import ProjectContext from 'contexts/ProjectContext';

function getState(projectID) {
  if ( !projectID ) return {
    project: null,
    user: {
      ...(UserStore.getUser(projectID) || {}),
      featureFlagAICoding: FeatureFlagStore.hasAICoding()
    }
  }

  return {
    project: ProjectsStore.getProject(projectID),
    user: {
      ...(UserStore.getUser(projectID) || {}),
      featureFlagAICoding: FeatureFlagStore.hasAICoding()
    },
    ...ShareStore.getCollaborators(projectID)
  }
}

class ProjectInfoWrapper extends React.Component {
  constructor(props) {
    super(props);
    this._onChange = this._onChange.bind(this);
    this.state = getState(props.projectID);
  }

  _onChange()
  { 
    this.setState(getState(this.props.projectID));
  }

  componentDidUpdate(prevProps) {
    if (this.props.projectID !== prevProps.projectID) {
      this._onChange()
    }
  }

  componentDidMount() {
    ProjectsStore.addChangeListener(this._onChange);
    ShareStore.addChangeListener(this._onChange);
    UserStore.addChangeListener(this._onChange);
    FeatureFlagStore.addChangeListener(this._onChange);
    this._onChange()
  }

  componentWillUnmount() {
    ProjectsStore.removeChangeListener(this._onChange);
    ShareStore.removeChangeListener(this._onChange);
    UserStore.removeChangeListener(this._onChange);
    FeatureFlagStore.removeChangeListener(this._onChange);
  }

 render () {
  const role = (this.state.project || {}).role;
  const roleIsEdit = role === 'edit';
  const hasEditPermission = roleIsEdit && this.props.isSubscriptionActive;

   return (
    <UserContext.Provider value={{
      user: this.state.user,
      hasEditPermission: hasEditPermission
    }}>
      <ProjectContext.Provider
        value={{
          project: this.state.project,
          owner: this.state.owner,
          collaborators: this.state.collaborators,
          collaboratorsLoaded: this.state.collaboratorsLoaded
        }}
      >
        <div>
          {React.cloneElement(this.props.children, {
            project: this.state.project,
            user: this.state.user,
            hasEditPermission: hasEditPermission
          })}
        </div>
      </ProjectContext.Provider>
    </UserContext.Provider>
   );
 }
}

export default ProjectInfoWrapper;
