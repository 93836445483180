import nameMapper from './NameMapper';

const emailNameMapper = (state, user_id) => {
  const user = nameMapper(state, user_id);
  return {
    ...user,
    name: user.name || user.email
  }
}

export default emailNameMapper
