import AppDispatcher from 'dispatcher/AppDispatcher'
import QualAPI from 'utils/QualAPI'
import UpgradeConstants from 'constants/UpgradeConstants'

function dispatchError(type, payload)
{
  const response = payload.response ? payload.response : {};
  const data = response.data ? response.data : {};
  const errors = data.errors ? data.errors : [];

  AppDispatcher.handleAction({
    actionType: type,
    data: response,
    errors: errors,
  })
}

var UpgradeActions = {
  /***************************\
  *
  * Fetch Upgrades
  *
  \***************************/
  fetchUpgrades: function()
  {
    AppDispatcher.handleAction({
      actionType: UpgradeConstants.FETCH_UPGRADES,
      data:{}
    })

    return QualAPI.fetchUpgrades().then((response)=>{
      AppDispatcher.handleAction({
        actionType: UpgradeConstants.FETCH_UPGRADES_RESULT,
        data: response.data
      })
    }).catch((error)=>{
      AppDispatcher.handleAction({
        actionType: UpgradeConstants.FETCH_UPGRADES_ERROR,
        data: {},
        error: error
      })
    });
  },
  /***************************\
  *
  * Fetch Upgrades
  *
  \***************************/
  upgradeSubscription: function(plan_id)
  {
    AppDispatcher.handleAction({
      actionType: UpgradeConstants.CREATE_UPGRADE,
      data:{}
    })

    return QualAPI.createUpgrade(plan_id).then((response)=>{
      AppDispatcher.handleAction({
        actionType: UpgradeConstants.CREATE_UPGRADE_RESULT,
        data: response.data
      })
    }).catch((error)=>{
      AppDispatcher.handleAction({
        actionType: UpgradeConstants.CREATE_UPGRADE_ERROR,
        data: {},
        error: error
      })
    });
  },
}

export default UpgradeActions
