// app/javascript/projects/components/CodeBookH3.jsx

import React from 'react';
import {FormControl, ControlLabel, FormGroup, InputGroup} from 'react-bootstrap'
import QualCodeActions from 'actions/QualCodeActions'
import {Link} from 'react-router-dom'
import ColorConstants from 'constants/ColorConstants'
import CodeBookH from './CodeBookH'

class CodeBookH3 extends React.Component {
  constructor () {
    super();
    this.state = {}
  }

  render () {
    return <div style={{paddingLeft:40}}>
      <CodeBookH
        code={this.props.code}
        projectID={this.props.projectID}
        hasEditPermission={this.props.hasEditPermission}
        />
    </div>
  }
}

export default CodeBookH3;
