// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';

function TranscriptMenuItem(props) {
  const style = props.marginBottom ? {marginBottom: props.marginBottom} : {}
  return (
    <div>
        {props.children}
        <div style={{fontSize: '.8em', ...style}}>
          {props.description}
        </div>
    </div>
  )
}

export default TranscriptMenuItem;