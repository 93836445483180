module.exports =  {
                    // FETCH_SUBSCRIPTIONS
                    FETCH_SUBSCRIPTIONS: "FETCH_SUBSCRIPTIONS",
                    FETCH_SUBSCRIPTIONS_RESULT: "FETCH_SUBSCRIPTIONS_RESULT",
                    FETCH_SUBSCRIPTIONS_ERROR: "FETCH_SUBSCRIPTIONS_ERROR",
                    // FETCH PLANS
                    FETCH_PLANS: "FETCH_PLANS",
                    FETCH_PLANS_RESPONSE:"FETCH_PLANS_RESPONSE",
                    FETCH_PLANS_ERROR:"FETCH_PLANS_ERROR",
                    // SUBSCRIBE
                    SUBSCRIBE: "SUBSCRIBE",
                    SUBSCRIBE_RESPONSE:"SUBSCRIBE_RESPONSE",
                    SUBSCRIBE_ERROR:"SUBSCRIBE_ERROR",
                    // SEATS
                    FETCH_SEATS: "FETCH_SEATS",
                    FETCH_SEATS_RESPONSE: "FETCH_SEATS_RESPONSE",
                    FETCH_SEATS_ERROR: "FETCH_SEATS_ERROR",
                    // ADD SEATS
                    ADD_SEAT: "ADD_SEAT",
                    ADD_SEAT_RESPONSE: "ADD_SEAT_RESPONSE",
                    ADD_SEAT_ERROR: "ADD_SEAT_ERROR",
                    // REMOVE SEAT
                    REMOVE_SEAT: "REMOVE_SEAT",
                    REMOVE_SEAT_RESPONSE: "REMOVE_SEAT_RESPONSE",
                    REMOVE_SEAT_ERROR: "REMOVE_SEAT_ERROR",
                    // CANCEL SUBSCRIPTION
                    CANCEL_SUBSCRIPTION: "CANCEL_SUBSCRIPTION",
                    CANCEL_SUBSCRIPTION_RESPONSE: "CANCEL_SUBSCRIPTION_RESPONSE",
                    CANCEL_SUBSCRIPTION_ERROR: "CANCEL_SUBSCRIPTION_ERROR",
                    // UNCANCEL SUBSCRIPTION
                    UNCANCEL_SUBSCRIPTION: "UNCANCEL_SUBSCRIPTION",
                    UNCANCEL_SUBSCRIPTION_RESPONSE: "UNCANCEL_SUBSCRIPTION_RESPONSE",
                    UNCANCEL_SUBSCRIPTION_ERROR: "UNCANCEL_SUBSCRIPTION_ERROR",
                    // TEAM MODAL PAGE
                    TEAM_MODAL_PAGE:"TEAM_MODAL_PAGE",
                    CLEAR_CASHIER_ERRORS: "CLEAR_CASHIER_ERRORS"
                  }
