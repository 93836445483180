// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';
import {Modal, Button, Alert} from 'react-bootstrap'
import QualCodeActions from 'actions/QualCodeActions'
import {FormControl, ControlLabel, FormGroup} from 'react-bootstrap'
import {ButtonToolbar} from 'react-bootstrap'

class TeamModalAdd extends React.Component {
  constructor () {
    super();
    this.state = {
      email: "",
    };

    this.onEmailChange = this.onEmailChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(e)
  {
    this.props.onAddEmail(this.state.email);
    e.preventDefault();
  }

  onEmailChange(e)
  {
    this.setState({email: e.target.value})
  }

  render () {
    const subscription = this.props.subscription ? this.props.subscription : {};
    const users = subscription.users ? subscription.users : [];

    return (
      <div style={{textAlign:"center"}}>
          <h4>Add Team Member to Delve</h4>
          <p>Add a team member for an additional ${subscription.amount} per {subscription.interval} per member</p>

          <div style={{marginTop:40, marginBottom:30}}>
            <form onSubmit={this.handleSubmit} style={{height:"100%"}}>
               <FormGroup controlId="formtranscriptName">
                 <FormControl
                    type="email"
                    label="Email address"
                    placeholder="Enter Address"
                    value={this.state.email}
                    onChange={this.onEmailChange}
                  />
               </FormGroup>
               <Button bsStyle="white" type="submit">
                   Add Team Member
               </Button>
           </form>
          </div>
      </div>
    );
  }
}

export default TeamModalAdd;
