const _ = require('underscore');


// NOTE: this could be made more effecient but going to keep it for now
function interCoderStats(excerpts) {
  // count the number of unique users in the excerpt codings
  // filter users that are not defined or null
  const codings_flattened = _.chain(excerpts).pluck('codings').flatten();

  const user_count = codings_flattened.pluck('user_id').uniq().filter((user_id) => !!user_id).value().length;
  const code_count = codings_flattened.pluck('code_id').uniq().value().length;

  return {
    code_count,
    user_count
  };
}



export default interCoderStats;
