// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';
import HoverButton from './HoverButton'
import { Glyphicon } from 'react-bootstrap';
import ColorConstants from 'constants/ColorConstants'

function GlyphHoverButton(props) {
  return <HoverButton
    show={props.show}
    onClick={props.onClick}
    className={props.className}
  >
    <Glyphicon
      style={{
          paddingLeft:"5px",
          paddingTop:"3px",
          paddingRight: "0px",
          paddingBottom:"0px",
          color:ColorConstants.LIGHT_GREY}}
      glyph={props.glyph}
    />
  </HoverButton>
}

export default GlyphHoverButton;
