// app/javascript/projects/components/ProjectsDisplay.jsx

import { Scrollbars } from 'react-custom-scrollbars';
import React from 'react';

import CodedTranscriptStore from 'stores/CodedTranscriptStore'

import CodingActions from '../../actions/CodingActions';
import PageLoadingComponent from './PageLoadingComponent';
import TranscriptBody from './TranscriptBody';
import LocalAPI from 'utils/LocalAPI'
import PagerWrapper from './PagerWrapper';

class TranscriptScrollbar extends React.Component {
  constructor () {
    super();
    this.state = {};

    this.handleScrollStop = this.handleScrollStop.bind(this);
    this.initParagraphRefs = this.initParagraphRefs.bind(this);
    this.setParagraphRef = this.setParagraphRef.bind(this);
    this.initParagraphRefs();
    this.transcriptToLocation = {};
    this.previousScrollTranscript = null;
    this.lastScroll = {};
    this.onPage = this.onPage.bind(this);
  }

  onPage(page) {
    if ( this.scrollbar) {
      this.scrollbar.scrollTop(0);
    }
  }

  clearScroll() {
    this.lastScroll = {};
  }

  _doPagesMatch() {
    const currentPage = this.props.page || 1;
    const codedTranscript = this.props.codedTranscript;

    if ( !codedTranscript ) return false;
    const page = codedTranscript.page || 1;

    return currentPage === page;
  }

  shouldScroll(transcriptID, pos, end)
  {
    if ( !this._doPagesMatch() )
      return false;

    if ( !this.lastScroll )
      return true;

    return this.lastScroll.transcriptID !== transcriptID ||
            this.lastScroll.pos !== pos ||
            this.lastScroll.end !== end;
  }

  didScroll(transcriptID, values)
  {
    this.lastScroll = {
      transcriptID: transcriptID,
      ...values
    }
  }

  setTranscriptLocation(transcriptID, loc, page) {
    LocalAPI.setTranscriptLocation(transcriptID, loc, page || 1);
  }

  getSavedTranscriptLocation(transcriptID) {
    return LocalAPI.getTranscriptLocation(transcriptID) || {};
  }

  handleScrollStop(values) {
    if ( this.props.transcriptID ) {
      const scrollTop = this.scrollbar.getScrollTop();
      if ( !scrollTop ) return;
      this.setTranscriptLocation(this.props.transcriptID, scrollTop, this.props.page || 1);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const prevSelection = prevProps.selection ? prevProps.selection : {};
    const nextSelection = this.props.selection;

    if ( !this.props.codedTranscript || !this.props.codedTranscript.paragraphs || this.props.codedTranscript.paragraphs.length === 0 ) {
      this.clearScroll()
    }

    if ( nextSelection && (nextSelection.pos != prevSelection || nextSelection.end != prevSelection))
    {
      this.scrollTo(nextSelection.pos, nextSelection.end);
    } else {
    }
    const currentTranscriptID = this.props.transcriptID;
    this.scrollToTranscript(currentTranscriptID, this.props.page);
  }

  initParagraphRefs() {
    this.paragraphRefs = {};
  }

  isAutoScrollAllowed(currentTranscriptID, currentPage, pos) {
      if ( !this.props.autoScrollEnabled ) {
        return false;
      }

      // if the scroll bar doesn't exist yet just return
      if ( !this.scrollbar ) {
        return false;
      }

      // if the paragraphs don't exist yet just return
      if ( this.props.codedTranscript.paragraphs.length === 0 ) {
        return false;
      }

      // if you already scrolled on this page for this transcript
      // not necessary to scroll again
      if ( this.lastScroll.transcriptID === currentTranscriptID &&
          this.lastScroll.page === currentPage
      ) {
        return false;
      }

      return true;
  }


  scrollToTranscript(currentTranscriptID, currentPage) {
    if ( !this.isAutoScrollAllowed(
      currentTranscriptID,
      currentPage
    )) return;

    // get
    const {loc,page} = this.getSavedTranscriptLocation(currentTranscriptID);

    // if the location is at 0 or doesn't exist, no need to scroll
    // Marking it off as scrolled in case it's a new transcript
    if ( !loc ) {
      this.didScroll(currentTranscriptID, {page});
      return;
    }

    // so if this is the first time on this transcript
    // and you are not on the right page
    // and you have a page number to go to
    if ( this.lastScroll.transcriptID !== currentTranscriptID &&
      page !== currentPage
      && !!page
    ) {
      this.props.shouldGotoOtherPage(page);
      return;
    }

    // if the height of the scrollbar is less than where you are going skip
    // the scrollbar may not have loaded yet...

    const height = this.scrollbar.getScrollHeight();

    if ( height < loc ) {
      return;
    }
    // if the page doesn't match the current page just go to the beginning of the page
    // otherwise just go to the saved lcoation
    const pageLoc = currentPage === page ? loc : 0;

    // goto the saved location
    this.scrollbar.scrollTop(pageLoc);

    // save that you scrolled
    this.didScroll(currentTranscriptID, {page});
  }

  scrollTo(pos, end)
  {
    if ( pos != 0 && !pos ) return;

    const transcriptID = this.props.transcriptID;
    if ( !transcriptID ) return;

    if ( 
      this.paragraphRefs 
      && this.scrollbar 
      && this.shouldScroll(transcriptID, pos, end)
    )
    {

      const paragraphHash = CodedTranscriptStore.getParagraphIndexWithLocation(transcriptID, pos);
      if ( !paragraphHash )
        return;

      if ( this.props.shouldGotoOtherPage(paragraphHash.page)) {
        return;
      }

      const paragraphIndex = paragraphHash.paragraphIndex;
      const paragraphDiv = this.paragraphRefs[paragraphIndex];
      if ( !paragraphDiv ) return;

      if ( end )
      {
        setTimeout(function() { // Run after dispatcher has finished
          CodingActions.selectWordExcerpt({
            start: Number(pos),
            end: Number(end)
          })
        }, 0);
      }

      const y = paragraphDiv.offsetTop;
      if ( y )
      {
        this.scrollbar.scrollTop(y);
        this.scrolled = true;
      }
      this.didScroll(transcriptID, {pos, end, page:this.props.page});
    }
  }

  componentDidMount () {
    this.scrollToTranscript(this.props.transcriptID, this.props.page);
  }

  setParagraphRef(index, pc) {
    this.paragraphRefs[index] = pc;
  }

  render () {
    return (
      <div style={{height:"100%"}}>
        <Scrollbars
          autoHide
          style={{height:"100%"}}
          ref={(ref)=>{this.scrollbar=ref}}
          onScrollStop={this.handleScrollStop}
        >
        {
          !this.props.codedTranscript ||
          this.props.codedTranscript.paragraphs.length === 0 ?
            <PageLoadingComponent/>
          :
          <PagerWrapper pageCount={this.props.pageCount} onPage={this.onPage}>
            <TranscriptBody
              initParagraphRefs={this.initParagraphRefs}
              setParagraphRef={this.setParagraphRef}
              transcriptID={this.props.transcriptID}
              codedTranscript={this.props.codedTranscript}
              selection={this.props.selection}
              shouldGotoOtherPage={this.props.shouldGotoOtherPage}
              hasEditPermission={this.props.hasEditPermission}
            />
          </PagerWrapper>
        }
        </Scrollbars>
      </div>
    );
  }
}

export default TranscriptScrollbar;
