import AppDispatcher from 'dispatcher/AppDispatcher'
import OnboardingConstants from 'constants/OnboardingConstants'
import onboardingReducer  from 'reducers/OnboardingReducer'
import State from 'stores/State'

var _ = require('underscore');
var EventEmitter = require('events').EventEmitter;

var OnboardingStore = _.extend({}, EventEmitter.prototype, {
  // Emit Change event
  emitChange: function() {
    this.emit('change');
  },

  // Add change listener
  addChangeListener: function(callback) {
    this.on('change', callback);
  },

  // Remove change listener
  removeChangeListener: function(callback) {
    this.removeListener('change', callback);
  }
});

// Register callback with AppDispatcher
AppDispatcher.register(function(payload) {
  var action = payload.action;

  switch(action.actionType)
  {
    // this is just to trigger changes
    case OnboardingConstants.ONBOARDING_GET_STARTED:
      State.set(onboardingReducer(action, State.get()));
    break;
    default:
      return true;
  }

  OnboardingStore.emitChange();
  return true;
});

export default OnboardingStore
