var _ = require('underscore');


function computeRatios(objArray) {
    return objArray.map(obj => {
        let sumSquares = 0;
        let sumValues = 0;
        Object.values(obj.cellValues).forEach(entry => {
            sumSquares += entry.square;
            sumValues += entry.value;
        });

        return {
            ratio: obj.size * sumSquares / (sumValues-1),
            sum: sumValues * obj.size
        };
    });
}

function computeResult(objArray) {
    let sumRatios = 0;
    let sumSums = 0;

    objArray.forEach(obj => {
        sumRatios += obj.ratio;
        sumSums += obj.sum;
    });

    const inverseSumSums = 1 / sumSums;
    const ratio = sumRatios * inverseSumSums;

    return (1 - inverseSumSums) * ratio + inverseSumSums;
}

function calculatePA(overlappingExcerpts) {
    const codeSize = overlappingExcerpts.map((excerpt) => {
        // for each excerpt calculate the value (value * (value - 1))
        // then sum the valu
        const values = _.chain(excerpt.code_count).mapObject((value, key) => {
            return {
                square: value * (value - 1),
                value: value,    
            }
        }).value()

        return {
            size: excerpt.end - excerpt.start,
            cellValues: values,
        }
    })

    const ratios = computeRatios(codeSize);
    const pa = computeResult(ratios);
    return pa;    
}

export default calculatePA;