import React, { createContext, useState, useContext } from 'react';
import BetaModal from 'chat/BetaModal';
const BetaModalContext = createContext();
import { withToastManager } from 'react-toast-notifications';
import FeatureFlagActions from 'feature_flags/FeatureFlagActions';

export const useModal = () => useContext(BetaModalContext);

export const BetaModalProvider = withToastManager(({children, toastManager, onReject}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = (data) => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const rejectBetaProgram = () => {
    setIsModalOpen(false);
    if (!!onReject) {
      onReject();
    }
  }

  const joinBetaProgram = () => {
    FeatureFlagActions.joinBetaProgram().then(() => {
      closeModal();      
    }).catch((error) => {
      toastManager.add(
        'Error joining beta program. Please refresh and try again.', {
        appearance: 'error',
        autoDismiss: true,
        autoDismissTimeout: 7000
      })

      rejectBetaProgram()
    });
  }


  return (
    <BetaModalContext.Provider value={{ openModal, closeModal, isModalOpen }}>
      {children} {/* This renders the parts of your app wrapped by the provider */}
      <BetaModal
        show={isModalOpen}
        onYes={joinBetaProgram}
        onNo={rejectBetaProgram}
      />
    </BetaModalContext.Provider>
  );
});
