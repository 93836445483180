var options = { year: 'numeric', month: 'long', day: 'numeric' };
const NULL_DATE = {date: null, dateString: null};

const dateMapper = (dateString) => {
  if ( !dateString ) return NULL_DATE
  var date = new Date(Date.parse(dateString))
  if ( !date ) return NULL_DATE

  return {
    date: date,
    dateString: date.toLocaleDateString('en-US', options)
  }
}

export default dateMapper
