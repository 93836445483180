// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';
import CodeStore from 'stores/CodeStore'
import CodePageContainer from './CodePageContainer'
import { Redirect } from 'react-router-dom'

class CodebookCodePageContainer extends React.Component {
  constructor () {
    super();
    this.state = {
      ...CodeStore.getCodePageModalInfo(null),
      redirect: false
    };
    this._onChange = this._onChange.bind(this);
    this.hideModal = this.hideModal.bind(this);
  }

  _onChange()
  {
    this._loadState(this.props);
  }

  _loadState(props)
  {
    this.setState({
      ...CodeStore.getCodePageModalInfo(props.codeID),
    });
  }

  componentDidMount () {
    CodeStore.addChangeListener(this._onChange);
    this._loadState(this.props);
  }

  componentWillUnmount () {
    CodeStore.removeChangeListener(this._onChange);
  }

  static getDerivedStateFromProps(props, state)
  {
    return {
      ...state,
      ...CodeStore.getCodePageModalInfo(props.codeID),
    }
  }

  hideModal ()
  {
    this.setState({
      redirect: true
    })
  }

  render () {
    return (
      <div>
        {this.state.redirect &&
          <Redirect
            to={`/projects/${this.props.projectID}/codes`}
            />
        }
        <CodePageContainer
          codePage={true}
          projectID={this.props.projectID}
          excerpts={this.state.excerpts}
          transcripts={this.state.transcripts}
          transcriptCount={this.state.transcriptCount}
          code={this.state.code}
          show={this.state.show}
          canPage={this.state.canPage}
          loading={this.state.loading}
          hideModal={this.hideModal}
          overlappingCodes={this.state.overlappingCodes}
          hasEditPermission={this.props.hasEditPermission}
          user={this.state.user}
        />
      </div>
    );
  }
}

export default CodebookCodePageContainer;
