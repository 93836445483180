// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';

import CodedTranscriptStore from 'stores/CodedTranscriptStore'
import PageConstants from 'constants/PageConstants'
import PageParserUtil from 'utils/PageParserUtil'

import CodeDisplay from './CodeDisplay'
import CodingActions from '../../actions/CodingActions';

var _ = require('underscore');

class TranscriptCodeContainer extends React.Component {
  constructor () {
    super();

    this.state = {
      selectedCodes:[],
      disabled: true
    };

    this._onChange = this._onChange.bind(this);
    this.onClick = this.onClick.bind(this);
    this.addNewCode = this.addNewCode.bind(this);
  }

  componentDidMount () {
    CodedTranscriptStore.addChangeListener(this._onChange);
    this._loadState(this.props);
  }

  componentWillUnmount () {
    CodedTranscriptStore.removeChangeListener(this._onChange);
  }

  addNewCode(code)
  {
    if ( this.props.projectID && this.props.transcriptID )
    {
      CodingActions.addCodeAndExcerpt({
        name: code.name,
        projectId: this.props.projectID,
        transcriptID: this.props.transcriptID,
        page: PageConstants.TRANSCRIPT_PAGE}
      );
    }
  }

  _onChange() {
    this._loadState(this.props);
  }

  onClick(code, selected)
  {
    if ( !this.state.disabled )
    {
      if ( !selected )
      {
        CodingActions.codePressed({
          code_id: code.id,
          page: PageConstants.TRANSCRIPT_PAGE,
          transcriptID: this.props.transcriptID,
          project_id: this.props.projectID,
        })
      }
      else {
        CodingActions.codeUnPressed({
          code_id:code.id,
          page: PageConstants.TRANSCRIPT_PAGE,
          project_id: this.props.projectID
        })
      }
    } else {
      CodingActions.expandParents(
        this.props.projectID,
        code.id
      )
    }
  }

  _loadState (props) {
    this.setState({
      selectedCodes: CodedTranscriptStore.getSelectedCodes(),
      disabled: !CodedTranscriptStore.isSelected(),
      select: CodedTranscriptStore.getSelect()
    });
  }

  _parseParams(match)
  {
    return PageParserUtil.parse(match);
  }

  componentWillReceiveProps (nextProps) {

    const nextParam = this._parseParams(nextProps.match);
    const thisParam = this._parseParams(this.props.match);

    if ( !_.isEqual(nextParam, thisParam))
    {
      this._loadState(nextProps);
    }
  }

  render () {
    return (
      <CodeDisplay
        disabled={this.state.disabled}
        selectedCodes={this.state.selectedCodes}
        onClick={this.onClick}
        addNewCode={this.addNewCode}
        projectID={this.props.projectID}
        height={this.props.height}
        select={this.state.select}
        hideCodeCounts={this.props.page === PageConstants.CODED_BY_ME_PAGE}
      />
    );
  }
}

export default TranscriptCodeContainer;
