import { normalize, schema } from 'normalizr';
const _ = require('underscore');

import {
  GET_JOB_RESULT,
  CREATE_JOB,
  CREATE_JOB_RESULT, 
  DESTROY_JOB,
  DESTROY_JOB_RESULT,
  STOP_JOB,
  STOP_JOB_RESULT,
  JOB_STATUS_UNDOING,
  UPDATE_JOB_CABLE,
  CREATE_JOB_ERROR,
  JOB_STOPPING
} from './JobConstants'

// JOB SCHEMA
const jobSchema = new schema.Entity('transcript', {}, {
  idAttribute: job => job.transcript_id
});
const jobsSchema = new schema.Entity('jobs', {
  ai_coding_jobs: [jobSchema]
});

function getJob(state, job_id) {
  return Object.values(state.transcripts).find(transcript => transcript.id === job_id);
}

function filterTranscriptsByTranscriptId(state, transcript_id) {
  // Convert transcript_id to string
  const stringTranscriptId = String(transcript_id);

  // Filter out transcripts in the state that have the same transcript_id as the new transcripts
  const { [stringTranscriptId]: _, ...filteredTranscripts } = state.transcripts;

  return {
    ...state,
    transcripts: {
      ...filteredTranscripts,
    }
  };
}

function filterTranscriptsByJobId(state, newJobIds) {
  // Filter out transcripts in the state that have the same job_id as the new transcripts
  const filteredTranscripts = Object.values(state.transcripts)
    .filter(transcript => !newJobIds.has(transcript.id))
    .reduce((obj, transcript) => {
      obj[transcript.transcript_id] = transcript;
      return obj;
    }, {});

  return {
    ...state,
    transcripts: {
      ...filteredTranscripts,
    }
  };
}

function setJob(state, transcript_id, job) {
  return {
    ...state,
    transcripts: {
      ...state.transcripts,
      [transcript_id]: job
    }
  }
}

function updateJobStatus(state, job_id, status) {
  const job = getJob(state, job_id);
  if ( !job ) return state;

  const transcript_id = job.transcript_id;
  const newState = filterTranscriptsByJobId(state, new Set([job_id]));

  return setJob(newState, transcript_id, {
    ...job,
    status
  })  
}

function jobReducer(action, state = {})
{
  if ( !action ) return state;

  switch (action.actionType)
  {
    case GET_JOB_RESULT: {
      // if no job return state
      if (action.data.ai_coding_jobs.length === 0) return state;

      const normalizedData = normalize(action.data, jobsSchema);
      const newTranscripts = normalizedData.entities.transcript;

      // Get the job_ids of the new transcripts
      const newJobIds = new Set(Object.values(newTranscripts).map(transcript => transcript.id));
      const newState = filterTranscriptsByJobId(state, newJobIds);

      return {
        ...newState,
        transcripts: {
          ...newState.transcripts,
          ...newTranscripts
        }
      };
    }
    case CREATE_JOB:
     // create job should be added to transcript state
     return setJob(state, action.data.transcript_id, action.data);
    case CREATE_JOB_ERROR:
      return filterTranscriptsByTranscriptId(state, action.data.transcript_id);
    case CREATE_JOB_RESULT:
    case UPDATE_JOB_CABLE:
    case STOP_JOB_RESULT: {
      const newState = filterTranscriptsByJobId(state, new Set([action.data.id]));
      return setJob(newState, action.data.transcript_id, action.data);
    }
    case STOP_JOB:
      return updateJobStatus(state, action.data.id, JOB_STOPPING);
    case DESTROY_JOB:
      return updateJobStatus(state, action.data.id, JOB_STATUS_UNDOING);
    case DESTROY_JOB_RESULT:
      return filterTranscriptsByJobId(state, new Set([action.data.id]));
    default:
      return state;
  }
}

export default jobReducer
