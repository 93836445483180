import React, { useContext } from 'react';
import UserContext from 'contexts/UserContext';

import LeftSideBar from './LeftSideBar'

const QualLeftSideBar = (props) => {
  const { user } = useContext(UserContext);

  return (
    <div style={{height:"100%"}}>
      <LeftSideBar  page={props.page}
                    transcriptID={props.transcriptID}
                    projectID={props.projectID}
                    hasEditPermission={props.hasEditPermission}
                    subscriptionState={props.subscriptionState}
                    onCanceledNewProject={props.onCanceledNewProject}
                    user={user}
                    />
    </div>
  );
}

export default QualLeftSideBar;