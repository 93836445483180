import FileParser from "./FileParser"
import mammoth from 'mammoth'

class FileParserDocx extends FileParser {
  onload(reader, fileName, resolve, reject) {
    // Do whatever you want with the file contents
    const binaryStr = reader.result

    mammoth.extractRawText({arrayBuffer: binaryStr}).then((data)=>{
      resolve({
        body: data.value,
        name: fileName,
        fileType: 'docx'
      })
    }).catch((error)=>{
      reject(error);
    });
  }

  readAs(reader) {
    reader.readAsArrayBuffer(this._file);
  }
}

export default FileParserDocx;
