const CODE = 'codes';
const EXCERPT = 'excerpts'

var _asyncCodePromises = {
  [CODE]: {},
  [EXCERPT]: {},
};

var asyncCodePromises = {
  set: function(entityType, clientID, promise) {
    _asyncCodePromises[entityType][clientID] = promise;
  },
  // WARNING: this system uses promises to map client codes to server codes
  // HOWEVER, this only works on teh client that created the code. So client IDs should only be used on originating computer
  get: function(entityType, clientID, defaultValue){
    var promise = _asyncCodePromises[entityType][clientID];
    if ( !promise )
      promise = new Promise((resolve, reject) => {
        resolve(defaultValue);
      });

    return promise;
  },
  setCode: function(clientID, promise) {
    this.set(CODE, clientID, promise);
  },
  getCode: function(clientID){
    var promise = this.get(CODE, clientID, {code: {id: clientID}});
    return promise.then((result)=>{
      return result.code.id;
    });
  },
  setExcerpt: function(clientID, promise) {
    this.set(EXCERPT, clientID, promise, {});
  },
  getExcerpt: function(clientID) {
    var promise = this.get(EXCERPT, clientID, {excerpt: {id: clientID}});
    return promise.then((result)=>{
      if ( result && result.excerpt && result.excerpt.id )
        return result.excerpt.id;
      // throw error if the excerpt does not exist, shouldn't really happen...
      else
        throw new Error('result does not have excerpt id: ' + JSON.stringify(result))
    });
  },
}

export default asyncCodePromises
