import API from './API'

export default {
  // search transcript
  searchTranscripts: async function(project_id, query)
  {
    if ( !project_id )
      throw new Error('Project Id required for Search Transcripts')

    if ( !query )
      throw new Error('Query required for Search Transcripts')
      
    query = query.replace(/%/g,'');
    return API.get(`/api/projects/${project_id}/search?query=${query}`);
  }
};
