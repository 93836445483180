// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';
import { DragItemTypes } from 'constants/DragConstants';
import { DragSource } from 'react-dnd';
import CodeLabel from './CodeLabel'
import DimensionsConstants from 'constants/DimensionsConstants'
import { getEmptyImage } from 'react-dnd-html5-backend'

const codeSource = {
  beginDrag(props) {
    props.startDrag(props.id);
    return {
      codeID: props.id,
      name: props.name,
      childrenCount: props.childrenCount,
      position: props.position,
      parentId: props.parentId
    };
  },

  canDrag(props, monitor) {
    return props.draggingEnabled;
  },

  endDrag(props, monitor) {
    props.endDrag()
  }
};

function collect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
    connectDragPreview: connect.dragPreview(),
  }
}

class DragCodeLabel extends React.Component {
  componentDidMount() {
    // Use empty image as a drag preview so browsers don't draw it
    // and we can draw whatever we want on the custom drag layer instead.
    this.props.connectDragPreview(getEmptyImage(), {
      // IE fallback: specify that we'd rather screenshot the node
      // when it already knows it's being dragged so we can hide it with CSS.
      captureDraggingState: true,
    })
  }

  render () {
    const { connectDragSource, isDragging} = this.props;
    return connectDragSource(
      <div className='codeLabel' style={{width: DimensionsConstants.CODE_LABEL_WIDTH}}>
        <div
          style={{
            border: "1px solid white"
          }}>
          <CodeLabel
            initiateMergeCode={this.props.initiateMergeCode}
            fadeIn={this.props.fadeIn}
            name={this.props.name}
            mergeName={this.props.mergeName}
            id={this.props.id}
            newCode={this.props.newCode}
            count={this.props.count}
            mergeParent={this.props.mergeParent}
            showConfirmMerge={this.props.showConfirmMerge}
            showMerge={this.props.showMerge}
            onShowMerge={this.props.onShowMerge}
            barCollapsed={this.props.barCollapsed}
            selected={this.props.selected}
            childrenCount={this.props.childrenCount}
            onClick={this.props.onClick}
            newCode={this.props.newCode}
            disabled={this.props.disabled}
            deleteCode={this.props.deleteCode}
            dragOver={this.props.dragOver && !isDragging}
            isOverCode={this.props.dragOver}
            isDragging={isDragging}
            ghost={this.props.ghost}
            onCollapseClicked={this.props.onCollapseClicked}
            cancelMerge={this.props.cancelMerge}
            confirmMerge={this.props.confirmMerge}
            allowDropdown={this.props.allowDropdown}
            renameCode={this.props.renameCode}
            onRename={this.props.onRename}
            renameMode={this.props.renameMode}
            canDropOnParent={this.props.canDropOnParent}
            searchedCodeUnmounted={this.props.searchedCodeUnmounted}
          />
        </div>
      </div>
    );
  }
}

export default DragSource(DragItemTypes.CODE_LABEL, codeSource, collect)(DragCodeLabel);
