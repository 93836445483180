// app/javascript/projects/components/OnboardingTranscript.jsx

import React from 'react';
import FullPageMessage from './FullPageMessage'

class OnboardingTranscript extends React.Component {
  render () {
    return <FullPageMessage
      header='Welcome to Delve'
      body='Gather your transcripts and notes in one place, organize them using codes, and find insights.'
      onClick={this.props.onClick}
      buttonText='Get Started'
    />
  }
}

export default OnboardingTranscript;
