const NULL_STATE = {};
const ROOT_KEY = 'ROOT'

const codesHiearchyMapper = (state, projectID) => {
  try {
    const project = state.entities.projects[projectID];
    if ( !project ) return NULL_STATE;
    if ( !project.codes ) return NULL_STATE;
    var parentMapper = project.codes.map((codeID)=>{
      return state.entities.codes[state.mappers.codes[codeID]];
    }).filter((code)=>{
      return !!code;
    }).map((code)=>{
      if ( code.parent_id )
      {
        const parentID = state.mappers.codes[code.parent_id] ? state.mappers.codes[code.parent_id] : code.parent_id;
        return {[parentID]: [code.id] }
      }
      else
      {
        return {[ROOT_KEY]: [code.id]}
      }
    }).reduce((obj, parentToChild)=>{
      for ( var key in parentToChild )
      {
        if ( key in obj)
          obj[key] = parentToChild[key].concat(obj[key]);
        else {
          obj[key] = parentToChild[key];
        }
      }

      return obj;
    }, {})

    return parentMapper;
  } catch (e) {
    console.error(e);
    return NULL_STATE;
  }
}

export default codesHiearchyMapper
