// app/javascript/projects/components/ProjectsDisplay.jsx

import React, { useState } from 'react';
import {Button} from 'react-bootstrap'
import ModalDivider from 'projects/components/modal/ModalDivider';
import ExcerptConstants from 'constants/ExcerptConstants';
import Textarea from 'react-textarea-autosize';
import MemoAvatar from './MemoAvatar';
import {
  AVATAR_SIZE
} from 'constants/DimensionsConstants';


function AvatarInput(props) {
  const [newInput, setNewInput] = useState("");

  function addInput(e) {
    const _newInput = newInput;
    e.preventDefault();
    e.stopPropagation();
    setNewInput('')
    props.addInput(_newInput).catch((error)=>{
      console.log(error);
      if ( props.handleError ) props.handleError()
      setNewInput(_newInput)
    })
  }

  function onKeyDown(e) {
    // if field is currently empty, and the new key is @, then we want to add '@ai ' to the field
    if (newInput.length === 0 && e.key === '@' && props.featureFlagAICoding) {
      e.preventDefault();
      setNewInput('@ai_assistant ')
    }
    else if(e.key === 'Enter' && (e.ctrlKey || e.metaKey)) {
      e.preventDefault();
      addInput(e)
    }
  }
  
  const buttonEnabled = !!newInput && newInput.length > 0;

  return (
    <div>
      <ModalDivider/>
      <form 
        onSubmit={addInput}
        style={{
          marginLeft: ExcerptConstants.PADDING,
          marginRight: ExcerptConstants.PADDING,
          marginBottom: '13px',
          marginTop: '13px',
        }}
      >
        <div style={{
          display: 'flex',
          flexDirection: 'row',
        }}>
          <div style={{
            width: AVATAR_SIZE,
            flex: "0 0 auto",
            paddingTop: '6px',
          }}>
            <MemoAvatar 
              initials={props.initials}
              color={props.color}
              />
          </div>
          <div style={{
            flex: 1,
            paddingLeft: '8px',
            alignItems: "center",
            justifyContent: "center",
            display: 'flex',
          }}>
            <Textarea
              placeholder="Enter Memo"
              onChange={(e)=>setNewInput(e.target.value)}
              onKeyDown={onKeyDown}
              value={newInput}
              style={{
                width: "100%",
                border: "none",
                borderStyle:"none",
                outline:"none",
                resize:'none',
                alignItems: "center",
                justifyContent: "center",    
              }}/>
          </div>
          <div style={{
            display: 'flex',
            "justifyContent": "flex-end",
            "alignItems": "flex-end"
          }}>
            <div style={{
              flex: "0 0 auto"
            }}>
              <Button
                type="submit"
                bsStyle={buttonEnabled ? "blue" : "white" }
                bsSize="small"
                disabled={!buttonEnabled}
                >
                Add Memo
              </Button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default AvatarInput;