// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';
import {Modal} from 'react-bootstrap'
import BillingModalRenew from './BillingModalRenew'
import BillingModalBlocker from './BillingModalBlocker'
import BillingPastDueBlocker from './BillingPastDueBlocker';
import BillingModalProjectBlocker from './BillingModalProjectBlocker'
import AccountPageConstants from 'constants/AccountPageConstants'
import CashierStore from 'stores/CashierStore'
import ProjectsStore from 'stores/ProjectsStore'

class HeaderSubscribeModal extends React.Component {
  constructor () {
    super();
    this.state = {
      ...CashierStore.getUncancelSubscriptionState(),
    }

    this._onChange = this._onChange.bind(this);
    this.onSubscribe = this.onSubscribe.bind(this);
  }

  onSubscribe() {
    this.setState({
      page: AccountPageConstants.UNCANCEL_SUBSCRIPTION
    })
  }

  _onChange()
  {
    this.setState({
      ...CashierStore.getUncancelSubscriptionState(),
    })
  }

  componentDidMount () {
    CashierStore.addChangeListener(this._onChange);
    ProjectsStore.addChangeListener(this._onChange);
  }

  componentWillUnmount () {
    CashierStore.removeChangeListener(this._onChange);
    ProjectsStore.removeChangeListener(this._onChange);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if ( nextProps.page !== prevState.cachedPage ) {
      return {
        cachedPage: nextProps.page,
        page: nextProps.page
      }
    }

    return null;
  }

  render () {
    var page = <div></div>
    const subscription = (this.props.subscriptionState || {}).subscription;
    const isCancelled = (this.props.subscriptionState || {}).isCancelled;
    const isPastDue = (this.props.subscriptionState || {}).isPastDue;

    const pageEnum = isPastDue ? AccountPageConstants.SUBSCRIPTION_PAST_DUE :  this.state.page;

    switch (pageEnum) {
      case AccountPageConstants.SUBSCRIPTION_PAST_DUE:
        page = <BillingPastDueBlocker
          subscription={subscription}
          isSubscriptionOwner={this.props.isSubscriptionOwner}
          onClick={this.props.onHide}
        />
        break
      case AccountPageConstants.UNCANCEL_SUBSCRIPTION:
        page = <BillingModalRenew
                subscription={subscription}
                uncancelSubscriptionInfo={this.state.uncancelSubscription}
                />
      break;
      case AccountPageConstants.BLOCKER:
        page = <BillingModalBlocker
          subscription={subscription}
          isSubscriptionOwner={this.props.isSubscriptionOwner}
          onClick={this.onSubscribe}
        />
      break;
      case AccountPageConstants.PROJECT_BLOCKER:
        page = <BillingModalProjectBlocker
          subscription={subscription}
          isSubscriptionOwner={this.props.isSubscriptionOwner}
          onClick={this.onSubscribe}
        />
      break;
    }

    return (
      <div>
        <Modal show={this.props.show && (isCancelled || isPastDue)} onHide={this.props.onHide}>
          <Modal.Body>
            {page}
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default HeaderSubscribeModal;
