import React from 'react';
import AccountSingleButton from './AccountSingleButton'

const BillingPastDueBlocker = ({ subscription = {}, isSubscriptionOwner, onClick }) => {
  const getText = (isSubscriptionOwner) => {
    if (isSubscriptionOwner) {
      return "Your subscription is past due. Please update your payment information to continue using Delve."
    } else {
      return "Your subscription is past due. Please ask your team owner to renew the subscription."
    }
  }

  const text = getText(isSubscriptionOwner);

  return (
    <AccountSingleButton
      onClick={onClick}
      loading={false}
      header="Subscription is Past Due"
      button='Go to Accounts Page'
      body={<p>{text}</p>}
      hideTerms={true}
    />
  );
}

export default BillingPastDueBlocker;