module.exports =  {
                    // SIGN UP
                    AUTH_SIGN_UP: "AUTH_SIGN_UP",
                    AUTH_SIGN_UP_RESPONSE: "AUTH_SIGN_UP_RESPONSE",
                    AUTH_SIGN_UP_ERROR: "AUTH_SIGN_UP_ERROR",
                    // SIGN IN
                    AUTH_SIGN_IN: "AUTH_SIGN_IN",
                    AUTH_SIGN_IN_RESPONSE: "AUTH_SIGN_IN_RESPONSE",
                    AUTH_SIGN_IN_ERROR: "AUTH_SIGN_IN_ERROR",
                    // SIGN OUT
                    AUTH_SIGN_OUT: "AUTH_SIGN_OUT",
                    AUTH_SIGN_OUT_RESPONSE: "AUTH_SIGN_OUT_RESPONSE",
                    AUTH_UPDATE_TOKEN: "AUTH_UPDATE_TOKEN",
                    // AUTH new password
                    AUTH_CHANGE_PASSWORD_MODAL: "AUTH_CHANGE_PASSWORD_MODAL",
                    // NEW PASSWORD
                    AUTH_NEW_PASSWORD: "AUTH_NEW_PASSWORD",
                    AUTH_NEW_PASSWORD_RESPONSE: "AUTH_NEW_PASSWORD_RESPONSE",
                    AUTH_NEW_PASSWORD_ERROR: "AUTH_NEW_PASSWORD_ERROR",
                    // RESET PASSWORD
                    AUTH_RESET_PASSWORD: "AUTH_RESET_PASSWORD",
                    AUTH_RESET_PASSWORD_RESPONSE: "AUTH_RESET_PASSWORD_RESPONSE",
                    AUTH_RESET_PASSWORD_ERROR: "AUTH_RESET_PASSWORD_ERROR",
                    // FORGOT PASSWORD
                    AUTH_FORGOT_PASSWORD: "AUTH_FORGOT_PASSWORD",
                    AUTH_FORGOT_PASSWORD_RESPONSE: "AUTH_FORGOT_PASSWORD_RESPONSE",
                    AUTH_FORGOT_PASSWORD_ERROR: "AUTH_FORGOT_PASSWORD_ERROR",
                    // ACCEPT INVITE
                    AUTH_ACCEPT_INVITE: "AUTH_ACCEPT_INVITE",
                    AUTH_ACCEPT_INVITE_RESPONSE: "AUTH_ACCEPT_INVITE_RESPONSE",
                    AUTH_ACCEPT_INVITE_ERROR: "AUTH_ACCEPT_INVITE_ERROR",
                  }
