// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';
import {Glyphicon, Button} from 'react-bootstrap'
import GlyphBorder from './GlyphBorder'

function HoverButton(props) {
  return (
    <div className={props.className}>
      <GlyphBorder show={props.show}>
        <Button
          bsStyle="link"
          onClick={props.onClick}
          style={{padding:0}}
        >
          {props.children}
        </Button>
      </GlyphBorder>
    </div>
  );
}

export default HoverButton;
