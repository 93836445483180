import AppDispatcher from 'dispatcher/AppDispatcher';
import CashierConstants from 'constants/CashierConstants';
import QualAPI from 'utils/QualAPI';

const Joi = require('joi');

function dispatchError(type, payload)
{
  const response = payload.response ? payload.response : {};
  const data = response.data ? response.data : {};
  const errors = data.errors ? data.errors : [];

  AppDispatcher.handleAction({
    actionType: type,
    data: response,
    errors: errors,
  })
}

var CashierActions = {
  clearCashierErrors: function () {
    AppDispatcher.handleAction({
      actionType: CashierConstants.CLEAR_CASHIER_ERRORS,
    })
  },
  /****************************\
  *
  * Cashier Section
  *
  \****************************/
  fetchSubscriptions: function()
  {
    AppDispatcher.handleAction({
      actionType: CashierConstants.FETCH_SUBSCRIPTIONS,
      data:{}
    })

    return QualAPI.fetchSubscriptions().then((response)=>{
      AppDispatcher.handleAction({
        actionType: CashierConstants.FETCH_SUBSCRIPTIONS_RESULT,
        data: response.data
      })
    }).catch((payload)=>{
      dispatchError(CashierConstants.FETCH_SUBSCRIPTIONS_ERROR, payload);
    });
  },

  /****************************\
  *
  * Cancel Subscriptions
  *
  \****************************/
  cancelSubscription: function(subscription_id)
  {
    AppDispatcher.handleAction({
      actionType: CashierConstants.CANCEL_SUBSCRIPTION,
      data:{id: subscription_id}
    })

    return QualAPI.cancelSubscription(subscription_id).then((response)=>{
      AppDispatcher.handleAction({
        actionType: CashierConstants.CANCEL_SUBSCRIPTION_RESPONSE,
        data: response.data
      })
    }).catch((error)=>{
      dispatchError(CashierConstants.CANCEL_SUBSCRIPTION_ERROR,error);
    });
  },

  renewSubscription: function(subscription_id)
  {
    AppDispatcher.handleAction({
      actionType: CashierConstants.UNCANCEL_SUBSCRIPTION,
      data:{id: subscription_id}
    })

    return QualAPI.renewSubscription(subscription_id).then((response)=>{
      AppDispatcher.handleAction({
        actionType: CashierConstants.UNCANCEL_SUBSCRIPTION_RESPONSE,
        data: response.data
      })
    }).catch((error)=>{
      dispatchError(CashierConstants.UNCANCEL_SUBSCRIPTION_ERROR,error);
    });
  },
  /****************************\
  *
  * Fetch Plans
  *
  \****************************/
  fetchPlans: function(token)
  {
    AppDispatcher.handleAction({
      actionType: CashierConstants.FETCH_PLANS,
      data:{}
    })

    return QualAPI.fetchPlans().then((response)=>{
      AppDispatcher.handleAction({
        actionType: CashierConstants.FETCH_PLANS_RESPONSE,
        data: response.data
      })
    }).catch((error)=>{
      dispatchError(CashierConstants.FETCH_PLANS_ERROR, error);
    });
  },

  fetchSeats: function(subscriptionID)
  {
    AppDispatcher.handleAction({
      actionType: CashierConstants.FETCH_SEATS,
      data:{}
    })

    return QualAPI.fetchSeats(subscriptionID).then((response)=>{
      AppDispatcher.handleAction({
        actionType: CashierConstants.FETCH_SEATS_RESPONSE,
        data: response.data
      })
    }).catch((error)=>{
      dispatchError(CashierConstants.FETCH_SEATS_ERROR, error);
    });
  },

  addSeat: function(subscriptionID, email)
  {
    AppDispatcher.handleAction({
      actionType: CashierConstants.ADD_SEAT,
      data:{id: subscriptionID,
        seat:{
          email: email
        }
      }
    })

    return QualAPI.addSeat(subscriptionID, email).then((response)=>{
      AppDispatcher.handleAction({
        actionType: CashierConstants.ADD_SEAT_RESPONSE,
        data: response.data
      })
    }).catch((error)=>{
      dispatchError(CashierConstants.ADD_SEAT_ERROR, error);
    });
  },

  removeSeat: function(subscriptionID, seatID)
  {
    AppDispatcher.handleAction({
      actionType: CashierConstants.REMOVE_SEAT,
      data:{id: subscriptionID, seat:{id:seatID}}
    })

    return QualAPI.removeSeat(subscriptionID, seatID).then((response)=>{
      AppDispatcher.handleAction({
        actionType: CashierConstants.REMOVE_SEAT_RESPONSE,
        data: response.data
      })
    }).catch((error)=>{
      dispatchError(CashierConstants.REMOVE_SEAT_ERROR, error);
    });
  },

  // TEAM MODAL PAGE
  gotoTeamModal: function(page)
  {
    AppDispatcher.handleAction({
      actionType: CashierConstants.TEAM_MODAL_PAGE,
      data:{
        page:page,
      },
    })
  }
}

export default CashierActions
