// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';
import ColorConstants from 'constants/ColorConstants'
import UserAvatarBase from './UserAvatarBase'
const CIRCLE_SIZE = "45px";

function UserCircle(props) {
  return <UserAvatarBase
      size={45}
      borderRadius="50%"
      fontSize="20px"
      marginTop={8}
      initials={props.initials}
    />
}

export default UserCircle;
