// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';

const LEFT_MARGIN = "30px"
const divStyle = {margin:LEFT_MARGIN}
import {Button} from 'react-bootstrap'


class ProfileComponent extends React.Component {
  constructor () {
    super();
    this.state = {
    };
  }

  render () {
    return (
      <div>
        <div style={{margin:`10px ${LEFT_MARGIN}`}}>
          <h4>Profile</h4>
          <div><strong>Name</strong></div>
          <div>{this.props.user ? this.props.user.name : ""}</div>
        </div>
        <div style={divStyle}>
          <div><strong>Email</strong></div>
          <div>{this.props.user ? this.props.user.email : ""}</div>
        </div>

        <div style={divStyle}>
          <Button bsStyle='blue' onClick={this.props.changePassword}>Change Password</Button>
        </div>

        {/*
          <div style={divStyle}>
            <Button bsStyle='blue' onClick={this.props.changeEmail}>Change Email</Button>
          </div>
        */}
        
      </div>
    );
  }
}

export default ProfileComponent;
