var _pdfjsLib = null;

try {
  _pdfjsLib = window['pdfjs-dist/build/pdf'];

  // The workerSrc property shall be specified.
  _pdfjsLib.GlobalWorkerOptions.workerSrc = '//cdn.jsdelivr.net/npm/pdfjs-dist@2.16.105/build/pdf.worker.js';
} catch (e) {
}

export default ()=>{
  return _pdfjsLib;
}
