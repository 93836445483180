// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';
import {DropdownButton, MenuItem, Glyphicon} from 'react-bootstrap'

class CollabDropdown extends React.Component {
  constructor () {
    super();
    this.getMenuItems = this.getMenuItems.bind(this);
  }

  getMenuItems ()  {
    const edit = this.props.role === 'edit';
    const canEditProject = this.props.myRole === 'edit';
    const editAndViewItems = [
      <MenuItem
        eventKey="1"
        key="edit"
        active={edit}
        onClick={(e)=>{this.props.updateCollaborator('edit')}}
      >
          Can edit
      </MenuItem>,
      <MenuItem
        eventKey="2"
        key="view_only"
        active={!edit}
        onClick={(e)=>{this.props.updateCollaborator('view_only')}}
      >
        Can view
      </MenuItem>,
    ];

    if ( this.props.newCollaborator ) return editAndViewItems;

    const editItems = canEditProject && !this.props.isMe ?
      [...editAndViewItems, <MenuItem key="divider" divider />] : [];

    return [
      ...editItems,
      <MenuItem
        eventKey="4"
        key="remove"
        onClick={this.props.removeCollaborator}
      >
        {!this.props.isMe ?
          "Remove" : "Leave Project"
        }
      </MenuItem>
    ]
  }

  render () {
    const edit = this.props.role === 'edit';
    const menuItems = this.getMenuItems();
    const dropDownAppears = this.props.myRole === 'edit' || this.props.isMe || this.props.newCollaborator;
    const roleText = edit ? "Can edit" : "Can view"

    if ( this.props.newCollaborator ) {
        return <div>
          <DropdownButton
              bsStyle="white"
              id="new-collab-dropdown"
              title={roleText}
          >
            {menuItems}
          </DropdownButton>
        </div>
    } else {
      return <div>
        <span>{roleText}</span>
        {dropDownAppears &&
          <DropdownButton
              bsStyle="link"
              bsSize="large"
              id="collab-dropdown"
              style={{margin: "0 0px 4px 5px", padding: 0}}
              title=''
            >
            {menuItems}
          </DropdownButton>
        }
      </div>
    }
  }
}

export default CollabDropdown;
