// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';
import CodedTranscriptStore from 'stores/CodedTranscriptStore'
import CodeStore from 'stores/CodeStore'
import UserStore from 'stores/UserStore'
import ErrorStore from 'stores/ErrorStore'
import QualCodeActions from 'actions/QualCodeActions';
import ProjectContext from 'contexts/ProjectContext';
import UrlUtil from 'utils/UrlUtil'
import trackingUtil from 'utils/TrackingUtil';
import ComparisonUrlUtil from 'utils/ComparisonUrlUtil';
import InterCoderModal from './InterCoderModal';


import {
  TRANSCRIPT_NAVIGATION_PADDING
} from 'constants/DimensionsConstants'
import { Redirect } from 'react-router-dom';
import ComparisonView from './ComparisonView';
import ComparisonViewSolo from './ComparisonViewSolo';

const PADDING = `${TRANSCRIPT_NAVIGATION_PADDING}px`

const tabStyle = {
  paddingLeft: PADDING,
  margin: "0px"
}

class ComparisonViewContainer extends React.Component {
  static contextType = ProjectContext;

  constructor () {
    super();
    this.state = {
      codedTranscript: CodedTranscriptStore.getCodedTranscript(null),
      otherCodedTranscript: CodedTranscriptStore.getCodedTranscript(null),
    }

    this._onChange = this._onChange.bind(this);
    this.onRightUserSelect = this.onRightUserSelect.bind(this);
    this.onLeftUserSelect = this.onLeftUserSelect.bind(this);
    this.onPage = this.onPage.bind(this);
  }

  componentDidMount () {
    CodedTranscriptStore.addChangeListener(this._onChange);
    CodeStore.addChangeListener(this._onChange);
    UserStore.addChangeListener(this._onChange);
    ErrorStore.addChangeListener(this._onChange);
    this._loadState(this.props);

    // set timeout here
    const {leftUserID, rightUserID} = ComparisonUrlUtil.getUserIds(this.props.location);

    setTimeout(() => {
      QualCodeActions.filterCoders([leftUserID, rightUserID]);
    });
    
  }

  componentWillUnmount () {
    CodedTranscriptStore.removeChangeListener(this._onChange);
    CodeStore.removeChangeListener(this._onChange);
    UserStore.removeChangeListener(this._onChange);
    ErrorStore.removeChangeListener(this._onChange);
  }

  gotoTranscript(queryParams) {
    this.props.history.push(`${this.props.location.pathname}${queryParams}`)
  }

  onPage(page) {
    if ( this.scrollbar ) {
      this.scrollbar.scrollTop(0)
    }
  }

  _loadState (props) {
    this.setState({
        codedTranscript: CodedTranscriptStore.getCodedTranscript(props.transcriptID, UrlUtil.getPageNumber(props.location), false),
        otherCodedTranscript: CodedTranscriptStore.getCodedTranscript(props.transcriptID, UrlUtil.getPageNumber(props.location), true)
      });
  }

  _onChange () {
    this._loadState(this.props);
  }

  onUserSelect(userID, key) {
    trackingUtil.logEvent('switch-user-coding-comparison')
    // in the url parameters, replace left with the user id
    const urlObject = UrlUtil.addParamsToUrlObject(this.props.location, {
      [key]: userID
    })

    this.props.history.push(urlObject);
  }

  onLeftUserSelect(userID) {
    this.onUserSelect(userID, 'left');
  }

  onRightUserSelect(userID) {
    this.onUserSelect(userID, 'right');
  }

  componentDidUpdate (prevProps) {
    // this has a lot of redundancy with the code
    // with CodedTranscriptContainer
    const nextTranscriptID = this.props.transcriptID;
    const thisTranscriptID = prevProps.transcriptID;
    const nextPage = UrlUtil.getPageNumber(this.props.location);
    const thisPage = UrlUtil.getPageNumber(prevProps.location);

    if ( nextTranscriptID != thisTranscriptID
        || this.props.location.search != prevProps.location.search
        || thisPage != nextPage)
    {
      this._loadState(this.props);
    }

    const nextCoders = ComparisonUrlUtil.getUserIds(this.props.location);
    const thisCoders = ComparisonUrlUtil.getUserIds(prevProps.location);

    // if nextCoders and thisCoders do not have the same elements
    if  ( nextCoders.leftUserID !== thisCoders.leftUserID 
          || nextCoders.rightUserID !== thisCoders.rightUserID )
    {
      setTimeout(() => {
        QualCodeActions.filterCoders([nextCoders.leftUserID, nextCoders.rightUserID])
      }, 0);
    }
  }

  getUsers(users) {
    users = users || [];
    // get current left and right user ids
    const userIds = ComparisonUrlUtil.getUserIds(this.props.location);

    const leftUserID = parseInt(userIds.leftUserID);
    const rightUserID = parseInt(userIds.rightUserID);

    const leftUser = users.find((user) => user.id === leftUserID) || null;
    const rightUser = users.find((user) => user.id === rightUserID) || null;

    return {leftUser, rightUser};
  }

  isNone() {
    const {rightUserID} = ComparisonUrlUtil.getUserIds(this.props.location);
    return rightUserID === 'none';
  }

  render () {
    const data = this.context;
    const collaborators = data.collaborators || [];
    const owner = data.owner || {};
    const collaboratorsLoaded = data.collaboratorsLoaded

    const redirectUrl = ComparisonUrlUtil.redirectComparisonUrl(this.props.location, collaboratorsLoaded, owner, collaborators);

    const users = [
      owner,
      ...collaborators
    ]

    const showSolo = this.isNone();

    if ( redirectUrl) {
      return (<Redirect to={redirectUrl} />)
    }

    if ( showSolo ) {
      return (<div style={{height: "100%"}}>
        <InterCoderModal
          show={this.props.showInterCoderReliability}
          transcriptID={this.props.transcriptID}
          onHide={this.props.onHideInterCoderReliability}
        />
        <ComparisonViewSolo
          pageCount={this.props.pageCount}
          onPage={this.onPage}
          users={users}
          onLeftUserSelect={this.onLeftUserSelect}
          onRightUserSelect={this.onRightUserSelect}
          selectedUsers={this.getUsers(users)}
          codedTranscript={this.state.codedTranscript}
          otherCodedTranscript={this.state.otherCodedTranscript}
          transcriptID={this.props.transcriptID}
          onShowCollabModal={this.props.onShowCollabModal}
          />
      </div>)
    }

    return (<div style={{height: "100%"}}>
        <InterCoderModal
          show={this.props.showInterCoderReliability}
          transcriptID={this.props.transcriptID}
          onHide={this.props.onHideInterCoderReliability}
        />
        <ComparisonView
          pageCount={this.props.pageCount}
          onPage={this.onPage}
          users={users}
          onLeftUserSelect={this.onLeftUserSelect}
          onRightUserSelect={this.onRightUserSelect}
          selectedUsers={this.getUsers(users)}
          codedTranscript={this.state.codedTranscript}
          otherCodedTranscript={this.state.otherCodedTranscript}
          transcriptID={this.props.transcriptID}/>
      </div>)
  }
}

export default ComparisonViewContainer;
