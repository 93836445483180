import React, { useEffect, useState } from 'react';
import AnalysisCodeContainer from 'projects/components/AnalysisCodeContainer';
import ChatStore from './ChatStore';

function SnippetsCodeContainer({ projectID, page, height }) {
    const [selectedExcerptId, setSelectedExcerptId] = useState(null);
    const _onChange = () => {
        setSelectedExcerptId(ChatStore.getSelectedExcerptId(projectID));
    }

    useEffect(() => {
        ChatStore.addChangeListener(_onChange);
        return () => {
            ChatStore.removeChangeListener(_onChange);
        }
    }, []);

    return (
        <AnalysisCodeContainer
            projectID={projectID}
            page={page}
            height={height}
            selectedExcerptId={selectedExcerptId}
        />
    );
}

export default SnippetsCodeContainer;