function getParent(state, nodeID)
{
  const code = state.entities.codes[state.mappers.codes[nodeID]];
  if ( code )
    if ( code.parent_id )
      return code.parent_id;

  return null;
}

const codeAncestryMapper = (state, codeId) => {
  const parents = [];
  let parentCode = getParent(state, codeId);
  while (parentCode)
  {
    parents.push(parentCode);
    parentCode = getParent(state, parentCode);
  }
  return parents;
}

export default codeAncestryMapper
