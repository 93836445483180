// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';
import Header from './Header'
import PopoverButton from './PopoverButton'
import QualCodeActions from 'actions/QualCodeActions'
import SessionAPI from 'apis/SessionAPI'
import matchToProjectID from 'utils/MatchToProjectID'
import { withToastManager } from 'react-toast-notifications';


class SubscribeHeader extends React.Component {
  constructor () {
    super();
    this.state = {
      subscribing: false,
    }

    this.onSubscribe = this.onSubscribe.bind(this);
  }

  onSubscribe() {
    this.setState({
      subscribing: true
    });

    SessionAPI.createSessionWithLocation({}).catch((error)=>{
      this.props.toastManager.add(
        'There was an error subscribing. Please refresh and try again.', {
        appearance: 'error',
        autoDismiss: true,
        autoDismissTimeout: 7000
      })
    }).then(()=>{
      this.setState({
        subscribing: false
      });
    })
  }

  render () {
    const projectID = matchToProjectID(this.props.match)
    if ( this.props.isSeparatelyInvoiced )
      return null;

    return (
      <div style={{width:"100%"}}>
        <div style={{float:"left", width:"75%"}}>
          <Header name={this.props.name}/>
        </div>
        <div style={{width: "auto", float:"right"}}>
          <div style={{float:"left"}} className="subscribeButton">
            <PopoverButton
              name= {this.props.isSubscriptionOwner ? "Renew Subscription" :  "Start Subscription" }
              onClick={this.props.isSubscriptionOwner ? this.props.onRenew : this.onSubscribe }
              disabled={!!this.state.subscribing}
            >
              The project owner has granted you permission to edit
              this project, but you need an active subscription to edit.
            </PopoverButton>
          </div>
        </div>
      </div>
    );
  }
}

export default withToastManager(SubscribeHeader);
