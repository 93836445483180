import { normalize, schema } from 'normalizr';
import InitialState from 'constants/InitialState'

import {
  addCodesToMapper,
  setExcerptMapper,
  setCodeMapper,
  setCodes,
  setCode,
  updateCode,
  updateCodes,
  setExcerpt,
  setExcerpts,
  updateExcerpt,
  setProject,
  setLoadedCodes,
  setLoadingCodes,
} from './SetStateReducer'

import {
    UPDATE_CODE_CABLE,
    ADD_CODE_TO_EXCERPT_RESULT,
    DELETE_CODE_FROM_EXCERPT,
    FETCH_CODES,
    FETCH_CODES_RESULT,
    CREATE_CODE,
    CREATE_CODE_RESULT,
    CREATE_CODE_ERROR,
    // ADD CODE TO EXCERPT
    ADD_CODE_TO_EXCERPT,
    ADD_CODE_TO_EXCERPT_ERROR,
    // DELETE CODE FROM EXCERPT
    DELETE_CODE_FROM_EXCERPT_RESULT,
    GET_CODE_RESULT,
    DELETE_CODE,
    RENAME_CODE,
    CREATE_CODE_CABLE,
    DELETE_CODE_CABLE,
    // update code
    UPDATE_CODE_SYNTHESIS,
} from 'constants/QualConstants'
import positionReducer from './PositionReducer'
import mergeExcerptsReducer from './MergeExcerptsReducer';
import codeIdToProjectIdMapper from 'mappers/CodeIdToProjectIdMapper'

import {
  MERGE_CODE_RESULT
} from 'constants/MergeConstants'

import OptimisticMapperHelper from 'mappers/OptimisticMapperHelper';
import { NEST_CODE_CABLE } from '../constants/QualConstants';


const code_schema = new schema.Entity('codes')
const memo_schema = new schema.Entity('memos')

const excerpt_schema = new schema.Entity('excerpt', {
  codes: [code_schema],
  memos: [memo_schema]
})

const project_codes_schema = new schema.Entity('projects',
{
  codes: [code_schema]
});


const getCodeData = (action) => {
  const projectID = action.data.id;
  const {clientID} = action.data;
  const code = action.data.code;
  const serverID = code.id;

  return {
    projectID,
    clientID,
    code,
    serverID
  }
}

var _ = require('underscore');


function setExcerptCodings(excerptID, codings, state) {
  return updateExcerpt(excerptID, {
    codings: codings
  }, state)
}

function addExcerptCodings(excerptID, codeID, state) {
  const existingExcerpt = OptimisticMapperHelper.getExcerpt(state, excerptID);
  if ( !existingExcerpt ) return state;

  const codings = [
    ...(existingExcerpt.codings || []),
    {
      code_id: OptimisticMapperHelper.getCodeIdOrOriginal(state, codeID),
      user_id: state.entities.user.id,
      created_at: new Date().toISOString()
    }
  ]

  return setExcerptCodings(
    OptimisticMapperHelper.getExcerptIdOrOriginal(state, excerptID),
    codings,
    state
  )
}

function setProjectCodes(projectID, codes, state) {
  const project = state.entities.projects[projectID] || {};

  return setProject(projectID, {
    ...project,
    id: projectID,
    codes: codes
  }, state)
}

function getProjectCodes(projectID, state) {
  const project = state.entities.projects[projectID] || {};
  return project.codes || [];
}

function addCodeToProject(projectID, codeID, state) {
  const codes = getProjectCodes(projectID, state);
  return setProjectCodes(projectID, [...new Set([...codes, codeID])], state);
}

function removeCodeFromProject(projectID, codeID, state) {
  let newState = setCodes(_.omit(state.entities.codes, codeID), state)
  newState = setCodeMapper(_.omit(state.mappers.codes, codeID), newState)

  const project = state.entities.projects[projectID];
  if ( !project ) return newState;
  const codes = getProjectCodes(projectID, newState);
  return setProjectCodes(projectID, codes.filter((code_id)=>code_id !== codeID), newState)
}

function connectClientToServerCode(clientID, serverID, state) {
  if ( !clientID || !serverID ) return state;

  return setCodeMapper({
    ...state.mappers.codes,
    [serverID]: clientID,
    [clientID]: clientID
  }, state)
}

function removeCodingFromExcerpt(excerptID, codeID, coders, state) {
  const clientID = OptimisticMapperHelper.getCodeIdOrOriginal(state, codeID);
  if (!clientID) return state;

  excerptID = OptimisticMapperHelper.getExcerptIdOrOriginal(state, excerptID);

  const excerpt = OptimisticMapperHelper.getExcerpt(state, excerptID);

  if (!excerpt) return state;

  const code = OptimisticMapperHelper.getCode(state, codeID);
  const serverID = (code || {}).server_id || null;

  coders = coders || [];

  const codersEmpty = coders.length == 0;

  const codings = excerpt.codings ? excerpt.codings.filter((coding)=>{
    return (coding.code_id != clientID
    && coding.code_id != serverID)
    || (!codersEmpty && !coders.includes(coding.user_id))
  }) : [];

  let newState = changeCount(clientID, -1, 0, state)

  return setExcerptCodings(excerptID, codings, newState)
}

function changeCount(code_id, delta, defaultCount, state) {
  const code = OptimisticMapperHelper.getCode(state, code_id);
  const count = code && code.count ? code.count + delta : defaultCount;
  return updateCode(code_id, {
    count: count
  }, state)
}

function removeCodes(code_ids, state) {
  return setCodes(_.omit(state.entities.codes, code_ids), state)
}

// function that updates codes to use the clientID
function createClientCode(code, state) {
  const clientCodeId = OptimisticMapperHelper.getCodeIdOrOriginal(state, code.id)
  const originalCode = OptimisticMapperHelper.getCode(state, code.id) || {};
  const parentID = 'parent_id' in code ? code.parent_id : (originalCode || {}).parent_id || null;
  let clientParentID = OptimisticMapperHelper.getCodeIdOrOriginal(state, parentID)

  return {
    ...originalCode,
    ...code,
    server_id: code.id,
    id: clientCodeId,
    parent_id:  clientParentID,
    user_id: code.user_id || null,
  }
}

function setClientCode(code, state) {
  const clientCode = createClientCode(code, state);
  return setCode(clientCode.id, clientCode, state)
}

function codeReducer(action, state = InitialState)
{
  if ( !action ) return null;

  switch (action.actionType)
  {
    // TODO: this doesn't add the excerpt to the project... which it should
    case ADD_CODE_TO_EXCERPT_RESULT:
      // there is actually nothing new to keep track of here, everything should be up to date
      // thanks to the optimistic work
      return state
    case UPDATE_CODE_CABLE:
    { 
      const newState = setClientCode(action.data, state);
      return addCodesToMapper([action.data.id], newState)
    }
    case FETCH_CODES:
    {
      const projectID = action.data.project_id;
      if ( !projectID ) return state;

      return setLoadingCodes(projectID, state);
    }
    case FETCH_CODES_RESULT:
    case NEST_CODE_CABLE:
    {
      const normalizedData = normalize(action.data, project_codes_schema);
      const projectID = normalizedData.result;
      const normalizedProject = normalizedData.entities.projects[projectID] || {};
      const normalizedProjectCodes = normalizedProject.codes || [];
      /*
        Set codes as loaded
      */
      let newState = setLoadedCodes(projectID, state);
  
      /* 
        If clientID exists, then we can map the clientID to the serverID
        This updates state.mappers.codes
        Specifically:
          serverId->clientID
          clientID->clientID
      */
      
      let clientID = action.data.clientID;
      const serverID = action.data.code_id;
      clientID = OptimisticMapperHelper.getCodeId(state, clientID); // will return null if clientID not stored
      newState = connectClientToServerCode(clientID, serverID, newState);
      
      /* 
        Update the project code list with it's new codes but use the client IDs
        This updates state.entities.projects[projectID].codes
      */
      newState = setProjectCodes(projectID, normalizedProjectCodes.map(
        (code_id)=>
          OptimisticMapperHelper.getCodeIdOrOriginal(newState, code_id)
      ), newState);    

      /*
        A code may have been deleted in which case we need to remove it from the state
        However we only want to remove codes that have been saved to the server
        Because the server would not know to send that down
        So we are going to remove all the project codes
        This removes all the codes associated with the project from state.entities.codes
      */

      newState = removeCodes(getProjectCodes(projectID, state).filter((code_id)=>{
        const code = OptimisticMapperHelper.getCode(state, code_id);
        // if the code is undefined of course it should be removed
        // if the code does not have a server_id it is still being created and should remain
        return !!code && !!code.server_id;
      }), newState)

      /*
        This adds the codes back to the state
        This updates state.entities.codes
        However the codes make sure to use the clientID as the id

        This essentially maps {server_id->server_code}
        to
        {client_id->{...client_code, ...server_code}
      */
      
      // this line is not ideal but there could be information in the old state
      // that is not in the normalized data
      // but we need the mapping to be part of the old state, so that we connect the client to the server
      const oldStateWithNewMapping = connectClientToServerCode(clientID, serverID, state);
  
      const codes = Object.values(normalizedData.entities.codes || {}).reduce((acc, code) => {
        if (code) {
          const clientCode = createClientCode(code, oldStateWithNewMapping);
          acc[clientCode.id] = clientCode;
        }
        return acc;
      }, {});
  

      newState = updateCodes(codes, newState);
      return addCodesToMapper(normalizedProjectCodes, newState);
    }
    case CREATE_CODE:
    {
      /*
        {
          id: 839719613,
          code: {
            id: 'new-client-id',
            name: 'Code Add',
            synthesis: 'Synthesis Code Add'
          }
        }
      */
      const {projectID, code} = getCodeData(action);
      const clientID = code.id;

      // this assumes the code got added at position one at the root
      // this will need to be more complex in the future
      let newState = positionReducer(
        {
          delta: 1,
          parent_code_id: null,
          position: 0,
          project_ids: [projectID]
        },
        state
      )

      newState = addCodeToProject(projectID, clientID, newState)

      newState = setCode(clientID, {
        parent_id: null,
        position: 1,
        ...code,
        count: 0
      }, newState)

      return addCodesToMapper([clientID], newState)
    }
    case CREATE_CODE_CABLE:
    {
      /*
       {
        id: 42,
        code: { id: 91, name: 'Optimistic' },
        clientID: '08dd33d0-4ee2-11e8-baca-0d3da395d665'
      }
      */

      // json stringify
      const {projectID, code, clientID, serverID} = getCodeData(action);

      // this is asking if this session was the originator of the client ID.
      // if it was, there is no reason to consume this code cable
      if ( !!OptimisticMapperHelper.getCodeId(state, clientID) )
        return state;
      
      let newState = addCodeToProject(projectID, serverID, state)
      newState = addCodesToMapper([serverID], newState)
      // set client code will be using the serverID as the key
      // because the clientID is not in the state
      // and you have added the serverID to the mapper
      return setClientCode(code, newState)
    }
    case CREATE_CODE_RESULT:
    {
      /*
      {
        id: 839719613,
        code: { id: 118, name: 'Optimistic', user_id: 1 },
        clientID: '08dd33d0-4ee2-11e8-baca-0d3da395d665'
      }
      */

      const {projectID, code, clientID, serverID} = getCodeData(action);

      /* 
        Remove the serverID code from the state if it exists
      */
      let newState = removeCodeFromProject(projectID, serverID, state)

      /* 
        Add the client code to the state, and set up the mapping to the serverID
      */
      newState = connectClientToServerCode(clientID, serverID, newState)
      newState = setClientCode(code, newState)
      return addCodeToProject(projectID, clientID, newState)
    }
    // stopped code clean up here...
    case CREATE_CODE_ERROR:
    {
      const {clientID, projectId} = action.data || {};
      return removeCodeFromProject(projectId, clientID, state)
    }
    case MERGE_CODE_RESULT:
    case GET_CODE_RESULT:
    {
      const get_code_schema = new schema.Entity('code', {
        excerpts: [excerpt_schema]
      })

      const normalizedData = normalize(action.data, get_code_schema);
      
      /*
        Get the code and add them to the state after clientifying them
      */
      const newCode = _.omit(normalizedData.entities.code[normalizedData.result], 'excerpts')
      let newState = setClientCode(newCode, state)
      newState = addCodesToMapper([newCode.id], newState)

      /*
        Get the excerpts and add them to the state
      */

      const mergedExcerpts = mergeExcerptsReducer(state, normalizedData.entities.excerpt);
      newState = setExcerpts(mergedExcerpts.entities.excerpts, newState);
      return setExcerptMapper(mergedExcerpts.mappers.excerpts, newState)
    }
    case ADD_CODE_TO_EXCERPT:
    {
      const codeID = OptimisticMapperHelper.getCodeIdOrOriginal(state, action.data.code_id);
      const existingExcerpt = OptimisticMapperHelper.getExcerpt(state, action.data.excerpt_id);
      if ( !existingExcerpt ) return state;

      let newState = changeCount(codeID, 1, 1, state)
      return addExcerptCodings(action.data.excerpt_id, action.data.code_id, newState)
    }
    case ADD_CODE_TO_EXCERPT_ERROR:
    {
      return removeCodingFromExcerpt(
        action.data.excerpt_id,
        action.data.code_id,
        [], // this could cause a problem, because what if the error was on just one coder
        state
      )
    }
    case DELETE_CODE_FROM_EXCERPT:
    {
      return removeCodingFromExcerpt(
        action.data.excerpt_id,
        action.data.code_id,
        action.data.coders,
        state
      )
    }  
    // all of this is handled optimistically
    case DELETE_CODE_FROM_EXCERPT_RESULT:
    {
      return state;
    }
    // CABLE LOGIC MAY NEED TO BE FUNDAMENTALLY DIFFERENT, SINCE IT CAN UPDATE STUFF
    case DELETE_CODE_CABLE:
    case DELETE_CODE:
    {
      const codeID = OptimisticMapperHelper.getCodeIdOrOriginal(state, action.data.id)
      const code = OptimisticMapperHelper.getCode(state, action.data.id);
      if ( !code ) return state;

      const parentID = OptimisticMapperHelper.getCodeId(state, code.parent_id) || null;

      const deletedCodeChildren = _.sortBy(_.pairs(state.entities.codes).filter((codeArray)=>{
        if ( !codeArray ) return false;
        const code = codeArray[1];
        if ( !code ) return false;
        return OptimisticMapperHelper.getCodeId(state, code.parent_id) === codeID;
      }), ((codeArray)=>(codeArray[1] || {}).position || 0)) // sort by
      .map((codeArray, index)=>{
        const child = codeArray[1]
        return [
          codeArray[0],
          {
            ...child,
            position: index + (code.position || 1),
            parent_id: parentID
          }
        ]
      });

      const projectId = codeIdToProjectIdMapper(state, codeID);

      const shiftPeerState = positionReducer(
        {
          delta: deletedCodeChildren.length - 1,
          parent_code_id: parentID,
          position: code.position,
          project_ids: [projectId]
        },
        state
      )

      let mappedChildCodes = {
        ...shiftPeerState.entities.codes,
        ...Object.fromEntries(deletedCodeChildren)
      }

      return setCodes(_.omit(mappedChildCodes, codeID), state)
    }
    case RENAME_CODE:
    {

      return addCodesToMapper(
        [action.data.id],
          updateCode( action.data.id, {
            name: action.data.name
          }, state)
      )
    }
    case UPDATE_CODE_SYNTHESIS:
    {
      return addCodesToMapper(
        [action.data.id],
        updateCode(action.data.id, {
          synthesis: action.data.synthesis
        }, state)
      )
    }
    default:
      return null;  
  }
}

export default codeReducer
