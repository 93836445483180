// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';
import {Modal, Button, Alert, Glyphicon} from 'react-bootstrap'
import QualCodeActions from 'actions/QualCodeActions'
import {FormControl, ControlLabel, FormGroup} from 'react-bootstrap'
import ColorConstants from 'constants/ColorConstants'
import UserCircle from './UserCircle'

const BORDER_COLOR = "1px solid " + ColorConstants.ULTRA_LIGHT_GREY;
const BUTTON_WIDTH = 300;

class TeamModalMain extends React.Component {
  constructor () {
    super();
    this.state = {
    };

    this.memberClick = this.memberClick.bind(this);
  }

  memberClick(seatID)
  {
    this.props.memberClick(seatID);
  }

  createRow(name, email, initials, seatID) {
    return (
      <div>
      <div style={{float:"left"}}>
        <UserCircle
          initials={initials}
        />
      </div>
      <div style={{marginLeft:12, marginTop:2, float:"left"}}>
        <h5 style={{marginBottom:2}}>{name}</h5>
        <p style={{pading:0}}>{email}</p>
      </div>

      {seatID && <div style={{float:"right", paddingTop:15}}>
        <Glyphicon onClick={(e)=>this.memberClick(seatID)} style={{color:ColorConstants.LIGHT_GREY, cursor:"pointer", fontSize:20}} glyph='chevron-right'/>
      </div> }
      <div style={{clear:"both"}}></div>
    </div>);
  }

  render () {
    const seats = this.props.subscription.seats ? this.props.subscription.seats : [];
    const disabled = this.props.subscription.status == 'canceled';

    const userHtml = seats.map((seat)=>{
      if ( seat.user )
      {
        return (<div style={{borderBottom: BORDER_COLOR, padding:10}} key={seat.id}>
          {this.createRow(seat.user.name, seat.user.email, seat.user.initials, seat.id)}
        </div>);
      }
      else
      {
        return <div></div>
      }
    })

    return (
      <div>
          <h4>Manage Team</h4>

          <div style={{borderBottom: BORDER_COLOR, borderTop: BORDER_COLOR, padding:10, marginTop:30}}>
            {this.createRow("You","Owner",this.props.subscription && this.props.subscription.owner ? this.props.subscription.owner.initials : null, null)}
          </div>

          {userHtml}

          <div style={{margin:"0 auto", width:"100%", width:BUTTON_WIDTH, paddingTop:50}}>
            <Button bsStyle="white" style={{width:BUTTON_WIDTH}} onClick={this.props.addTeamMember} disabled={disabled}>Add Team Member</Button>
          </div>
      </div>
    );
  }
}

export default TeamModalMain;
