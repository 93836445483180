// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';
import CashierActions from 'actions/CashierActions'
import BillingModalMain from './BillingModalMain'
import BillingModalRenew from './BillingModalRenew'
import BillingModalUpgrade from './BillingModalUpgrade'
import BillingModalPauseFeedback from './BillingModalPauseFeedback'
import AccountPageConstants from 'constants/AccountPageConstants'
import GenericModal from './GenericModal'


class BillingModal extends React.Component {
  constructor () {
    super();

    this.onHide = this.onHide.bind(this);
    this.backButtonPressed = this.backButtonPressed.bind(this);
    this.setHandleBack = this.setHandleBack.bind(this);
    this.state = {
      handleBack: null,
    }
  }

  onHide(e)
  {
    this.props.onHide();
  }

  backButtonPressed(e)
  {
    if (this.state.handleBack) {
      this.state.handleBack();
    } else { 
      CashierActions.gotoTeamModal(AccountPageConstants.PLAN_AND_BILLING);
    }
  }

  setHandleBack(handleBack) {
    this.setState({handleBack});
  }

  // on props.page updates we need to update the handleBack function, but not if it's the same page or the PAUSE_FEEDBACK_PAGE
  componentDidUpdate(prevProps) {
    if (prevProps.page !== this.props.page && this.props.page !== AccountPageConstants.PAUSE_FEEDBACK) {
      this.setHandleBack(null);
    }
  }


  render () {
    var page = <div></div>

    switch (this.props.page) {
        case AccountPageConstants.PLAN_AND_BILLING:
        page = <BillingModalMain
                error={this.props.error}
                user={this.props.user}
                subscription={this.props.subscription}
                upgradeInfo={this.props.upgradeInfo}
                />
      break;
      case AccountPageConstants.PAUSE_FEEDBACK:
        page = <BillingModalPauseFeedback
          subscription={this.props.subscription}
          cancelSubscriptionInfo={this.props.cancelSubscriptionInfo}
          setHandleBack={this.setHandleBack}
          />
      break;
      case AccountPageConstants.UNCANCEL_SUBSCRIPTION:
        page = <BillingModalRenew
                subscription={this.props.subscription}
                uncancelSubscriptionInfo={this.props.uncancelSubscriptionInfo}
                />
      break;
      case AccountPageConstants.ANNUAL_SUBSCRIPTION:
        page = <BillingModalUpgrade
                upgradeInfo={this.props.upgradeInfo}
          />
      break;
    }

    const mainPage = this.props.page == AccountPageConstants.PLAN_AND_BILLING;

    const showBackButton = !mainPage && !this.props.hideBackButton; 

    return <GenericModal
      show={this.props.show}
      onHide={this.onHide}
      body={page}
      backButtonClick={showBackButton ? this.backButtonPressed : null}
    />
  }
}

export default BillingModal;
