const { convertArrayToCSV } = require('convert-array-to-csv');
var _ = require('underscore');

const ROW_OFFSET = 3;

class ExportMatrix {
  constructor(rowCount, colCount) {
    this._array = Array.from(Array(rowCount + ROW_OFFSET), _ => Array(colCount).fill(""));
  }

  setHeader(header) {
    this._array[0][0] = header
  }

  setSubHeader(subHeader) {
    this._array[1][0] = subHeader
  }

  setValue(rowIndex, colIndex, value) {
    this._array[rowIndex + ROW_OFFSET][colIndex] = value;
  }

  getValue(rowIndex, colIndex) {
    return this._array[rowIndex + ROW_OFFSET][colIndex];
  }

  setCodeRows(codes, rowOffset, colOffset) {
    const NESTED_LEVEL_OFFSET = colOffset - 2;
    const CODE_NAME_OFFSET = colOffset - 1;
    const ORDER_OF_CODES_COL = colOffset - 3;

    const codeNames = codes.map((code)=>code[3]);
    const codeNests = codes.map((code)=>code[2]);
    this.setValue(rowOffset - 1, NESTED_LEVEL_OFFSET, 'Nested Level')
    this.setValue(rowOffset - 1, CODE_NAME_OFFSET, 'Code Name')
    this.setValue(rowOffset - 1, ORDER_OF_CODES_COL, 'Order of Code in List')
    this.setColumn(codeNames, CODE_NAME_OFFSET, rowOffset);
    this.setColumn(codeNests, NESTED_LEVEL_OFFSET, rowOffset);
    this.setColumn(_.range(1, codes.length + 1), ORDER_OF_CODES_COL, rowOffset);
  }

  setHeaders(projectName, exportName) {
    this.setHeader(`${projectName} [${exportName}]`)
    this.setSubHeader('Generated from Delve (www.DelveTool.com)')
  }


  setColumn(values, colIndex, offset) {
    for ( var valueIndex = 0; valueIndex < values.length; valueIndex ++ ) {
      const value = values[valueIndex];
      this.setValue(valueIndex + offset, colIndex, value);
    }
  }

  setRow(values, rowIndex, offset) {
    for ( var valueIndex = 0; valueIndex < values.length; valueIndex ++ ) {
      const value = values[valueIndex];
      this.setValue(rowIndex, valueIndex + offset, value);
    }
  }

  incrementValue(rowIndex, colIndex, inc) {
    inc = inc || 1;
    const value = this.getValue(rowIndex, colIndex);
    const newValue = value ? value + inc : inc;
    this.setValue(rowIndex, colIndex, newValue);
  }

  toCSV() {
    return convertArrayToCSV(
      this._array,
      {
        separator: ','
      }
    )
  }
}

export default ExportMatrix
