// app/javascript/projects/components/ProjectsDisplay.jsx
import React from 'react';
import EditDescriptorComponent from './EditDescriptorComponent'
import NormalDescriptorComponent from './NormalDescriptorComponent'

class DescriptorComponent extends React.Component {
  constructor () {
    super();
    this.editClicked = this.editClicked.bind(this);
    this.doneEditingCallback = this.doneEditingCallback.bind(this);

    this.state = {
      edit: false,
    }
  }

  doneEditingCallback(id, name, newPayload, updatePayload, deletePayload)
  {
    this.setState({
      edit: false
    });

    this.props.doneEditingCallback(id, name, newPayload, updatePayload, deletePayload)
  }

  _isEditState()
  {
    return this.state.edit || !this.props.descriptor;
  }

  editClicked(e)
  {
    e.preventDefault();
    this.setState({
      edit:true,
    });
  }

  render () {
    if ( !this._isEditState() )
    {
      return (
              <div>
                <NormalDescriptorComponent
                    descriptor={this.props.descriptor}
                    addAndSelectChoiceCallback={this.props.addAndSelectChoiceCallback}
                    editCallback={this.editClicked}
                    createSelectionCallback={this.props.createSelectionCallback}/>
              </div>
            )
    }
    else
    {
      return (
        <div>
          <EditDescriptorComponent
                  descriptor={this.props.descriptor}
                  deleteCallback={this.props.deleteCallback}
                  doneEditingCallback={this.doneEditingCallback}/>
        </div>
      )
    }
  }
}

export default DescriptorComponent;
