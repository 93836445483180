module.exports =  {
  // GET_JOB
  GET_JOB: "GET_JOB",
  GET_JOB_RESULT: "GET_JOB_RESULT",

  // CREATE_JOB
  CREATE_JOB: "CREATE_JOB",
  CREATE_JOB_RESULT: "CREATE_JOB_RESULT",
  CREATE_JOB_ERROR: "CREATE_JOB_ERROR",

  // DESTROY_JOB
  DESTROY_JOB: "DESTROY_JOB",
  DESTROY_JOB_RESULT: "DESTROY_JOB_RESULT",

  // CABLE
  UPDATE_JOB_CABLE: "UPDATE_JOB_CABLE",

  // STOP JOB
  STOP_JOB: "STOP_JOB",
  STOP_JOB_RESULT: "STOP_JOB_RESULT",

  // STATUS
  JOB_STATUS_NOT_STARTED: "not_started",
  JOB_STATUS_UNDOING: "undoing",
  JOB_STATUS_IN_PROGRESS: "in_progress",
  JOB_STATUS_COMPLETED: "completed",
  JOB_STATUS_ERROR: "error",
  JOB_STOPPING: "stopping"
}
