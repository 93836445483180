import InitialState from 'constants/InitialState'
import codeParentMapper from  'mappers/CodeParentMapper'
import codesHiearchyMapper from 'mappers/CodesHiearchyMapper'
import positionReducer from './PositionReducer'
import OptimisticMapperHelper from 'mappers/OptimisticMapperHelper'
import codeIdToProjectIdMapper from 'mappers/CodeIdToProjectIdMapper'

import {
    // NEST CODE
    NEST_CODE
  } from 'constants/QualConstants'

var _ = require('underscore');

function nestReducer(action, state = InitialState)
{
  if ( !action ) return state;

  switch (action.actionType)
  {
    case NEST_CODE:
    {
      var newParentCodeID = OptimisticMapperHelper.getCodeId(state, action.data.parent_id);
      const childCodeID = OptimisticMapperHelper.getCodeId(state, action.data.child_code_id);
      const position = action.data.position || 1;
      const projectID = codeIdToProjectIdMapper(state, childCodeID);

      // Note: This still guarantees that you do not nest anything too deep
      // It is less important now that we calculate this earlier,
      // But it seems like a fine failsafe
      const parentMapper = codesHiearchyMapper(state, projectID);
      newParentCodeID = codeParentMapper(state, parentMapper, childCodeID, newParentCodeID);

      var parentCode = OptimisticMapperHelper.getCode(state, newParentCodeID);
      const childCode = OptimisticMapperHelper.getCode(state, childCodeID);

      if ( !childCode ) return state;
      if ( !parentCode ) parentCode = !!parentCode;
      if ( newParentCodeID == 'ROOT' ) newParentCodeID = null;


      const originalParentID = childCode.parent_id || null;
      const originalPosition = childCode.position || 1;
      state = positionReducer(
        {
          delta: -1,
          parent_code_id: originalParentID,
          position: originalPosition,
          project_ids: [projectID]
        },
        state
      )

      state = positionReducer(
        {
          delta: 1,
          parent_code_id: newParentCodeID,
          position,
          project_ids: [projectID]
        },
        state
      )

      return {
        ...state,
        entities: {
          ...state.entities,
          codes: {
            ...state.entities.codes,
            [childCodeID]: {
              ...childCode,
              parent_id: newParentCodeID,
              position
            }
          }
        }
      }

    }
    default:
      return state;
  }
}

export default nestReducer
