// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';
import ProjectsStore from 'stores/ProjectsStore'

import QualCodeActions from 'actions/QualCodeActions'
import SideBarButton from './SideBarButton'
import ColorConstants from 'constants/ColorConstants'
import { withRouter } from 'react-router';

import PageConstants from 'constants/PageConstants'
import { Scrollbars } from 'react-custom-scrollbars';
import {Glyphicon, Button} from 'react-bootstrap'
import DimensionsConstants from 'constants/DimensionsConstants'

import ProjectPickerModal from './ProjectPickerModal'
import FileLoadingModal from './FileLoadingModal'
import AccountFooter from './AccountFooter'
import TranscriptLink from './TranscriptLink';

const TITLE_WIDTH = DimensionsConstants.LEFT_WIDTH - 60;

const NULL_PROJECT = {transcripts:[], id:'?', name:""};

const LEFT_PADDING = "15px";
const MENU_PADDING = "8px"

const titleStyle = {
  paddingLeft:LEFT_PADDING,
  paddingTop:"20px",
  paddingBottom:"20px",
  fontSize:"1.25em",
  color:"white",
  float:"left",
  width: "100%"
}

const _sharedMenuItemStyle = {
  paddingLeft:LEFT_PADDING,
  paddingTop:MENU_PADDING,
  fontSize:"1.1em",
  cursor: "pointer",
  paddingBottom:MENU_PADDING,
}


const headerStyle = {
  ..._sharedMenuItemStyle,
  width:"100%",
  borderBottom:"1px solid " + ColorConstants.LIGHT_GREY,
  background:ColorConstants.MEDIUM_GREY,
}

const selectedStyle = {
  ...headerStyle,
  color: "white"
}

const footerStyle = {
  position: "absolute",
  bottom: 0,
  width: "100%",
  height: "60px",
  paddingBottom:"5px",
}

class LeftSideBar extends React.Component {
  constructor () {
    super();
    this.state = {
      project:NULL_PROJECT,
      editableProjectTitle: null,
      footerHeight:5,
    };

    this._onChange = this._onChange.bind(this);
    this.onTitleChange = this.onTitleChange.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.onTitleBlur = this.onTitleBlur.bind(this);
    this.onTitleFocus = this.onTitleFocus.bind(this);
    this.saveTitle = this.saveTitle.bind(this);
    this.showProjectModal = this.showProjectModal.bind(this);
    this.footerRef = null;
  }

  showProjectModal(e)
  {
    QualCodeActions.loadProjects();
    QualCodeActions.showProjectModal();
  }

  saveTitle(e) {
    if ( document.activeElement )
      document.activeElement.blur();
  }

  onTitleFocus(e)
  {
    this.setState({
      editableProjectTitle:this.state.project.name
    })
  }

  onTitleBlur(e)
  {
    if ( this.state.project.name != this.state.editableProjectTitle)
    {
      QualCodeActions.updateProjectName(this.state.project.id, this.state.editableProjectTitle);
    }

    this.setState({
      editableProjectTitle:null
    })
  }

  onKeyDown(e) {
    if ( e.key === 'Enter' )
      this.saveTitle();
  }

  onTitleChange(e)
  {
    this.setState({
      editableProjectTitle:e.target.value
    })
  }

  _onChange () {
    this._loadState(this.props);
  }

  _getProjectFromParam (props) {
    // const page = PageParserUtil.parse(props.match, props.location);
    var projectID = props.projectID;
    var transcriptID = props.transcriptID;

    const nullState = {};
    if ( !projectID && !transcriptID ) return nullState;
    var project = ProjectsStore.getProject(projectID);
    project = project ? project : NULL_PROJECT;
    return {project: project, showProjectModal: false};
  }

  _loadState(props) {
    var projectTranscriptState = this._getProjectFromParam(props);

    const footerHeight = this.footerRef ? this.footerRef.clientHeight : 60;

    this.setState({
      ...projectTranscriptState,
      footerHeight: footerHeight,
    });
  }

  componentDidMount () {
    ProjectsStore.addChangeListener(this._onChange);
    this._loadState(this.props);
  }

  componentWillUnmount () {
    ProjectsStore.removeChangeListener(this._onChange);
  }

  componentWillReceiveProps (nextProps) {
    if ( this.props.transcriptID != nextProps.transcriptID ||
          this.props.projectID != nextProps.projectID ||
          this.props.page != nextProps.page )
    {
      this._loadState(nextProps);
    }
  }

  render () {
    const isOnUserPage = this.props.page === PageConstants.USER_PAGE;


    var transcriptLinks = [];
    if ( this.state.project && this.state.project.transcripts && !isOnUserPage )
    {
      transcriptLinks = this.state.project.transcripts.map((transcript, index) =>
      {
        return <TranscriptLink
          key={transcript.id}
          page={this.props.page}
          selected={this.props.transcriptID === transcript.id}
          transcriptID={transcript.id}
          location={this.props.location}
        >
          {transcript.name}
        </TranscriptLink>
      }
      );
    }

    const renderSearchStyle = this.props.page == PageConstants.SEARCH_PAGE ? selectedStyle : headerStyle;
    const renderCodeBookStyle = this.props.page == PageConstants.CODE_BOOK_PAGE ? selectedStyle : headerStyle;
    const renderChatStyle = this.props.page == PageConstants.CHAT_PAGE ? selectedStyle : headerStyle;
    const renderAnalysisStyle = this.props.page == PageConstants.ANALYSIS_PAGE ? selectedStyle : headerStyle;
    const transcriptsStyle = this.props.page == PageConstants.NEW_TRANSCRIPT_PAGE ? selectedStyle : headerStyle;
    const nonInputStyle = {padding:"1px 0px 0px 2px", cursor: "default"}
    const projectName = isOnUserPage ? null : this.state.project.name;

    return (
      <div style={{background:ColorConstants.DARK_GREY, color:ColorConstants.LIGHT_GREY, height:"100%"}}>
      <ProjectPickerModal
        subscriptionState={this.props.subscriptionState}
        onCanceledNewProject={this.props.onCanceledNewProject}
      />
      <FileLoadingModal/>

      <Scrollbars autoHide style={{height:"100%"}}>
          <div style={titleStyle}>
            <div style={{float:"left", width:TITLE_WIDTH.toString() + "px"}}>

            {this.props.hasEditPermission && !isOnUserPage ?
              <input style={{
                          borderStyle:"none",
                          outline:"none",
                          width:"100%",
                          background:"transparent"
                        }}
                    value={this.state.editableProjectTitle != null ? this.state.editableProjectTitle : this.state.project.name ? this.state.project.name : ""}
                    onBlur={this.onTitleBlur}
                    onChange={this.onTitleChange}
                    onFocus={this.onTitleFocus}
                    onKeyDown={this.onKeyDown}
                    placeholder="Untitled Project"
                    />
              :
              <div style={!projectName ? {color:'#727272', ...nonInputStyle} : nonInputStyle}>
                {projectName || "Untitled Project"}
              </div>
            }
            </div>
            <div style={{float:"left"}}>
              <Button bsStyle="link" onClick={this.showProjectModal}>
                <Glyphicon style={{color:"white"}} glyph='chevron-down'/>
              </Button>
            </div>
            <div style={{clear:"both"}}/>
          </div>

          <div style={{clear:"both"}}></div>

          {(this.props.hasEditPermission || isOnUserPage) &&
            <SideBarButton
              to={`/projects/` + this.state.project.id + `/search`}
              onClick={this.showProjectModal}
              isOnUserPage={isOnUserPage}
              linkStyle={renderSearchStyle}
            >
              Search
            </SideBarButton>
          }

          {(this.props.hasEditPermission || isOnUserPage) && !!process.env['AI_FEATURE_FLAG'] &&
            <SideBarButton
              to={`/projects/` + this.state.project.id + `/chat`}
              onClick={this.showProjectModal}
              isOnUserPage={isOnUserPage}
              linkStyle={renderChatStyle}
            >
              AI Chat +
            </SideBarButton>
          }


          <SideBarButton
            className="codesTab"
            to={`/projects/` + this.state.project.id + `/codes`}
            onClick={this.showProjectModal}
            isOnUserPage={isOnUserPage}
            linkStyle={renderCodeBookStyle}
          >
            Codes
          </SideBarButton>

          {(isOnUserPage || this.props.hasEditPermission) &&
            <SideBarButton
              to={`/projects/` + this.state.project.id + `/analysis`}
              onClick={this.showProjectModal}
              isOnUserPage={isOnUserPage}
              linkStyle={renderAnalysisStyle}
            >
              Snippets
            </SideBarButton>
          }

          {(isOnUserPage || this.props.hasEditPermission) &&
            <SideBarButton
              to={`/projects/` + this.state.project.id + `/transcripts/new`}
              onClick={this.showProjectModal}
              isOnUserPage={isOnUserPage}
              linkStyle={transcriptsStyle}
            >
              Transcripts  +
            </SideBarButton>
          }

          {transcriptLinks}

          <div style={{height:"100px", width:"10px"}}/>

        </Scrollbars>

        <div style={{...footerStyle, height:this.state.footerHeight}}>
          <div ref={(input) => {this.footerRef=input}}>
            <AccountFooter name={this.props.user.name} initials={this.props.user.initials}/>
        </div>
      </div>
    </div>
    );
  }
}

export default withRouter(LeftSideBar);
