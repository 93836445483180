const projectDetailsMapper = (state, projectID) => {
  try {
    let project = state.entities.projects[projectID];
    if ( !project ) return null;

    const transcripts = project.transcripts ? project.transcripts : [];

    project = {
      ...project,
      isOwner: state.entities.user['id'] === project.owner,
      ...{
        transcripts:transcripts.map((transcriptIndex)=>{
          const transcript = state.entities.transcripts[transcriptIndex];
          if ( transcript )
          {
            return {
              body: transcript.body,
              id: transcript.id,
              name: transcript.name && transcript.name.length > 0 ? transcript.name : "Untitled"
            }
          }
          else {
            return null;
          }
        }).filter((transcript)=>{return !!transcript})
          .sort(function (a, b) {
              return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
          }),
      }
    }
    
    delete project['codes'];
    delete project['updated_at']
    delete project['owner']
    delete project['recent_transcript_id']
    return project;
  } catch (e) {
    console.error(e);
    return null;
  }
}

export default projectDetailsMapper
