import {
  CREATE_UPGRADE
} from 'constants/UpgradeConstants'

import metaSubscriptionMapper from 'mappers/MetaSubscriptionMapper'

const EMPTY_RETURN_VALUE = {
  upgrade_savings: null,
  upgrade_per_person: null,
  upgrade_amount_total: null
};

function getFirstObject(hash) {
  const values = Object.keys(hash).map((id)=>hash[id]);
  if ( values.length == 0) return null;
  return values[0];
}

const subscriptionMapper = (state) => {
  try {
    const subscriptions = state.subscriptionState.subscriptions.map((subscriptionID)=>
      state.entities.subscriptions[subscriptionID]
    );
    const subscription = subscriptions[0] || {};
    const plan = state.entities.plans[subscription.plan] || {};
    const upgrade = getFirstObject(state.entities.upgrades) || {};

    const amount = plan.amount;
    const quantity = subscription.quantity;
    const upgrade_amount = upgrade.amount;
    const upgrade_plan_id = upgrade.id;

    return {
      upgrade_amount_total: upgrade_amount && quantity ? upgrade_amount * quantity / 100 : null,
      upgrade_per_person: upgrade_amount ? upgrade_amount / 100 : null,
      upgrade_savings: quantity && amount && upgrade_amount ?
        Math.max(0,quantity * amount * 12 - quantity * upgrade_amount) / 100 : null,
      plan_id: upgrade_plan_id || null,
      ...metaSubscriptionMapper(state, CREATE_UPGRADE)
    }
  } catch (e) {
    console.error(e);
    return EMPTY_RETURN_VALUE;
  }
}

export default subscriptionMapper
