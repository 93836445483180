var _ = require('underscore');

const overlappingCodesMapper = (state, code_id) => {
  const codeId = code_id ? code_id : state.codePageModal.codeID;

  if ( !codeId ) return {overlappingCodes: []};
  const clientId = state.mappers.codes[codeId];
  if ( !clientId ) return {overlappingCodes: []};

  const code = state.entities.codes[clientId];

  const codeIds = _.flatten(_.keys(state.entities.excerpts).map((excerptId)=>{
    return state.entities.excerpts[excerptId];
  }).map((excerpt) => {
    return excerpt.codings.map((coding)=>state.mappers.codes[coding.code_id]);
  }).filter((codes)=>{
    return codes.includes(clientId);
  })).filter((code_id)=>{
    return code_id != clientId && !!code_id;
  });

  const codeCounts = _.countBy(codeIds);

  const codes = _.keys(codeCounts).map((codeId)=>{
    const count = codeCounts[codeId];
    const code = state.entities.codes[state.mappers.codes[codeId]] || {};
    return {
      name: code.name,
      count: count,
      id: code.id,
      server_id:code.server_id
    }
  }).sort((a, b) => {
    return b.count - a.count;
  })

  return {overlappingCodes: codes};
}

export default overlappingCodesMapper
