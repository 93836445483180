// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';
import {Button, Alert, ButtonToolbar} from 'react-bootstrap'
import {FormGroup, ControlLabel, FormControl, HelpBlock} from 'react-bootstrap'

import ColorConstants from 'constants/ColorConstants'
import ExcerptConstants from 'constants/ExcerptConstants'

class ErrorDisplay extends React.Component {
  constructor () {
    super();
  }

  render () {
    return (
      <div>
        <link href="https://fonts.googleapis.com/css?family=Libre+Baskerville" rel="stylesheet"/>
        <div style={{ margin: "0 auto", fontFamily: "Libre Baskerville, serif", textAlign:"center"}} >
          <h1 style={{color:ColorConstants.ACTIVE_BUTTON_BLUE, fontSize:"4.0em"}}><b>Delve</b></h1>

          <div style={{marginTop:50}}>
            <h2>Opps!</h2>

          <div style={{textAlign:"center", marginTop:25}}>
            <p>
              Something went wrong.
            </p>

            <p style={{color:ColorConstants.LIGHT_GREY, fontSize:".8em"}}>
              Sending us a bug report will enable us to investigate and fix the problem.
            </p>

          </div>

              <div style={{textAlign:"center", marginTop:25}}>
                <Button style={{margin:10}} onClick={this.props.report}>Report Bug</Button>
                <Button style={{margin:10}} bsStyle="primary" href="/">Go back to Delve</Button>
              </div>
          </div>
        </div>
      </div>

    );
  }
}

export default ErrorDisplay;
