import { normalize, schema } from 'normalizr';
import codeReducerHelper from './CodeReducerHelper'
import InitialState from 'constants/InitialState'
import {
    CODE_BAR,
    EXPAND_CODE_BAR,
    COLLAPSE_CODE_BAR,
    CODE_BOOK,
    EXPAND_CODE,
    COLLAPSE_CODE,
} from 'constants/QualConstants'

var _ = require('underscore');

function codeBarReducer(action, state = InitialState)
{
  if ( !action ) return null;

  switch (action.actionType)
  {
    // TODO: this doesn't add the excerpt to the project... which it should
    case CODE_BAR:
    {
      const projectID = action.data.project_id;
      const codeBar = action.data.code_bar;
      return codeReducerHelper.getCodeObject(state, projectID, 'codeBar', codeBar);
    }
    case EXPAND_CODE_BAR:
    {
      const projectID = action.data.project_id;
      const codeID = action.data.code_id;
      return codeReducerHelper.collapseCode(state, projectID, codeID, 'codeBar', false);
    }
    case COLLAPSE_CODE_BAR:
    {
      const projectID = action.data.project_id;
      const codeID = action.data.code_id;
      return codeReducerHelper.collapseCode(state, projectID, codeID, 'codeBar', true);
    }
    case CODE_BOOK:
    {
      const codeBook = action.data.code_book;
      const projectID = action.data.project_id;
      return codeReducerHelper.getCodeObject(state, projectID, 'codeBook', codeBook);
    }  
    case COLLAPSE_CODE:
    {
      const projectID = action.data.project_id;
      const codeID = action.data.code_id;
      return codeReducerHelper.collapseCode(state, projectID, codeID, 'codeBook', true);
    }
    case EXPAND_CODE:
    {
      const projectID = action.data.project_id;
      const codeID = action.data.code_id;
      return codeReducerHelper.collapseCode(state, projectID, codeID, 'codeBook', false);
    }
    default:
      return null;  
  }
}

export default codeBarReducer
