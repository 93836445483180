import axios from 'axios';

import AuthStore from 'stores/AuthStore'
import QualCodeActions from 'actions/QualCodeActions'
import ErrorActions from 'actions/ErrorActions';
import {
  HTTPError
} from './HTTPErrors'


function _is401Error(error) {
  return error.response && error.response.status === 401;
}

function _basicErrorHandler(error)
{
  error = error || {};
  let response = error.response || {};

  if ( response.status == 401)
  {
    QualCodeActions.signOut();
  }
  else if (response.headers) {
    AuthStore.updateToken(response.headers);
  }

  const NETWORK_ERROR = 'Network Error';
  if ( error.message === NETWORK_ERROR )
    ErrorActions.networkError(error);

  const httpError = HTTPError.createHTTPError(error);
  if ( !!httpError ) throw httpError;

  throw error;
}

function _axiosRequest(method, endpoint, data = null, retryCount = 0) {
  const config = {
    method: method,
    url: endpoint,
    ...AuthStore.getHeader(),
  };

  if (data) {
    config.data = data;
  }

  return axios(config)
    .then(response => {
      AuthStore.updateToken(response.headers);
      return response;
    })
    .catch(error => {
      if (_is401Error(error) && retryCount > 0) {

        // Calculate exponential delay
        const delay = 1000;
        const exponentialDelay = delay * Math.pow(2, 3 - retryCount);
        // Add jitter (randomness)
        const jitter = Math.random() * 0.3 * exponentialDelay;
        const finalDelay = exponentialDelay + jitter;

        console.log(`Retrying request, ${retryCount} attempts left..., retrying in ${finalDelay}ms`);
        
        // Here you can also introduce a delay before retrying, especially if you suspect it's a race condition.
        return new Promise(resolve => setTimeout(() => resolve(), finalDelay))
          .then(() => _axiosRequest(method, endpoint, data, retryCount - 1));
      } else {
        _basicErrorHandler(error);
      }
    });
}

function _get(endpoint) {
  return _axiosRequest('get', endpoint, null, 3);
}

function _post(endpoint, body) {
  return _axiosRequest('post', endpoint, body, 3);
}

function _put(endpoint, body, delay = 0) {
  return _axiosRequest('put', endpoint, body, 3);
}

function _delete(endpoint, body) {
  return _axiosRequest('delete', endpoint, body, 3);
}


var API = {
  get: _get,
  delete: _delete,
  put: _put,
  post: _post,
};


export default API
