import AppDispatcher from 'dispatcher/AppDispatcher'
import QualConstants from 'constants/QualConstants'
import FilterConstants from 'constants/FilterConstants'
import PageConstants from 'constants/PageConstants'
import filteredExcerptMapper from 'mappers/FilteredExcerptMapper'
import exportExcerptMapper from 'mappers/exports/ExportExcerptMapper'
import exportCodingsMapper from 'mappers/exports/ExportCodingsMapper'
import exportCodesVCodesMapper from 'mappers/exports/ExportCodesVCodesMapper'
import exportCodesVTranscriptsMapper from 'mappers/exports/ExportCodesVTranscriptsMapper'
import exportCodesVDescriptorMapper from 'mappers/exports/ExportCodesVDescriptorMapper'
import codesWithExcerptCountsMapper from 'mappers/CodesWithExcerptCountsMapper'
import analysisDescriptorMapper from 'mappers/AnalysisDescriptorMapper'
import analysisTranscriptMapper from 'mappers/AnalysisTranscriptMapper'
import projectExcerptMapper from 'mappers/ProjectExcerptMapper'
import excerptMapper from 'mappers/ExcerptMapper'

import FilterStore from 'stores/FilterStore'
import asyncUtil from 'utils/AsyncUtil'

import State from 'stores/State'
import descriptorReducer  from 'reducers/DescriptorReducer'

var _ = require('underscore');
var EventEmitter = require('events').EventEmitter;

var _selectedExcerptID = null;

function _addCodeToExcerpt(data)
{
  const excerptId = data.excerpt_id;
  if (!excerptId ) return null;
  asyncUtil.addCodeToExcerpt(data.code_id, excerptId);
}

function _removeCodeFromExcerpt(data)
{
  const excerptId = data.excerpt_id;
  const codeID = data.code_id;
  if ( !codeID || !excerptId) return null;

  asyncUtil.removeCodeFromExcerpt(codeID, excerptId);
}

function _addCodeAndExcerpt(data)
{
  const excerptId = data.excerpt_id;
  const clientID = asyncUtil.createCode(data.projectId, data.name);
  if ( !excerptId ) return null;
  asyncUtil.addCodeToExcerpt(clientID, excerptId);
}

function _setSelectExcerpt(excerptID)
{
  _selectedExcerptID = excerptID;
}

var AnalysisStore = _.extend({}, EventEmitter.prototype, {
  getCodesWithExcerptCounts: (projectID) => {
    return codesWithExcerptCountsMapper(State.get(), FilterStore.getState(), projectID)
  },

  getAnalysisDescriptors: (projectID) => {
    return analysisDescriptorMapper(State.get(), FilterStore.getState(), projectID)
  },

  getAnalysisTranscripts: (projectID) => {
    return analysisTranscriptMapper(State.get(), FilterStore.getState(), projectID)
  },

  getCodesVDescriptorMatrix: (projectId, report) => {
    return exportCodesVDescriptorMapper(State.get(), projectId, report);
  },

  getCodesVTranscriptsMatrix: (projectId, report) => {
    return exportCodesVTranscriptsMapper(State.get(), projectId, report);
  },

  getCodesVCodesMatrix: (projectId, report) => {
    return exportCodesVCodesMapper(State.get(), projectId, report)
  },

  getExportExcerptMapper: (projectId) => {
    return exportExcerptMapper(State.get(), FilterStore.getState(), projectId);
  },

  getExportCodingsMapper: (projectId) => {
    return exportCodingsMapper(State.get(), FilterStore.getState(), projectId);
  },

  getStateWithMapper: function(mapper){
    return mapper(State.get());
  },

  getShowMore: function(projectID) {
    return showMoreMapper(State.get(), projectID);
  },

  getExcerptCodes: function(excerptID) {
    const excerpt = excerptMapper(State.get(), excerptID);
    return ((excerpt || {}).codes || []).map((code)=>code.id);
  },

  getSelectedExcerpt: function(projectID) {
    if ( !_selectedExcerptID) return null;

    const filteredExcerpts = filteredExcerptMapper(State.get(), FilterStore.getState(), projectID).excerpts;
    var selectedExcerpt = _.filter(filteredExcerpts, function(excerpt) {return excerpt.id == _selectedExcerptID});
    if ( !selectedExcerpt || selectedExcerpt.length == 0) return null;
    return selectedExcerpt[0];
  },

  getFilteredExcerpts: function(projectID) {
    const filteredExcerpts = filteredExcerptMapper(State.get(), FilterStore.getState(), projectID);
    return filteredExcerpts;
  },

  getExcerpts: function(projectID) {
    return projectExcerptMapper(State.get(), projectID).excerpts;
  },

  // Emit Change event
  emitChange: function() {
    this.emit('change');
  },

  // Add change listener
  addChangeListener: function(callback) {
    this.on('change', callback);
  },

  // Remove change listener
  removeChangeListener: function(callback) {
    this.removeListener('change', callback);
  }
});

// Register callback with AppDispatcher
AppDispatcher.register(function(payload) {
  var action = payload.action;

  switch(action.actionType) {
    case QualConstants.ADD_CODE_AND_EXCERPT:
      if ( action.data.page != PageConstants.ANALYSIS_PAGE ) return true;
      _addCodeAndExcerpt(action.data);
      break

    case QualConstants.CODE_PRESSED:
      if ( action.data.page != PageConstants.ANALYSIS_PAGE ) return true;
      _addCodeToExcerpt(action.data);
    break;

    case QualConstants.CODE_UNPRESSED:
      if ( action.data.page != PageConstants.ANALYSIS_PAGE ) return true;
      _removeCodeFromExcerpt(action.data);
    break;

    case QualConstants.ANALYSIS_EXCERPT_CLICKED:
      _setSelectExcerpt(action.data);
    break

    case QualConstants.ADD_CODE_TO_EXCERPT_RESULT:
    case QualConstants.DELETE_CODE_FROM_EXCERPT_RESULT:      
    // REDUX VERSION
    case QualConstants.FETCH_CODES_RESULT:
    case QualConstants.NEST_CODE_CABLE:
    case FilterConstants.FILTER_EXCERPTS_RESULT:
    case FilterConstants.FILTER_EXCERPTS:
    case QualConstants.ADD_CODE_TO_EXCERPT_ERROR:
      State.set(descriptorReducer(action, State.get()));
    break;
    default:
      return true;
  }

  AnalysisStore.emitChange();
  return true;
});

export default AnalysisStore
