const intercomMapper = (state) => {
  try {
    if ( !state.entities.user ) return {};
    const user = state.entities.user;
    if ( !user.id ) return {};
    if ( !user.email ) return {};


    return {
      user_id: user.id,
      email: user.email,
      name: user.name,
      user_hash: user.intercom,
    }
  } catch (e) {
    console.error(e);
    return {}
  }
}

export default intercomMapper;
