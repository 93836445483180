// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';
import ColorConstants from 'constants/ColorConstants'


function UserAvatarBase(props) {
  const circleSize = props.size + 'px';
  return <div style={{
    width: circleSize,
    height: circleSize,
    borderRadius: props.borderRadius,
    fontSize: props.fontSize,
    color: "#fff",
    lineHeight: circleSize,
    textAlign: "center",
    background: props.color || ColorConstants.ACTIVE_BUTTON_BLUE,
    marginTop:props.marginTop}}>
    {props.initials}
  </div>
}

export default UserAvatarBase;
