const codeIdToProjectIdMapper = (state, codeId) => {
  if ( !codeId ) return null;

  const projectIDs = Object.keys(state.entities.projects).map((projectID)=>state.entities.projects[projectID])
                            .filter((project)=>{
                              if ( !project.codes ) return false;
                              return project.codes.includes(codeId)
                            }).map((project)=>project.id);

  if ( projectIDs.length == 0 ) return null;
  return projectIDs[0];
}

export default codeIdToProjectIdMapper
