// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';
import {Button} from 'react-bootstrap'
import UserDisplaySectionContainer from './UserDisplaySectionContainer'

function TeamComponent(props) {
  const subscription = props.subscription ? props.subscription : {};
  const freeAccount = subscription.total === 0;

  return (
    <div>
        {subscription.isOwner && !freeAccount &&
          <UserDisplaySectionContainer header="Team">
            <p>
              You have {subscription.quantity} {subscription.quantity == 1 ? "teammember" : "teammembers"}
            </p>

            <Button bsStyle='blue' onClick={props.onShowTeamModal}>Manage Team</Button>
          </UserDisplaySectionContainer>
        }
    </div>
  );
}

export default TeamComponent;
