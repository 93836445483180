import ChatAPI from './ChatAPI'
import MemoConstants from 'memos/MemoConstants';
import AppDispatcher from 'dispatcher/AppDispatcher'
import asyncCodePromises from 'utils/AsyncCodePromises';
import { v1 as uuidv1 } from 'uuid';
import {
  CREATE_JOB,
  CREATE_JOB_RESULT,
  CREATE_JOB_ERROR,
  DESTROY_JOB,
  DESTROY_JOB_RESULT,
  STOP_JOB,
  STOP_JOB_RESULT,
  GET_JOB_RESULT,
  UPDATE_JOB_CABLE,
  JOB_STATUS_IN_PROGRESS
} from 'jobs/JobConstants';

const dispatchJob = (actionType, data) => {
  AppDispatcher.handleAction({
    actionType,
    data,
  })
}

const dispatchJobAPIResult = (actionType, response) => {
  dispatchJob(actionType, response.data)
}
  

var ChatActions = {
  createAICoding: async function(transcript_id) {
    dispatchJob(CREATE_JOB, {
      transcript_id,
      status: JOB_STATUS_IN_PROGRESS
    })
      
    return ChatAPI.createAICoding(transcript_id).then((response) => {
      dispatchJobAPIResult(CREATE_JOB_RESULT, response);
      return response;
    }).catch((error) => {
      dispatchJob(CREATE_JOB_ERROR, {
        transcript_id,
      })
      throw error;
    });
  },
  getAICoding: async function(transcript_id) {
    return ChatAPI.getAICoding(transcript_id).then((response) => {
      dispatchJobAPIResult(GET_JOB_RESULT, response);
      return response;
    })
  },
  destroyAICoding: async function(jobId) {
    dispatchJob(DESTROY_JOB, {id: jobId})
    return ChatAPI.destroyAICoding(jobId).then((response) => {
      dispatchJob(DESTROY_JOB_RESULT, {
        id: jobId,
      });
      return response;
    });
  },
  stopAICoding: async function(jobId) {
    dispatchJob(STOP_JOB, {id: jobId})

    return ChatAPI.stopAICoding(jobId).then((response) => {
      dispatchJobAPIResult(STOP_JOB_RESULT, response);
    })
  },
  updateJob: async function(data) {
    dispatchJob(UPDATE_JOB_CABLE, (data || {}).job)
  },
  createMemoChat: async function(excerpt_id)
  {   
    const clientId = uuidv1();

    AppDispatcher.handleAction({
      actionType: MemoConstants.CREATE_MEMO,
      data: {
        excerpt_id,
        text: '',
        clientId,
        user_id: 'bot'
      }
    })

    const server_excerpt_id = await asyncCodePromises.getExcerpt(excerpt_id) // you need tests for this
    return ChatAPI.createMemoChat(server_excerpt_id).then((response) => {
      AppDispatcher.handleAction({
        actionType: MemoConstants.CREATE_MEMO_RESULT,
        data: {
          id: response.data.id,
          memo: response.data.memo,
          clientId
        }
      })
      return response;
    })
  }
}

export default ChatActions
