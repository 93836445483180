import {
  FETCH_SUBSCRIPTIONS_ERROR,
  FETCH_SEATS_ERROR
} from 'constants/CashierConstants'

import {
  NETWORK_ERROR
} from 'constants/NetworkConstants'

import {
  CREATE_EXCERPT_ERROR,
} from 'constants/QualConstants'

import {
  CODING_VERSION_MISMATCH,
  VERSION_MISMATCH
} from 'constants/VersioningErrorConstants'

const defaultMessage = "Something went wrong, please reload and try again.";

const errorMessageDict = {
}

const NO_ERROR = {error: {error: false, message: null}}

const ERRORS = [FETCH_SUBSCRIPTIONS_ERROR, FETCH_SEATS_ERROR, CREATE_EXCERPT_ERROR]
const SEVERE_ERRORS = [NETWORK_ERROR];

function getMessage(state, type)
{
  const errorDict = state.errorDict || {};
  const error = errorDict[type] || {};
  return error.message || defaultMessage;
}

function getStatus(type) {
  return SEVERE_ERRORS.includes(type) ?
    'SEVERE'
    : 'WARNING';
}

function getError(state, type){
  const error = (state.errorDict || {}) [type] || {};
  if ( !error.error ) return null;
  // these are handled elswehre
  if ( error.errorCode == CODING_VERSION_MISMATCH ) return null;
  if ( error.errorCode == VERSION_MISMATCH ) return null;

  return {
    error:{
      error: true,
      message: getMessage(state, type),
      status: getStatus(type)
    }
  }
}

const defaultErrorMapper = (state) => {
  try {
    let error;
    for ( const e of SEVERE_ERRORS)
    {
      error = getError(state, e);
      if (error) return error;
    }

    if ( state.error.error )
      return {error: state.error};

    for ( const e of ERRORS)
    {
      error = getError(state, e);
      if (error) return error;
    }

    return NO_ERROR;
  } catch (e) {
    console.error(e);
    return NO_ERROR;
  }
}

export default defaultErrorMapper
