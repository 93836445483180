// app/javascript/projects/components/ProjectsDisplay.jsx
import React from 'react';
import {Button, Alert} from 'react-bootstrap'
import CashierActions from 'actions/CashierActions'

import {
  PAUSE_FEEDBACK,
  UNCANCEL_SUBSCRIPTION
} from 'constants/AccountPageConstants'

class BillingModalMainBilling extends React.Component {
  constructor () {
    super();
    this.state = {
    };

    this.cancelSubscription = this.cancelSubscription.bind(this);
    this.subscriptionCopy = this.subscriptionCopy.bind(this);
    this.renewSubscription = this.renewSubscription.bind(this);
  }

  cancelSubscription(e)
  {
    CashierActions.gotoTeamModal(PAUSE_FEEDBACK)
  }

  renewSubscription(e)
  {
    CashierActions.gotoTeamModal(UNCANCEL_SUBSCRIPTION);
  }

  subscriptionButton()
  {
    const subscription = this.props.subscription ? this.props.subscription : {};
    const status = subscription.status;
    const canceled = subscription.cancel_at_period_end;

    if ( !status )
    {
      return <div></div>
    }
    else if (status=='canceled' || canceled )
    {
      return(<Button bsStyle="white" onClick={this.renewSubscription}>Renew Subscription</Button>);
    }
    else
    {
      return(<Button bsStyle="white" onClick={this.cancelSubscription}>Cancel Subscription</Button>);
    }
  }

  subscriptionCopy(e)
  {
    const subscription = this.props.subscription ? this.props.subscription : {};
    const status = subscription.status;
    const canceled = subscription.cancel_at_period_end;
    const amount = subscription && subscription.quantity ? subscription.quantity : 0;
    const memberString = amount == 1 ? `${amount} member` : `${amount} members`;

    const introParagraph = <p>
            Your subscription is ${subscription.amount}/{subscription.interval} per team member and you have {memberString}.
          </p>

    if ( !status )
    {
      return (<div></div>)
    }
    else {
      var secondParagaph = <div></div>
      if (status != 'canceled' && !canceled )
      {
        if ( status == 'trialing')
        {
          secondParagaph = <p>
            Your free trial ends on {subscription.current_period_end}. After that, you'll be charged ${subscription.total} per {subscription.interval}.
          </p>
        }
        else
        {
          secondParagaph = <p>
            Your subscription will automatically renew on {subscription.current_period_end} and you'll be charged ${subscription.total}.
          </p>
        }
      }
      else if (status != 'canceled' && canceled )
      {
        secondParagaph = <p>
          Your subscription will end on {subscription.current_period_end}. Renew your plan for ${subscription.total}/{subscription.interval}.
        </p>
      }
      else if (status == 'canceled')
      {
        secondParagaph = <p>
            Renew your plan for ${subscription.total}/{subscription.interval}.
          </p>
      }

      return (<div style={{maxWidth:"329px"}}>
        {introParagraph}
        {secondParagaph}
      </div>)
    }
  }


  render () {
    return (
      <div>
        <h4>Subscription and Billing</h4>

        {this.subscriptionCopy()}

        <div style={{margin:"20px 20px 29px 0px"}}>
          {this.subscriptionButton()}
        </div>
      </div>
    );
  }
}

export default BillingModalMainBilling;
