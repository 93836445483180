import {
  DELVE_TIME_REGEXP,
  NAME_REGEX,
  BLANK_REGEX
} from 'utils/FormatterRegExp'

export default (transcriptText) => {
  const regex = new RegExp(`^${BLANK_REGEX}\\(${BLANK_REGEX}(${DELVE_TIME_REGEXP})${BLANK_REGEX}-+${BLANK_REGEX}${DELVE_TIME_REGEXP}${BLANK_REGEX}\\)${BLANK_REGEX}[\n \t]*(\\w)`, 'gm');
  
  return transcriptText.replace(
    regex,
    "$1 $2"
  );
}