import {Popover, Overlay, Button} from 'react-bootstrap'
import React from "react";
import OutsideAlerter from './OutsideAlerter'

function CombineCodesOverlay(props) {
  return (<Overlay placement="bottom"
            show={props.show}
            container={props.container}
            target={props.target}>
              <Popover id="popover">
                <OutsideAlerter onHide={props.onHide}>
                  <div>
                    <div>
                      Want to merge codes?
                    </div>
                    <div style={{float:'right', marginTop:"5px", marginBottom:"10px"}}>
                      <Button bsSize="small" onClick={props.onClick} bsStyle="white">Yes</Button>
                    </div>
                  </div>
                </OutsideAlerter>
              </Popover>
          </Overlay>);
}

export default CombineCodesOverlay;
