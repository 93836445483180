import React from 'react';
import ActiveProjectRow from './ActiveProjectRow'
import ArchivedProjectRow from './ArchivedProjectRow'

function ProjectRow(props) {
  /*
    Open can be used to open the native file picker
  */
  if ( !props.project ) return <div></div>
  if ( !props.project.id ) return <div></div>

  if ( props.project.status === 'archived' ) 
  {
    return <ArchivedProjectRow {...props} />
  } else {
    return <ActiveProjectRow {...props} />
  }
}


export default ProjectRow
