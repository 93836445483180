const Diff = require('diff');

const MATCH = 'MATCH';
const INSERT = 'INSERT';
const DELETE = 'DELETE';
const REPLACE = 'REPLACE';
const EMPTY = 'EMPTY';

function getType(diff)
{
  if ( diff.insert && diff.delete) return REPLACE;
  if ( diff.insert ) return INSERT;
  if ( diff.delete ) return DELETE;
  if ( diff.match ) return MATCH;
  return EMPTY;
}


function merge(previous, current)
{
  const previousType = getType(previous);
  const currentType = getType(current);

  if ( previousType == INSERT && currentType == DELETE )
  {
    return ({
      previous: {insert: previous.insert, delete: current.delete},
      current: {},
    });
  }
  else if (previousType == DELETE && currentType == INSERT) {
    return ({
      previous: {insert: current.insert, delete: previous.delete},
      current: {},
    });
  }
  else
  {
    return({
      previous: previous,
      current: current,
    })
  }
}

var diffUtil = {
  diff: function(originalBody, newBody) {
    var diffs = Diff.diffWordsWithSpace(originalBody, newBody);

    const mappedDiffs = diffs.map((diff)=>{
      if ( diff.added )
        return {'insert':diff.value, 'delete': '', 'match': ''}
      else if ( diff.removed )
        return {'delete':diff.value, 'insert': '', 'match': ''}
      else
        return {'delete':'', 'insert': '', 'match':diff.value}
    });

    // you should see if adds and removed are next to eachother
    // so there's a replace

    let previousDiff = {};
    let returnDiffs = [];
    mappedDiffs.forEach((diff, index)=>{
      const merged = merge(previousDiff, diff);

      if ( getType(merged.previous) != EMPTY ) returnDiffs.push(merged.previous);
      previousDiff = merged.current;
    })

    if ( getType(previousDiff) != EMPTY ) returnDiffs.push(previousDiff);

    return returnDiffs;
  },
}

export default diffUtil;
