import AppDispatcher from 'dispatcher/AppDispatcher'
import CashierConstants from 'constants/CashierConstants'
import cashierReducer from 'reducers/CashierReducer'

import UpgradeConstants from 'constants/UpgradeConstants'
import upgradeReducer from 'reducers/UpgradeReducer'
import upgradeMapper from 'mappers/UpgradeMapper'

import subscriptionMapper from 'mappers/SubscriptionMapper'
import planMapper from 'mappers/PlanMapper'
import subscribeErrorMapper from 'mappers/SubscribeErrorMapper'
import teamMapper from 'mappers/TeamMapper'
import addSeatMapper from 'mappers/AddSeatMapper'
import removeSeatMapper from 'mappers/RemoveSeatMapper'
import cancelSubscriptionMapper from 'mappers/CancelSubscriptionMapper'
import uncancelSubscriptionMapper from 'mappers/UncancelSubscriptionMapper'

import State from 'stores/State'


var _ = require('underscore');
var EventEmitter = require('events').EventEmitter;

var CashierStore = _.extend({}, EventEmitter.prototype, {
  getUpgradeInfo: function() {
      return upgradeMapper(State.get());
  },

  getUncancelSubscriptionState: function() {
      return uncancelSubscriptionMapper(State.get());
  },

  getCancelSubscriptionState: function() {
      return cancelSubscriptionMapper(State.get());
  },

  getAddSeatState: function() {
      return addSeatMapper(State.get())
  },

  getRemoveSeatState: function() {
    return removeSeatMapper(State.get())
  },

  getTeamModal: function() {
      return teamMapper(State.get());
  },

  getSubscribeError: function() {
    return subscribeErrorMapper(State.get()).error;
  },

  getSubscriptions: function() {
    return subscriptionMapper(State.get());
  },

  getPlan: function() {
    return planMapper(State.get());
  },

  // Emit Change event
  emitChange: function() {
    this.emit('change');
  },

  // Add change listener
  addChangeListener: function(callback) {
    this.on('change', callback);
  },

  // Remove change listener
  removeChangeListener: function(callback) {
    this.removeListener('change', callback);
  }
});

// Register callback with AppDispatcher
AppDispatcher.register(function(payload) {
  var action = payload.action;

  switch(action.actionType) {
    case CashierConstants.FETCH_SUBSCRIPTIONS:
    case CashierConstants.FETCH_SUBSCRIPTIONS_RESULT:
    case CashierConstants.FETCH_SUBSCRIPTIONS_ERROR:
    case CashierConstants.FETCH_PLANS_RESPONSE:
    case CashierConstants.SUBSCRIBE_RESPONSE:
    case CashierConstants.SUBSCRIBE_ERROR:
    case CashierConstants.FETCH_SEATS_RESPONSE:
    case CashierConstants.ADD_SEAT:
    case CashierConstants.ADD_SEAT_RESPONSE:
    case CashierConstants.ADD_SEAT_ERROR:
    case CashierConstants.REMOVE_SEAT:
    case CashierConstants.REMOVE_SEAT_RESPONSE:
    case CashierConstants.REMOVE_SEAT_ERROR:
    case CashierConstants.CANCEL_SUBSCRIPTION:
    case CashierConstants.CANCEL_SUBSCRIPTION_RESPONSE:
    case CashierConstants.UNCANCEL_SUBSCRIPTION:
    case CashierConstants.UNCANCEL_SUBSCRIPTION_RESPONSE:
    case CashierConstants.FETCH_PLANS_ERROR:
    case CashierConstants.FETCH_SEATS_ERROR:
    case CashierConstants.TEAM_MODAL_PAGE:
    case CashierConstants.CANCEL_SUBSCRIPTION_ERROR:
    case CashierConstants.UNCANCEL_SUBSCRIPTION_ERROR:
    case CashierConstants.SUBSCRIBE:
    case CashierConstants.SUBSCRIBE_RESPONSE:
    case CashierConstants.CLEAR_CASHIER_ERRORS:
    case UpgradeConstants.CREATE_UPGRADE:
    case UpgradeConstants.CREATE_UPGRADE_ERROR:
      State.set(cashierReducer(action, State.get()));
      break
    case UpgradeConstants.FETCH_UPGRADES:
    case UpgradeConstants.FETCH_UPGRADES_RESULT:
    case UpgradeConstants.FETCH_UPGRADES_ERROR:
    case UpgradeConstants.CREATE_UPGRADE_RESULT:
      State.set(upgradeReducer(action, State.get()));
    break;
    default:
      return true;
  }

  CashierStore.emitChange();
  return true;
});

export default CashierStore
