// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';
import {
  Route,
} from 'react-router-dom'
import ReactGA from 'react-ga';


class GoogleAnalytics extends React.Component {
  render () {
    return (
      <Route path="/" render={({location}) => {
        if ( this.props.userID )
        {
          ReactGA.set({ userId: this.props.userID });
        }
        ReactGA.pageview(location.pathname + location.search);
        return null;
        }} />
    );
  }
}

export default GoogleAnalytics;
