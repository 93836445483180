// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';
import TranscriptPager from './TranscriptPager'
import {
  TRANSCRIPT_NAVIGATION_PADDING
} from 'constants/DimensionsConstants'

function PagerWrapper(props) {
  const pageCount = props.pageCount || 1;
  const showPager = pageCount > 1;
  if ( !showPager ) return props.children;

  return (<div>
    <div style={{
      paddingTop: '10px',
      paddingLeft: `${TRANSCRIPT_NAVIGATION_PADDING}px`,
    }}>
      <TranscriptPager
        pageCount={props.pageCount}
        onPage={props.onPage}
      />
    </div>
      {props.children}
    <div style={{
      paddingLeft: `${TRANSCRIPT_NAVIGATION_PADDING}px`,
      marginBottom:'10px'
    }}>
      <TranscriptPager
        pageCount={props.pageCount}
        onPage={props.onPage}
      />
    </div>
  </div>)
}

export default PagerWrapper;
