// app/javascript/projects/components/TranscriptNotFound.jsx

import React from 'react';
import FullPageMessage from './FullPageMessage'
import QualCodeActions from 'actions/QualCodeActions'


class TranscriptNotFound extends React.Component {
  render () {
    return <FullPageMessage
      header='Transcript not Found'
      body='You do not have access to this transcript or it does not exist.'
      onClick={()=>QualCodeActions.showProjectModal()}
      buttonText='See Your Projects'
    />
  }
}

export default TranscriptNotFound;
