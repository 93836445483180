// NIL means way in the future

const exportNameMapper = (name, postFix, defaultName) => {
  const date = new Date();
  const dateString = date.toISOString().substring(0,10);
  postFix = !!postFix ? [postFix] : [];
  const exportFileName = [dateString, name || defaultName, ...postFix].join('-')
  return exportFileName;
}

export default exportNameMapper
