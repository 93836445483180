const _ = require('underscore')
import FileParser from "./FileParser"
import pdfjsLib from './Pdfjslib'

class FileParserPdf extends FileParser {
  onload(reader, fileName, resolve, reject) {
    const binaryStr = reader.result

    try {
      var loadingTask = pdfjsLib().getDocument({data: binaryStr});
    } catch (e) {
      return reject(e);
    }

    loadingTask.promise.then(async function(pdf) {
      const promises = _.range(1, pdf.numPages + 1).map((pageNumber)=>{
        return pdf.getPage(pageNumber).then(async function(page) {
          const text = await page.getTextContent()
          return [...text.items.map((item)=> {
            if ( item.str.length === 0 ) return '\n'
            return item.str + (item.hasEOL ? ' ' : '')
          }), '\n']
        })
      });

      let error;
      const strArray = await Promise.all(promises).catch((e)=>{
        error = e;
      });

      if ( !!error )
        return reject(error);

      const string = _.flatten(strArray).join('');

      resolve({
        body: string,
        name: fileName,
        fileType: 'pdf'
      })
    }).catch((error)=>reject(error))
  }

  readAs(reader) {
    reader.readAsArrayBuffer(this._file);
  }
}

export default FileParserPdf;
