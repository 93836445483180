import {
  AUTH_RESET_PASSWORD_ERROR,
} from 'constants/AuthConstants'

const errorMessageDict = {
  AUTH_RESET_PASSWORD_ERROR: "Something went wrong, please reload and try again."
}

const NULL_STATE = {error:{error: false}, lastReset:null};

const resetPasswordMapper = (state) => {
  try {
    const lastReset = state.resetState.lastReset;
    const error = state.errorDict[AUTH_RESET_PASSWORD_ERROR];
    if ( !error.error ) return {
      ...NULL_STATE,
      lastReset:lastReset,
    }

    return {
      error:{
        error: true,
        message: error.message ? error.message : errorMessageDict[AUTH_RESET_PASSWORD_ERROR],
      },
      lastReset:lastReset,
    }
  } catch (e) {
    console.error(e);
    return NULL_STATE;
  }
}

export default resetPasswordMapper
