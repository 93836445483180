// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';
import {Modal} from 'react-bootstrap'

import CollabModalTeam from './collab/CollabModalTeam'
import CollabModalInvite from './collab/CollabModalInvite'
import CollabModalActivate from './collab/CollabModalActivate'
import CollabInviteWrapper from './collab/CollabInviteWrapper'


import CollabActions from 'actions/CollabActions'
import CashierActions from 'actions/CashierActions'
import ShareStore from 'stores/ShareStore'

const COLLAB_PAGE_TEAM = 'COLLAB_PAGE_TEAM'
const COLLAB_PAGE_INVITE = 'COLLAB_PAGE_INVITE'
const COLLAB_PAGE_ACTIVATE = 'COLLAB_PAGE_ACTIVATE'

class CollabModal extends React.Component {
  constructor () {
    super();
    this.gotoInvitePage = this.gotoInvitePage.bind(this);
    this.gotoTeamPage = this.gotoTeamPage.bind(this);
    this.state = {
      page: COLLAB_PAGE_TEAM,
      collabText: '',
      collabRole: 'edit',
      sharingProject: false,
      sharedEmail: '',
      addingSeat: false,
    }

    this.onSubmit = this.onSubmit.bind(this)
    this.collabRoleChange = this.collabRoleChange.bind(this);
    this.collabTextChange = this.collabTextChange.bind(this);
    this.onAddSeat = this.onAddSeat.bind(this);
    this.onActivateSubscription = this.onActivateSubscription.bind(this);
    this.hideCollabModal = this.hideCollabModal.bind(this);
  }

  hideCollabModal(e) {
    this.setState({
      page: COLLAB_PAGE_TEAM,
      collabText: '',
      collabRole: 'edit',
      sharingProject: false,
      sharedEmail: '',
      addingSeat: false,
    })

    CashierActions.clearCashierErrors()
    this.props.hideCollabModal();
  }

  collabTextChange(e)
  {
    this.setState({
      collabText: e.target.value
    })
  }

  collabRoleChange(role) {
    this.setState({
      collabRole: role
    })
  }

  onAddSeat(email) {
    const subscription = this.props.subscription || {};
    if (  !subscription.id && !subscription.isOwner ) return;

    this.setState({
      addingSeat: true
    })

    CashierActions.addSeat(subscription.id, email).then((data)=>{
      CollabActions.getCollabList(this.props.projectID).then((data)=>{
        this.setState({
          addingSeat: false,
          page: COLLAB_PAGE_TEAM
        })
      })
    })
  }

  onActivateSubscription(email) {
    this.setState({
      sharingProject: false,
      collabText:'',
      page: COLLAB_PAGE_ACTIVATE,
      collabRole: 'edit',
      sharedEmail: email
    })
  }

  onSubmit()
  {
    this.setState({
      sharingProject: true,
    })

    CollabActions.addCollborator(
      this.props.projectID,
      this.state.collabText,
      this.state.collabRole
    ).then((data)=>{
      const collaborator = ShareStore.getCollaborator(
        this.props.projectID,
        this.state.collabText
      );

      if ( !!data.error ) {
          this.setState({
            sharingProject: false
          })
          return;
      }

      if (
        !!collaborator.subscribed || // should check for collaborator as well, sometimes does not exist (not sure why) https://qual-production.sentry.io/issues/3463043195/?project=1309285&query=is%3Aunresolved&referrer=issue-stream&stream_index=0
        this.state.collabRole === 'view_only' ||
        !(this.props.subscription || {}).isOwner
      ) {
        this.setState({
          sharingProject: false,
          collabText:'',
          page: COLLAB_PAGE_TEAM,
          collabRole: 'edit'
        })
      } else {
        this.onActivateSubscription(this.state.collabText);
      }
    })
  }

  gotoInvitePage() {
    this.setState({
      page: COLLAB_PAGE_INVITE
    })
  }

  gotoTeamPage() {
    this.setState({
      page: COLLAB_PAGE_TEAM
    })

    CollabActions.clearCollaborationError()
  }

  collabRoleChange(role) {
    this.setState({
      collabRole: role
    })
  }

  getPage() {
    switch (this.state.page) {
      case COLLAB_PAGE_INVITE:
        return (<CollabInviteWrapper
          name={this.props.name}
          nextPage={this.gotoInvitePage}
          isInvite={true}
          onSubmit={this.onSubmit}
          collabRoleChange={this.collabRoleChange}
          collabTextChange={this.collabTextChange}
          collabText={this.state.collabText}
          collabRole={this.state.collabRole}
          clickBack={this.gotoTeamPage}
          shareError={this.props.shareError}
          seatError={this.props.seatError}
        >
          <CollabModalInvite
            projectID={this.props.projectID}
            myRole={this.props.myRole}
            name={this.props.name}
            owner={this.props.owner}
            cancel={this.gotoTeamPage}
            sharing={this.state.sharingProject}
            />
        </CollabInviteWrapper>)
      break;
      case COLLAB_PAGE_ACTIVATE:
        return <CollabModalActivate
                email={this.state.sharedEmail}
                subscription={this.props.subscription}
                onAddSeat={this.onAddSeat}
                onSkip={this.gotoTeamPage}
                addingSeat={this.state.addingSeat}
              />
      break;
      default:
        return (<CollabInviteWrapper
                name={this.props.name}
                nextPage={this.gotoInvitePage}
                isInvite={false}
                onSubmit={this.onSubmit}
                collabRoleChange={this.collabRoleChange}
                collabTextChange={this.collabTextChange}
                collabText={this.state.collabText}
                collabRole={this.state.collabRole}
                shareError={this.props.shareError}
                seatError={this.props.seatError}
              >
                <CollabModalTeam
                  projectID={this.props.projectID}
                  collaborators={this.props.collaborators}
                  myRole={this.props.myRole}
                  name={this.props.name}
                  owner={this.props.owner}
                  nextPage={this.gotoInvitePage}
                  isSubscriptionOwner={(this.props.subscription || {}).isOwner}
                  onActivateSubscription={this.onActivateSubscription}
              />
          </CollabInviteWrapper>)
      break;
    }
  }

  render () {
    const page = this.getPage();

    return (
      <div>
          <style type="text/css">{`
          .custom-modal {width:500px !important;}
        `}</style>
        <Modal
          dialogClassName="custom-modal"
          show={this.props.show}
          onHide={this.hideCollabModal}
        >
          <Modal.Body>
              {page}
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default CollabModal;
