import { useEffect } from 'react';
import * as amplitude from '@amplitude/analytics-browser';
import { withRouter } from 'react-router';

const InitializeAmplitude = ({ apiKey, userId }) => {
  // a_id is a query parameter that is used to set the device id
  // get amplitude device_id
  const a_id = new URLSearchParams(window.location.search).get('ampDeviceId');

  // Function to set a cookie
  const setCookie = (name, value, days) => {
    const d = new Date();
    d.setTime(d.getTime() + (days * 24 * 60 * 60 * 1000));
    const expires = "expires=" + d.toUTCString();
    document.cookie = `${name}=${value};${expires};path=/;domain=localhost`;
  };

  // Function to get a cookie by name
  const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    
    const parts = value.split(`; ${name}=`);

    if (parts.length === 2) return parts.pop().split(';').shift();
  };

  useEffect(() => {
    try {
      let deviceId = a_id || localStorage.getItem('amplitudeDeviceId') || getCookie('amplitudeDeviceId');

      if (deviceId) {
        localStorage.setItem('amplitudeDeviceId', deviceId);
        setCookie('amplitudeDeviceId', deviceId, 1); // Set cookie for 30 days
      }

      if (deviceId) {
        if (userId) {
          amplitude.init(apiKey, userId, {
            deviceId: deviceId,
            minIdLength: 1
          });
        } else {
          amplitude.init(apiKey, null, {
            deviceId: deviceId,
            minIdLength: 1
          });
        }
      }
    }
    catch (error) {
      console.error('Error initializing Amplitude', error);
    }
  }, [apiKey, a_id, userId]);

  return null;
};

export default withRouter(InitializeAmplitude);