module.exports =  {
                    // FETCH_UPGRADES
                    FETCH_UPGRADES: "FETCH_UPGRADES",
                    FETCH_UPGRADES_RESULT: "FETCH_UPGRADES_RESULT",
                    FETCH_UPGRADES_ERROR: "FETCH_UPGRADES_ERROR",
                    // CREATE_UPGRADES
                    CREATE_UPGRADE: "CREATE_UPGRADE",
                    CREATE_UPGRADE_RESULT: "CREATE_UPGRADE_RESULT",
                    CREATE_UPGRADE_ERROR: "CREATE_UPGRADE_ERROR"
                  }
