// memoMapper.js
const _ = require('underscore');
import FallbackNameMapper from './FallbackNameMapper';
import {
  LIGHT_GREY,
} from 'constants/ColorConstants';

function getUserColor(user_id, userColors) {
  const userColor = userColors[user_id] ? userColors[user_id].color || null : null;
  if ( userColor )
    return userColor;

  return LIGHT_GREY
}

const memoMapper = (state, id, userColors) => {
  const memo = state.entities.memos[id];
  if (!memo) return null;
  const user = FallbackNameMapper(state, memo.user_id) || {};

  return {
    ...memo,
    name: user.name,
    initials: user.initials || 'U',
    color: getUserColor(memo.user_id, userColors),
  }
}

export default memoMapper
