import ConversationsAPI from './ConversationsAPI';
import AppDispatcher from 'dispatcher/AppDispatcher'
import FilterStore from 'stores/FilterStore'
import {
  SELECT_CHAT_EXCERPT
} from './ChatConstants'

const dispatchJob = (actionType, data) => {
  AppDispatcher.handleAction({
    actionType,
    data,
  })
}

var ConversationActions = {
  createConversation: async function(project_id, content) {
    return ConversationsAPI.createConversation(project_id, content)
  },
  createMessage: async function(conversation_id, content) {
    return ConversationsAPI.createMessage(conversation_id, content)
  },
  getConversation: async function(conversation_id) {
    return ConversationsAPI.getConversation(conversation_id)
  },
  createAIConversation: async function(projectID, conversation_id) {
    const {codes, multi_choices, transcripts, op} = FilterStore.getFilters(projectID);

    return ConversationsAPI.createAIConversation(conversation_id, codes, op, transcripts)
  },
  getConversations: async function(project_id) {
    return ConversationsAPI.getConversations(project_id)
  },
  excerptClicked: function(excerptID) {
    AppDispatcher.handleAction({
      actionType: SELECT_CHAT_EXCERPT,
      data: {
        selected_excerpt_id: excerptID
      }
    })
  },
  clearExcerpt: function() {
    AppDispatcher.handleAction({
      actionType: SELECT_CHAT_EXCERPT,
      data: {
        selected_excerpt_id: null
      }
    })
  }
}

export default ConversationActions
