import React from 'react';
import GenericOnboardingMessage from 'projects/components/GenericOnboardingMessage';

const ChatInfoScreen = ({  }) => {
  return (
    <GenericOnboardingMessage
      header='Ask anything about your coded snippets'
      body='The AI Assistant can help you delve into your data. Filter by a code and ask a question.'
      intercomArticleId={process.env['INTERCOM_ARTICLE_AI_CHAT']}
      helpArticleText="Learn more about Delve's AI Chat feature"
    />
  );
}

export default ChatInfoScreen;