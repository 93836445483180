import InitialState from 'constants/InitialState'
const _ = require('underscore')

function positionReducer(action, state = InitialState)
{
  const {parent_code_id, position, project_ids} = action;
  const delta = action.delta || 1;

  const project_codes = project_ids.map(
    (project_id)=>(state.entities.projects || {})[project_id]
  ).filter((project_id)=>!!project_id).map((project)=>project.codes).flat()

  const codes = _.mapObject(state.entities.codes, (code)=>{
    if ( !code ) return code;
    if ( !project_codes.includes(code.id) ) return code;
    if ( (code.parent_id || null) !== (parent_code_id || null) ) return code;
    if ( code.position < position ) return code;

    return {
      ...code,
      position: (code.position || 1 ) + delta
    }
  })

  return {
    ...state,
    entities: {
      ...state.entities,
      codes
    }
  }

  return state;
}

export default positionReducer
