import codesMapper from 'mappers/CodesMapper'
import flattenCodes from 'mappers/codes/FlattenCodes'

const codesSideMapper = (state, projectID, dragCodeID, searchText) => {
  const result = codesMapper(state, projectID, dragCodeID, searchText);
  const codes = flattenCodes(result.codes, false);
  return {
    ...result,
    codes
  };
}

export default codesSideMapper
