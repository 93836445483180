import React from "react";

import CodePill from './CodePill'
import {Link} from 'react-router-dom'

function TranscriptPillLink(props) {
  const name = props.count ? `${props.name} (${props.count})` :  props.name;
  return (
    <span style={{marginRight:4}}>
      <Link style={{ textDecoration: 'none' }} onClick={props.onClick} to={`/transcripts/${props.id}`}>
        <CodePill
          dark
          name={name}
        />
      </Link>
      {' '}
    </span>

  );
}

export default TranscriptPillLink;
