var _ = require('underscore');
import transcriptMapper from './TranscriptMapper'

const EMPTY_RESULT = {
  show: false,
  code: {},
  excerpts: [],
  transcripts: [],
  transcriptCount: 0,
  canPage: false,
  loading: true
};

const botTranscriptExcerptMapper = (state, transcriptID) => {
  const transcript = transcriptMapper(state, transcriptID);
  if (transcript === null)
    return null; // or return {} if you want to return an empty object

  const { body, ...rest } = transcript;

  // Filter excerpts
  const coderID = 'bot';

  rest.excerpts = rest.excerpts
    .filter(excerpt => 
      (excerpt.codes || []).some(code => 
        (code.coders || []).some(coder => coder.id === coderID)
      )
    )
    .sort((a, b) => {
      return a.start - b.start;
    });

  return rest;
}
export default botTranscriptExcerptMapper

