import {
  CONTEST_MAX_CHARACTERS
} from 'constants/GenericConstants'

import {
  FILES__TOO_BIG,
  FILES__TYPE_UNSUPPORTED,
  FILES__COUNT_LIMIT
} from 'constants/ErrorConstants'

import ErrorUtil from 'utils/ErrorUtil'
import transcriptCleaner from 'utils/TranscriptCleanerUtil'
const FILE_COUNT_LIMIT = 200;
const SUPPORTED_FILE_TYPES = [
  '.txt',
  '.docx',
  !!process.env.PDF_IMPORT_FLAG ? '.pdf' : null
].filter((item)=>!!item)

class FileParser {
  constructor(file) {
    this._file = file;
  }

  getFileName() {
    if ( !this._file ) throw new Error('Empty File');
    if ( !this._file.name ) throw new Error('No File Name');
    return this._file.name;
  }

  getFileReader(reject)  {
    const onabort = () => {reject(new Error('file reading aborted'))};
    const onerror = () => {reject(new Error('file reading error'))};
    const reader = new FileReader()
    reader.onabort = onabort;
    reader.onerror = onerror;
    return reader;
  }

  async uploadPromise(fileName) {
    return new Promise((resolve, reject)=>{
      const reader = this.getFileReader(reject)
      reader.onload = () => {
        this.onload(reader, fileName, resolve, reject)
      }
      this.readAs(reader);
    })
  }

  async upload() {
    const fileName = this.getFileName();

    const response = await this.uploadPromise(fileName);
    if ( !response.body || response.body.length > CONTEST_MAX_CHARACTERS ) {
      throw ErrorUtil.createError(FILES__TOO_BIG)
    }
    return response;
  }
}

export default FileParser;
