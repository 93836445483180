// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';
import CashierStore from 'stores/CashierStore'
import ProjectsStore from 'stores/ProjectsStore'

import {
  Redirect,
} from 'react-router-dom'

class SubscriptionModal extends React.Component {
  constructor () {
    super();
    this.state = {
      ...CashierStore.getSubscriptions(),
      projectState: ProjectsStore.getProjectStatus()
    }

    this._onChange = this._onChange.bind(this);
  }

  _onChange () {
    this.setState({
      ...CashierStore.getSubscriptions(),
      projectState: ProjectsStore.getProjectStatus()
    });
  }

  componentDidMount () {
    CashierStore.addChangeListener(this._onChange);
    ProjectsStore.addChangeListener(this._onChange);
  }

  componentWillUnmount () {
    CashierStore.removeChangeListener(this._onChange);
    ProjectsStore.removeChangeListener(this._onChange);
  }

  redirect() {
    const projectState = this.state.projectState || {};

    if (
      !this.state.subscriptionState.loaded ||
      !projectState.loaded
    )
      return <div></div>

    if ( !!projectState.hasShared )
      return <div></div>

    const subscription = this.state.subscriptionState.subscription;

    if ( !subscription ) {
      return <Redirect to='/intro_survey'/>
    }

    return <div></div>
  }

  render () {
    return (
      <div>
        {this.redirect()}
      </div>
    );
  }
}

export default SubscriptionModal;
