import React from 'react';
import {Link} from 'react-router-dom'
import {Glyphicon} from 'react-bootstrap'
import {DropdownButton, MenuItem} from 'react-bootstrap'
const LEFT_PADDING = 45;
import ModalRow from './ModalRow'
import CollabActions from 'actions/CollabActions'
import QualCodeActions from 'actions/QualCodeActions'
import ArchiveActions from 'project_archives/ArchiveActions'

const WRAP_STYLE = {
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis"
};

function leaveProject(e, projectId)
{
  e.preventDefault();
  CollabActions.leaveProject(projectId).then(()=>{
    QualCodeActions.loadProjects();
  });
}

function archiveProject(e, projectId, errorArchivingProject)
{
  e.preventDefault();
  ArchiveActions.archiveProject(projectId).catch((err)=>{
    errorArchivingProject(true)
  });
}

function ActiveProjectRow(props) {
  /*
    Open can be used to open the native file picker
  */
  if ( !props.project ) return <div></div>
  if ( !props.project.id ) return <div></div>

  const owner = props.project.owner || {};

  return <ModalRow
      row={[
        <div
        style={WRAP_STYLE}
        className='projectRow'
        >
          <Link to={`/projects/${props.project.id}`} onClick={props.onHide}>
            {!props.project.name || props.project.name.length == 0 ? "Untitled Project" : props.project.name}
          </Link>
        </div>,
        <div style={WRAP_STYLE}>{owner.name || owner.email || '-'}</div>,
        <div style={WRAP_STYLE}>{props.project.updated_at}</div>,
        <DropdownButton
          bsStyle="link"
          title={<Glyphicon style={{color:"black"}} glyph='option-vertical'/>}
          id={1}
          noCaret
          pullRight
          style={{padding:0, margin:0}}
        >
          <MenuItem
            eventKey="1"
            onClick={()=>props.duplicateProject(props.project.id)}
          >
              Copy Codes Into New Project
          </MenuItem>
          {!props.project.isOwner &&
            <MenuItem
              eventKey="2"
              onClick={(e)=>leaveProject(e, props.project.id)}
            >
              Leave Project
            </MenuItem>
          }
          {props.project.isOwner && 
            <MenuItem
              eventKey="2"
              onClick={(e)=>archiveProject(
                e,
                props.project.id,
                props.errorArchivingProject)}
            >
              Archive Project
            </MenuItem>
          }
        </DropdownButton>
      ]}
    />
}


export default ActiveProjectRow
