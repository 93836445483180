// NIL means way in the future
const { convertArrayToCSV } = require('convert-array-to-csv');
var _ = require('underscore');
import exportProjectNameMapper from './ExportProjectNameMapper'
import exportExcerptHelper from './ExportExcerptHelper'

const {
  TRANSCRIPT,
  SNIPPET,
  URL,
  CODED_BY,
  CODE,
  START,
  END,
} = require('./ExportConstants')

const HEADER = [TRANSCRIPT, SNIPPET, URL, START, END, CODED_BY, CODE]

const exportCodingsMapper = (state, filterState, projectID) => {
  const host = window && window.location && window.location.host ? window.location.host : "app.delvetool.com";
  let exportExcerpts;

  let descriptorNames = []

  try {
    const exportObjects = exportExcerptHelper(state, filterState, projectID);
    const excerpts = exportObjects.excerpts;
    descriptorNames = exportObjects.descriptorNames;
    
    exportExcerpts = _.chain(excerpts || []).filter((excerpt)=>
      (excerpt.codes || []).length > 0
    ).map((excerpt)=>{
      const result = _.chain(excerpt.codes || []).map(
        (code)=>(code.coders || []).map((coder)=>
          {
            return {
              ...excerpt,
              coder: (coder || {}).name,
              code: code.name || "???",
            }
          }
        )
      ).flatten().value()

      return result;
    }).flatten().value().sort(function (a, b) {
      
      const aName = a.transcriptName.toLowerCase();
      const bName = b.transcriptName.toLowerCase();
      const localeCompare = aName.localeCompare(bName);
      if ( localeCompare != 0 ) return localeCompare;
      return (a.start < b.start) ? -1 : 1;
    }).map((excerpt) => {
      return [
        excerpt.transcriptName,
        excerpt.text,
        excerpt.url,
        excerpt.start,
        excerpt.end,
        excerpt.coder,
        excerpt.code,
        ...excerpt.choices,
      ]
    });
  } catch (e) {
    console.error(e);
    exportExcerpts = '';
  };

  const {exportFileName} = exportProjectNameMapper(state, projectID, 'Codings');

  return {
    projectName: exportFileName,
    exportExcerpts: convertArrayToCSV(
      exportExcerpts,
      {
        separator: ',',
        header: [...HEADER, ...descriptorNames]
      }
    )
  }
}

export default exportCodingsMapper
