// app/javascript/projects/components/DelveBootstrapStyles.jsx

import React from 'react';
import ColorConstants from 'constants/ColorConstants'
import { bootstrapUtils } from 'react-bootstrap/lib/utils';
import {Button} from 'react-bootstrap'

bootstrapUtils.addStyle(Button, 'white');
bootstrapUtils.addStyle(Button, 'blue');
bootstrapUtils.addStyle(Button, 'red');

class DelveBootstrapStyles extends React.Component {
  constructor () {
    super();
  }
  render () {
    return (
      <div>
        <style type="text/css">{`
          .btn-blue, .btn-blue:hover, .btn-blue:focus {
              background-color: ${ColorConstants.ACTIVE_BUTTON_BLUE};
              color: #ffffff;
              border-color: ${ColorConstants.ACTIVE_BUTTON_BLUE};
          }
        `}</style>
        <style type="text/css">{`
          .btn-blue-drop, .btn-blue-drop:hover, .btn-blue-drop:focus {
              background-color: ${ColorConstants.ACTIVE_BUTTON_BLUE};
              color: #ffffff;
              border-color: ${ColorConstants.ACTIVE_BUTTON_BLUE};
              margin:1px;
          }
        `}</style>
        <style type="text/css">{`
          .btn-white-drop, .btn-white-drop:hover, .btn-white-drop:focus {
            background-color: white;
            color: black;
            border-color: ${ColorConstants.LIGHT_GREY};
            margin:1px;
          }
        `}</style>
        <style type="text/css">{`
        .btn-white, .btn-white:hover, .btn-white:focus{
          background-color: white;
          color: black;
          border-color: ${ColorConstants.LIGHT_GREY};
        }
        `}</style>
        <style type="text/css">{`
        .btn-red, .btn-red:hover, .btn-red:focus{
          background-color: ${ColorConstants.ERROR_TEXT};
          color: #ffffff;
          border-color: ${ColorConstants.ERROR_TEXT};
        }
        `}</style>
      </div>
    );
  }
}

export default DelveBootstrapStyles;
