import OptimisticMapperHelper from 'mappers/OptimisticMapperHelper';
const _ = require('underscore');

function optimisticMemoReducer(state, excerptId, clientId)
{
  if ( !excerptId || !clientId ) return state;

  excerptId = OptimisticMapperHelper.getExcerptId(state, excerptId);
  const excerpt = OptimisticMapperHelper.getExcerpt(state, excerptId) || {};
      
  // remove the memo with the client id
  const newMemoIds = _.without(excerpt.memos || [], clientId);
  delete state.entities.memos[clientId];

  return {
    ...state,
    entities: {
      ...state.entities,
      excerpts: {
        ...state.entities.excerpts,
        [excerptId]: {
          ...excerpt,
          memos: newMemoIds,
        },
      }
    }
  }
}

export default optimisticMemoReducer
