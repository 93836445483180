import {
  NOT_LOADING_STATE,
  LOADING_DICT_DEFAULT,
} from 'constants/LoadingConstants'

import {
  SUBSCRIBE,
  SUBSCRIBE_ERROR,
  FETCH_SUBSCRIPTIONS_ERROR,
  FETCH_PLANS_ERROR,
  FETCH_SEATS_ERROR,
  ADD_SEAT,
  ADD_SEAT_ERROR,
  REMOVE_SEAT,
  REMOVE_SEAT_ERROR,
  CANCEL_SUBSCRIPTION,
  CANCEL_SUBSCRIPTION_ERROR,
  UNCANCEL_SUBSCRIPTION,
  UNCANCEL_SUBSCRIPTION_ERROR,
} from 'constants/CashierConstants'

import {
  CREATE_UPGRADE,
  CREATE_UPGRADE_ERROR
} from 'constants/UpgradeConstants'

import {
  AUTH_RESET_PASSWORD_ERROR,
  AUTH_FORGOT_PASSWORD_ERROR,
  AUTH_ACCEPT_INVITE_ERROR,
} from 'constants/AuthConstants'

import {
  UPDATE_TRANSCRIPT_TEXT_ERROR,
  CREATE_EXCERPT_ERROR,
} from 'constants/QualConstants'

export default  {
  entities:
  {
    descriptors:{},
    memos:{},
    multi_choices:{},
    selections:{},
    transcripts:{},
    projects:{},
    codes:{},
    upgrades:{},
    user:{},
    users:{},
    excerpts:{},
    subscriptions:{},
    plans:{},
    seats:{},
  },
  // Stores Expand/Collapsed Code Book
  codeBar:{},
  codeBook:{},
  // Stores whether to show the code page
  codePageModal:{
    show: false,
    codeID: null
  },
  // Stores what the filters are on the filtered page
  dragDrop:{
    show: false,
    files:{}
    /*
    files:{
      5: {
        id: 5,
        fileName: 'LoadingFile.txt'
      },
      8: {
        id: 8,
        fileName: 'ErrorFile.txt',
        error: {
          message: 'File size too big',
          type: 'FileSizeTooBig'
        }
      },
      9: {
        id: 9,
        fileName: 'LoadedFile.txt',
        loaded: true,
      }
    }*/
  },
  inviteState:{
    lastInvite: null,
    success:false,
  },
  // Stores whether there is an error in the login state
  loginState:{
    error:{
      show: false,
    }
  },
  // Stores loading information for multiple locations
  loadingState:
  {
    transcriptDetails:{},
    projectDetails:{},
    codes:{},
  },
  mergeCodes: {},
  // ?? I remember this is required to know if you need to redirect on the new project page
  newProject:{
    show:false
  },
  // keeping track of the edit transcript situation
  editTranscript:{
    saving: false,
    paragraphIndex: null,
  },
  // onboarding state
  onboardingState:{
    getStarted: false,
  },
  // errors in the password state
  passwordState:{
    show: false,
  },
  // maps project to descriptor (probably this should be stored in entities)
  projectToDescriptor:{},
  // project list and loading info
  projectsList:{
    projects: [],
    loaded: false
  },
  resetState:{
    lastReset: null,
    success:{
      message: null,
    }
  },
  // search term, results, and loading
  search: {
    searchTerm: null,
    results: null,
    loadState: NOT_LOADING_STATE
  },
  // share error
  shareState:{
    show: false,
    message: null
  },
  // signup error
  signupState:{
    show: false,
  },
  // loading subscription
  subscriptionState:
  {
    loading: false,
    loaded: false,
    subscriptions:[],
  },
  teamModal:{
    page:null,
  },
  // transcript to selection, this should probably be in entities
  transcriptToSelection:{},
  // generic error storage
  error: {
    error: false,
    message: null
  },
  // keeping a dictionary of feedback
  errorDict: {
    [SUBSCRIBE_ERROR]:{},
    [FETCH_SUBSCRIPTIONS_ERROR]:{},
    [FETCH_PLANS_ERROR]:{},
    [FETCH_SEATS_ERROR]:{},
    [ADD_SEAT_ERROR]:{},
    [REMOVE_SEAT_ERROR]:{},
    [CANCEL_SUBSCRIPTION_ERROR]:{},
    [UNCANCEL_SUBSCRIPTION_ERROR]:{},
    [AUTH_RESET_PASSWORD_ERROR]:{},
    [AUTH_FORGOT_PASSWORD_ERROR]:{},
    [AUTH_ACCEPT_INVITE_ERROR]:{},
    [UPDATE_TRANSCRIPT_TEXT_ERROR]:{},
    [CREATE_EXCERPT_ERROR]:{},
    [CREATE_UPGRADE_ERROR]:{}
  },
  loadingDict: {
    [ADD_SEAT]:LOADING_DICT_DEFAULT,
    [CANCEL_SUBSCRIPTION]:LOADING_DICT_DEFAULT,
    [SUBSCRIBE]:LOADING_DICT_DEFAULT,
    [UNCANCEL_SUBSCRIPTION]:LOADING_DICT_DEFAULT,
    [REMOVE_SEAT]:LOADING_DICT_DEFAULT,
    [CREATE_UPGRADE]:LOADING_DICT_DEFAULT
  },
  // Optimistic Mappers
  mappers:
  {
    codes:{},
    excerpts:{}
  },
}
