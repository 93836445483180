import metaSubscriptionMapper from 'mappers/MetaSubscriptionMapper'

import {
  REMOVE_SEAT,
} from 'constants/CashierConstants'

const removeSeatMapper = (state) => {
  return {
    removeSeat:metaSubscriptionMapper(state, REMOVE_SEAT)
  }
}

export default removeSeatMapper
