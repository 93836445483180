// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';
import HeaderDropdown from './HeaderDropdown'
import {MenuItem} from 'react-bootstrap'
import EditableName from '../EditableName'
import ShareHeader from './ShareHeader'
import SubscribeHeader from './SubscribeHeader'
import BotModal from '../BotModal'
import DeleteTranscriptModal from '../modal/DeleteTranscriptModal';


class TranscriptHeader extends React.Component {
  render () {
    const subscriptionState = this.props.subscriptionState || {};
    const subscription = subscriptionState.subscription || {};

    if ( !this.props.roleIsEdit && !!this.props.isActive ) {
      return (<ShareHeader
        name={this.props.title || 'Untitled'}
        hasEditPermission={this.props.hasEditPermission}
        subscriptionState={this.props.subscriptionState}
      />)
    }

    if ( subscriptionState.isCancelled ) {
      return <SubscribeHeader
        name={this.props.title || 'Untitled'}
        isSubscriptionOwner={subscriptionState.isOwner}
        onRenew={this.props.onRenew}
        isSeparatelyInvoiced={subscription.isSeparatelyInvoiced}
      />
    }

    if ( !this.props.hasEditPermission ) {
      return (<ShareHeader
        name={this.props.title || 'Untitled'}
        hasEditPermission={this.props.hasEditPermission}
        subscriptionState={this.props.subscriptionState}
      />)
    }

    const aiMenuItems = !!process.env['AI_FEATURE_FLAG'] ? [
      {
        title: 'Apply Codes Using AI',
        onClick: this.props.aiCodings
      }
    ] : []

    const menuItemObjects = [
      ...aiMenuItems,
      {
        title: 'Export Transcript (.txt)',
        onClick: this.props.exportTranscript
      },
      {
        title: 'Export Transcript with Codes (.pdf)',
        onClick: this.props.printTranscript
      },
      {
        title: 'Delete Transcript',
        onClick: this.props.deleteModalClicked
      }
    ]

    const menuItems = menuItemObjects.map((item, i) => {
      return (
        <MenuItem
          key={i}
          eventKey={i}
          onClick={item.onClick}
        >
          {item.title}
        </MenuItem>
      )
    })

    return (
      <div style={{
        width:"100%",
        display:"flex",
        flexWrap:"nowrap",
        }}>
        <div style={{
          flexGrow:1,
        }}>
          <EditableName
            title={this.props.title}
            onSave={(title) => this.props.onTitleChange(title)}
            placeholder="Untitled"
          />
        </div>

        <div
          style={{
            flexBasis: 'auto',
            flexGrow: 0,
            flexShrink: 0}}
        >
          <HeaderDropdown
            projectID={this.props.projectID}
            hasEditPermission={this.props.hasEditPermission}
            subscriptionState={this.props.subscriptionState}
            onShowCollabModal={this.props.onShowCollabModal}
            showCollabModal={this.props.showCollabModal}
          >
            {menuItems}            
          </HeaderDropdown>
        </div>
        <DeleteTranscriptModal
          showModal={this.props.showModal}
          cancelDelete={this.props.cancelDelete}
          confirmDelete={this.props.confirmDelete}
        />
        <BotModal 
          transcriptID={this.props.transcriptID}
          show={this.props.showDeductiveModal}
          hideModal={this.props.hideAiCodings}
          projectID={this.props.projectID}
        />
      </div>
    );
  }
}

export default TranscriptHeader;
