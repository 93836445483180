// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';
import Header from './Header'
import HeaderDropdown from './HeaderDropdown'
import ShareHeader from './ShareHeader'
import SubscribeHeader from './SubscribeHeader'

class DropdownHeader extends React.Component {
  render () {

    const subscriptionState = this.props.subscriptionState || {};
    const subscription = subscriptionState.subscription || {};

    // view only person
    if ( !this.props.roleIsEdit && !!this.props.isActive ) {
      return <ShareHeader
        name={this.props.name}
        hasEditPermission={this.props.hasEditPermission}
        subscriptionState={this.props.subscriptionState}
        onShowCollabModal={this.props.onShowCollabModal}
        showCollabModal={this.props.showCollabModal}
      />
    }

    // cancelled person
    if ( subscriptionState.isCancelled ) {
      return <SubscribeHeader
        name={this.props.name}
        isSubscriptionOwner={subscriptionState.isOwner}
        onRenew={this.props.onRenew}
        isSeparatelyInvoiced={subscription.isSeparatelyInvoiced}
        onShowCollabModal={this.props.onShowCollabModal}
        showCollabModal={this.props.showCollabModal}
      />
    }

    // TODO: does this happen?
    if ( !this.props.hasEditPermission ) {
      return (
        <ShareHeader
          name={this.props.name}
          hasEditPermission={this.props.hasEditPermission}
          subscriptionState={this.props.subscriptionState}
          onShowCollabModal={this.props.onShowCollabModal}
          showCollabModal={this.props.showCollabModal}  
        />
      )
    }

    return (
      <div style={{width:"100%"}}>
        <div style={{float:"left"}}>
          <Header name={this.props.name}/>
        </div>

        <HeaderDropdown
          projectID={this.props.projectId}
          hasEditPermission={this.props.hasEditPermission}
          subscriptionState={this.props.subscriptionState}
          onShowCollabModal={this.props.onShowCollabModal}
          showCollabModal={this.props.showCollabModal}  
        >
          {this.props.children}
        </HeaderDropdown>
      </div>
    );
  }
}

export default DropdownHeader;
