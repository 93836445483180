import {
  LOADING_STATE,
  LOADED_STATE
} from 'constants/LoadingConstants'

import OptimisticMapperHelper from 'mappers/OptimisticMapperHelper';

function _setItemMapper (newMapper, itemType, state) {
  return {
    ...state,
    mappers: {
      ...state.mappers,
      [itemType]: newMapper
    }
  }
}

function setCodeMapper (newCodeMapper, state) {
  return _setItemMapper(newCodeMapper, 'codes', state)
}

function updateCodeMapper (newCodeMapper, state) {
  return setCodeMapper({
    ...state.mappers.codes,
    ...newCodeMapper
  }, state)
}

function setExcerptMapper (newExcerptMapper, state) {
  return _setItemMapper(newExcerptMapper, 'excerpts', state)
}

function updateExcerptMapper (newExcerptMapper, state) {
  return setExcerptMapper({
    ...state.mappers.excerpts,
    ...newExcerptMapper
  }, state)
}

// this will take any new codes and add them to the code mapper
// if the code already had a mapper it will be overwritten and thus stay the same
function _codeMapper(newCodes, state)
{
  return {
    ...newCodes.reduce((acc, codeID) => {
      acc[codeID] = codeID;
      return acc;
    }, {}),
    ...state.mappers.codes,
  }
}

function _mapper(newCodes, state)
{
  return {
    ...state.mappers,
    codes:_codeMapper(newCodes, state)
  }
}

function addCodesToMapper(newCodes, state) {
  return {
    ...state,
    mappers: _mapper(newCodes, state)
  }
}

function _setAllItems(items, itemType, state) {
  return {
    ...state,
    entities: {
      ...state.entities,
      [itemType]: items
    }
  }
}

function setCodes(codes, state) {
  return _setAllItems(codes, 'codes', state)
}

function updateCodes(newCodes, state) {
  return setCodes({
    ...state.entities.codes,
    ...newCodes
  }, state)
}

function setExcerpts(excerpts, state) {
  return _setAllItems(excerpts, 'excerpts', state)
}

function _setItem(id, item, itemType, state) {
  if ( !item ) 
    return state;
  
  return {
    ...state,
    entities: {
      ...state.entities,
      [itemType]: {
        ...state.entities[itemType],
        [id]: item
      }
    }
  }
}

function setCode(id, code, state) {
  return _setItem(id, code, 'codes', state)
}

function updateCode(codeId, newCode, state) {
  const code = OptimisticMapperHelper.getCode(state, codeId);
  const id = OptimisticMapperHelper.getCodeIdOrOriginal(state, codeId);
  return setCode(id, {...code, ...newCode, id}, state);
}

function setExcerpt(id, excerpt, state) {
  return _setItem(id, excerpt, 'excerpts', state)
}

function updateExcerpt(id, newExcerpt, state) {
  const excerpt = OptimisticMapperHelper.getExcerpt(state, id);
  const excerptId = OptimisticMapperHelper.getExcerptIdOrOriginal(state, id);
  
  return setExcerpt(id, {...excerpt, ...newExcerpt, id: excerptId}, state);
}

function setProject(id, project, state) {
  return _setItem(id, project, 'projects', state)
}

function updateProject(id, newProject, state) {
  const project = state.entities.projects[id];
  return setProject(id, {...project, ...newProject}, state);
}


function _setCodeLoadState(projectID, loadState, state) {
  return {
    ...state,
    loadingState: {
      ...state.loadingState,
      codes: {
        ...state.loadingState.codes,
        [projectID]: loadState
      }
    }
  }
}

function setLoadedCodes(projectID, state) {
  return _setCodeLoadState(projectID, LOADED_STATE, state)
}

function setLoadingCodes(projectID, state) {
  return _setCodeLoadState(projectID, LOADING_STATE, state)
}

function setTranscript(id, transcript, state) {
  return _setItem(id, transcript, 'transcripts', state)
}

function updateTranscript(id, newTranscript, state) {
  const transcript = state.entities.transcripts[id];
  return setTranscript(id, {...transcript, ...newTranscript}, state);
}

function setMemo(id, memo, state) {
  return _setItem(id, memo, 'memos', state)
}


export {
  addCodesToMapper,
  setCodeMapper,
  updateCodeMapper,
  setExcerptMapper,
  updateExcerptMapper,
  setCodes,
  setCode,
  updateCode,
  updateCodes,
  setExcerpt,
  setExcerpts,
  updateExcerpt,
  setProject,
  updateProject,
  setLoadedCodes,
  setLoadingCodes,
  setTranscript,
  updateTranscript,
  setMemo
}
