// app/javascript/projects/components/CodeBookH2.jsx

import React from 'react';
import {FormControl, ControlLabel, FormGroup, InputGroup} from 'react-bootstrap'
import QualCodeActions from 'actions/QualCodeActions'
import {Link} from 'react-router-dom'
import ColorConstants from 'constants/ColorConstants'
import CodeBookH from './CodeBookH'
import CodeBookH3 from './CodeBookH3'

class CodeBookH2 extends React.Component {
  constructor () {
    super();
    this.state = {}
  }

  renderChildren(codes)
  {
    codes = codes ? codes : [];
    return codes.map((code, index) =>{
      return <CodeBookH3
                key={code.id}
                code={code}
                projectID={this.props.projectID}
                hasEditPermission={this.props.hasEditPermission}
              />
    });
  }

  startRecursiveRender(code)
  {
    const childComponents = this.renderChildren(code.children);
    return (
      <div style={{paddingLeft: 40}}>
        <CodeBookH code={code}
          projectID={this.props.projectID}
          hasEditPermission={this.props.hasEditPermission}
          />
        {childComponents}
      </div>
    );
  }

  render () {
    return this.startRecursiveRender(this.props.code);
  }
}

export default CodeBookH2;
