// app/javascript/projects/components/ShareToCompare.jsx

import React from 'react';
import GenericOnboardingMessage from './GenericOnboardingMessage';

class ShareToCompare extends React.Component {
  constructor()
  {
    super();
    this.onClick = this.onClick.bind(this);
  }

  onClick(e)
  {
    this.props.onShowCollabModal(true);
  }

  render () {
    return (<GenericOnboardingMessage
      header='Coding Comparison'
      body='Compare how different people coded the same transcript.'
      secondBody='Share project to get started.'
      buttonText="Share Project"
      onClick={this.onClick}
      intercomArticleId={process.env['INTERCOM_ARTICLE_CODING_COMPARISON']}
      helpArticleText='Learn more about coding comparison.'
    />)
  }
}

export default ShareToCompare;
