// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';
import Memo from './Memo'

function MemoList(props) {
  const expandedMemoList = props.expandedMemoList || [];
  
  const memos = (props.memos || []).map((memo) => {
   return {
      ...memo,
      expanded: expandedMemoList.includes(memo.id)
   } 
  });

  return (
    <div>
      {memos.map((memo, index) => {
        return (
          <Memo
            key={memo.id}
            text={memo.text}
            name={memo.name}
            initials={memo.initials}
            time={memo.created_at}
            color={memo.color}
            id={memo.id}
            memoExpanded={props.memoExpanded}
            isExpanded={memo.expanded}
            hasEditPermission={props.hasEditPermission}
          />
        );
      })}
    </div>
  )
}

export default MemoList;