const _ = require('underscore');
import createOverlappingExcerpts from './OverlappingExcerptMapper';
import calculateInterCoder from './CalculateInterCoder';
import interCoderStats from './InterCoderStats';

function interCoderMapper(state, transcriptId) {
  // convert excerpts hash to array, and pull the ones that match the transcriptId
  // use undescore module to filter the array and pull the values
  const excerpts = _.chain(state.entities.excerpts).values().filter((excerpt) => {
    return excerpt.transcript_id === transcriptId;
  }).value(); // convert excerpts array to array of excerpt ids
  
  const overlappingArray = createOverlappingExcerpts(state, excerpts);

  // filter out any objects where code_count does not have any keys
  // also the sum of the code count values must be greater than 1
  // TODO: make this better
  const filteredOverlappingArray = overlappingArray.filter((obj) => {
    if ( Object.keys(obj.code_count).length == 0 ) return false;
    if ( Object.values(obj.code_count).reduce((a, b) => a + b, 0) < 2 ) return false;
    return true
  });
  const score = calculateInterCoder(filteredOverlappingArray);
  const stats = interCoderStats(excerpts);
  return {
    score,
    ...stats
  };
}

export default interCoderMapper;
