import { normalize, schema } from 'normalizr';
import InitialState from 'constants/InitialState'
import LoadingConstants from 'constants/LoadingConstants'
import subscriptionReducer from './SubscriptionReducer'

var _ = require('underscore');

import {
  FETCH_SUBSCRIPTIONS,
  FETCH_SUBSCRIPTIONS_RESULT,
  FETCH_SUBSCRIPTIONS_ERROR,
  SUBSCRIBE,
  SUBSCRIBE_RESPONSE,
  SUBSCRIBE_ERROR,
  FETCH_PLANS_RESPONSE,
  FETCH_PLANS_ERROR,
  FETCH_SEATS_RESPONSE,
  FETCH_SEATS_ERROR,
  ADD_SEAT,
  ADD_SEAT_RESPONSE,
  ADD_SEAT_ERROR,
  REMOVE_SEAT,
  REMOVE_SEAT_RESPONSE,
  REMOVE_SEAT_ERROR,
  CANCEL_SUBSCRIPTION,
  CANCEL_SUBSCRIPTION_RESPONSE,
  CANCEL_SUBSCRIPTION_ERROR,
  UNCANCEL_SUBSCRIPTION,
  UNCANCEL_SUBSCRIPTION_RESPONSE,
  UNCANCEL_SUBSCRIPTION_ERROR,
  TEAM_MODAL_PAGE,
  CLEAR_CASHIER_ERRORS,
} from 'constants/CashierConstants'

import {
  CREATE_UPGRADE,
  CREATE_UPGRADE_ERROR
} from 'constants/UpgradeConstants'

import {
  LOADED_STATE,
  LOADING_STATE,
} from 'constants/LoadingConstants'

import AccountPageConstants from 'constants/AccountPageConstants'

const plan_schema = new schema.Entity('plans');
const plans_schema = new schema.Entity('plans_schema', {
  plans:[plan_schema]
});

const subscription_schema = new schema.Entity('subscriptions', {
  plan: plan_schema
});
const subscriptions_schema = new schema.Entity('subscription_list', {
  subscriptions:[subscription_schema]
});

const user_schema = new schema.Entity("users");

const seat_schema  = new schema.Entity("seats", {
  user: user_schema
})

const seats_schema = new schema.Entity('subscriptions', {
  seats:[seat_schema]
})

const single_seat_schema = new schema.Entity('subscriptions', {
  seat:seat_schema,
})

function clearErrorState(state, type)
{
  return {
    ...state,
    errorDict:{
      ...state.errorDict,
      [type]:{},
    }
  }
}

function getErrorState(state, action)
{
  const errors = action.errors ? action.errors : [];
  const error = errors.length > 0 ? errors[0] : {};

  return {
      ...state,
      errorDict:{
        ...state.errorDict,
        [action.actionType]:{
          errorCode:error.code ? error.code : null,
          message:error.detail && error.detail.length > 0 ? error.detail :
                                  error.title ? error.title : null,
          error: true,
        }
      }
    }
}

function cashierReducer(action, state = InitialState)
{
  if ( !action ) return state;

  switch (action.actionType)
  {
    case FETCH_SUBSCRIPTIONS:
    {
      return {
        ...state,
        subscriptionState:
        {
          ...state.subscriptionState,
          loading: true,
        }
      }
    }
    break;
    case FETCH_SUBSCRIPTIONS_RESULT:
    {
      const normalizedData = normalize(action.data, subscriptions_schema);
      const subscriptionList = normalizedData.entities.subscription_list.undefined.subscriptions;
      const subscriptionEntities = normalizedData.entities.subscriptions ? normalizedData.entities.subscriptions : {};
      var finalSubscriptions = {};

      subscriptionList.map((subID)=>{
        const existingSubscription = state.entities.subscriptions[subID] ? state.entities.subscriptions[subID] : {};
        const newSubscription = subscriptionEntities[subID] ? subscriptionEntities[subID] : {};
        const mergedSubscription =  {
          ...existingSubscription,
          ...newSubscription
        }

        if ( mergedSubscription.id )
          finalSubscriptions[mergedSubscription.id] = mergedSubscription
      })

      return {
        ...state,
        entities:{
          ...state.entities,
          subscriptions: finalSubscriptions,
          plans:{
            ...state.entities.plans,
            ...normalizedData.entities.plans,
          }
        },
        subscriptionState:
        {
          loading: false,
          loaded: true,
          subscriptions:subscriptionList,
        },
        errorDict:{
          ...state.errorDict,
          [FETCH_SUBSCRIPTIONS_ERROR]:{}
        }
      }

      return state;
    }
    case FETCH_SUBSCRIPTIONS_ERROR:
    {
      return {
        ...getErrorState(state, action),
        subscriptionState:
        {
          ...state.subscriptionState,
          loading: false,
        }
      }
    }
    case CANCEL_SUBSCRIPTION_RESPONSE:
    {
      const subscriptionState = subscriptionReducer(state, action)

      return {
        ...subscriptionState,
        teamModal:{
          page:AccountPageConstants.PLAN_AND_BILLING,
        },
        errorDict:{
          ...subscriptionState.errorDict,
          [CANCEL_SUBSCRIPTION_ERROR]:{},
        },
        loadingDict:{
          ...subscriptionState.loadingDict,
          [CANCEL_SUBSCRIPTION]:LoadingConstants.LOADING_DICT_LOADED(),
        }
      }
    }
    break;
    case UNCANCEL_SUBSCRIPTION_RESPONSE:
    {
      const subscriptionState = subscriptionReducer(state, action);

      return {
        ...subscriptionState,
        teamModal:{
          page:AccountPageConstants.PLAN_AND_BILLING,
        },
        errorDict:{
          ...subscriptionState.errorDict,
          [UNCANCEL_SUBSCRIPTION_ERROR]:{},
        },
        loadingDict:{
          ...subscriptionState.loadingDict,
          [UNCANCEL_SUBSCRIPTION]:LoadingConstants.LOADING_DICT_LOADED(),
        }
      }
    }
    break;
    case SUBSCRIBE_RESPONSE:
    {
      const subscriptionState = subscriptionReducer(state, action);

      return {
        ...subscriptionState,
        errorDict:{
          ...subscriptionState.errorDict,
          [SUBSCRIBE_ERROR]:{},
        },
        loadingDict:{
          ...subscriptionState.loadingDict,
          [SUBSCRIBE]:LoadingConstants.LOADING_DICT_LOADED(),
        }
      }
    }
    break;
    case SUBSCRIBE_ERROR:
    case CANCEL_SUBSCRIPTION_ERROR:
    case UNCANCEL_SUBSCRIPTION_ERROR:
    case REMOVE_SEAT_ERROR:
    case ADD_SEAT_ERROR:
    case CREATE_UPGRADE_ERROR:
    {
      var state = getErrorState(state, action);
      var loadingAction = action.actionType.replace("_ERROR","");
      return {
        ...state,
        loadingDict: {
          ...state.loadingDict,
          [loadingAction]:{
            ...state.loadingDict[loadingAction],
            loading:false,
          }
        }
      }
    }
    break;
    case FETCH_PLANS_ERROR:
    case FETCH_SEATS_ERROR:
    {
      return getErrorState(state, action);
    }
    break;
    case FETCH_PLANS_RESPONSE:
    {
      const normalizedData = normalize(action.data, plans_schema);
      const plans = normalizedData.entities.plans ? normalizedData.entities.plans : {};

      return {
        ...state,
        entities:{
          ...state.entities,
          plans: plans,
        },
        errorDict:{
          ...state.errorDict,
          [FETCH_PLANS_ERROR]:{},
        }
      }
    }
    break;
    case FETCH_SEATS_RESPONSE:
    {
      const normalizedData = normalize(action.data, seats_schema);
      const subscriptionID = normalizedData.result;
      const existingSubscription = state.entities.subscriptions[subscriptionID] ? state.entities.subscriptions[subscriptionID] : {};

      state = clearErrorState(state, FETCH_SEATS_ERROR);

      return {
        ...state,
        entities:
        {
          ...state.entities,
          users:
          {
            ...state.entities.users,
            ...normalizedData.entities.users,
          },
          seats:
          {
            ...normalizedData.entities.seats,
          },
          subscriptions:
          {
            ...state.entities.subscriptions,
            [subscriptionID]:
            {
              ...existingSubscription,
              ...normalizedData.entities.subscriptions[subscriptionID]
            }
          },
        },
      }
    }
    break;
    case REMOVE_SEAT_RESPONSE:
    {
      const normalizedData = normalize(action.data, seats_schema);
      const subscriptionID = normalizedData.result;
      const existingSubscription = state.entities.subscriptions[subscriptionID] ? state.entities.subscriptions[subscriptionID] : {};

      return {
        ...state,
        entities:
        {
          ...state.entities,
          users:
          {
            ...state.entities.users,
            ...normalizedData.entities.users,
          },
          seats:
          {
            ...normalizedData.entities.seats,
          },
          subscriptions:
          {
            ...state.entities.subscriptions,
            [subscriptionID]:
            {
              ...existingSubscription,
              ...normalizedData.entities.subscriptions[subscriptionID]
            }
          },
        },
        teamModal:{
          page:AccountPageConstants.MAIN_PAGE,
        },
        errorDict:{
          ...state.errorDict,
          [REMOVE_SEAT_ERROR]:{},
        },
        loadingDict:{
          ...state.loadingDict,
          [REMOVE_SEAT]:LoadingConstants.LOADING_DICT_LOADED()
        }
      }
    }
    break;
    case ADD_SEAT_RESPONSE:
    {
      const normalizedData = normalize(action.data, single_seat_schema);
      const subscriptionID = normalizedData.result;
      const currentSubscription = state.entities.subscriptions[subscriptionID] ? state.entities.subscriptions[subscriptionID] : {};
      const newSubscription = normalizedData.entities.subscriptions[subscriptionID];
      const newSeatID = newSubscription.seat;
      var seats = currentSubscription.seats ? currentSubscription.seats.slice() : [];
      if ( !seats.includes(newSeatID) )
        seats.push(newSeatID);

      return {
        ...state,
        entities:{
          ...state.entities,
          users:{
            ...state.entities.users,
            ...normalizedData.entities.users,
          },
          seats:{
            ...state.entities.seats,
            ...normalizedData.entities.seats,
          },
          subscriptions:{
            ...state.entities.subscriptions,
            [subscriptionID]:{
              ...currentSubscription,
              id: subscriptionID,
              seats: seats,
              quantity: newSubscription.quantity,
            }
          },
        },
        teamModal:{
          page:AccountPageConstants.MAIN_PAGE,
        },
        errorDict:{
          ...state.errorDict,
          [ADD_SEAT_ERROR]:{},
        },
        loadingDict:{
          ...state.loadingDict,
          [ADD_SEAT]:LoadingConstants.LOADING_DICT_LOADED(),
        }
      }
      return state;
    }
    break;
    case TEAM_MODAL_PAGE:
    {
      const data = action.data;
      return {
        ...state,
        teamModal:data,
        errorDict:{
          ...state.errorDict,
          [ADD_SEAT_ERROR]:{},
          [REMOVE_SEAT_ERROR]:{},
        }
      }
    }
    break;
    case CLEAR_CASHIER_ERRORS:
    {
      return {
        ...state,
        errorDict:{
          ...state.errorDict,
          [ADD_SEAT_ERROR]:{},
          [REMOVE_SEAT_ERROR]:{},
        }
      }
    }
    break;
    case ADD_SEAT:
    case SUBSCRIBE:
    case CANCEL_SUBSCRIPTION:
    case UNCANCEL_SUBSCRIPTION:
    case REMOVE_SEAT:
    case CREATE_UPGRADE:
    {
      return {
        ...state,
        loadingDict:{
          ...state.loadingDict,
          [action.actionType]:{
            ...state.loadingDict[action.actionType],
            loading:true,
          }
        }
      }
    }
    break;
    default:
      return state;
  }
}

export default cashierReducer
