// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';
import QualCodeActions from 'actions/QualCodeActions'
import {DropdownButton, MenuItem, Glyphicon, Modal, Button} from 'react-bootstrap'
import ProjectsStore from 'stores/ProjectsStore'
import {CONTENT_MIN_WIDTH, RIGHT_WIDTH} from 'constants/DimensionsConstants'

class EditableName extends React.Component {
  constructor () {
    super();
    this.state = {
      title: '',
      focused: false,
    };

    this.onTitleChange = this.onTitleChange.bind(this);
    this.onTitleBlur = this.onTitleBlur.bind(this);
    this.onTitleFocus = this.onTitleFocus.bind(this);
    this.onKeyUp = this.onKeyUp.bind(this);
  }

  onTitleChange(e) {
    this.setState({
      title: e.target.value
    })
  }

  onTitleFocus(e) {
    this.setState({
      focused: true
    })
  }

  onTitleBlur(e) {
    if ( this.state.title &&
      this.state.title.length > 0 &&
      this.state.title != this.props.title )
    {
      this.props.onSave(this.state.title);
    }

    this.setState({
      focused: false
    })
  }

  saveTitle(e) {
    if ( document.activeElement )
      document.activeElement.blur();
  }

  componentDidMount()
  {
    if ( this.props.title )
    {
      this.setState({
        title: this.props.title
      })
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const prevTitle = prevProps.title;
    const currentTitle = this.props.title;

    if ( currentTitle != prevTitle)
    {
      this.setState({
        title: currentTitle
      })
    }
  }

  onKeyUp(e) {
    if ( e.key === 'Enter')
      this.saveTitle();
  }

  render () {
    return (
        <input style={{
                      fontSize:"1.5em",
                      borderStyle:"none",
                      outline:"none",
                      width:"100%",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis"
                    }}
                value={!this.state.focused && this.props.blurredPostfix ? this.state.title + this.props.blurredPostfix : this.state.title}
                onBlur={this.onTitleBlur}
                onChange={this.onTitleChange}
                onFocus={this.onTitleFocus}
                placeholder={this.props.placeholder}
                onKeyUp={this.onKeyUp}
              />
    );
  }
}

export default EditableName;
