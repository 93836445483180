import projectToDescriptorMapper from './descriptors/ProjectToDescriptorMapper';
import getFilteredExcerpts from './filters/FilteredExcerptMapper'
import getCheckedIds from './filters/CheckedDescriptorMapper'

const _getChoiceToCount = (state, filterState, projectID) => {
  const projects = filterState.projects || {};
  const project = projects[projectID] || {};
  return (project.counts || {}).multi_choice_ids || {};
}

const analysisDescriptorMapper = (state, filterState, projectID) => {
  try {
    const nullState = {descriptors:[]};
    if ( !projectID ) nullState;
    if ( !state.projectToDescriptor[projectID]) return nullState;

    const {descriptors} = projectToDescriptorMapper(state, projectID);
    const choiceToCount = _getChoiceToCount(state, filterState, projectID);

    const filteredDescriptors = descriptors.map((descriptor)=>{
      const checkedIDs = getCheckedIds(filterState, projectID, descriptor.id)

      return {
        ...descriptor,
        multi_choices: descriptor.multi_choices.map((choice)=>{
          const count = choice.id in choiceToCount ? choiceToCount[choice.id] : 0;
          return {
            ...choice,
            checked: checkedIDs.includes(choice.id),
            count: count
          }
        })
      }
    });

    return {descriptors: filteredDescriptors};
  } catch (e) {
    console.error(e);
    return {descriptors:[]};
  }
}

export default analysisDescriptorMapper
