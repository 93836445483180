import API from 'apis/API'

var ChatAPI = {
  // create memo
  createConversation: async function(project_id, content) {
    return API.post(`/api/projects/${project_id}/conversations`, {
      message: {
        content: content,
      }
    });
  },
  createAIConversation: async function(conversation_id, checkedCodes, op, transcripts) {
    return API.post(`/api/conversations/${conversation_id}/messages/ai`, {
      code_ids: checkedCodes,
      op: op,
      transcript_ids: transcripts
    });
  },
  createMessage: async function(conversation_id, content) {
    return API.post(`/api/conversations/${conversation_id}/messages`, {
      content: content,
    });
  },
  getConversation: async function(conversation_id) {
    return API.get(`/api/conversations/${conversation_id}/messages`);
  },
  getConversations: async function(project_id) {
    return API.get(`/api/projects/${project_id}/conversations`);
  }
};

export default ChatAPI
