// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';
import {MenuItem, DropdownButton} from 'react-bootstrap'
import TranscriptMenuItem from './TranscriptMenuItem';

import {
  TRANSCRIPT_TITLE,
  DEMOGRAPHICS_TITLE,
  CODED_BY_ME_TITLE,
  COMPARISON_TITLE,
} from 'constants/TranscriptNavigationConstants'

function TranscriptDropdown(props) {
  return (
    <DropdownButton
    title={props.title}
    id={`transcript-navigation-dropdown`}
    bsStyle='white'
  >
    <MenuItem
      onClick={(event)=>{props.onNavigationClick(null)}}
      eventKey="1">
        <TranscriptMenuItem
          description="See transcript and codes."
        >
          {TRANSCRIPT_TITLE}
        </TranscriptMenuItem>
    </MenuItem>
    <MenuItem divider />
    {props.showGotoCodedByMe && <MenuItem
      onClick={props.gotoCodedByMe}    
      eventKey="2"
    >
      <TranscriptMenuItem
        description="Hide how other people coded.">
        {CODED_BY_ME_TITLE}
      </TranscriptMenuItem>
    </MenuItem>
    }
    {props.showGotoCodedByMe && <MenuItem divider />}
    <MenuItem
      eventKey="3"
      onClick={props.gotoCompare}>
      <TranscriptMenuItem
        description="Compare how people coded.">
        {COMPARISON_TITLE}
      </TranscriptMenuItem>
    </MenuItem>

    <MenuItem divider />
    <MenuItem
      eventKey="4"
      onClick={props.gotoDemographics}>
      <TranscriptMenuItem
        description="Assign descriptors to transcripts."
        marginBottom="5px">
      {DEMOGRAPHICS_TITLE}
    </TranscriptMenuItem>
    </MenuItem>
  </DropdownButton>
  )

}

export default TranscriptDropdown;
