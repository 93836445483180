import {
  NOT_LOADING_STATE,
} from 'constants/LoadingConstants'

import {
  SUBSCRIBE
} from 'constants/CashierConstants'

function getPlan(state, interval) {
  const plans = Object.keys(state.entities.plans)
    .map((planID)=>{
      const plan = state.entities.plans[planID];
      return {
        id: plan.id,
        interval: plan.interval,
        amount: plan.amount/100,
      }
    })
    .filter((plan)=>plan.interval==interval);

    return plans.length > 0 ? plans[0] : null;
}

function calcPercOff(plan, annual_plan) {
  if ( !plan || !annual_plan || !plan.amount || !annual_plan.amount ) return null;
  const plan_total = plan.amount * 12;
  const discount = (plan_total - annual_plan.amount)/(plan_total);
  return `${Math.round(discount * 100)}%`;
}

function _isInt(amount) {
  return amount % 1 === 0;
}

function _calcAnnualBilledMonthly(amount) {
  const billedMonthly = amount/12;
  return _isInt(billedMonthly) ? billedMonthly : billedMonthly.toFixed(2);
}

const planMapper = (state) => {
  try {
    const plan = getPlan(state, 'month');
    let annual_plan = getPlan(state, 'year');
    annual_plan = annual_plan ? {
      ...annual_plan,
      amount: _calcAnnualBilledMonthly(annual_plan.amount),
      discount: calcPercOff(plan, annual_plan),
      annual_plan_amount: annual_plan.amount
    } : null;

    const loading = state.loadingDict[SUBSCRIBE].loading;

    return {plan:plan, annual_plan:annual_plan, loading: loading};

  } catch (e) {
    console.log(e);
    return {plan:null};
  }
}

export default planMapper
