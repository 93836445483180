// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';
import QualCodeActions from 'actions/QualCodeActions'
import { Scrollbars } from 'react-custom-scrollbars';
import CodeBookH1 from './CodeBookH1'
import CodebookCodePageContainer from './CodebookCodePageContainer'
import { MemoModalProvider } from './modal/MemoModalProvider';


class CodeBook extends React.Component {
  constructor () {
    super();
    this.onCollapseClicked = this.onCollapseClicked.bind(this);
  }

  onCollapseClicked (codeID, collapsed) {
    if ( collapsed )
    {
      QualCodeActions.expandCode(this.props.projectID, codeID);
    }
    else
    {
      QualCodeActions.collapseCode(this.props.projectID, codeID);
    }
  }

  render () {
    const codeEntries = ( this.props.codes || [] ).map((code)=>{
      return (
          <CodeBookH1 key={code.id}
                      code={code}
                      projectID={this.props.projectID}
                      onCollapseClicked={this.onCollapseClicked}
                      hasEditPermission={this.props.hasEditPermission}
          />
      )
    })

    const paddingTop = 10;
    const sidePadding= 30;

    return (
      <div style={{height:"100%", paddingLeft:sidePadding, paddingBottom: 0, paddingTop:0, margin: 0, width: "100%"}}>
        { this.props.codeID &&
          <MemoModalProvider>
              <CodebookCodePageContainer
              codeID={this.props.codeID}
              projectID={this.props.projectID}
              hasEditPermission={this.props.hasEditPermission}
            />
          </MemoModalProvider>
        }
        <Scrollbars autoHide style={{height:(this.props.height), padding: 10, margin: 0, width: "100%"}}>
          <div style={{padding: "0px 30px 30px 0px"}}>
            {codeEntries}
          </div>
        </Scrollbars>
    </div>
    );
  }
}

export default CodeBook;
