
module.exports = {
   HEADER_HEIGHT: 50,
   LEFT_WIDTH: 228,
   RIGHT_WIDTH: 377,
   CONTENT_MIN_WIDTH: 100,
   CONTENT_MAX_WIDTH: 700,
   CODE_LABEL_HEIGHT: 38,
   CODE_LABEL_WIDTH: 237,
   CODE_PADDING_LEFT: 30,
   ACCOUNT_WIDTH: 350,
   BOTTOM_DROP_ZONE_HEIGHT: 200,
   PARAGRAPH_PADDING: 60,
   AVATAR_SIZE: '18px',
   TRANSCRIPT_NAVIGATION_PADDING: 25,
}
