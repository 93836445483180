// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';
import ColorConstants from 'constants/ColorConstants'


class TermsBlurb extends React.Component {
  render () {
    return (
      <div style={{marginTop: 20, fontSize:'.8em', color:ColorConstants.LIGHT_GREY, textAlign:"center"}}>
        <p>
          By creating an account you are agreeing to
          Delve's <a href="https://delvetool.com/terms" target="_blank">Terms of Service</a> and <a href="https://delvetool.com/privacy-policy" target="_blank">Privacy Policy</a>.
        </p>
      </div>
    );
  }
}

export default TermsBlurb;
