const newestProjectMapper = (state) => {
  try {
    var maxProject = null;

    if ( state.projectsList.recent_project_id )
    {
      maxProject = {id: state.projectsList.recent_project_id};
    }
    else {
      for (var i = 0; i < state.projectsList.projects.length; i++) {
        const projectID = state.projectsList.projects[i];
        const project = state.entities.projects[projectID];
        if ( !maxProject || project.updated_at >= maxProject.updated_at )
          maxProject = project;
      }
    }

    const loaded = state.projectsList.loaded ? true : false;

    const project = maxProject ? {
      id: maxProject.id,
    } : null;

    return {project:project, loaded: loaded};
  } catch (e) {
    console.error(e);
    return {project: null, loaded: false};
  }
}

export default newestProjectMapper
