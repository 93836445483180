// app/javascript/projects/components/ProjectsDisplay.jsx
import React from 'react';
import {Button, Glyphicon} from 'react-bootstrap'
import TextDropdown from './TextDropdown'
import EditDescriptorComponent from './EditDescriptorComponent'

import ColorConstants from 'constants/ColorConstants'
var _ = require('underscore');
import { v1 as uuidv1 } from 'uuid';

class NormalDescriptorComponent extends React.Component {
  constructor () {
    super();
    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
    this.addAndSelectChoice = this.addAndSelectChoice.bind(this);

    this.state = {
      hover: false,
    }
  }

  _getDescriptorName()
  {
    return this.props.descriptor ? this.props.descriptor.name : "";
  }

  onMouseEnter(e) {
    this.setState({
      hover:true
    });
  }

  onMouseLeave(e) {
    this.setState({
      hover:false
    });
  }

  addAndSelectChoice(choiceText)
  {
    const descriptorID = this.props.descriptor ? this.props.descriptor.id : null;
    this.props.addAndSelectChoiceCallback(descriptorID, choiceText);
  }

  normalDisplay()
  {
    const mainStyle = {
      margin:'10px',
      width: "300px"
    };

    const descriptor = this.props.descriptor || {};
    const multi_choices = descriptor.multi_choices || [];

    const options = multi_choices.map((choice, index) =>{
            return <option value={choice.choice} key={index}/>
      });


    return (
      <div  onMouseEnter={this.onMouseEnter}
            onMouseLeave={this.onMouseLeave}
            style={mainStyle}>
        <div>
          <label>{this._getDescriptorName()}</label>
              <Button
                      style={{
                        float:"right",
                        visibility: this.state.hover ? 'visible' : 'hidden'
                      }}
                      bsSize="small"
                      bsStyle="link"
                      onClick={this.props.editCallback}>Edit Field</Button>
        </div>

        <div style={{clear:"both"}}>
          <TextDropdown multi_choices={multi_choices}
                        selected_multi_choice={descriptor.selected_multi_choice}
                        createSelectionCallback={this.props.createSelectionCallback}
                        addAndSelectChoiceCallback={this.addAndSelectChoice}
                        />
        </div>

        <div style={{float:"right", paddingLeft:"10px"}}>
        </div>
        <div style={{clear:"both"}}/>
    </div>
    );
  }

  render () {
    return (<div>{this.normalDisplay()}</div>);
  }
}

export default NormalDescriptorComponent;
