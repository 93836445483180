import React from 'react';
import {Link} from 'react-router-dom'
import {Glyphicon} from 'react-bootstrap'
import {DropdownButton, MenuItem} from 'react-bootstrap'

import ModalRow from './ModalRow'
import ArchiveActions from 'project_archives/ArchiveActions'

const WRAP_STYLE = {
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis"
};

function unarchiveProject(e, projectId, errorArchivingProject)
{
  e.preventDefault();
  ArchiveActions.unarchiveProject(projectId).catch((err)=>{
    errorArchivingProject(false)
  });
}

function ArchivedProjectRow(props) {
  /*
    Open can be used to open the native file picker
  */
  if ( !props.project ) return <div></div>
  if ( !props.project.id ) return <div></div>

  const owner = props.project.owner || {};
  if ( !props.project.isOwner )
    return <div> </div>

  return <ModalRow
      row={[
        <div
        style={WRAP_STYLE}
        className='projectRow'
        >
          {!props.project.name || props.project.name.length == 0 ? "Untitled Project" : props.project.name}
        </div>,
        <div style={WRAP_STYLE}>{owner.name || owner.email || '-'}</div>,
        <div style={WRAP_STYLE}>{props.project.updated_at}</div>,
        <DropdownButton
          bsStyle="link"
          title={<Glyphicon style={{color:"black"}} glyph='option-vertical'/>}
          id={1}
          noCaret
          pullRight
          style={{padding:0, margin:0}}
        >
          <MenuItem
            eventKey="2"
            onClick={(e)=>unarchiveProject(
              e,
              props.project.id,
              props.errorArchivingProject
            )}
          >
            Unarchive Project
          </MenuItem>
        </DropdownButton>
      ]}
    />
}


export default ArchivedProjectRow
