import React from 'react';
import { Button } from 'react-bootstrap';

import {
  JOB_STATUS_NOT_STARTED,
  JOB_STATUS_UNDOING,
  JOB_STATUS_IN_PROGRESS,
  JOB_STATUS_COMPLETED,
  JOB_STATUS_ERROR,
  JOB_STOPPING
} from 'jobs/JobConstants';


const AICodingButton = (props) => {
  const getButtonText = () => {
    switch(props.status) {
      case JOB_STATUS_COMPLETED:
      case JOB_STATUS_ERROR:
      case JOB_STATUS_UNDOING:
        return 'Remove codes applied with AI';
      case JOB_STATUS_IN_PROGRESS:
      case JOB_STOPPING:
        return 'Coding in progress...'
      case JOB_STATUS_NOT_STARTED:
      default:
        return 'Apply codes using AI';
    }
  }

  const getButtonStyle = () => {
    switch(props.status) {
      case JOB_STATUS_NOT_STARTED:
        return 'blue';
      default:
        return 'white'
    }
  }

  const getButtonDisabled = (loading) => {
    if ( !!props.disabled ) return true;
    if ( loading ) return true;
    switch(props.status) {
      case JOB_STATUS_UNDOING:
      case JOB_STOPPING:
      case JOB_STATUS_IN_PROGRESS:
        return true;
      default:
        return false;
    }
  }

  const onClick = () => {
    switch(props.status) {
      case JOB_STATUS_COMPLETED:
      case JOB_STATUS_ERROR:
        props.onUndo();
        break;
      case JOB_STATUS_NOT_STARTED:
        props.onAutoCode();
      default:
        break;
    }
  }

  return (
    <Button bsStyle={getButtonStyle()} onClick={onClick} disabled={getButtonDisabled(props.loading)}>
      {getButtonText()}
    </Button>
  );
}

export default AICodingButton;
