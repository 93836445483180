import AppDispatcher from 'dispatcher/AppDispatcher'
import QualConstants from 'constants/QualConstants'
import searchReducer from 'reducers/SearchReducer'
import searchMapper from 'mappers/SearchMapper'
import State from 'stores/State'

var _ = require('underscore');
var EventEmitter = require('events').EventEmitter;

var _searchResults = []

function setSearchResults(data)
{
  _searchResults = data['hits'];
}

var SearchStore = _.extend({}, EventEmitter.prototype, {
  getSearchResults: function(projectID) {
    return searchMapper(State.get(), projectID);
  },

  // Emit Change event
  emitChange: function() {
    this.emit('change');
  },

  // Add change listener
  addChangeListener: function(callback) {
    this.on('change', callback);
  },

  // Remove change listener
  removeChangeListener: function(callback) {
    this.removeListener('change', callback);
  }
});

// Register callback with AppDispatcher
AppDispatcher.register(function(payload) {
  var action = payload.action;

  switch(action.actionType) {
    case QualConstants.TRANSCRIPT_SEARCH_RECEIVED:
    case QualConstants.TRANSCRIPT_SEARCH:
      State.set(searchReducer(action, State.get()))
      break
    default:
      return true;
  }

  SearchStore.emitChange();
  return true;
});

export default SearchStore
