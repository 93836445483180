const _ = require('underscore')
import OptimisticMapperHelper from 'mappers/OptimisticMapperHelper'

const _convertProjects = (state, projects) => {
  return _.mapObject(projects, (project, projectId)=>{
      return {
        ...project,
        ...(project.codes && {
            codes: project.codes.map((codeId)=>
              OptimisticMapperHelper.getCodeId(state, codeId) || codeId
            )
          }
        )
      }
    }
  )
}

const _convertExcerpts = (state, excerpts) => {
  return _.mapObject(excerpts, (excerpt, excerptId)=>{
    if ( !excerpt ) return excerpt;
    return {
      ...excerpt,
      ...(excerpt.codings && {
          codings: excerpt.codings.map((coding)=>{
            return {
                ...coding,
                code_id: OptimisticMapperHelper.getCodeId(state, coding.code_id) || coding.code_id
            }
          })
      })
    }
  })
}

const _convertCodes = (state, codes) => {
  return _.mapObject(codes, (code, codeId)=>{
    if ( !code ) return code;
    return {
      ...code,
      ...(code.parent_id && {
        parent_id: OptimisticMapperHelper.getCodeId(state, code.parent_id) || code.parent_id
      })
    }
  })
}

const _convertCodeBar = (state, codeBar) => {
  return _.mapObject(codeBar, (project)=>{
    return Object.fromEntries(_.pairs(project).map((keyValue)=>{
      const [codeId, isCollapsed] = keyValue;
      return [OptimisticMapperHelper.getCodeId(state, codeId) || codeId, isCollapsed]
    }))
  })
}

export default (state) => {
  const entities = state.entities || {};

  return {
    ...state,
    codeBar: _convertCodeBar(state, state.codeBar || {}),
    entities: {
      ...entities,
      projects: _convertProjects(state, entities.projects),
      excerpts: _convertExcerpts(state, entities.excerpts),
      codes: _convertCodes(state, entities.codes)
    }
  }
}
