import AppDispatcher from 'dispatcher/AppDispatcher'
import MergeConstants from 'constants/MergeConstants'
import QualAPI from 'utils/QualAPI'
import asyncUtil from 'utils/AsyncUtil'
import CodeStore from 'stores/CodeStore'

function dispatchError(type, payload)
{
  const response = payload.response ? payload.response : {};
  const data = response.data ? response.data : {};
  const errors = data.errors ? data.errors : [];

  AppDispatcher.handleAction({
    actionType: type,
    data: response,
    errors: errors,
  })
}

var MergeCodeActions = {
  /***********************\
  *
  * Merging
  *
  \************************/

  mergeCode: function()
  {
    const {mergeCodeId,mergeIntoCodeId} = CodeStore.getMergeCodes();
    if ( !mergeCodeId || !mergeIntoCodeId ) return;
    AppDispatcher.handleAction({
      actionType: MergeConstants.MERGE_CODE,
      data:{
        mergeCodeId: mergeCodeId,
        mergeIntoCodeId: mergeIntoCodeId
      }
    });


    asyncUtil.getServerCodes(mergeCodeId, mergeIntoCodeId).then((result)=>{
      const {serverCodeId1, serverCodeId2} = result;
      return QualAPI.mergeCode(serverCodeId1, serverCodeId2).then((response) => {
        AppDispatcher.handleAction({
          actionType: MergeConstants.MERGE_CODE_RESULT,
          data: {
            ...response.data,
          }
        })
      })
      .catch(error => dispatchError(MergeConstants.MERGE_CODE_ERROR, error));
    })
  },

  initiateMergeCode: function(mergeCodeId, mergeIntoCodeId) {
    AppDispatcher.handleAction({
      actionType: MergeConstants.MERGE_CODE_INITIATED,
      data:{
        mergeCodeId: mergeCodeId,
        mergeIntoCodeId: mergeIntoCodeId
      }
    });
  },

  cancelMergeCode: function() {
    AppDispatcher.handleAction({
      actionType: MergeConstants.MERGE_CODE_CANCELLED,
    });
  },
}

export default MergeCodeActions
