import OptimisticMapperHelper from 'mappers/OptimisticMapperHelper'

const CANT_DROP_MARGIN = {
  canDropOnMargin: false
}

const BOTTOM_MARGIN_MAPPER = {
  parent_id: null,
  canDropOnMargin: true
}

const _getMaxPosition = (state, dragCodeId) => {
  if ( !dragCodeId ) return 0;
  const positions = Object.values(state.entities.projects)
  .filter((project)=>(project.codes || []).includes(dragCodeId))
  .map((project)=>project.codes).flat()
  .map((codeId)=>OptimisticMapperHelper.getCode(state, codeId) || {})
  .filter((code)=>!code.parent_id)
  .map((code)=>code.position || 0)
  return Math.max(...positions, 0)
}

export default (state, dragCodeId) => {
  _getMaxPosition(state, dragCodeId)
  if ( !dragCodeId ) return CANT_DROP_MARGIN;
  const dragCode = OptimisticMapperHelper.getCode(state, dragCodeId);
  const maxPosition = _getMaxPosition(state, dragCodeId);
  const isRoot = !dragCode.parent_id;
  const isMax = isRoot && dragCode.position === maxPosition;

  return {
    ...BOTTOM_MARGIN_MAPPER,
    position: isRoot ? maxPosition : maxPosition + 1,
    noop: !!isMax
  };
}
