import metaSubscriptionMapper from 'mappers/MetaSubscriptionMapper'

import {
  ADD_SEAT,
} from 'constants/CashierConstants'

const addSeatMapper = (state) => {
  return {
    addSeat:metaSubscriptionMapper(state, ADD_SEAT)
  }
}

export default addSeatMapper
