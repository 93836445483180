import API from './API'

var ReportAPI = {
  // load single proje
  getTranscriptCodesReport: function(project_id) {
    return API.get(`/api/projects/${project_id}/transcript_codes_report`);
  },

  getCodesVCodesReport: function(project_id) {
    return API.get(`/api/projects/${project_id}/codes_codes_report`);
  },

  getDescriptorVCodesReport: function(project_id) {
    return API.get(`/api/projects/${project_id}/descriptor_codes_report`);
  },

  getCodeReport: function(code_id) {
    return API.get(`/api/codes/${code_id}/code_report`);
  }
};

export default ReportAPI
