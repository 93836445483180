import UrlUtil from 'utils/UrlUtil';
import queryString from 'query-string';

function getUserIds(location) {
  const parsed = queryString.parse(location.search);
  const leftUserID = parsed.left;
  const rightUserID = parsed.right;
  return {leftUserID, rightUserID};
}

var ComparisonUrlUtil = {
  getUserIds,
  redirectComparisonUrl: function(location, collaboratorsLoaded, owner, collaborators) {
    const {leftUserID, rightUserID} = getUserIds(location);
    const rightExist = !!rightUserID && rightUserID !== 'none';

    if ( !!leftUserID && !!rightExist ) return null;
    if ( !collaboratorsLoaded ) return null;
    const ownerId = (owner || {}).id;

    if ( !ownerId ) return null;
    const collaboratorId = (collaborators[0] || {}).id || 'none';

    if ( leftUserID === ownerId.toString() && rightUserID === collaboratorId.toString() ) return null;
    
    const url = UrlUtil.addParamsToUrlObject(location, {
      ...(!leftUserID && !!ownerId ? {left: ownerId} : {}),
      ...(!rightExist && !!collaboratorId ? {right: collaboratorId} : {})
    })

    return url;
  },
}

export default ComparisonUrlUtil;
