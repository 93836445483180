// app/javascript/projects/components/ModalDivider.jsx
import React from 'react';
import ModalDivider from './ModalDivider'

function ModalComponent(props) {
  const margin = props.margin || 30;
  let marginStyle = `${props.header ? 0 : margin}px 0px ${margin}px ${props.indent ? margin : 0}px`;

  return (
    <div>
      <div style={{margin: marginStyle}}>
        {props.children}
      </div>
      {!props.footer && <ModalDivider/>}
    </div>
  );
}
export default ModalComponent;
