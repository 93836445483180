// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';
import {Button} from 'react-bootstrap'
import LoadingButton from '../LoadingButton'

class CollabModalInvite extends React.Component {
  constructor () {
    super();
  }

  render () {
    return (
          <div style={{
            textAlign: 'center',
            paddingTop: '20px',
            marginTop: '20px',
            borderTop: '1px solid lightGray'
          }}>
            <div>
              <LoadingButton
                centered
                type="submit"
                bsStyle="blue"
                loading={!!this.props.sharing}
                disabled={!!this.props.sharing}
              >
                Invite
              </LoadingButton>
            </div>
            <div onClick={this.props.cancel}>
              <Button bsStyle="link" disabled={!!this.props.sharing}>
                Cancel
              </Button>
            </div>
          </div>
    );
  }
}

export default CollabModalInvite;
