// ContactUs.jsx

import React from 'react';
import {Button, ButtonToolbar, ButtonGroup} from 'react-bootstrap'
const WIDTH = "400px"


class ContactUs extends React.Component {
  constructor () {
    super();
  }

  render () {
    return (
      <div style={{textAlign:"center", margin:"0 auto"}}>
        <h4>Would you like to talk to one of our teammembers?</h4>


        <div>
          We would love to see if we can help you out.
        </div>

        <div style={{marginTop:"80px", marginBottom:"40px", textAlign:"center"}}>
            <Button style={{marginRight:10}} bsStyle='white' onClick={this.props.onNext}>
              No, Continue to Cancellation
            </Button>

            <Button style={{marginLeft:10}} bsStyle='blue' id="delve_help_link" href="mailto:team@delvetool.com">
              Start Chat
            </Button>
        </div>
      </div>
    );
  }
}

export default ContactUs;
