const transcriptCleaner = (body) => {
  if ( body === null || body === undefined || body === 0 ) return null;

  const other = body.replace(/[\r\0]/g, '');


  return other;
}

export default transcriptCleaner;
