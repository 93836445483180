// useStreamHandler.js
import { useEffect, useState } from 'react';
import StreamHandler from './StreamHandler';

function useStreamHandler(streamUrl, headers) {
  const [data, setData] = useState('');

  useEffect(() => {
    if (streamUrl && headers) {
      setData('');
      
      const streamHandler = new StreamHandler(newData => {
        setData(newData);
      });

      streamHandler.startStream(streamUrl, headers);

      return () => {
        streamHandler.closeStream();
      };
    }
  }, [streamUrl, headers]);

  return data;
}

export default useStreamHandler;