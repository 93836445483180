// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';
import { BsFillChatSquareFill, BsChatSquare } from "react-icons/bs"
import { IconContext } from "react-icons";
import {
  LIGHT_GREY
} from 'constants/ColorConstants'


function MemoTag(props) {
  return (
    <div onClick={props.onClick}
      style={{
        visibility: props.show ? 'visible' : 'hidden',
        display: 'flex',
      }}
    >
    <div style={{
      visibility: props.count > 0 ? 'visible' : 'hidden',
      fontSize: '.8em',
      marginRight: '5px',
      cursor: 'default',
      color: LIGHT_GREY
    }}>
      {props.count}
    </div>
    <div className='memo-tag'>
      <IconContext.Provider value={
      {size:'1.5em', margin:0, padding:0, color: LIGHT_GREY}
        }>
          {props.count === 0 || !props.selected ? <BsChatSquare/> : 
          <BsFillChatSquareFill/>}
        </IconContext.Provider>
    </div>
  </div>)
}

export default MemoTag;
