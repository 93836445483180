// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';
import ErrorModal from './ErrorModal'
import fileUtil from 'utils/FileUtil'
import {
  FILES__COUNT_LIMIT,
  FILES__TYPE_UNSUPPORTED
} from 'constants/ErrorConstants'

const show = (type) => {
  switch (type) {
    case FILES__COUNT_LIMIT.name:
    case FILES__TYPE_UNSUPPORTED.name:
      return true;
    default:
      return false;
    break;
  }
}

const header = (type) => {
  switch (type) {
    case FILES__COUNT_LIMIT.name:
      return "Too many files";
    case FILES__TYPE_UNSUPPORTED.name:
      return  "File type not supported";
    default:
      return null;
    break;
  }
}

const body = (type) => {
  switch (type) {
    case FILES__COUNT_LIMIT.name:
      return (<div>Delve can only import 200 files at a time.</div>);
    case FILES__TYPE_UNSUPPORTED.name:
      return (<div>
        <p>
            Delve only supports {fileUtil.fileTypesAndCopy()} files at this time.
        </p>
        <p>
          We are working hard to support other file types in the future.
          We recommend converting your transcripts to .txt or .docx files and trying again.
        </p>
      </div>);
    default:
      return null;;
    break;
  }
}

function FileErrorModal(props) {
  return   <ErrorModal
            show={show(props.type)}
            header={header(props.type)}
            button="Okay"
            onClick={props.onClear}
            onHide={props.onClear}
            body={body(props.type)}/>
}

export default FileErrorModal;
