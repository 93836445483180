var _ = require('underscore');
import excerptMapper from 'mappers/ExcerptMapper'
import optimisticMapperHelper  from 'mappers/OptimisticMapperHelper'
import codeIdToProjectIdMapper from 'mappers/CodeIdToProjectIdMapper'
import FallbackNameMapper from './FallbackNameMapper'

const EMPTY_RESULT = {
  show: false,
  code: {},
  excerpts: [],
  transcripts: [],
  transcriptCount: 0,
  canPage: false,
  loading: true
};

const _getNumberOfTranscripts = (state, codeID) => {
  const projectID = codeIdToProjectIdMapper(state, codeID);
  if ( !projectID ) return 0;
  const project = state.entities.projects[projectID];
  if ( project.transcripts )
    return project.transcripts.length;
  else
    return 0;
}


const codePageMapper = (state, codePageState, code_id) => {
  const show = code_id ? true : state.codePageModal.show;
  const codeID = code_id ? code_id : state.codePageModal.codeID;

  if ( !show || !codeID )
    return EMPTY_RESULT

  const clientID = optimisticMapperHelper.getCodeId(state, codeID);
  const code = optimisticMapperHelper.getCode(state, codeID);
  if ( !code || !clientID )
    return EMPTY_RESULT


  
  const user = FallbackNameMapper(state, code.user_id) || {};


  const codePageCode = (codePageState.codes || [])[code.server_id || code.id] || {};
  const excerptIds = (codePageCode).excerpts || [];

  const excerpts = excerptIds.filter((id)=>!!id)
  .map((excerptID)=>{
    return excerptMapper(state, optimisticMapperHelper.getExcerptId(state, excerptID))
  }).filter((e)=>!!e)
  //.sort((a,b)=>(b.updated_at || '').localeCompare(a.updated_at || '')); (intentionally removed)
  // note: we specifically want the mapper to keep the CodePageState order of excerpts
  // and not filter by updated_at
  // This is so the excerpts do not move around when a excerpt is edited


  const transcriptToCount = (codePageCode.counts || {}).transcripts;

  const transcripts = _.keys(transcriptToCount).map((transcriptID)=>{
    const transcript = state.entities.transcripts[transcriptID];
    if ( !transcript ) return null;

    return {
      id: transcript.id,
      name: transcript.name ? transcript.name : "Untitled",
      count: transcriptToCount[transcriptID],
    }
  }).filter((transcript)=>!!transcript).sort(function (a, b) {
    return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
  });

  const transcriptCount = _getNumberOfTranscripts(state, clientID)
  return {
    show: true,
    code: code,
    user: user,
    excerpts: excerpts,
    transcripts: transcripts,
    transcriptCount: transcriptCount,
    canPage: !!codePageCode.next,
    loading: !!codePageCode.loading
  };
}

export default codePageMapper
