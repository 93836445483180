import descriptorReducer  from 'reducers/DescriptorReducer'
var _state = descriptorReducer();

var State = {
  set: function(newState) {
    _state = newState;
  },
  get: function(){
    return _state;
  }
}

export default State
