function createState(initialState) {
  let _state = initialState;

  return {
    set: function(newState) {
      _state = newState;
    },
    get: function() {
      return _state;
    }
  };
}

export default createState;