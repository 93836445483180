import codesMapper from 'mappers/CodesMapper'
import getCheckedCodes from './filters/CheckedCodesMapper'
import {
  OP_DEFAULT
} from 'constants/OpConstants'

const _getCount = (code, codeToCount) => {
  if ( !code ) return 0;
  if ( !!code.id && code.id in codeToCount ) return codeToCount[code.id];
  if ( !!code.server_id && code.server_id in codeToCount ) return codeToCount[code.server_id];
  return 0;
}

function recursiveCodes(codes, codeToCount, checkedCodes, checkedCodeNames)
{
  if ( !codes ) return [];

  return codes.map((code=>{
        const count = _getCount(code, codeToCount);
        const checked = checkedCodes.includes(code.id) || checkedCodes.includes(code.server_id);
        if ( checked )
          checkedCodeNames.push(code.name);

        return {
          children: recursiveCodes(code.children, codeToCount, checkedCodes, checkedCodeNames),
          id: code.id,
          name: code.name,
          server_id: code.server_id,
          count: count,
          checked: checked,
        }
    }))
}

const _getCodeOp = (filterState, projectID) => {
  const projects = filterState.projects || {};
  const project = projects[projectID] || {};
  const filters = project.filters || {};
  return filters.op || OP_DEFAULT;
}

const codesWithExcerptCountsMapper = (state, filterState, projectID) => {
  try {
    var codes = codesMapper(state, projectID).codes;
    if ( !codes ) return {codes: []};

    const projects = filterState.projects || {};
    const project = projects[projectID] || {};
    const codeToCount = (project.counts || {}).code_ids || {};

    const checkedCodes = getCheckedCodes(filterState, projectID);

    var checkedCodeNames = [];
    const returnCodes = recursiveCodes(codes, codeToCount, checkedCodes, checkedCodeNames);

    return {
      codes: returnCodes,
      checkedCodeNames: checkedCodeNames,
      op: _getCodeOp(filterState, projectID)
    };
  } catch (e) {
    console.error(e);
  }
}

export default codesWithExcerptCountsMapper
