// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';

import CodedTranscriptStore from 'stores/CodedTranscriptStore'
import CodeStore from 'stores/CodeStore'
import UserStore from 'stores/UserStore'
import ErrorStore from 'stores/ErrorStore'
import EditTranscriptContainer from './EditTranscriptContainer'
import OutOfDateTranscriptModal from './OutOfDateTranscriptModal'
import TranscriptPagingUtil from 'utils/TranscriptPagingUtil'
import TranscriptScrollbar from './TranscriptScrollbar'
import UrlUtil from 'utils/UrlUtil';

import {
  Redirect
} from 'react-router-dom'
import QualCodeActions from '../../actions/QualCodeActions';

const queryString = require('query-string');

var _ = require('underscore');


class TranscriptBodyContainer extends React.Component {
  constructor () {
    super();
    this.state = {
      codedTranscript: CodedTranscriptStore.getCodedTranscript(null),
      redirectSearchLoc: null,
      editParagraphIndex: CodedTranscriptStore.getEditState(null).paragraphIndex,
      versionError: ErrorStore.getVersioningError()
    };
    this._onChange = this._onChange.bind(this);
    this.shouldGotoOtherPage = this.shouldGotoOtherPage.bind(this);
  }

  _allowAutoScroll(props) {
    const parsed = queryString.parse(props.location.search);
    const search = parsed.search;
    const pos = parsed.pos;
    const end = parsed.end;
    const snippet = parsed.snippet;
    return !search && !pos && !end && !snippet;
  }

  _getSearchLoc(props)
  {
    const parsed = queryString.parse(props.location.search);
    const search = parsed.search;
    const pos = parsed.pos;
    const end = parsed.end;
    const snippet = parsed.snippet;

    if ( search )
    {
      const loc = CodedTranscriptStore.getLocationWithSearchText(props.transcriptID, search);
      return {
        redirectSearchLoc: loc
      };
    }
    else if ( pos || end )
    {
      return {
        redirectSearchLoc: null,
        selection:{
          pos: pos,
          end: end
        }
      }
    }
    else if ( snippet )
    {
      const excerpt = CodedTranscriptStore.getExcerptDetails(snippet);

      if ( excerpt )
      {
        const pos = excerpt.start;
        const end = excerpt.end;
        if ( !isNaN(pos) && !isNaN(end))
        {
          return {
            redirectSearchLoc: null,
            selection:{
              pos: pos,
              end: end,
            }
          }
        }
      }
    }
    return {
      selection: {},
      redirectSearchLoc: null
    };
  }

  getCoders(props) {
    if ( props.location.pathname.includes('user/me') )
      return ['me']
    else
      return []
  }

  _loadState (props) {
    const loc = this._getSearchLoc(props);

    this.setState({
        codedTranscript: CodedTranscriptStore.getCodedTranscript(props.transcriptID, UrlUtil.getPageNumber(props.location)),
        editParagraphIndex: CodedTranscriptStore.getEditState(props.transcriptID).paragraphIndex,
        ...loc,
        versionError: ErrorStore.getVersioningError(),
      });
  }

  _onChange () {
    this._loadState(this.props);
  }

  componentDidMount () {
    CodedTranscriptStore.addChangeListener(this._onChange);
    CodeStore.addChangeListener(this._onChange);
    UserStore.addChangeListener(this._onChange);
    ErrorStore.addChangeListener(this._onChange);
    this._loadState(this.props);

    const nextCoders = this.getCoders(this.props);
    setTimeout(() => {
      QualCodeActions.filterCoders(nextCoders)
    }, 0);
  }

  componentWillUnmount () {
    CodedTranscriptStore.removeChangeListener(this._onChange);
    CodeStore.removeChangeListener(this._onChange);
    UserStore.removeChangeListener(this._onChange);
    ErrorStore.removeChangeListener(this._onChange);
  }

  componentDidUpdate (prevProps) {
    const nextTranscriptID = this.props.transcriptID;
    const thisTranscriptID = prevProps.transcriptID;
    const nextPage = UrlUtil.getPageNumber(this.props.location);
    const thisPage = UrlUtil.getPageNumber(prevProps.location);

    if ( nextTranscriptID != thisTranscriptID
        || this.props.location.search != prevProps.location.search
        || thisPage != nextPage)
    {
      this._loadState(this.props);
    }

    const nextCoders = this.getCoders(this.props);
    const thisCoders = this.getCoders(prevProps);

    // if nextCoders and thisCoders do not have the same elements
    if ( !_.isEqual(nextCoders.sort(), thisCoders.sort()) ) {
      setTimeout(() => {
        QualCodeActions.filterCoders(nextCoders)
      }, 0);
    }
  }

  gotoTranscript(queryParams) {
    this.props.history.push(`${this.props.location.pathname}${queryParams}`)
  }

  shouldGotoOtherPage(page) {
    const currentPage = UrlUtil.getPageNumber(this.props.location);
    if ( currentPage !== page ) {
      this.gotoTranscript(TranscriptPagingUtil.calcQueryParams(this.props.location.search, page));
      return true;
    }
  }

  render () {
    const editParagraph = this.state.editParagraphIndex ? this.state.editParagraphIndex : {};
    const page = UrlUtil.getPageNumber(this.props.location);

    return (
      <div style={{height:"100%"}}>
        {(this.state.redirectSearchLoc && Number.isInteger(this.state.redirectSearchLoc.start) && Number.isInteger(this.state.redirectSearchLoc.end) ) &&
          <Redirect to={"/transcripts/" + this.props.transcriptID +
                        "?pos=" + this.state.redirectSearchLoc.start +
                        "&end=" + this.state.redirectSearchLoc.end
                        }/>
        }
        <EditTranscriptContainer
          show={!!editParagraph.paragraph}
          saving={!!editParagraph.saving}
          text={editParagraph.paragraph}
          onDoneEdit={this.onDoneEdit}
          transcriptID={editParagraph.transcript_id}
          paragraphID={editParagraph.paragraph_id}
          error={editParagraph.error}
        />
        <TranscriptScrollbar
          transcriptID={this.props.transcriptID}
          codedTranscript={this.state.codedTranscript}
          selection={this.state.selection}
          shouldGotoOtherPage={this.shouldGotoOtherPage}
          page={page}
          autoScrollEnabled={this._allowAutoScroll(this.props)}
          hasEditPermission={this.props.hasEditPermission}
          pageCount={this.props.pageCount}
          />
        <OutOfDateTranscriptModal
          show={!!this.state.versionError.error}
        />
      </div>
    );
  }
}

export default TranscriptBodyContainer;
