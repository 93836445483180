import QualAPI from 'utils/QualAPI';
import AppDispatcher from '../dispatcher/AppDispatcher';
import QualConstants from '../constants/QualConstants';
import trackingUtil from 'utils/TrackingUtil';
import FileUtil from 'utils/FileUtil';
import FileConstants from 'constants/FileConstants';
import TranscriptFormatter from 'utils/TranscriptFormatter';
import { v1 as uuidv1 } from 'uuid';

var TranscriptActions = {
  _createTranscript: async function(data) {
    const transcriptFormatter = new TranscriptFormatter();

    AppDispatcher.handleAction({
      actionType: QualConstants.TRANSCRIPT_CREATE,
      data: data
    })

    return QualAPI.createTranscript(data.project_id, data.name, transcriptFormatter.run(data.body))
    .then((result) => {
      AppDispatcher.handleAction({
        actionType: QualConstants.TRANSCRIPT_CREATE_RESULT,
        data: result.data
      })
      return result.data;
    })
    .catch(function (error) {
      AppDispatcher.handleAction({
        actionType: QualConstants.TRANSCRIPT_CREATE_ERROR,
        data: data,
        error: error.response && error.response.data ? error.response.data : error,
      });
      throw error;
    });
  },

  createTranscript: async function(data)
  {
    return this._createTranscript(data).catch((error)=>{});
  },

  uploadTranscript: async function(projectID, file, fileId) {
    return await FileUtil.upload(file).then((response)=>{
        return this._createTranscript({
          project_id: projectID,
          name: response.name,
          body: response.body
        }).then((transcript)=>{
          AppDispatcher.handleAction({
            actionType: FileConstants.PARSED_FILE,
            data: {
              id: fileId,
              transcript_id: transcript.id
            }
          });

          trackingUtil.logEvent(`dropped_file_${response.fileType || 'unknown'}`);
        });
    }).catch((e)=>{
      AppDispatcher.handleAction({
        actionType: FileConstants.PARSED_FILE_ERROR,
        data: {
          id: fileId,
        },
        error:e
      })
    })
  },

  uploadManyTranscripts: async function(projectID, files)
  {
    try {
      const filesValid = FileUtil.checkFiles(files);
    } catch (e) {
      AppDispatcher.handleAction({
        actionType: FileConstants.PARSE_FILES_ERROR,
        error: e
      });
      return;
    };

    trackingUtil.logEvent('drag-drop-transcripts', {
      file_count: files.length
    });

    const filesWithIds = files.map((file)=>{
      return {
        id: uuidv1(),
        file: file
      }
    })

    AppDispatcher.handleAction({
      actionType: FileConstants.PARSE_FILES,
      data:{
        files: filesWithIds.map((file)=>{
          return {
            id: file.id,
            fileName: file.file.name
          }
        })
      }
    });

    for (var i = 0; i < filesWithIds.length; i++) {
      const fileId = filesWithIds[i].id;
      const file = filesWithIds[i].file;
      const fileName = file.name;
      await this.uploadTranscript(projectID, file, fileId);
    }
  }
};

export default TranscriptActions;
