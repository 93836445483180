import API from './API'

export default {
  // log event
  logEvent: async function(event) {
    if ( !event )
      throw new Error('Event required for Log Event')

    return API.post('/api/logs', {event});
  }
};
