// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';
import ErrorStore from 'stores/ErrorStore'
import CashierStore from 'stores/CashierStore'
import ProjectsStore from 'stores/ProjectsStore'
import {Alert, Button} from 'react-bootstrap'
import QualCodeActions from 'actions/QualCodeActions'
import ErrorActions from 'actions/ErrorActions'
import { withToastManager } from 'react-toast-notifications';

class ErrorAlert extends React.Component {
  constructor () {
    super();
    this.state = {
      error: ErrorStore.getError(),
      fileStatus: ProjectsStore.getFileStatus(),
    };

    this._onChange = this._onChange.bind(this);
    this.fileClick = this.fileClick.bind(this);
    this.onFileDismiss = this.onFileDismiss.bind(this);
    this.onDefaultErrorDismiss = this.onDefaultErrorDismiss.bind(this);
  }

  fileClick(e) {
    QualCodeActions.dismissFile(false);
    if ( e.stopPropagation )
      e.stopPropagation();
  }

  onFileDismiss (e) {
    QualCodeActions.clearFileUpload();
    if ( e.stopPropagation )
      e.stopPropagation();
  }

  onDefaultErrorDismiss (e) {
    ErrorActions.clearError();
    if ( e.stopPropagation )
      e.stopPropagation();
  }

  // component did update
  componentDidUpdate (prevProps, prevState) {
    // if previous error message does not equal new error message or is new
    const newError = this.state.error || {};
    const prevError = prevState.error || {};
    if ( !!newError.message && newError.message !== prevError.message ) {
      // show toast
      this.props.toastManager.add(
        newError.message, {
         appearance: this.state.error.status === 'SEVERE' ? 'error' : 'warning',
         autoDismiss: false,
         onDismiss: this.onDefaultErrorDismiss
       });      
    }
  }

  _onChange()
  {
    this.setState({
      error: ErrorStore.getError(),
      fileStatus: ProjectsStore.getFileStatus(),
    })
  }

  componentDidMount () {
    ErrorStore.addChangeListener(this._onChange);
    CashierStore.addChangeListener(this._onChange);
    ProjectsStore.addChangeListener(this._onChange);
  }

  componentWillUnmount () {
    ErrorStore.removeChangeListener(this._onChange);
    CashierStore.removeChangeListener(this._onChange);
    ProjectsStore.removeChangeListener(this._onChange);
  }

  render () {
    if ( this.state.error.error )
    {
      return <div></div>
    } else if ( this.state.fileStatus && this.state.fileStatus.message ) {
      let style = this.state.fileStatus.error ? 'danger' :
                        this.state.fileStatus.done ? 'info' : 'info';

      const seeStatus = <span>. <b>See status.</b></span>
      const showStatus = !this.state.fileStatus.done && !this.state.fileStatus.error;

      return (<div style={{position:"absolute", cursor:"pointer", right: "10px", top: "10px", zIndex:1}}>
        <Alert onDismiss={this.state.fileStatus.done ? this.onFileDismiss : null}
                bsStyle={style}
                onClick={this.fileClick}
                style={{width:"400px"}}>

                <div>
                  {this.state.fileStatus.message}
                  {showStatus && seeStatus}
                </div>

                {this.state.fileStatus.errorMessage && <div>
                  {this.state.fileStatus.errorMessage}{seeStatus}
                </div>}
        </Alert>
      </div>);
    }
    else {
      return <div></div>
    }
  }
}

export default withToastManager(ErrorAlert);
