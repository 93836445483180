// app/javascript/projects/components/CodeOpComponent.jsx

import React from 'react';
import ColorConstants from 'constants/ColorConstants'
import {
  OP_OR
} from 'constants/OpConstants'

const CodeOpComponent = ({ op, anyClick, allClick, text }) => {
  const textCopy = text || 'Filter snippets by ** of the selected codes';
  // split text by ** to get the two parts
  const textParts = textCopy.split('**');
  const firstPart = textParts[0];
  const secondPart = textParts.length < 2 ? '' : textParts[1];
  
  const onClick = op === OP_OR ? anyClick : allClick;
  const opText = op === OP_OR ? 'any' : 'all';

  return (
    <div>
      <em>
        {firstPart}
        <span
          style={{color:ColorConstants.ACTIVE_BUTTON_BLUE, cursor:'pointer'}}
          onClick={onClick}
          className='op-button'
          ><strong>{opText}</strong></span>
        {secondPart}
      </em>
    </div>
  );
}

export default CodeOpComponent;