// app/javascript/projects/components/LoadingButton.jsx

import React from 'react';
import {Button} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import ColorConstants from 'constants/ColorConstants'
import ClipLoader from 'react-spinners/ClipLoader';
import {LinkContainer} from 'react-router-bootstrap'

class LoadingButton extends React.Component {
  constructor () {
    super();
  }
  render () {
    const width = this.props.width ? this.props.width : 200;

    const style = this.props.centered ?
      {width: width, margin:"0 auto", position:"relative" }
      :
      {width: width, position:"relative" };

    return (
      <div style={style}>
        <div style={{position:"absolute", top:"0px", left:"100%", width:"100px", padding:5}}>
          <ClipLoader
              color={ColorConstants.ACTIVE_BUTTON_BLUE}
              sizeUnit={"px"}
              size={20}
              loading={this.props.loading}
          />
        </div>
        {this.props.to &&
          <LinkContainer to={this.props.to}>
            <Button style={{width:width}}
                    bsStyle={this.props.bsStyle}
                    disabled={this.props.loading || this.props.disabled}
                    >{this.props.children}</Button>
          </LinkContainer>
        }
        {!this.props.to && !this.props.type &&
            <Button style={{width:width}}
                    bsStyle={this.props.bsStyle}
                    disabled={this.props.loading || this.props.disabled}
                    onClick={this.props.onClick}
                  >{this.props.children}</Button>
        }
        {this.props.type &&
          <Button style={{width:width}}
                  bsStyle={this.props.bsStyle}
                  disabled={this.props.loading || this.props.disabled}
                  type={this.props.type}
                  >{this.props.children}</Button>

        }
      </div>
    );
  }
}

export default LoadingButton;
