// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';
import {Glyphicon} from 'react-bootstrap'
import {
  ACTIVE_BUTTON_BLUE
} from 'constants/ColorConstants'

function HelpLink(props) {
  return (
    <div className="help-link">
      <Glyphicon
        style={{color:ACTIVE_BUTTON_BLUE}} 
        glyph={props.glyph || 'question-sign'}
      />
      {' '}<a href="#" onClick={()=>{
        if ( Intercom )
          Intercom('showArticle', props.intercomArticle)
      }}>
        {props.children}
      </a>
    </div>
  )
}

export default HelpLink;