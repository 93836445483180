// app/javascript/projects/components/DescriptorDropdown.jsx

import React from 'react';
import ColorConstants from 'constants/ColorConstants'
import {ButtonToolbar, Button, Checkbox} from 'react-bootstrap';
import { Scrollbars } from 'react-custom-scrollbars';
import FilterButton from './analysis/FilterButton'
import {
  OP_OR
} from 'constants/OpConstants'


var _ = require('underscore')

const CONTENT_BUFFER = 150;
const CHECKBOX_BUFFER = 100;

const dropDownStyle = {
  position: "relative",
  display: "inlineBlock",
  backgroundColor: "yellow",
}

const dropDownContentStyle = {
  //position: "fixed",
  position: "absolute",
  borderColor: ColorConstants.LIGHT_GREY,
  backgroundColor: "white",
  minWidth: "160px",
  width: "300px",
  zIndex: 3,
  marginTop: "40px",
  boxShadow: "2px 2px 2px 2px rgba(0,0,0,0.2)",
  borderRadius: "3px",
}

class DescriptorDropdown extends React.Component {
  constructor () {
    super();
    this.state = { width: 0, height: 0, shown:false, searchText:"", filteredCodes:[]};
    this.currentFilteredText = null;
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.onClick = this.onClick.bind(this);
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.filterText = this.filterText.bind(this);
    this.onChecked = this.onChecked.bind(this);
    this.onDoneButton = this.onDoneButton.bind(this);
  }

  setWrapperRef(node) {
     this.wrapperRef = node;
   }

  componentDidUpdate(prevProps) {
    if (this.props.codes !== prevProps.codes) {
      this.addCodes(this.props.codes);
    }
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({shown:false})
    }
  }

  componentDidMount () {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.updateWindowDimensions);
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  onClick(e) {
    this.setState({
      shown: !this.state.shown
    })
  }

  onDoneButton(e) {
    this.setState({
      shown: false
    })

    if ( this.props.applyFilter()) this.props.applyFilter();
  }

  addCodes(codes)
  {
    if ( !codes )
      return;

    if ( this.currentFilteredText == null || this.currentFilteredText.length == 0)
    {
      this.setState({filteredCodes: codes, newCodeText: ""})
    }
    else
    {
      var lowerText = this.currentFilteredText.toLowerCase();
      var filteredCodes = _.filter(codes, function(code){ return code.name.toLowerCase().includes(lowerText)});
      this.setState({
        filteredCodes: filteredCodes,
      });
    }
  }

  filterText(text) {
    this.currentFilteredText = text;
    this.setState({
      searchText: this.currentFilteredText
    });

    if ( text == null || text.length == 0)
      this.currentFilteredText = null;


    this.addCodes(this.props.codes);
  }

  onChecked(codeID, checked)
  {
    this.filterText("");
    this.props.onChecked(codeID, checked);
  }

  _getCodeName(code)
  {
    if ( code.count )
      return code.name + " (" + code.count + ")";
    else
      return code.name;
  }

  _createCheckboxFromCodes(codes)
  {
    return (codes || []).map((code, index) => {
      return <Checkbox
                      key={index}
                      checked={code.checked}
                      onChange={() => this.onChecked(code.id, code.checked)}
                      >
                      {this._getCodeName(code)}
                    </Checkbox>
    });
  }

  render () {
    var checkedCodes = this.props.codes ? this.props.codes.filter((code)=>{return code.checked}).map((code)=>{return code.name}) : [];

    var displayStyle = this.state.shown ? 'block' : 'none';
    const contentStyleDynamic = {
      ...dropDownContentStyle,
      maxHeight:this.state.height - CONTENT_BUFFER,
      display: displayStyle,
      padding: "5px",
      ...this.props.dropdownAbove ? {
        bottom: "100%"
      } : {
        top: "100%"
      }
    }

    var checkLists = this._createCheckboxFromCodes(this.props.codes);

    return (
      <div>
            <div style={dropDownStyle}>
              <FilterButton
                op={OP_OR}
                filters={checkedCodes}
                name={this.props.name}
                onClick={this.onClick}
              />
              <div style={contentStyleDynamic} ref={this.setWrapperRef}>
                <div className='descriptor-dropdown' style={{padding:"10px", width:"100%"}}>

                  <Scrollbars autoHide autoHeight autoHeightMax={this.state.height - CONTENT_BUFFER - CHECKBOX_BUFFER}>
                    <div style={{color:ColorConstants.LIGHT_GREY}}>
                      <em>
                        {this.props.subHeader || "Filter snippets by any of the selected"}               
                      </em>
                    </div>
                    <div style={{clear:"both", display:"block"}}>
                      <div>
                        {checkLists}
                      </div>
                    </div>
                  </Scrollbars>
                  <div style={{float:"right"}}>
                    <ButtonToolbar>
                      <Button bsStyle="white" onClick={this.props.onClearChecked}>Clear</Button>
                      <Button bsStyle="blue" onClick={this.onDoneButton}>{this.props.filterButtonText || "Apply Filters"}</Button>
                    </ButtonToolbar>

                  </div>
                </div>
              </div>
            </div>
      </div>
    );
  }
}

export default DescriptorDropdown;
