// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';
import GlyphDropdown from './GlyphDropdown'
import MergeDropZone from './MergeDropZone'

class CodeLabelRight extends React.Component {
  render () {
    return (
      <div>
        { this.props.showMerge ?
          <div >
            <MergeDropZone
              codeID={this.props.codeId}
              onDragHover={this.props.onDragHover}
              showMerge={this.props.isOverCode}
              showConfirmMerge={!!this.props.showConfirmMerge}
              container={this.props.container}
              confirmMerge={this.props.confirmMerge}
              onHide={this.props.onHide}
              initiateMergeCode={this.props.initiateMergeCode}
            />
          </div>
          :
          <GlyphDropdown show={this.props.showDropdown || this.props.hover}
            seeDetails={this.props.onSeeDetails}
            onRename={this.props.onRename}
            deleteCode={this.props.deleteCode}
          />
        }
      </div>
    );
  }
}

export default CodeLabelRight;
