import AppDispatcher from '../dispatcher/AppDispatcher';
import FeatureFlagAPI from './FeatureFlagAPI';
import {
  GET_FEATURE_FLAGS_RESULT
} from './FeatureFlagConstants';

export default {
  getFeatureFlags: async function() {
    return FeatureFlagAPI.getFeatureFlags().then((response) => {
      AppDispatcher.handleAction({
        actionType: GET_FEATURE_FLAGS_RESULT,
        data: response.data,
      })
    }).catch((error)=>{
      // if there was an error getting feature flags, assume that the user has no feature flags
      AppDispatcher.handleAction({
        actionType: GET_FEATURE_FLAGS_RESULT,
        data: {
          user_feature_flags: []
        },
      })
    })
  },
  joinBetaProgram: async function() {
    return FeatureFlagAPI.createFeatureFlag('ai_coding').then((response) => {
      AppDispatcher.handleAction({
        actionType: GET_FEATURE_FLAGS_RESULT,
        data: response.data,
      })
    })
  }
}