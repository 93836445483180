var _ = require('underscore');
import excerptMapper from './ExcerptMapper';

const transcriptMapper = (state, transcriptID) => {
  if ( !transcriptID ) return null;
  const transcript = state.entities.transcripts[transcriptID];
  if (!transcript ) return null;
  const excerptIDs = transcript.excerpts ? transcript.excerpts : [];
  const excerpts = excerptIDs.map((excerptID)=>{
    return state.entities.excerpts[state.mappers.excerpts[excerptID]]
  }).filter((excerpt)=>!!excerpt).map((excerpt)=>{
    return excerptMapper(state, excerpt.id)
  }).filter((excerpt)=>!!excerpt);

  return {
    id: transcriptID,
    body: transcript.body,
    excerpts: excerpts
  }

}

export default transcriptMapper
