import React, {useState, useEffect} from 'react';
import {Radio, FormGroup} from 'react-bootstrap'
import PQLAPI from './PQLAPI'
const {ACTIVE_BUTTON_BLUE} = require('../constants/ColorConstants');


function randomizeAnswers(answers, randomize) {
    // randomize the order of the answers but if there is an Other option, put it at the end
    if (answers.length > 1 && answers[answers.length - 1].name.includes('Other') && randomize) {
        // get the other
        const other = answers[answers.length - 1];
        answers = answers.slice(0, answers.length - 1);
        answers.sort(() => Math.random() - 0.5);
        answers.push(other);
    } else if (randomize) {
        answers.sort(() => Math.random() - 0.5);
    }
    return answers;
}

function Question({question, type, answers, onResponse, answer, randomize}) {
    const [randomizedAnswers, setRandomizedAnswers] = useState([]);
    answers = answers || [];

    useEffect(() => {
        setRandomizedAnswers(randomizeAnswers([...answers], randomize));
    }, [answers, randomize]);

    // hook to save the answer
    const onChange = (e) => {
        PQLAPI.saveResponse(type, e.target.value).catch((error) => {})
        onResponse(type, e.target.value);
    }

    return <div style={{textAlign: 'left'}}>
        <h4 style={{marginTop:'0px', fontWeight: 'bold'}}>{question} <span style={{ color: ACTIVE_BUTTON_BLUE }}>*</span></h4>
        <FormGroup
            controlId={type}
            style={{display: 'inline-block', textAlign: 'left'}} // Adjust the width as needed
        >
            {randomizedAnswers.map((option) => {
                return (
                    <Radio 
                        value={option.type}
                        key={option.type}
                        checked={answer === option.type}
                        onChange={onChange}
                    >
                        {option.name}
                    </Radio>
                );
            })}
        </FormGroup>
    </div>
}

export default Question;