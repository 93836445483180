// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';
import ParagraphContainer from './ParagraphContainer';
import Scrollbars from 'react-custom-scrollbars';
import UserDropdown from './UserDropdown';
import PagerWrapper from './PagerWrapper';

import {
  TRANSCRIPT_NAVIGATION_PADDING
} from 'constants/DimensionsConstants'

const PADDING = `${TRANSCRIPT_NAVIGATION_PADDING}px`

const tabStyle = {
  paddingLeft: PADDING,
  margin: "0px"
}

class ComparisonView extends React.Component {
  render () {
    const codedTranscript = this.props.codedTranscript || {paragraphs: []};
    const otherCodedTranscript = this.props.otherCodedTranscript || {paragraphs: []};

    const paragraphItems = codedTranscript.paragraphs.map((paragraph, index) =>
    {
        if ( !paragraph || (paragraph.start === paragraph.end)) return null;
        const otherParagraph = otherCodedTranscript.paragraphs[index] || null;

        return (
          <div key={paragraph.index} style={{display: 'flex'}}>
            <div style={{flex: 1}}>
              <ParagraphContainer codedParagraph={paragraph}
                                  absoluteIndex={paragraph.index}
                                  paragraphIndex={index}
                                  transcriptID={this.props.transcriptID}
                                  canEdit={false}
                                  isPrint={true}
                                  hasEditPermission={false}
                                  marginLeft={PADDING}
                                  marginRight="20px"
                                />
            </div>
            <div style={{flex: 1, borderLeft:"1px solid lightGray"}}>
              {otherParagraph ? <ParagraphContainer codedParagraph={otherParagraph}
                                  absoluteIndex={otherParagraph.index}
                                  paragraphIndex={index}
                                  transcriptID={this.props.transcriptID}
                                  canEdit={false}
                                  isPrint={true}
                                  hasEditPermission={false}
                                  marginLeft={PADDING}
                                  marginRight="20px"
                                  /> : null}
            </div>
          </div>
        );
      }
    );


    return (
      <Scrollbars
        autoHide
        style={{height:"100%"}}
        ref={(ref)=>{this.scrollbar=ref}}
      >
        <PagerWrapper pageCount={this.props.pageCount} onPage={this.props.onPage}>
          <div style={{display: 'flex', paddingTop: '15px', paddingBottom: '15px'}} >
            <div className="coded-by" style={{flex: 1, ...tabStyle}}>
              <UserDropdown
                users={this.props.users}
                onSelect={this.props.onLeftUserSelect}
                user={this.props.selectedUsers.leftUser}
              />
            </div>
            <div className="coded-by" style={{flex: 1, ...tabStyle}}>
              <UserDropdown
                users={this.props.users}
                onSelect={this.props.onRightUserSelect}
                user={this.props.selectedUsers.rightUser}
              />
            </div>
          </div>
          <div style={{marginTop:'10px'}}>
            {paragraphItems}
          </div> 
        </PagerWrapper>
      </Scrollbars>
    );
  }
}

export default ComparisonView;
