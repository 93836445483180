import React from 'react';
import {FormControl, FormGroup, InputGroup, Button} from 'react-bootstrap'

function getSearchText(loadState, count, total, searchTerm) {
  if (loadState.loaded) {
    if (count > 0) {
      if (!!total) {
        return <span>Showing {count} results, appearing in {total} transcripts.</span>;
      } else {
        resultText = <span>Showing {count} results.</span>;
      }
    } else {
      return <span>We couldn't find <strong>{searchTerm}</strong> in any transcripts.</span>;
    }
  } else if (loadState.loading) {
    return <span>Searching...</span>
  }

  return <span></span>;
}

function isDisabled(searchText) {
  return !searchText && searchText.length === 0;
}

const SearchBar = ({loadState, count, total, searchTerm, searchText, onSubmit, onSearchTextChange}) => {
  const paddingTop = 10;
  const height = 100;

  const resultText = getSearchText(loadState, count, total, searchTerm);

  return (
    <div style={{paddingTop: paddingTop, margin: 0, paddingBottom: 0, height: height}}>
      <FormGroup style={{marginBottom: 5, padding: 0}}>
        <InputGroup>
          <form onSubmit={onSubmit}>
            <InputGroup.Button>
              <FormControl type="text"
                  value={searchText}
                  onChange={onSearchTextChange}
              />
              <Button
                type="submit"
                bsStyle="primary"
                disabled={isDisabled(searchText)}>
                  Search
                </Button>
            </InputGroup.Button>
          </form>
        </InputGroup>
      </FormGroup>

      <div style={{marginTop:20}}>
        <p>{resultText}</p>
      </div>
    </div>
  );
}

export default SearchBar;