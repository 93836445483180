// SpeakerMapper
import {
  DELVE_TIME_REGEXP,
  NAME_REGEX,
  BLANK_REGEX
} from 'utils/FormatterRegExp'

const speakerMapper = (paragraphText) => {
  if ( !paragraphText ) return null;
  const regex = new RegExp(`^(${NAME_REGEX})${BLANK_REGEX}:(?:${BLANK_REGEX}(${DELVE_TIME_REGEXP}))?`);
  var res = paragraphText.match(regex);

  if ( !res ) {
    const noNameRegex = new RegExp(`^${BLANK_REGEX}(${DELVE_TIME_REGEXP})`);
    res = paragraphText.match(noNameRegex);

    if ( !!res ) {
      return {
        time: res[1],
        introText: res[0],
        bodyText: paragraphText.substring(res[0].length),
        name: null
      }
    } else {
      return {
        introText: null,
        bodyText: paragraphText,
        name: null,
        time: null    
      }
    }
  }

  return {
    introText: res[0],
    bodyText: paragraphText.substring(res[0].length),
    name: res[1],
    time: res[2] && res[2].length > 0 ? res[2] : null
  }
}

export default speakerMapper
