class TranscriptSplitter {
  constructor(){
  }

  build(transcriptText) {
    if (typeof transcriptText === 'string' || transcriptText instanceof String)
    {
      const paragraphSplit = transcriptText.split('\n');
      return paragraphSplit;
    }

    return [];
  }
}

export default TranscriptSplitter;
