// app/javascript/projects/components/ProjectsDisplay.jsx

import { Glyphicon } from 'react-bootstrap';
import React from 'react';

import CodeLabelStateConstants from 'constants/CodeLabelStateConstants'
import ColorConstants from 'constants/ColorConstants'
import DimensionsConstants, {
  CODE_LABEL_HEIGHT
} from 'constants/DimensionsConstants';

import CodeLabelText from './CodeLabel/CodeLabelText'

const LEFT_BUFFER = "30px";
const BORDER_RADIUS = "5px"
const HEIGHT = CODE_LABEL_HEIGHT.toString() + "px"
const _BOTTOM_MARGIN_INT = 0;
const BOTTOM_MARGIN = _BOTTOM_MARGIN_INT.toString() + "px";
const MERGE_HEIGHT = (CODE_LABEL_HEIGHT * 2).toString() + "px";
const COLOR_BUTTON_WIDTH = 5;

const baseStyle = {
  "borderRadius": BORDER_RADIUS + " 0px 0px " + BORDER_RADIUS,
  "background": ColorConstants.ULTRA_LIGHT_GREY,
  "padding": "0px",
  "marginBottom": BOTTOM_MARGIN,
  "width": DimensionsConstants.CODE_LABEL_WIDTH - COLOR_BUTTON_WIDTH,
  "height": HEIGHT,
  "float": "left",
  color: ColorConstants.DARK_GREY
}

const hoverStyle = {
  boxShadow: "5px 4px 10px #00000033",
}

const mergeStyle = {
  height: MERGE_HEIGHT
}

const draggingStyle = {
  opacity: .2
}

const codeColorStyle = {
  "padding": "0",
  "marginBottom": "10px",
  "background": ColorConstants.UNDERLINE_COLOR,
  "height": HEIGHT,
  "width": COLOR_BUTTON_WIDTH,
  "float": "left"
}

const ghostCodeColorStyle = {
  ...codeColorStyle,
  background: ColorConstants.GHOST_COLOR,
}

class CodeLabelBody extends React.Component {
  constructor() {
    super();
    this.state = {
      fade: true
    }
  }

  shouldFade() {
    return this.state.fade && this.props.fadeIn;
  }

  setFadeTimer() {
    setTimeout(() => {
      this.setState({
        fade: false
      })
    }, 250);
  }

  componentDidMount() {
    if ( !!this.props.fadeIn ) {
      this.setFadeTimer();
    }
  }

  componentWillUnmount() {
    if ( this.props.fadeIn ) {
      // This is called so when this scrolls off the screen the search code clears
      // I am curious if there is a better way to do this...
      // but it does seem to work
      this.props.searchedCodeUnmounted();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.fadeIn !== prevProps.fadeIn) {
      if ( !!this.props.fadeIn ) {
        this.setFadeTimer();
      } else {
        this.setState({
          fade: true
        })
      }
    }
  }

  getColor()
  {
    switch(this.props.state) {
      case CodeLabelStateConstants.CODE_MERGING_ENABLED:
      case CodeLabelStateConstants.CODE_MERGING_DISABLED:
        return ColorConstants.GHOST_COLOR;
      case CodeLabelStateConstants.CODE_GHOSTED:
        return ColorConstants.ULTRA_LIGHT_GREY;
      case CodeLabelStateConstants.CODE_SELECTED:
        if ( this.shouldFade() ) {
          return ColorConstants.ULTRA_LIGHT_GREY;
        } else {
          return ColorConstants.LIGHT_GREY;
        }
      case CodeLabelStateConstants.CODE_ENABLED:
        if ( this.shouldFade() ) {
          return ColorConstants.LIGHT_GREY;
        } else {
          return ColorConstants.ULTRA_LIGHT_GREY;
        }
      case CodeLabelStateConstants.CODE_DISABLED:
        if ( this.shouldFade() ) {
          return ColorConstants.HOVER_COLOR;
        } else {
          return ColorConstants.ULTRA_LIGHT_GREY;
        }
      default:
        return ColorConstants.ULTRA_LIGHT_GREY;
    }
  }

  getTextColor()
  {
    switch (this.props.state ) {
      case CodeLabelStateConstants.CODE_DISABLED:
      case CodeLabelStateConstants.CODE_MERGING_DISABLED:
        return ColorConstants.LIGHT_GREY;
      default:
        return ColorConstants.DARK_GREY;
    }
  }

  getFadeStyle() {
    const isDisabled = this.props.state === CodeLabelStateConstants.CODE_DISABLED;
    const timing = isDisabled ? '500ms' : '500ms';
    const delay = isDisabled ? ' 2s' : '';

    const transition = `background-color ${timing} ease-out${delay}`;

    return {
      backgroundColor: this.getColor(),
      ...(
        this.props.fadeIn && !this.state.fade &&
        {
          transition: transition,
          WebkitTransition: transition,
          MozTransition: transition
      })
    }
  }

  getStyle()
  {
    var labelCodeStyle = {
      ...baseStyle,
      color: this.getTextColor(),
      backgroundColor: this.getColor(),
      ...this.getFadeStyle()
    }

    if ( this.props.dragOver && !this.props.ghost && !!this.props.canDropOnParent ){
      labelCodeStyle = {
        ...labelCodeStyle,
        ...hoverStyle
      };
    }

    if ( this.props.mergeParent || this.props.showConfirmMerge) {
      labelCodeStyle = {
        ...labelCodeStyle,
        ...mergeStyle
      }
    }

    return labelCodeStyle;
  }

  render () {
    const code = this.props.code ? this.props.code : {};

    return (
      // width was + 43 originally, now I changed it to 60. But that was really haphazzard
        <div>
          <div className='codeLabelBody' style={this.getStyle()} onClick={this.props.onClick}>
              <div style={{float:"left", marginBottom: "0px", paddingBottom: "0px"}}>
                <div style={{width: LEFT_BUFFER}}>
                  {this.props.showCollapseGlyph &&
                    <div onClick={this.props.onCollapseClicked}
                          style={{paddingLeft: '12px',
                                  paddingTop: '12px',
                                  fontSize:"10px",
                                  cursor:"pointer"}}>
                      <Glyphicon style={{color:this.getTextColor()}}
                                  glyph={this.props.barCollapsed ? "triangle-right" : "triangle-bottom"}/>
                    </div>
                  }
                  {!this.props.showCollapseGlyph && <div style={{width:LEFT_BUFFER, height:"10px"}}>{' '}</div>}
                </div>
              </div>

            <div>
              <CodeLabelText
                  value={this.props.editableName}
                  onBlur={this.props.onBlur}
                  onFocus={this.props.onFocus}
                  onChange={this.props.onChange}
                  ghost={this.props.ghost}
                  name={this.props.name}
                  count={this.props.count}
                  edit={this.props.edit}
                  dragOver={this.props.dragOver}
                  allowPopover={this.props.allowPopover}
                  id={this.props.id}
                  />
            </div>
          </div>

          {![CodeLabelStateConstants.CODE_MERGING_ENABLED, CodeLabelStateConstants.CODE_MERGING_DISABLED].includes(this.props.state) &&
            <div style={
              this.props.state == CodeLabelStateConstants.CODE_GHOSTED ?
                ghostCodeColorStyle : codeColorStyle
            }></div>
          }
        </div>
    );
  }
}

export default CodeLabelBody;
