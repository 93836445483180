// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';
import { Button } from 'react-bootstrap';
// TimeUtil
import TimeUtil from 'utils/TimeUtil';
import MemoAvatar from './MemoAvatar';
import { useModal } from './modal/MemoModalProvider';
import AtMentionMemo from 'memos/AtMentionMemo';

import {
  ULTRA_LIGHT_GREY,
  LIGHT_GREY
} from 'constants/ColorConstants';
import HoverX from './HoverX';

const {
  AVATAR_SIZE
} = require('constants/DimensionsConstants');


const LINE_CLAMP_STYLE = {
  overflow: "hidden",
  display: "-webkit-box",
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 2,
  LineClamp: 2,
  position: "relative",
}

const UNCLAMPED_STYLE = {

}

function Memo(props) {
  const time = TimeUtil.getDisplayTime(props.time)
  const [isOverflow, setIsOverflow] = React.useState(false);
  const [isHovering, setIsHovering] = React.useState(false);
  const [isDeleteHovering, setIsDeleteHovering] = React.useState(false);
  
  const measuredRef =  React.useCallback(node => {
    if (node !== null) 
      setIsOverflow(node.scrollHeight > node.clientHeight);
  }, []);

  const style = props.isExpanded ? UNCLAMPED_STYLE : LINE_CLAMP_STYLE;
  const showSeeMoreButton = isOverflow && !props.isExpanded;
  const { openModal } = useModal();


  return (
    <div
      style={{marginTop: '15px'}}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <div className="memo">
        <div style={{ display: 'flex' }}>  
          <div style={{width: AVATAR_SIZE, marginTop: '2px'}}>
            <MemoAvatar initials={props.initials} color={props.color}/>
          </div>
      
          <div style={{flexGrow: 1, paddingLeft: '5px'}}>
            <div className="memo-author" style={{
                fontSize: '.8em',
                display: 'inline-block',
                maxWidth: '100%',
                paddingLeft: '5px',
                paddingRight: '5px',
                marginRight: '5px',
                backgroundColor: isDeleteHovering ? ULTRA_LIGHT_GREY : null,
                borderRadius: '5px',
              }}>
                <strong>{props.name}</strong>
                <span style={{color: LIGHT_GREY, paddingLeft: '10px'}}>{time}</span>
            </div>
            <div className="memo-text" ref={measuredRef} style={{
              whiteSpace: "pre-line",
              paddingLeft: '5px',
              ...style
            }}>
              <AtMentionMemo text={props.text} />
              {
              showSeeMoreButton &&
              <div
                style={{
                  position: 'absolute',
                  bottom: 0,
                  right: 0,
                  paddingLeft: '10px',
                  backgroundImage: 'linear-gradient(to right, transparent, white 20%)',
                }}
              >
                <Button 
                  bsStyle="link"
                  style={{
                    margin: 0,
                    padding: 0,
                    fontSize: '.8em',
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    props.memoExpanded(props.id)
                    }
                  }
                >
                  ...See More
                </Button>
              </div>
            }
            </div>
          </div>

          {process.env.MEMO_FLAG && props.hasEditPermission &&
            <div style={{width: '20px', flexShrink: 0, flexGrow: 0}}>
              <HoverX
                className="delete-memo"
                show={isHovering}
                onClick={() => openModal(props.id)}
                isHovering={setIsDeleteHovering}
              />
            </div>
          }
        </div>
      </div>
    </div>
  );
}

export default Memo;
