const _ = require('underscore');

import {
  GET_FEATURE_FLAGS_RESULT
} from './FeatureFlagConstants'

function feature_flag_reducer(action, state = {})
{
  if ( !action ) return state;

  switch (action.actionType)
  {
    case GET_FEATURE_FLAGS_RESULT:
      {
        const feature_flags = action.data.user_feature_flags.map(feature_flag => {
          return feature_flag.feature
        })

        // just get the values of the object and make it an array
        return {
          ...state,
          feature_flags,
          loaded: true
        }
      }
    default:
      return state;
  }
}

export default feature_flag_reducer
