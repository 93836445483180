module.exports =  {
                    // CREATE MEMO
                    CREATE_MEMO: "CREATE_MEMO",
                    CREATE_MEMO_RESULT: "CREATE_MEMO_RESULT",
                    CREATE_MEMO_ERROR: "CREATE_MEMO_ERROR",
                    EXPAND_MEMO_STEP: 50,
                    // DELETE MEMO
                    DELETE_MEMO: "DELETE_MEMO",
                    DELETE_MEMO_RESULT: "DELETE_MEMO_RESULT",
                    DELETE_MEMO_ERROR: "DELETE_MEMO_ERROR",
                    DELETE_MEMO_CABLE: "DELETE_MEMO_CABLE",
                  }
