// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';
import ReactDOM from 'react-dom'
import {MenuItem, FormControl, FormGroup, InputGroup, DropdownButton} from 'react-bootstrap'

const DROPDOWN_TYPES = {
  TEXT: "TEXT",
  BUTTON: "BUTTON",
}

class TextDropdown extends React.Component {
  constructor () {
    super();
    this.state = {
      answerText: "hey",
      showDropdown: false,
      filtered_multi_choices: [],
      dropdownType: DROPDOWN_TYPES.BUTTON,
      textMatchesChoice: false
    };

    this.onInputChange = this.onInputChange.bind(this);
    this.onClick = this.onClick.bind(this);
    this.onToggle = this.onToggle.bind(this);
    this.addAndSelectChoice = this.addAndSelectChoice.bind(this);
  }

  filterChoices(newText)
  {
    const lowerCase = newText.toLowerCase();

    const choices = this.props.multi_choices.filter((choice)=> {
      return choice.choice.toLowerCase().includes(lowerCase)
    });

    this.setState({
      filtered_multi_choices: choices
    });
  }

  parseNewText(newText)
  {
    const choices = this.props.multi_choices.filter((choice)=> {return choice.choice==newText});
    if ( choices.length == 0 ){
      this.setState({
        textMatchesChoice: newText.length == 0
      })
      return
    };

    const choice = choices[0];
    this.setState({
      textMatchesChoice: true
    })
    this.props.createSelectionCallback(choice.id);
  }

  onInputChange(e)
  {
    this.setState({
      answerText: e.target.value,
      showDropdown: true,
      dropdownType: DROPDOWN_TYPES.TEXT
    })

    this.filterChoices(e.target.value);
    this.parseNewText(e.target.value);


    /*
    if ( this.firstMenuItem )
    {
      let node = ReactDOM.findDOMNode(this.firstMenuItem);
      if ( node.childNodes.length > 0)
        node.childNodes[0].focus();
    }
    */

  }

  onClick (choiceText, e) {
    this.setState({
      answerText: choiceText
    });
    this.parseNewText(choiceText);
  }

  onToggle(isOpen, e, source)
  {
    this.setState({
      showDropdown: isOpen,
      dropdownType: DROPDOWN_TYPES.BUTTON
    })

    /*
    if ( source.source == 'click' &&
          this.state.dropdownType == DROPDOWN_TYPES.TEXT &&
          !isOpen)
    {
      this.setState({
        dropdownType: DROPDOWN_TYPES.BUTTON,
        filtered_multi_choices: this.props.multi_choices
      })
    }
    else {
      this.setState({
        dropdownType: isOpen ? DROPDOWN_TYPES.BUTTON : DROPDOWN_TYPES.NONE
      })
    }
    */
  }

  _getAnswerText(props)
  {
    if ( !props.selected_multi_choice ) return "";
    return props.selected_multi_choice.choice;
  }

  componentWillReceiveProps (nextProps) {
    const nowAnswerText = this._getAnswerText(this.props);
    const nextAnswerText = this._getAnswerText(nextProps);

    if ( nowAnswerText != nextAnswerText)
    {
      this.setState({
        answerText: nextAnswerText,
      });

      this.filterChoices(nextAnswerText);
    }
  }

  componentDidMount () {
    this.filterChoices("");
    this.setState({
      answerText: this._getAnswerText(this.props)
    })
  }

  componentWillUnmount () {
  }

  addAndSelectChoice(text, e) {
    this.props.addAndSelectChoiceCallback(text);
  }

  render () {
    const choices = this.state.dropdownType == DROPDOWN_TYPES.TEXT ?
                                                this.state.filtered_multi_choices :
                                                this.props.multi_choices;

    const dropdownOptions = choices.map((choice, index)=>{
        return <MenuItem  key={index}
                          style={{width:"280px"}}
                          onClick={(e) => this.onClick(choice.choice, e)}
                          active={this.props.selected_multi_choice &&
                                    this.props.selected_multi_choice.id == choice.id}
                          >{choice.choice}
                        </MenuItem>
    });

    return (
      <div>
        <FormGroup>
            <InputGroup>
              <FormControl  type="text"
                            onChange={this.onInputChange}
                            value={this.state.answerText}/>
              <DropdownButton
                componentClass={InputGroup.Button}
                id="descriptor-dropdown"
                title=""
                pullRight
                open={this.state.showDropdown}
                onToggle={this.onToggle}
              >
              {!this.state.textMatchesChoice &&
                this.state.dropdownType == DROPDOWN_TYPES.TEXT &&
                <MenuItem style={{width:"280px"}}
                  ref={(input)=>{
                    this.firstMenuItem = input;
                  }}
                  onClick={(e)=> this.addAndSelectChoice(this.state.answerText)}
                  >Add: {this.state.answerText}</MenuItem>
              }
              {!this.state.textMatchesChoice &&
                this.state.dropdownType == DROPDOWN_TYPES.TEXT &&
                <MenuItem style={{width:"280px"}} divider />
              }
              {dropdownOptions}
            </DropdownButton>
          </InputGroup>
        </FormGroup>
      </div>
    );
  }
}

export default TextDropdown;
