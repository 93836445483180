import ParagraphClass from 'utils/ParagraphClass'
import TranscriptSplitter from 'utils/TranscriptSplitter'

class ParagraphBuilder {
  constructor(){
    this.transcriptSplitter = new TranscriptSplitter();
  }

  build(transcriptText) {
    if ( !transcriptText ) return [];
    // you are going to want to pull this out. So that we can reuse it in edit paragraph mapper
    const paragraphSplit = this.transcriptSplitter.build(transcriptText);

    var paragraphStart = 0;
    let paragraphs = [];

    for ( var index = 0; index < paragraphSplit.length; index++ )
    {
      var paragraph = new ParagraphClass(paragraphSplit[index], paragraphStart, index);
      paragraphs.push(paragraph);
      paragraphStart = paragraph.getEnd() + 1; // one for the new line
    }

    return paragraphs;
  }
}

export default ParagraphBuilder;
