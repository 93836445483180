import OptimisticMapperHelper from 'mappers/OptimisticMapperHelper'

const CANT_DROP_MARGIN = {
  canDropOnMargin: false
}

const TOP_MARGIN_DROP = {
  parent_id: null,
  position: 1,
  canDropOnMargin: true
}

export default (state, dragCodeId) => {
  if ( !dragCodeId ) return CANT_DROP_MARGIN;
  const dragCode = OptimisticMapperHelper.getCode(state, dragCodeId);
  const noop = !!dragCode && !dragCode.parent_id && dragCode.position === 1;
  return {
    ...TOP_MARGIN_DROP,
    noop
  };
}
