// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';

import FileDropZone from './FileDropZone'
import ProjectsStore from 'stores/ProjectsStore'
import TranscriptActions from 'actions/TranscriptActions'
import FileLoadingContainer from './FileLoadingContainer'
import ColorConstants from 'constants/ColorConstants'
import NewTranscriptContainer from './NewTranscriptContainer'
import {Link} from 'react-router-dom'
import {Button} from 'react-bootstrap'

import {
  ACCOUNT_WIDTH
} from 'constants/DimensionsConstants'


class DragDropTranscriptContainer extends React.Component {
  constructor () {
    super();

    this.cachedFiles = ProjectsStore.getFiles();

    this.state = {
      files: this.cachedFiles,
      isHover: false
    };

    this._onChange = this._onChange.bind(this);
    this.onDrop = this.onDrop.bind(this);
    this.isHovering = this.isHovering.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState)
  {
    if ( nextProps.projectID != prevState.cachedProjectID )
    {
      return {
        ...prevState,
        cachedProjectID: nextProps.propertyID,
      }
    }

    return null;
  }

  componentDidMount () {
    ProjectsStore.addChangeListener(this._onChange);
  }

  componentWillUnmount () {
    ProjectsStore.removeChangeListener(this._onChange);
  }

  _onChange()
  {
    this.setState({
      files: ProjectsStore.getFiles()
    })
  }

  onDrop(acceptedFiles){
    this.setState({
      isHover: false
    })
    TranscriptActions.uploadManyTranscripts(this.props.projectID, acceptedFiles);
  }

  isHovering(isHover){
    this.setState({
      isHover: isHover
    })
  }

  render () {
      return (
        <div style={{height:this.props.height}}>
            <FileDropZone
              onDrop={this.onDrop}
              isHovering={this.isHovering}
              >
              {this.props.projectID &&
                <NewTranscriptContainer
                  projectID={this.props.projectID}
                  height={this.props.height}
                  isHover={this.state.isHover}
                  onboardingState={this.props.onboardingState}
                />
              }
            </FileDropZone>
        </div>
      );
  }
}

export default DragDropTranscriptContainer;
