function recursivePromise(func, offset, funcArguments) {
  const promise = func(...funcArguments, offset);
  if ( !promise ) return Promise.reject(new Error('Function did not return promise'))
  return promise.then((results)=>{
    if ( results && results.next )
      return recursivePromise(func, results.next, funcArguments)
    else
      return Promise.resolve({results:'completed'});
  })
}

var pagingUtil = (func, ...funcArguments) => {
  if ( !func ) return Promise.reject(new Error('Must Pass in Function'))

  return recursivePromise(func, 0, funcArguments);
}

export default pagingUtil
