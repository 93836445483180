// app/javascript/projects/components/AnalysisDisplayContainer.jsx

import React from 'react';

// Stores
import FilterStore from 'stores/FilterStore'
import AnalysisStore from 'stores/AnalysisStore'
import ProjectsStore from 'stores/ProjectsStore'
import CodeStore from 'stores/CodeStore'
import DescriptorStore from 'stores/DescriptorStore'
import CodedTranscriptStore from 'stores/CodedTranscriptStore'

import AnalysisDisplay from './AnalysisDisplay'
import {MemoModalProvider} from './modal/MemoModalProvider';

import TranscriptCodePageContainer from './TranscriptCodePageContainer'

class AnalysisDisplayContainer extends React.Component {
  constructor () {
    super();
    this.state = {
        ...AnalysisStore.getFilteredExcerpts(null)
      }

    this._onChange = this._onChange.bind(this);
  }

  // static
  static _getNewState (projectID) {
    if ( !projectID ) return {};

    const selectedExcerpt = AnalysisStore.getSelectedExcerpt(projectID);
    var selectedExcerptID = selectedExcerpt ? selectedExcerpt.id : null;
    return {
      selectedExcerptID: selectedExcerptID,
      ...AnalysisStore.getFilteredExcerpts(projectID),
    }
  }

  _onChange () {
    const newState = this.constructor._getNewState(this.props.projectID);
    this.setState(newState);
  }

  componentDidMount () {
    AnalysisStore.addChangeListener(this._onChange);
    CodeStore.addChangeListener(this._onChange);
    DescriptorStore.addChangeListener(this._onChange);
    ProjectsStore.addChangeListener(this._onChange);
    CodedTranscriptStore.addChangeListener(this._onChange);
    FilterStore.addChangeListener(this._onChange);
  }

  componentWillUnmount () {
    AnalysisStore.removeChangeListener(this._onChange);
    CodeStore.removeChangeListener(this._onChange);
    DescriptorStore.removeChangeListener(this._onChange);
    ProjectsStore.removeChangeListener(this._onChange);
    CodedTranscriptStore.removeChangeListener(this._onChange);
    FilterStore.removeChangeListener(this._onChange);
  }

  render () {
    return (
      <div>
        <MemoModalProvider>
          <TranscriptCodePageContainer
            projectID={this.props.projectID}
            hasEditPermission={this.props.hasEditPermission}
          />
          <AnalysisDisplay
            checkedCodeNames={this.state.checkedCodeNames}
            loadingState={this.state.loadingState}
            excerpts={this.state.excerpts}
            selectedExcerptID={this.state.selectedExcerptID}
            projectID={this.props.projectID}
            height={this.props.height}
            onboardingState={this.props.onboardingState}
            hasEditPermission={this.props.hasEditPermission}
            />
        </MemoModalProvider>
      </div>

    );
  }
}

export default AnalysisDisplayContainer;
