import AppDispatcher from 'dispatcher/AppDispatcher'
import QualAPI from 'utils/QualAPI'
import ReferConstants from 'constants/ReferConstants'

function dispatchError(type, payload)
{
  const response = payload.response ? payload.response : {};
  const data = response.data ? response.data : {};
  const errors = data.errors ? data.errors : [];

  AppDispatcher.handleAction({
    actionType: type,
    data: response,
    errors: errors,
  })
}

var ReferActions = {
  /***********************\
  *
  * Refer
  *
  \************************/

  referAFriend: function()
  {
    AppDispatcher.handleAction({
      actionType: ReferConstants.REFER_START,
      data:{}
    });
  },

  sendReferral: function(email) {
    QualAPI.referUser(email);

    AppDispatcher.handleAction({
      actionType: ReferConstants.REFER_SEND,
      data: {
        email: email
      }
    })
  },

  dismissReferral: () => {
    AppDispatcher.handleAction({
      actionType: ReferConstants.REFER_DISMISS,
      data:{}
    })
  }
}

export default ReferActions
