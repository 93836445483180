class HTTPError extends Error {
  constructor(error) {
    super(error.message);
    this.name = "HTTPError"
    this.originalError = error;
    Object.setPrototypeOf(this, HTTPError.prototype);
  }

  static createHTTPError(httpError) {
    const response = httpError.response || {};
    const message = httpError.message;
    if ( response.status === 404 ) {
      return new HTTP404Error(httpError)
    } else if ( response.status === 403 ) {
      return new HTTP403Error(httpError)
    }

    return null;
  }
}

class HTTP404Error extends HTTPError {
  constructor(error) {
    super(error);
    this.name = "HTTP404Error"
    this.status = 404;
    Object.setPrototypeOf(this, HTTP404Error.prototype);
  }
}

class HTTP403Error extends HTTPError {
  constructor(error) {
    super(error);
    this.name = "HTTP403Error"
    this.status = 403;
    Object.setPrototypeOf(this, HTTP403Error.prototype);
  }
}

class HTTP401Error extends HTTPError {
  constructor(error) {
    super(error);
    this.name = "HTTP401Error"
    this.status = 401;
    Object.setPrototypeOf(this, HTTP401Error.prototype);
  }
}

export {
  HTTPError,
  HTTP401Error,
  HTTP403Error,
  HTTP404Error
}
