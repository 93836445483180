var _ = require('underscore');

export default (action, state) =>
{
  const data = action.data || {};
  const projectID = data.project_id;
  const collaboratorId = data.collaborator_id;
  const role = data.role;
  state = {
    ...state,
    shareState:{
      show: false,
      message: null,
    }
  }

  const project = state.entities.projects[projectID];
  if ( !project || !project.collaborators ) return state;
  const collaborators = (project.collaborators || []).map((collabator)=>{
    if ( collabator.id !== collaboratorId ) return collabator;
    return {
      id: collaboratorId,
      role
    }
  })

  return {
    ...state,
    shareState:{
      show: false,
      message: null,
    },
    entities:{
      ...state.entities,
      projects:{
        ...state.entities.projects,
        [projectID]:
        {
          ...project,
          collaborators
        }
      }
    }
  }
}
