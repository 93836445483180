var _ = require('underscore');

const pluralFileString = (fileCount) => fileCount == 1 ? 'file' : 'files';

const dragDropMessageMapper = (fileLength, loadedFileLength, errorFileLength) => {
  let message = null;
  let errorMessage = null;
  let done = fileLength === loadedFileLength + errorFileLength;

  if ( errorFileLength > 0 ) {
    errorMessage = errorMessage = `Issues importing ${errorFileLength} ${pluralFileString(errorFileLength)}`;
  }

  if ( done ) {
    message = `${loadedFileLength} ${pluralFileString(loadedFileLength)} successfully imported`;
  } else {
    message = `Importing files: ${loadedFileLength}/${fileLength}`;
  }

  return {
    message: message,
    errorMessage: errorMessage
  }
}

export default dragDropMessageMapper
