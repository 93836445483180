import { normalize, schema } from 'normalizr';
import {
  MERGE_CODE,
  MERGE_CODE_INITIATED,
  MERGE_CODE_CANCELLED
} from 'constants/MergeConstants'

import InitialState from 'constants/InitialState'
import OptimisticMapperHelper from 'mappers/OptimisticMapperHelper'
import positionReducer from './PositionReducer'
import codeIdToProjectIdMapper from 'mappers/CodeIdToProjectIdMapper'

var _ = require('underscore');

function shouldMerge(name) {
  if ( name === '' ) return true;
  return !!name;
}

function mergeReducer(action, state = InitialState)
{
  if ( !action ) return state;

  switch (action.actionType)
  {
    case MERGE_CODE:
    {
      const codeId = OptimisticMapperHelper.getCodeId(state, action.data.mergeCodeId);
      const intoCodeId = OptimisticMapperHelper.getCodeId(state, action.data.mergeIntoCodeId);

      if ( !codeId || !intoCodeId ) return state;

      const mergeCode = OptimisticMapperHelper.getCode(state, codeId) || {};

      const projectID = codeIdToProjectIdMapper(state, codeId);
      state = positionReducer(
        {
          delta: -1,
          parent_code_id: mergeCode.parent_id || null,
          position: mergeCode.position || 1,
          project_ids: [projectID]
        },
        state
      )

      const intoCode = OptimisticMapperHelper.getCode(state, intoCodeId) || {};

      const newCode = {
        ...intoCode,
        name: [intoCode.name, mergeCode.name].filter((name)=>!!name).join(' | ')
      }

      const projects = Object.entries(state.entities.projects).map((entity)=>{
        const projectID = entity[0];
        const project = entity[1];
        return {
          [projectID]:{
            ...project,
            ...(project.codes && {codes: project.codes.filter((code)=> code!=codeId)})
          }
        }
      }).reduce((acc, project)=>{return {...acc, ...project}}, {});

      const codes = {...state.entities.codes};
      delete codes[codeId];

      return {
        ...state,
        entities:{
          ...state.entities,
          codes:{
            ...codes,
            ...(newCode.id && {[intoCodeId]: newCode})
          },
          projects:projects,
        },
        mergeCodes: {}
      }
    }
    break;
    case MERGE_CODE_INITIATED: {
      return {
        ...state,
        mergeCodes: action.data,
      }
    }
    break;
    case MERGE_CODE_CANCELLED: {
      return {
        ...state,
        mergeCodes: {}
      }
    }
    break;
    default:
      return null;
  }
}

export default mergeReducer
