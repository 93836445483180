// app/javascript/projects/components/CodeBookH.jsx

import React from 'react';
import {Glyphicon} from 'react-bootstrap'
import QualCodeActions from 'actions/QualCodeActions'
import ColorConstants from 'constants/ColorConstants'
import Textarea from 'react-textarea-autosize';
import ExcerptConstants from 'constants/ExcerptConstants'
import {LinkContainer} from 'react-router-bootstrap'

const CHANGE_COUNT_AUTO_SAVE = 100;

class CodeBookH extends React.Component {
  constructor () {
    super();
    this.state = {
      textAreaFocused: false,
      synthesisText: "",
    }

    this.onTextAreaFocus = this.onTextAreaFocus.bind(this);
    this.onTextAreaBlur = this.onTextAreaBlur.bind(this);
    this.onSynthesisChange = this.onSynthesisChange.bind(this);
    this.onCollapseClicked = this.onCollapseClicked.bind(this);
    this.count = 1;
  }

  saveText(synthesisText)
  {
    if ( synthesisText != this.props.code.synthesis )
      QualCodeActions.updateCodeSynthesis(this.props.code.id, synthesisText);
  }

  onSynthesisChange(event){
    this.setState({synthesisText: event.target.value})

    this.count += 1;
    if ( this.count % CHANGE_COUNT_AUTO_SAVE == 0)
      this.saveText(event.target.value);
  }

  onTextAreaFocus (e)
  {
    this.setState({
        textAreaFocused: true,
        synthesisText: this.props.code.synthesis,
    });
  }

  onTextAreaBlur (e)
  {
    this.setState({
        textAreaFocused: false,
    });

    this.saveText(this.state.synthesisText);
  }

  onCollapseClicked (e)
  {
    this.props.onCollapseClicked(this.props.code.id);
  }


  render () {
    const text = this.state.textAreaFocused ? this.state.synthesisText : this.props.code.synthesis;
    const backgroundColor = this.props.parent ? ColorConstants.ULTRA_LIGHT_GREY: "white";

    const to = this.props.code && this.props.code.server_id ?
              `/projects/${this.props.projectID}/codes/${this.props.code.server_id}` :
              `/projects/${this.props.projectID}/codes/`;


    return (
      <div style={{backgroundColor: backgroundColor,
                  border: ExcerptConstants.BORDER,
                  borderRadius: ExcerptConstants.BORDER_RADIUS,
                  padding: ExcerptConstants.PADDING,
                  paddingLeft: ExcerptConstants.PADDING,
                  paddingRight: ExcerptConstants.PADDING,
                  paddingTop: "20px",
                  paddingBottom: "15px",
                  marginTop: 10}}>

        <div>
          <div style={{float:"left", width:"95%", marginBottom: "0px", paddingBottom: "3px"}}>
            <LinkContainer to={to} style={{cursor:"pointer"}}>
              <div style={{margin:"0px", padding:"0px"}}>
                {this.props.parent && <h3 style={{margin:0}}>{this.props.code.name} ({this.props.code.count})</h3>}
                {!this.props.parent && <h5 style={{margin:0}}>{this.props.code.name} ({this.props.code.count})</h5>}
              </div>
            </LinkContainer>

          </div>

          {this.props.parent && this.props.code.children && this.props.code.children.length > 0 &&
            <div style={{float:"right", marginBottom: "0px", paddingBottom: "0px"}}>
              <Glyphicon onMouseUp={this.onCollapseClicked} style={{color:ColorConstants.LIGHT_GREY, cursor:"pointer"}} glyph={this.props.collapsed ? "chevron-right" : "chevron-down"}/>
            </div>
          }

          <div style={{clear:"both", margin:0, padding:0}}/>
        </div>

        {
          this.props.hasEditPermission ?
          <Textarea placeholder="Write a description or thoughts about this code"
                    onChange={this.onSynthesisChange}
                    onFocus={this.onTextAreaFocus}
                    onBlur={this.onTextAreaBlur}
                    value={text ? text : ""}
                    style={{  width: "95%",
                              resize:'none',
                              outline: "none",
                              borderStyle: "none",
                              backgroundColor:backgroundColor,
                              paddingLeft: 0,
                          }}
          />
          :
          <p>{text}</p>
        }
      </div>
    );
  }
}

export default CodeBookH;
