import {
  OP_DEFAULT
} from 'constants/OpConstants'

const NULL_STATE = {multi_choices:[], transcripts:[], codes: []};

const _getProject = (filterState, projectID) => {
  const projects = (filterState || {}).projects || {};
  return projects[projectID] || {};
}

const _getNext = (filterState, projectID) => _getProject(filterState, projectID).next;
const _getUuid = (filterState, projectID) => _getProject(filterState, projectID).uuid;
const _getCount = (filterState, projectID) => _getProject(filterState, projectID).count;

const _getFilter = (filterState, projectID) => {
  const project = _getProject(filterState, projectID);
  return project.filters || {
    transcripts: [],
    codes: [],
    descriptors: {},
    op: OP_DEFAULT
  };
}

const filterMapper = (filterState, projectID) => {
  try {
    if ( !projectID ) return NULL_STATE;
    const projectFilter = _getFilter(filterState, projectID);

    return {
      multi_choices: projectFilter.descriptors || {},
      transcripts: projectFilter.transcripts || [],
      codes: projectFilter.codes || [],
      op: projectFilter.op || OP_DEFAULT,
      sort: projectFilter.sort || null,
      uuid: _getUuid(filterState, projectID),
      next: _getNext(filterState, projectID),
      count: _getCount(filterState, projectID)
    }
  } catch (e) {
    console.error(e);
    return NULL_STATE;
  }
}

export default filterMapper
