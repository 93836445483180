import API from 'apis/API'

var MemoAPI = {
  // create memo
  createMemo: async function(excerpt_id, text, memo_client_id) {
    if (!excerpt_id)
      throw new Error('no excerpt_id for create memo');
    
    if (!text)
      throw new Error('no text for create memo');

    return API.post(`/api/excerpts/${excerpt_id}/memos`, {
      memo: { text },
      memo_client_id: memo_client_id || null
    });
  },
  deleteMemo: async function(memo_id) {
    if (!memo_id)
      throw new Error('no memo_id for delete memo');

    return API.delete(`/api/memos/${memo_id}`);
  }
};

export default MemoAPI
