import codesHiearchyMapper from 'mappers/CodesHiearchyMapper'
import codeMapper from 'mappers/CodeMapper'
const ROOT_KEY = 'ROOT'
import exportProjectNameMapper from './ExportProjectNameMapper'

function recursiveBuild(state, projectID, parentMapper, key)
{
  const host = window && window.location && window.location.host ? window.location.host : "app.delvetool.com";

  var children = [];
  const childrenIDs = key in parentMapper ? parentMapper[key] : [];

  var children = childrenIDs.map((childID)=>{
    return recursiveBuild(state, projectID, parentMapper, childID);
  });

  if ( key == ROOT_KEY)
    return children.sort(function (a, b) {return a.position-b.position});

  let code = codeMapper(state, key);

  const excerpts = code.excerpts;
  code = code.code ? code.code : {};
  const synthesis = code.synthesis ? code.synthesis : "";
  const synthesisArray  = synthesis.split('\n').filter((line)=>!!line && line.length > 0);

  return {
    id: code.server_id ? code.server_id : code.id,
    url:`https://${host}/projects/${projectID}/codes/${code.server_id ? code.server_id : code.id}`,
    name: code.name,
    count: code.count,
    position: code.position,
    synthesisArray: synthesisArray,
    excerpts: excerpts.map((excerpt)=>{
      const transcript = excerpt.transcript ? excerpt.transcript : {};
      const text = excerpt.text ? excerpt.text : "";
      const texts = text.split('\n').filter((line)=>!!line && line.length > 0)
      return {
        url:`https://${host}/transcripts/${excerpt.transcript.id}?snippet=${excerpt.server_id || excerpt.id}`,
        transcript_name: transcript.name,
        start: excerpt.start,
        end: excerpt.end,
        textArray: texts
      }
    }),
    children: children.sort(function (a, b) {return a.position-b.position}),
  }
}

const exportCodesMapper = (state, projectID) => {
  var parentMapper = codesHiearchyMapper(state, projectID);
  const returnCodes = recursiveBuild(state, projectID, parentMapper, ROOT_KEY);
  const project = state.entities.projects[projectID] ? state.entities.projects[projectID] : {};
  const {projectName, exportFileName} = exportProjectNameMapper(state, projectID,'Codes.docx');

  return {codes: returnCodes, fileName: exportFileName, projectName: projectName};
}

export default exportCodesMapper
