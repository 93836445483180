// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';
import QualCodeActions from 'actions/QualCodeActions'
import AuthStore from 'stores/AuthStore'
import {Link, Redirect} from 'react-router-dom'
import TokenDisplay from './TokenDisplay'

class ResetDisplay extends React.Component {
  constructor () {
    super();
    this._onChange = this._onChange.bind(this);
    this.shouldRedirect = this.shouldRedirect.bind(this);
    const resetState = AuthStore.getResetPasswordState();
    this.state = {
      ...resetState,
      initialLastReset: resetState.lastReset
    }
  }

  _onChange () {
    this.setState({
      ...AuthStore.getResetPasswordState()
    })
  }

  componentDidMount () {
    AuthStore.addChangeListener(this._onChange);
  }

  componentWillUnmount () {
    AuthStore.removeChangeListener(this._onChange);
  }

  resetPassword(token, password, confirmPassword)
  {
    QualCodeActions.resetPassword(token, password, confirmPassword);
  }

  shouldRedirect () {
    return this.state.initialLastReset != this.state.lastReset;
  }

  render () {
    return (
      <div>
        {this.shouldRedirect() && <Redirect to="/sign_in"/>}
        <TokenDisplay
          header="Reset Delve Password"
          error={this.state.error}
          tokenAction={this.resetPassword}
          buttonText="Reset Password"
        />
      </div>
    );
  }
}

export default ResetDisplay;
