const _ = require('underscore');

function flattenCodes(codes, collapsed) {
  codes = codes || [];
  const returnValue = codes.map((code)=>{
    let children = [];
    if ( !(code.barCollapsed))
      children = flattenCodes(code.children || [], code.barCollapsed || collapsed);

    code.children = null;
    if ( !code.filtered && (!collapsed))
      children.unshift(code);
    return children;
  });

  return _.flatten(returnValue);
}

export default flattenCodes;
