// NIL means way in the future
import codeListMapper from 'mappers/exports/CodeListMapper'
import ExportMatrix from './ExportMatrix'
import exportProjectNameMapper from './ExportProjectNameMapper'

var _ = require('underscore');

const getRowCode = (codeIndex) => codeIndex + 1;
const getColCode = (codeIndex) => codeIndex + 2;

const getDescriptorChoices = (state, projectID) => {
  const descriptors = state.entities.descriptors || {};
  const descriptorIndices = (state.projectToDescriptor[projectID] || {}).descriptors || [];
  const choices = state.entities.multi_choices || {};

  return descriptorIndices.map((descriptorIndex)=>{
    const descriptor = descriptors[descriptorIndex];
    if ( !descriptor ) return null;

    return (descriptor.multi_choices || []).map((choiceIndex)=>{
      return {
        ...choices[choiceIndex],
        descriptor_id: descriptor.id,
        name: descriptor.name
      }
    })
  }).flat().filter((choice)=>!!choice)
}

const COL_OFFSET = 3;
const ROW_OFFSET = 2;

const DESCRIPTOR_OFFSET = 1;
const DESCRIPTOR_DESCRIPTION_OFFSET = DESCRIPTOR_OFFSET - 1;

const DESCRIPTOR_DESCRIPTION = '# of unique transcripts with this descriptor that have this code'

const EXPORT_NAME = 'Code x Descriptor Matrix';

const codesVDescriptorArray = (state, projectID, projectName, report) => {
  const {codes} = codeListMapper(state, projectID);
  const descriptorList = getDescriptorChoices(state, projectID)

  const matrix = new ExportMatrix(codes.length + ROW_OFFSET, descriptorList.length + COL_OFFSET);

  const descriptorNames = descriptorList.map((descriptor)=>`${descriptor.name}: ${descriptor.choice}`)

  matrix.setCodeRows(codes, ROW_OFFSET, COL_OFFSET);
  matrix.setRow(descriptorNames.map(()=>DESCRIPTOR_DESCRIPTION), DESCRIPTOR_DESCRIPTION_OFFSET, COL_OFFSET);
  matrix.setRow(descriptorNames, DESCRIPTOR_OFFSET, COL_OFFSET);

  if ( report && report.multi_choices ) {
    for ( let descriptorIndex = 0; descriptorIndex < descriptorList.length; descriptorIndex ++ ) {
      const {id} = descriptorList[descriptorIndex];
      const reportDescriptor = report.multi_choices[id];
      if ( !reportDescriptor ) continue;
      for ( let codeIndex = 0; codeIndex < codes.length; codeIndex++ ) {
        const [code_id] = codes[codeIndex];
        const reportCode = reportDescriptor.codes[code_id];
        if ( !reportCode ) continue
        const transcriptCount = reportCode.transcript_count;
        matrix.setValue(codeIndex + ROW_OFFSET, descriptorIndex + COL_OFFSET, transcriptCount);
      }
    }
  }

  matrix.setHeaders(projectName, EXPORT_NAME)
  return matrix.toCSV();
}

const exportCodesVDescriptorMapper = (state, projectID, report) => {
  const {projectName, exportFileName} = exportProjectNameMapper(state, projectID, 'CodeXDescriptor');

  const matrix = codesVDescriptorArray(state, projectID, projectName, report.report);

  return {
    projectName: exportFileName,
    matrix
  }
}

export default exportCodesVDescriptorMapper
