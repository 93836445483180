// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';
import CashierActions from 'actions/CashierActions'
import AccountSingleButton from '../AccountSingleButton'

class Cancel extends React.Component {
  constructor () {
    super();
    this.state = {
    };

    this.cancelSubscription = this.cancelSubscription.bind(this);
  }

  cancelSubscription(e)
  {
    if ( this.props.subscription && this.props.subscription.id)
      CashierActions.cancelSubscription(this.props.subscription.id)
  }

  render () {
    return (
      <div>
        <AccountSingleButton
          error={this.props.cancelSubscriptionInfo.error}
          onClick={this.cancelSubscription}
          loading={this.props.cancelSubscriptionInfo.loading}
          header="Cancel Subscription"
          button="Cancel Subscription"
          disclaimer="By canceling your subscription, you agree to remove
                      access to Delve from you and your team members
                      after your next billing cycle ends."
          body={<p>After canceling, you and your team members will still be able to
                  use Delve until {this.props.subscription.current_period_end}.
                  Your research stays in Delve even while your subscription is canceled.
                  You can renew your subscription in the future to pick up where
                  you left off.</p>}
          />
      </div>
    );
  }
}

export default Cancel;
