// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';
import GlyphHoverButton from './GlyphHoverButton';

function EditButton(props) {
  return <GlyphHoverButton
    show={props.show}
    onClick={props.onClick}
    glyph='pencil'
  />
}

export default EditButton;
