// app/javascript/projects/components/ProjectsDisplay.jsx

import React, { useState, useContext } from 'react';
import Thread from './Thread';
import UserContext from 'contexts/UserContext';
import { EXPAND_MEMO_STEP } from 'memos/MemoConstants';
import useStreamHandler from 'utils/useStreamHandler';
import ChatActions from 'chat/ChatActions';


function ThreadContainer(props) {
  const [addedMemoCount, setAddedMemoCount] = useState(0);
  const [streamConfig, setStreamConfig] = useState({ url: null, headers: null });
  const streamData = useStreamHandler(streamConfig.url, streamConfig.headers);
  const data = useContext(UserContext); 
  const user = data.user || {};
  const featureFlagAICoding = !!user.featureFlagAICoding;

  
  function incrementAddedMemoCount(amount) {
    if ( amount === 0) 
      setAddedMemoCount(addedMemoCount)
    else
      setAddedMemoCount(Math.max(0, addedMemoCount + (amount || 1)))
  }

  async function askAI(memo) {
    if (!featureFlagAICoding) return;
    if ( !process.env['AI_FEATURE_FLAG'] ) return;
    
      //this.fetchStreamData(chat);
    ChatActions.createMemoChat(props.excerpt_id).then((data)=>{
      props.memoExpanded(data.data.memo.id)
      setStreamConfig({
        url: data.data.stream.stream_url,
        headers: data.data.auth,
        memo_id: data.data.memo.id
      });
    });  
  }
  
  // 
  const allMemos = (props.memos || []);
  // if streamingConfig memo_id is not null, then we are streaming
  // replace the the memo.text with that memo_id with streamData
  if (streamConfig.memo_id) {
    const memoIndex = allMemos.findIndex(memo => memo.id === streamConfig.memo_id);
    if (memoIndex > -1) {
      allMemos[memoIndex].text = streamData;
    }
  }


  if ( !props.memosExpanded && allMemos.length === 0 ) return null;
  const memoCount = props.memoCount;
  const memos = props.memosExpanded ? allMemos.slice(-1 - memoCount) : allMemos.slice(-1 - addedMemoCount);
  const memosToSee = Math.min(allMemos.length - memos.length, EXPAND_MEMO_STEP);

  return (
    <Thread
      memosToSee={memosToSee}
      memos={memos}
      incrementAddedMemoCount={incrementAddedMemoCount}
      hasEditPermission={data.hasEditPermission}
      initials={user.initials}
      userColor={user.color}
      excerpt_id={props.excerpt_id}
      memoExpanded={props.memoExpanded}
      expandedMemoList={props.expandedMemoList}
      increaseMemoCount={props.increaseMemoCount}
      askAI={askAI}
      featureFlagAICoding={featureFlagAICoding}
    />
  );
}

export default ThreadContainer;