// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';
import UpgradeActions from 'actions/UpgradeActions'
import AccountSingleButton from './AccountSingleButton'

class BillingModalUpgrade extends React.Component {
  constructor () {
    super();
    this.upgradeSubscription = this.upgradeSubscription.bind(this);
  }

  upgradeSubscription(e)
  {
    if ( this.props.upgradeInfo.plan_id )
      UpgradeActions.upgradeSubscription(this.props.upgradeInfo.plan_id)
  }

  render () {
    const upgradeInfo = this.props.upgradeInfo || {};

    //const subscription = this.props.subscription ? this.props.subscription : {};
    return (
      <div>
        <AccountSingleButton
          error={upgradeInfo.error}
          loading={!!upgradeInfo.loading}
          header="Switch to Annual Billing"
          button="Confirm Switching to Annual"
          disclaimer='By switching to annual billing, the changes will take
          effect at the end of your current billing period.
          You agree to automatically be charged annually until you cancel.'
          onClick={this.upgradeSubscription}
          body={
            <div>
              <p>
                You will be billed ${upgradeInfo.upgrade_per_person} a year per teammember
                at a total of ${upgradeInfo.upgrade_amount_total} per year.
              </p>
              {upgradeInfo.upgrade_savings &&
                <p>
                  You save ${upgradeInfo.upgrade_savings} a year by paying annually!
                </p>
              }
            </div>
          }
        />
      </div>
    );
  }
}

export default BillingModalUpgrade;
