import AppDispatcher from 'dispatcher/AppDispatcher'
import CashierConstants from 'constants/CashierConstants'
import MergeConstants from 'constants/MergeConstants'
import QualConstants from 'constants/QualConstants'
import FilterConstants from 'constants/FilterConstants'
import State from 'stores/State'
import codingVersionMapper from 'mappers/CodingVersionMapper'
import defaultErrorMapper from 'mappers/DefaultErrorMapper'
import transcriptNotFoundMapper from 'mappers/TranscriptNotFoundMapper'
import projectNotFoundMapper from 'mappers/ProjectNotFoundMapper'
import errorReducer from 'reducers/ErrorReducer'
import CodePageConstants from '../constants/CodePageConstants'

import NetworkConstants from 'constants/NetworkConstants';

var _ = require('underscore');

var EventEmitter = require('events').EventEmitter;

var ErrorStore = _.extend({}, EventEmitter.prototype, {
  getTranscriptNotFound: (transcriptId) => {
    return transcriptNotFoundMapper(State.get(), transcriptId);
  },

  getProjectNotFound: (projectId) => {
    return projectNotFoundMapper(State.get(), projectId);
  },

  getError: function() {
    return defaultErrorMapper(State.get()).error;
  },

  getVersioningError: function() {
    return codingVersionMapper(State.get());
  },

  // Emit Change event
  emitChange: function() {
    this.emit('change');
  },

  // Add change listener
  addChangeListener: function(callback) {
    this.on('change', callback);
  },

  // Remove change listener
  removeChangeListener: function(callback) {
    this.removeListener('change', callback);
  }
});

// Register callback with AppDispatcher
AppDispatcher.register(function(payload) {
  var action = payload.action;
  switch(action.actionType) {
    case QualConstants.FETCH_CODES_ERROR:
    case QualConstants.NEW_PROJECT_ERROR:
    case QualConstants.UPDATE_PROJECT_NAME_ERROR:
    case QualConstants.TRANSCRIPT_CREATE_ERROR:
    case QualConstants.FETCH_DESCRIPTORS_ERROR:
    case QualConstants.CREATE_MULTI_CHOICE_ERROR:
    case QualConstants.UPDATE_MULTI_CHOICE_DESCRIPTOR_ERROR:
    case QualConstants.CREATE_MULTI_CHOICE_DESCRIPTOR_ERROR:
    case QualConstants.DESTROY_MULTI_CHOICE_DESCRIPTOR_ERROR:
    case QualConstants.FETCH_SELECTIONS_ERROR:
    case QualConstants.FETCH_PROJECT_SELECTIONS_ERROR:
    case QualConstants.CREATE_MULTI_CHOICE_SELECTION_ERROR:
    case QualConstants.DELETE_MULTI_CHOICE_SELECTION_ERROR:
    case QualConstants.DELETE_TRANSCRIPT_ERROR:
    case QualConstants.UPDATE_TRANSCRIPT_ERROR:
    case QualConstants.DELETE_CODE_ERROR:
    case QualConstants.RENAME_CODE_ERROR:
    case QualConstants.FETCH_USER_ERROR:
    case QualConstants.ACCEPT_TERMS_OF_SERVICE_ERROR:
    case QualConstants.LOAD_PROJECT_DETAILS_ERROR:
    case QualConstants.LOAD_PROJECT_DETAILS: // wipes 404 errors
    case QualConstants.LOAD_TRANSCRIPT_ERROR:
    case QualConstants.LOAD_TRANSCRIPT: // wipes error
    case QualConstants.ADD_CODE_TO_EXCERPT_ERROR:
    case QualConstants.DELETE_CODE_FROM_EXCERPT_ERROR:
    case FilterConstants.FILTER_EXCERPTS_ERROR:
    case QualConstants.TRANSCRIPT_SEARCH_RECEIVED_ERROR:
    case QualConstants.NEST_CODE_ERROR:
    case QualConstants.CREATE_EXCERPT_ERROR:
    case QualConstants.CREATE_CODE_ERROR:
    case CashierConstants.FETCH_SUBSCRIPTIONS_ERROR:
    case MergeConstants.MERGE_CODE_ERROR:
    case NetworkConstants.NETWORK_ERROR:
    case FilterConstants.FILTER_REPORT_ERROR:
    case QualConstants.CLEAR_ERROR:
    case QualConstants.GET_CODE_ERROR:
    case CodePageConstants.CODE_REPORT_ERROR:
    case CodePageConstants.FILTER_CODE_EXCERPTS_ERROR:
      State.set(errorReducer(action, State.get()));
    break;
    default:
      return true;
  }

  ErrorStore.emitChange();
  return true;
});

export default ErrorStore
