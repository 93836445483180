// TODO figure out how to get keyMirror, is it not part of react?
//var keyMirror = require('react/lib/keyMirror');

module.exports =  {
                    // Sharing
                    FETCH_PROJECT_COLLABORATORS: "FETCH_PROJECT_COLLABORATORS",
                    FETCH_PROJECT_COLLABORATORS_RESULT: "FETCH_PROJECT_COLLABORATORS_RESULT",
                    FETCH_PROJECT_COLLABORATORS_ERROR: "FETCH_PROJECT_COLLABORATORS_ERROR",
                    // Add Collaborator
                    ADD_COLLABORATOR: "ADD_COLLABORATOR",
                    ADD_COLLABORATOR_RESULT: "ADD_COLLABORATOR_RESULT",
                    ADD_COLLABORATOR_ERROR: "ADD_COLLABORATOR_ERROR",
                    // Remove Collaborator
                    REMOVE_COLLABORATOR: "REMOVE_COLLABORATOR",
                    REMOVE_COLLABORATOR_RESULT: "REMOVE_COLLABORATOR_RESULT",
                    REMOVE_COLLABORATOR_ERROR: "REMOVE_COLLABORATOR_ERROR",
                    // Update Collaborator
                    UPDATE_COLLABORATOR: "UPDATE_COLLABORATOR",
                    UPDATE_COLLABORATOR_RESULT: "UPDATE_COLLABORATOR_RESULT",
                    UPDATE_COLLABORATOR_ERROR: "UPDATE_COLLABORATOR_ERROR",
                    // CLEAR COLLABORATION ERROR
                    CLEAR_COLLABORATION_ERROR: "CLEAR_COLLABORATION_ERROR"
                  }
