import ParagraphClass from 'utils/ParagraphClass'
import TranscriptSplitter from 'utils/TranscriptSplitter'

class ParagraphLocator {
  constructor(transcriptText){
    this.paragraphs = new TranscriptSplitter().build(transcriptText);
  }

  locate(paragraphIndex) {
    if ( isNaN(paragraphIndex) ) return null;
    // you are going to want to pull this out. So that we can reuse it in edit paragraph mapper

    if ( paragraphIndex >= this.paragraphs.length )
      return null;

    var paragraphStart = 0;

    for ( var index = 0; index < this.paragraphs.length; index++ )
    {
      const paragraph = this.paragraphs[index];

      if ( index == paragraphIndex )
      {
          return {
            start: paragraphStart,
            end: paragraphStart + [...paragraph].length,
          }
      }
      paragraphStart += ([...paragraph].length + 1);
    }
  }
}

export default ParagraphLocator;
