const NULL_STATE = {error:{show: false}, success:{message:null}};
const ACCEPT_INVITE_MESSAGE = "Well done! Your account is set up. Just log in to get started."

const loginMapper = (state) => {
  try {
    const loginState = state.loginState;
    if ( loginState.error.show ) return {
      ...NULL_STATE,
      ...loginState,
    }

    let message = null;

    if ( state.inviteState.success )
      message = ACCEPT_INVITE_MESSAGE
    else if ( state.resetState.success.message )
      message = state.resetState.success.message;

    return {
      ...NULL_STATE,
      success:{
        message: message
      }
    }
  } catch (e) {
    console.error(e);
    return NULL_STATE;
  }
}

export default loginMapper
