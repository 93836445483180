import * as Sentry from '@sentry/browser';
import React from 'react';
import ErrorDisplay from './ErrorDisplay'


// should have been called before using it here
// ideally before even rendering your react app

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { error: null };
    }

    componentDidCatch(error, errorInfo) {
      this.setState({ error });
      Sentry.withScope(scope => {
        Object.keys(errorInfo).forEach(key => {
          scope.setExtra(key, errorInfo[key]);
        });
        Sentry.captureException(error);
      });
    }

    report()
    {
      Sentry.showReportDialog()
    }

    render() {
        if (this.state.error) {
            //render fallback UI
            return (
              <ErrorDisplay report={this.report}/>
            );
        } else {
            //when there's not an error, render children untouched
            return this.props.children;
        }
    }
}

export default ErrorBoundary;
