const NULL_STATE = {};
const ROOT_KEY = 'ROOT'
import codesHiearchyMapper from  'mappers/CodesHiearchyMapper'
import getDepth from 'mappers/nest/DepthMapper'
import recursiveBuild from 'mappers/codes/RecursiveCodesMapper'
import flattenCodes from 'mappers/codes/FlattenCodes'
import optimisticMapper from 'mappers/OptimisticMapper'
import OptimisticMapperHelper from 'mappers/OptimisticMapperHelper'


const codeChildrenMapper = (state,
  projectID,
  codeId
  ) => {

  try {
    state = optimisticMapper(state);
    codeId = OptimisticMapperHelper.getCodeId(state, codeId);
    var parentMapper = codesHiearchyMapper(state, projectID);
    const dragCodeDepth = getDepth(parentMapper, codeId)

    const collapseMapper = state.codeBook[projectID] || false ? state.codeBook[projectID] : {};
    const collapseBarMapper = state.codeBar[projectID] || false ? state.codeBar[projectID] : {};

    var findCodes = {match: false};

    const codes = state.entities.codes;

    const returnCodes = recursiveBuild(
      state,
      0, // current level
      findCodes, // ?
      codes, // codes from state
      parentMapper, // required?
      collapseMapper, // if you are dragging a code, don't show children if it is collapsed (actually probably should just catch this sooner)
      collapseBarMapper,
      codeId, // should this be replaced with the codeId at this poit?
      null,
      dragCodeDepth, // probably need this for indents
      false,
      null,
      null,
      null,
      null,
      0,
      0
    );

    return {codes: flattenCodes([returnCodes], false)};
  } catch (e) {
    console.error(e);
    return NULL_STATE;
  }
}

export default codeChildrenMapper
