// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';
import ThreadContainer from './ThreadContainer'
import ExcerptBody from './ExcerptBody'
import ExcerptConstants from 'constants/ExcerptConstants'
import { EXPAND_MEMO_STEP } from 'memos/MemoConstants';
import CodingActions from 'actions/CodingActions';

class Excerpt extends React.Component {
  constructor () {
    super();
    this.onClick = this.onClick.bind(this);
    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
    this.onMouseEnterExcerpt = this.onMouseEnterExcerpt.bind(this);
    this.onMouseLeaveExcerpt = this.onMouseLeaveExcerpt.bind(this);
    this.expandMemos = this.expandMemos.bind(this);
    this.memoExpanded = this.memoExpanded.bind(this)
    this.increaseMemoCount = this.increaseMemoCount.bind(this)
    this.onCodeRemove = this.onCodeRemove.bind(this)
    this.state = {
      hover:false,
      hoverExcerpt: false,
      memosExpanded: false,
      expandedMemoList: [],
      memoCount: 0
    }
  }

  onCodeRemove(codeID, excerptId) {
    CodingActions.removeCodeFromExcerpt(codeID, excerptId);
  }

  memoExpanded(id) {
    this.setState({
      expandedMemoList: this.state.expandedMemoList.concat([id])
    })
  }

  increaseMemoCount() {
    this.setState({
      memoCount: this.state.memoCount + EXPAND_MEMO_STEP,
      memosExpanded: true
    })
  }

  expandMemos(e) {
    e.stopPropagation();
    e.preventDefault();

    this.setState({
      memoCount: !!this.state.memosExpanded ? 0 : EXPAND_MEMO_STEP,
      memosExpanded: !this.state.memosExpanded,
      expandedMemoList: this.state.memosExpanded ? [] : this.state.expandedMemoList
    })
  }

  onMouseEnter (e)
  {
    this.setState({
      hover: true
    })
  }

  onMouseLeave (e)
  {
    this.setState({
      hover: false
    })
  }

  onMouseEnterExcerpt (e)
  {
    this.setState({
      hoverExcerpt: true
    })
  }

  onMouseLeaveExcerpt (e)
  {
    this.setState({
      hoverExcerpt: false
    })
  }
  
  onClick (e)
  {
    this.props.onTextClicked(this.props.excerpt.id);

    if (!e)
      e = window.event;

    //IE9 & Other Browsers
    if (e.stopPropagation) {
      e.stopPropagation();
    }
    //IE8 and Lower
    else {
      e.cancelBubble = true;
    }
  }

  _getCodeString(codeArray) {
    return codeArray.map((code)=>code.name).sort().join('');
  }

  _didCodesChange(thisCodes, nextCodes ) {
    thisCodes = thisCodes || [];
    nextCodes = nextCodes || [];
    if ( thisCodes.length !== nextCodes.length ) return true;
    const thisCodeString = this._getCodeString(thisCodes);
    const nextCodeString = this._getCodeString(nextCodes);
    if ( thisCodeString !== nextCodeString ) return true;
    return false;
  }

  _didExcerptChage(thisExcerpt, nextExcerpt) {
    thisExcerpt = thisExcerpt || {};
    nextExcerpt = nextExcerpt || {};
    const thisTranscript = thisExcerpt.transcript || {};
    const nextTranscript = nextExcerpt.transcript || {};

    if ( thisExcerpt.text !== nextExcerpt.text ) return true;
    if ( thisTranscript.name !== nextTranscript.name ) return true;
    if ( thisExcerpt.id !== nextExcerpt.id ) return true;
    if ( thisExcerpt.server_id !== nextExcerpt.server_id ) return true;
    if ( thisExcerpt.transcript_id !== nextExcerpt.transcript_id ) return true;
    const thisMemos = thisExcerpt.memos || [];
    const nextMemos = nextExcerpt.memos || [];
    if ( thisMemos.length !== nextMemos.length ) return true;

    const thisMemoIds = new Set(thisMemos.map((memo)=>memo.id))
    const nextMemoIds = new Set(nextMemos.map((memo)=>memo.id))

    if ( thisMemoIds.size !== nextMemoIds.size ) return true;
    for (let id of thisMemoIds) if (!nextMemoIds.has(id)) {return true};

    if ( this._didCodesChange(thisExcerpt.codes, nextExcerpt.codes) ) return true;
    return false;
  }

  shouldComponentUpdate(nextProps, nextState)
  {
    if ( this.props.selected !== nextProps.selected ) return true;
    if ( this.state.hover !== nextState.hover ) return true;
    if ( this.state.hoverExcerpt !== nextState.hoverExcerpt ) return true;
    if ( this.state.memosExpanded !== nextState.memosExpanded ) return true;
    if ( this.state.memoCount !== nextState.memoCount ) return true;
    if ( this.props.hasEditPermission !== nextProps.hasEditPermission ) return true;
    if ( this.state.expandedMemoList.length !== nextState.expandedMemoList.length ) return true;
    // use Set to compare arrays of expandedMemoList, if memos do not contain the same ids exaclty, re render
    return this._didExcerptChage(this.props.excerpt, nextProps.excerpt);
  }

  render () {
    const hasMemos = this.props.excerpt.memos && this.props.excerpt.memos.length > 0;
    const showMemoIndicator = hasMemos || 
      (this.state.hoverExcerpt || this.props.selected) 
      && this.props.hasEditPermission;

    if ( this.props.excerpt == null || this.props.excerpt.codes == null )
    {
      return (
        <div></div>
      );
    }

    const excerpt = this.props.excerpt ? this.props.excerpt : {};
    const memoCount = excerpt.memos ? excerpt.memos.length : 0;

    return (
      <div
        onMouseEnter={this.onMouseEnterExcerpt}
        onMouseLeave={this.onMouseLeaveExcerpt}
      >
        <div
        className="excerpt"
        style={{
                    border: ExcerptConstants.BORDER,
                    margin: '30px 0px',
                    boxShadow: ExcerptConstants.BOX_SHADOW,
                    borderRadius: ExcerptConstants.BORDER_RADIUS,
                  }}>

          <ExcerptBody
            codePage={!!this.props.codePage}
            showMemoIndicator={showMemoIndicator}
            selected={this.props.selected}
            hasEditPermission={this.props.hasEditPermission}
            excerpt={this.props.excerpt}
            projectID={this.props.projectID}
            onCodeClick={this.props.onCodeClick}
            disabled={this.props.disabled}
            memoCount={memoCount}
            expandMemos={this.expandMemos}
            onMouseEnter={this.onMouseEnter}
            onMouseLeave={this.onMouseLeave}
            hover={this.state.hover}
            onTextClicked={this.props.onTextClicked}
            onCodeRemove={this.onCodeRemove}
            onLinkClick={this.props.onLinkClick}
          />
          <ThreadContainer
            excerpt_id={this.props.excerpt.id}
            memos={this.props.excerpt.memos}
            memosExpanded={this.state.memosExpanded}
            expandedMemoList={this.state.expandedMemoList}
            memoExpanded={this.memoExpanded}
            increaseMemoCount={this.increaseMemoCount}
            memoCount={this.state.memoCount}
          />
        </div>        
      </div>
    );
  }
}

export default Excerpt;
