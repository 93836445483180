var _ = require('underscore');

const CODE_BOOK_KEY = 'code_book';
const CODE_BAR_KEY = 'code_bar';

var LocalAPI = {
  _getKey(key, projectID)
  {
    return `${key}_${projectID}`;
  },

  _getCodeBookKey(projectID)
  {
    return this._getKey(CODE_BOOK_KEY, projectID)
  },

  _getCodeBarKey(projectID)
  {
    return this._getKey(CODE_BAR_KEY, projectID)
  },

  getCodeObject(key, projectID)
  {
    var collapseMapper = {};
    try {
      var collapseMapper = localStorage.getItem(key);
      collapseMapper = JSON.parse(collapseMapper);
     } catch(e) {
    }

    collapseMapper = collapseMapper ? collapseMapper : {};
    return collapseMapper
  },

  getCodeBook(projectID)
  {
    return this.getCodeObject(this._getCodeBookKey(projectID), projectID);
  },

  getCodeBar(projectID)
  {
    return this.getCodeObject(this._getCodeBarKey(projectID), projectID);
  },

  _setCodeObject(key, originalMapper, projectID, codeID, collapse)
  {
    const value = JSON.stringify({
      ...originalMapper,
      [codeID]:collapse,
    });
    localStorage.setItem(key, value);
  },

  _setCodeBook(projectID, codeID, collapse)
  {
    var key = this._getCodeBookKey(projectID);
    const originalMapper = this.getCodeBook(projectID);
    this._setCodeObject(key, originalMapper, projectID, codeID, collapse);
  },

  collapseCode(projectID, codeID)
  {
    this._setCodeBook(projectID, codeID, true);
  },

  expandCode(projectID, codeID)
  {
    this._setCodeBook(projectID, codeID, false);
  },

  _setCodeBar(projectID, codeID, collapse)
  {
    var key = this._getCodeBarKey(projectID);
    const originalMapper = this.getCodeBar(projectID);
    this._setCodeObject(key, originalMapper, projectID, codeID, collapse);
  },

  collapseCodeBar(projectID, codeID)
  {
    this._setCodeBar(projectID, codeID, true);
  },

  expandCodeBar(projectID, codeID)
  {
    this._setCodeBar(projectID, codeID, false);
  },

  setTranscriptLocation(transcriptID, loc, page) {
    if ( !!transcriptID && !!loc && !!page && localStorage ) {
      const string = JSON.stringify({loc,page});
      localStorage.setItem(transcriptID, string);
    }
  },

  getTranscriptLocation(transcriptID) {
    if ( !!transcriptID && localStorage ) {
      const string = localStorage.getItem(transcriptID);
      if ( !string ) return {};
      try {
        return JSON.parse(string);
      } catch (e) {
        return {};
      }
    }
  }
};

export default LocalAPI
