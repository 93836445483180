import projectListMapper from './ProjectListMapper'
const _ = require('underscore');

const projectStatusMapper = (state) => {
  const {projects, loaded} = projectListMapper(state);

  if ( !loaded )
    return {loaded}

  return {
    loaded,
    hasShared: _.any(projects.map((project)=>!project.isOwner))
  }
}

export default projectStatusMapper
