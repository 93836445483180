// app/javascript/projects/components/CodeDisplay.jsx

import React from 'react';
import DeleteModal from './DeleteModal';

function DeleteCodeModal({show, name, onCancel, onDelete}) {
  return (
    <DeleteModal
      show={show}
      onCancel={onCancel}
      onDelete={onDelete}
      title={`Are you sure you want to delete the '${name}' code?`}
    >
      Deleting it will remove the code from all transcripts and snippets.
    </DeleteModal>
  )
}

export default DeleteCodeModal;
