const getDepth = (parentMapper, nodeID, depth) =>
{
  depth = depth || 0;
  if ( !nodeID ) return depth;
  depth += 1;
  if ( depth >= 3 ) return depth;
  const children = parentMapper[nodeID] ? parentMapper[nodeID] : [];

  let maxDepth = depth;

  for ( var childrenIndex = 0; childrenIndex < children.length; childrenIndex++ )
  {
    const childID = children[childrenIndex];
    maxDepth = Math.max(getDepth(parentMapper, childID, depth), maxDepth);
    if ( maxDepth >= 3 ) return maxDepth;
  }
  return maxDepth;
}

export default getDepth;
