// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';
import {Button} from 'react-bootstrap'
import {LinkContainer} from 'react-router-bootstrap'
import QualCodeActions from 'actions/QualCodeActions'

import DelveAccountBody from './DelveAccountBody'
import SubscriptionBorder from './SubscriptionBorder'

class SuccessDisplay extends React.Component {
  signOut (e) {
    QualCodeActions.signOut();
  }

  render () {
    return (
      <div>
        <DelveAccountBody>
          <SubscriptionBorder>
            <div style={{marginTop:60, marginBottom:30}}>
              <div style={{marginBottom:40}}>
                <h2 style={{textAlign:"center"}}>Welcome to Delve</h2>
              </div>

              <div style={{margin:40}}>
                <p style={{textAlign:"center", margin:0}}>
                  We're thrilled for you to start using Delve for your research.
                </p>
              </div>

              <div style={{textAlign:"center"}}>
                <LinkContainer to="/" style={{width:200}}>
                  <Button bsStyle="blue">
                    Continue
                  </Button>
                </LinkContainer>
              </div>
            </div>
          </SubscriptionBorder>

          <div style={{textAlign:"center"}}>
              <Button bsStyle="link" onClick={this.signOut}>Log Out</Button>
          </div>
        </DelveAccountBody>
      </div>
    );
  }
}

export default SuccessDisplay;
