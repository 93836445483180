// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';
import CashierActions from 'actions/CashierActions'
import AccountSingleButton from './AccountSingleButton'

class BillingModalRenew extends React.Component {
  constructor () {
    super();
    this.state = {
    };

    this.uncancelSubscription = this.uncancelSubscription.bind(this);
  }

  uncancelSubscription(e)
  {
    if ( this.props.subscription && this.props.subscription.id)
      CashierActions.renewSubscription(this.props.subscription.id)
  }

  render () {
    const subscription = this.props.subscription ? this.props.subscription : {};
    return (
      <div>
        <AccountSingleButton
          error={this.props.uncancelSubscriptionInfo.error}
          onClick={this.uncancelSubscription}
          loading={this.props.uncancelSubscriptionInfo.loading}
          header="Renew Subscription"
          button="Confirm Subscription Renewal"
          disclaimer={`By renewing your subscription, you agree to automatically
                      be charged each ${subscription.interval} until you cancel.`}
          body={<p>Renew your subscription for ${subscription.total} a {subscription.interval}.</p>}
        />
      </div>
    );
  }
}

export default BillingModalRenew;
