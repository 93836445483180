// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';
import ShareStore from 'stores/ShareStore'
import ProjectsStore from 'stores/ProjectsStore'
import {CONTENT_MIN_WIDTH, RIGHT_WIDTH} from 'constants/DimensionsConstants'
import {Button} from 'react-bootstrap'
import CollabActions from 'actions/CollabActions'
import {Popover, OverlayTrigger} from 'react-bootstrap';


class PopoverButton extends React.Component {
  constructor () {
    super();
    this.state = {
      ...ShareStore.getCollaborators(null),
      project: {},
      collabModal:false,
    };

    this.showCollabModal = this.showCollabModal.bind(this);
    this.hideCollabModal = this.hideCollabModal.bind(this);
    this._onChange = this._onChange.bind(this);
  }

  _onChange()
  {
    this.setState({
      ...ShareStore.getCollaborators(this.props.projectID),
      project: ProjectsStore.getProject(this.props.projectID),
    });
  }

  showCollabModal(e)
  {
    if ( !this.props.projectID ) return;
    CollabActions.getCollabList(this.props.projectID);

    this.setState({
      ...ShareStore.getCollaborators(this.props.projectID),
      collabModal: true
    });
  }

  hideCollabModal(e)
  {
    this.setState({
      collabModal: false
    })
  }

  componentDidMount () {
    ShareStore.addChangeListener(this._onChange);
    ProjectsStore.addChangeListener(this._onChange);
  }

  componentWillUnmount () {
    ShareStore.removeChangeListener(this._onChange);
    ProjectsStore.removeChangeListener(this._onChange);
  }

  render () {
    const tooltip = (
      <Popover
        id="popover-tooltip"
      >
        <div style={{fontSize:".8em"}}>
          {this.props.children}
        </div>
      </Popover>
    );

    if ( !!this.props.disabled ) {
      return (<OverlayTrigger
          delayHide={0}
          placement="bottom"
          overlay={tooltip}
      >
        <div style={{position: 'relative'}}>
          <div style={{
            position: 'absolute',
            top: 0,
            bottom: 0,
            width: "100%",
            height: "100%",
            zIndex: 2
          }}/>
          <Button
            bsStyle="white"
            onClick={this.props.onClick}
            disabled={!!this.props.disabled}
          >
            {this.props.name}
          </Button>
        </div>
      </OverlayTrigger>)
    } else {
      return (<OverlayTrigger
          delayHide={0}
          placement="bottom"
          overlay={tooltip}
      >
        <Button
          bsStyle="white"
          onClick={this.props.onClick}
          disabled={!!this.props.disabled}
        >
          {this.props.name}
        </Button>
      </OverlayTrigger>)
    }
  }
}

export default PopoverButton;
