import nameMapper from './NameMapper'
import collaboratorsMapper from './CollaboratorsMapper'

const ownerMapper = (state, projectID) => {
  try {
    const {userColors} = collaboratorsMapper(state, projectID);
    const user = nameMapper(state, state.entities.user.id);
    const color = (userColors[user.id] || {}).color || null;
    return {
      ...user,
      ...(color && {color})
    }
  } catch (e) {
    console.error(e);
    return {}
  }
}

export default ownerMapper;
