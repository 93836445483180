// app/javascript/projects/components/CodeDragLayer.jsx

import React from 'react';
import { DragLayer } from 'react-dnd'
import CodeStore from 'stores/CodeStore';
import CodeLabelBody from './CodeLabelBody'

// components

const layerStyles = {
  position: 'fixed',
  pointerEvents: 'none',
  zIndex: 100,
  left: 0,
  top: 0,
};

function getItemStyles(props) {
  const { currentOffset } = props;
  if (!currentOffset) {
    return {
      display: 'none'
    };
  }

  var {x, y} = currentOffset;
  const transform = `translate(${x}px, ${y}px)`;

  return {
    transform: transform,
    WebkitTransform: transform
  };
}

class CodeDragLayer extends React.Component {
  constructor () {
    super();

    this.state = {
      codes: []
    };

  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const prevItem = prevProps.item || {};
    const item = this.props.item || {};
    if ( !!item.codeID && (prevItem.codeID !== item.codeID)) {
      this.setState({
        ...CodeStore.getCodeWithChildren(item.codeID),
      })
    }
  }

  renderItem(type, item) {
    // Closer together
    const heightStyle = {
      marginBottom:"5px"
    }

    const MAX_CODE_NUMBER = 17;

    const codes = (this.state.codes || []).slice(0,MAX_CODE_NUMBER)

    const renderCodes = (codes || []).map((code, index)=>{
      return <div
          style={{
            marginLeft: (code.level || 0) * 30,
          }}
          key={index}
        >
        <CodeLabelBody
          name={code.name}
          />
      </div>
    })

    return (
      <div style={{
          opacity: .5
        }}>
        {renderCodes}
      </div>
    )
  }

  render () {
    const { item, itemType, isDragging } = this.props;
    if ( !isDragging ) return null;

    return (
      <div>
        {isDragging && <div style={layerStyles}>
            <div style={getItemStyles(this.props)}>
              {this.renderItem(itemType, item)}
            </div>
          </div>
        }
      </div>
    );
  }
}

function collect(monitor) {
  return {
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
    initialOffset: monitor.getInitialClientOffset(),
    sourceClientOffset: monitor.getInitialSourceClientOffset(),
  };
}

export default DragLayer(collect)(CodeDragLayer);
