const DEFAULT_MESSAGE = "Something went wrong, please refresh and try again."

const NULL_STATE = {
  error:{
    error:false,
  },
  loading: false,
}

const metaSubscriptionMapper = (state, action) => {
  try {
    const errorAction = `${action}_ERROR`;

    const loadingState = {
      ...NULL_STATE,
      loading:state.loadingDict[action].loading,
    }

    const error = state.errorDict[errorAction];
    if ( !error.error ) return loadingState;

    return {
      ...loadingState,
      error: {
        error: true,
        message: error.message ? error.message : DEFAULT_MESSAGE,
      },
    }
  } catch (e) {
    console.error(e);
    return NULL_STATE;
  }
}

export default metaSubscriptionMapper
