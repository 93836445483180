const ACCEPTED_TERMS = {
  show: false,
  accepted_terms: true
};

const LOADING_TERMS = {
  show: false,
  accepted_terms: null
}

const MUST_ACCEPT_TERMS = {
  show: true,
  accepted_terms: false
}

function isLoading(user) {
  return !('accepted_terms_at' in user)
}

function acceptedTerms( user ) {
  const acceptedTermsAt = user.accepted_terms_at;
  const mostRecentTerms = user.most_recent_terms;
  if ( !acceptedTermsAt ) return false;
  if ( !mostRecentTerms ) return true;
  return acceptedTermsAt >= mostRecentTerms;
}

const termsOfServiceModal = (state) => {
  try {
    const user = state.entities.user || {};
    if ( isLoading(user) )
      return LOADING_TERMS;
    else if ( acceptedTerms(user) )
      return ACCEPTED_TERMS;
    else
      return MUST_ACCEPT_TERMS;
  } catch (e) {
    return ACCEPTED_TERMS;
  }
}

export default termsOfServiceModal;
