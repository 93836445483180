// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';
import {FormGroup, FormControl} from 'react-bootstrap'
import ColorConstants from 'constants/ColorConstants'
import CollabDropdown from './CollabDropdown'
import {Button, Alert} from 'react-bootstrap'

import QualCodeActions from 'actions/QualCodeActions'

class CollabRow extends React.Component {
  constructor () {
    super();
  }

  render () {
    const collaborator = this.props.collaborator || {};

    return <div key={collaborator.id}
                style={
                  {
                    padding:"10px 15px 10px 30px",
                    borderTop: "1px solid lightGray"}
                }
                className="collaboratorRow"
                >
              <div style={{width:"68%", float:"left"}}>
                {collaborator.name ? collaborator.name : collaborator.email}
              </div>
                <div style={{width:"32%", float:"left", padding: '0 0 0 30px', margin: 0}}>
                  <CollabDropdown
                    isMe={collaborator.isMe}
                    role={collaborator.role}
                    removeCollaborator={(e)=>this.props.removeCollaborator(e, collaborator.id)}
                    updateCollaborator={(role)=>this.props.updateCollaborator(collaborator.id, role)}
                    myRole={this.props.myRole}
                  />
                </div>
              <div style={{clear:"both"}}></div>
              { !collaborator.subscribed && collaborator.role === 'edit' &&
                <Alert bsStyle="warning">
                  {collaborator.name || collaborator.email} cannot edit until they have an active Delve subscription.
                  {
                    this.props.isSubscriptionOwner &&
                    <button
                      onClick={() => this.props.onActivateSubscription(collaborator.email)}
                      style={{background: 'none', border: 'none'}}
                    >
                      <strong>Add them to your subscription</strong>
                    </button>
                  }
                </Alert>
              }
            </div>
  }
}

export default CollabRow;
