import AppDispatcher from 'dispatcher/AppDispatcher'
import OnboardingConstants from 'constants/OnboardingConstants'

var OnboardingActions = {
  getStarted: function()
  {
    AppDispatcher.handleAction({
      actionType: OnboardingConstants.ONBOARDING_GET_STARTED,
      data: null
    })
  },
}

export default OnboardingActions
