import API from './API'

export default {
  // search transcript
  updateEmail: async function(email, verifyEmail, password)
  {
    if ( !email )
      throw new Error('Email required')

    if ( !password )
      throw new Error('Passowrd Required')
      
    return API.post(`/api/emails`, {
      email: email,
      password: password
    });
  }
};
