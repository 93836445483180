import AppDispatcher from '../dispatcher/AppDispatcher';
import ProjectsStore from '../stores/ProjectsStore';
import QualConstants from '../constants/QualConstants';
import ExcerptAPI from 'apis/ExcerptAPI';

const Joi = require('joi');

function dispatchError(type, payload, data)
{
  const response = payload.response || {};
  const responseData = response.data || {};
  const errors = responseData.errors || [];

  AppDispatcher.handleAction({
    actionType: type,
    data: data || {},
    errors: errors,
  });
}

var ExcerptActions = {
  /*****************************\
  *
  * Excerpt Actions
  *
  \*****************************/
  createEmptyExcerpt: async function(data)
  {
    const schema = Joi.object().keys({
      start: Joi.number().required(),
      end: Joi.number().required(),
      text: Joi.string().required(),
      transcript_id: Joi.number().required(),
      clientID: Joi.string(),
    });

    const result = schema.validate(data);

    if ( result.error ) {
      dispatchError(QualConstants.CREATE_EXCERPT_ERROR, result.error);
      throw result.error;
    }

    const clientID = data.clientID;
    const transcript_id = data.transcript_id;
    var transcript = ProjectsStore.getEditTranscript(transcript_id).transcript;
    const transcript_version = transcript ? transcript.version : null;

    const response = await ExcerptAPI.createExcerpt(
      clientID,
      ProjectsStore.getSessionID(),
      data.start,
      data.end,
      transcript_id,
      data.text,
      transcript_version
    ).catch(error => {
      dispatchError(
        QualConstants.CREATE_EXCERPT_ERROR,
        error,
        {
            transcriptID: transcript_id,
            clientID
        }
      );
      return error;
    });

    AppDispatcher.handleAction({
      actionType: QualConstants.CREATE_EXCERPT_RESULT,
      data: response.data
    });

    return response.data;
  },
};

export default ExcerptActions;
