// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';
import {Button} from 'react-bootstrap'
import QualCodeActions from 'actions/QualCodeActions'
import UserCircle from './UserCircle'

class TeamModalDetail extends React.Component {
  constructor () {
    super();
    this.state = {
    };
  }

  render () {
    const seat = this.props.seat ? this.props.seat : {};
    const user = seat.user ? seat.user : {};

    return (
      <div style={{textAlign:"center"}}>
        <div style={{margin:"0 auto", width:"45px"}}>
          <UserCircle initials={user.initials}/>
        </div>
        <h4 style={{marginBottom:0, marginTop:30}}>{user.name}</h4>
        <p>{user.email}</p>

        <div style={{marginTop:40, marginBottom:40}}>
          <div style={{margin:"0 auto", maxWidth:"250px"}}>
            <p style={{maxWidth:"250px"}}>If you remove this acount from your team, they will no longer be able
              to access projects in Delve.
            </p>
          </div>
        </div>

        <div style={{marginBottom:40}}>
          <Button bsStyle="white" onClick={(e)=>this.props.removeSeat(seat.id)}>Remove Team Member</Button>
        </div>
      </div>
    );
  }
}

export default TeamModalDetail;
