// app/javascript/projects/components/FilterButton.jsx

import React from 'react';
import ColorConstants from 'constants/ColorConstants'
import {Button, Popover, OverlayTrigger} from 'react-bootstrap';

import {
  OP_OR
} from 'constants/OpConstants'

const maxWidth = '100%'

class FilterButton extends React.Component {
  _isFiltered(props) {
    const filters = props.filters;
    return filters.length > 0;
  }

  _getFilteredText(props, shouldFilter) {
    const filters = props.filters;
    const isFiltered = this._isFiltered(props);
    const op = props.op === OP_OR ? 'OR' : 'AND';
    const codeNames = isFiltered ? filters.map(t=><span> {t} </span>).reduce((prev, curr) => [ prev, <strong>{op}</strong>, curr ])   : null//.join(<strong>{op}</strong>) : null;

    if ( isFiltered ) {
      if ( shouldFilter )
        return <div style={{
            maxWidth:maxWidth,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}>{props.name} | {codeNames}</div>
      else
        return <span>{props.name} | {codeNames}</span>
    } else {
      return <span>{props.name}</span>
    }

    return isFiltered ? <span>{props.name} | {codeNames}</span> : props.name;

  }

  _getCodeText(props) {
    return this._getFilteredText(props, true);
  }

  _getPopoverText(props) {
    return this._getFilteredText(props, false);
  }

  render () {
    const text = this._getCodeText(this.props);
    const isFiltered = this._isFiltered(this.props);

    const tooltip = (
      <Popover>
        {this._getPopoverText(this.props)}
      </Popover>
    );

    const button = <Button
      bsStyle={isFiltered ? "blue-drop" : "white-drop"}
      style={{maxWidth:maxWidth}}
      onClick={this.props.onClick}>
      {text}
    </Button>;

    if ( !isFiltered)
      return button;

    return (
      <OverlayTrigger delayShow={500}
        delayHide={0}
        placement="bottom"
        overlay={tooltip}>
          {button}
      </OverlayTrigger>
    )
  }
}

export default FilterButton;
