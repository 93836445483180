import exportCodesMapper from 'mappers/exports/ExportCodesMapper'
var _ = require('underscore');

function recursiveMapper(state, level, codes, count) {
  count = count || 1;
  let returnValue = [];
  codes.forEach((code)=>{
    const selfArray = [[code.id, count, '>'.repeat(level), code.name, code.count, code.synthesisArray.join('\n'), code.url]];
    count += 1;
    returnValue = returnValue.concat(selfArray);
    const children = code.children || [];
    const childrenArray = recursiveMapper(state, level + 1, children, count);
    count += childrenArray.length;
    returnValue = returnValue.concat(childrenArray);
  });

  return returnValue;
}


const codeListMapper = (state, projectID) => {
  const codeExport = exportCodesMapper(state, projectID);
  const codeObjects = codeExport.codes || [];
  const codes = recursiveMapper(state, 1, codeObjects);
  return {
    codes,
    projectName: codeExport.projectName
  };
}

export default codeListMapper
