import React from 'react';
import { Glyphicon } from 'react-bootstrap';
import ColorConstants from 'constants/ColorConstants';

const CloseModalButton = ({ onHide }) => {
  return (
    <React.Fragment>
      <div style={{float:"right"}}>
        <Glyphicon onClick={onHide} style={{color:ColorConstants.LIGHT_GREY, cursor:"pointer"}} glyph='remove'/>
      </div>
      <div style={{clear:"both"}}/>
    </React.Fragment>
  );
}

export default CloseModalButton;