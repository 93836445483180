// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';
import { OverlayTrigger } from 'react-bootstrap';
import CustomPopover from './CustomPopover'
import TimeUtil from 'utils/TimeUtil';
import {
  DARK_GREY,
  LIGHT_GREY,
} from 'constants/ColorConstants'


const CoderOverlay = (props) => {
  if ( (props.coders || []).length === 0 )
    return props.children;

  const tooltip = (
    <CustomPopover>
      {props.coders.map((coder, index) => {
        const time = TimeUtil.getDisplayTime(coder.time, true)
        const marginStyle = index > 0 ? {marginTop: '4px'} : {};

        return (
          <div className="coder" key={index} style={{
            ...marginStyle,
            color: !props.selected || coder.selected ? DARK_GREY : LIGHT_GREY,
          }}>
            Coded by {coder.name} {time}
          </div>
        );
      })}
    </CustomPopover>
  );

  return (<OverlayTrigger delayShow={500}
    delayHide={0}
    placement="bottom"
    overlay={tooltip}
  >
    {props.children}
  </OverlayTrigger>);
}

export default CoderOverlay;
