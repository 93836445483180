import API from './API'

function addUserIdsToUrl(url, user_ids)
{
  return [
    url,
    user_ids && user_ids.length > 0 && `user_ids=${user_ids.join(',')}`
  ].filter((part)=>part).join('?');
}

export default {
  addExcerpt: function(client_id, session_id, start, end, codeID, transcriptId, text, transcript_version, user_ids)
  {
    return API.post(addUserIdsToUrl(`/api/transcripts/${transcriptId}/codes/${codeID}/excerpts`, user_ids), {
      // I don't know why you need to next the excerpt like this here
      client_id: client_id,
      session_id: session_id,
      transcript_version: transcript_version,
      excerpt:{
        start: start,
        end: end,
        text: text
      }
    });
  },
  addCodeToExcerpt: async function(session_id, code_id, excerpt_id, user_ids)
  {
    return API.put(addUserIdsToUrl(`/api/codes/${code_id}/excerpts/${excerpt_id}`, user_ids), {session_id: session_id}).then((response) =>
    {
        return {
          excerpt: response.data,
          newCodeID: code_id,
        }
    }
    );
  },
  // I do not think this function is currently being used. It's for creating an empty excerpt, which I think was for memoing.
  createExcerpt: async function(client_id, session_id, start, end, transcriptId, text, transcript_version, user_ids)
  {
    return API.post(addUserIdsToUrl(`/api/transcripts/${transcriptId}/excerpts`, user_ids), {
      client_id: client_id,
      session_id: session_id,
      transcript_version: transcript_version,
      excerpt:{
        start: start,
        end: end,
        text: text
      }
    });
  },
  bulkGetExcerpts: function(project_id, excerpt_ids)
  {
    return API.post(addUserIdsToUrl(`/api/projects/${project_id}/excerpts/bulk`), {
      ids: excerpt_ids
    });
  }
};
