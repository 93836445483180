let _state = {
  next: null
}

var FilterState = {
  set: function(newState) {
    _state = newState;
  },
  get: function(){
    return _state;
  }
}

export default FilterState
