// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';
import QualCodeActions from 'actions/QualCodeActions'
import PageParserUtil from 'utils/PageParserUtil'
import { Redirect } from 'react-router-dom'
import newestProjectMapper from 'mappers/NewestProjectMapper'

// Stores
import ProjectsStore from 'stores/ProjectsStore'

class RootBody extends React.Component {
  constructor () {
    super();
    this._onChange = this._onChange.bind(this);
    this.state = ProjectsStore.getStateWithMapper(newestProjectMapper);
  }

  redirect()
  {
    this.setState(ProjectsStore.getStateWithMapper(newestProjectMapper));
  }

  _onChange()
  {
    this.redirect();
  }

  componentDidMount () {
    ProjectsStore.addChangeListener(this._onChange);
    this.redirect();
  }

  componentWillUnmount() {
    ProjectsStore.removeChangeListener(this._onChange);
  }

  render () {
    var redirectAddress = null;
    if ( this.state.loaded )
    {
      if ( this.state.project != null )
      {
        redirectAddress = `/projects/${this.state.project.id}`;
      }
      else
      {
        redirectAddress = `/projects/new`;
      }
    }

    return (
      <div>
        { redirectAddress && <Redirect to={redirectAddress}/> }
      </div>
    );
  }
}

export default RootBody;
