var _ = require('underscore');
import TranscriptSplitter from 'utils/TranscriptSplitter'
import {
  UPDATE_TRANSCRIPT_TEXT_ERROR,
} from 'constants/QualConstants'

const EMPTY_STATE = {
  paragraphIndex: null,
  error: {error: false}
};

// This is what you need to use to save the pragraph

const editParagraphMapper = (state, transcriptID) => {
  const paragraphIndex = state.editTranscript.paragraphIndex;
  if ( !paragraphIndex ) return EMPTY_STATE;

  if ( paragraphIndex.transcript_id != transcriptID) return EMPTY_STATE;
  const transcript = state.entities.transcripts[transcriptID];
  if ( !transcript || !transcript.body ) return EMPTY_STATE;

  const paragraphs = new TranscriptSplitter().build(transcript.body);
  const paragraphID = paragraphIndex.paragraph_id;
  if ( isNaN(paragraphID) ) return EMPTY_STATE;
  if ( paragraphID >= paragraphs.length ) return EMPTY_STATE
  const paragraph = paragraphs[paragraphID];

  const errorDict = state.errorDict[UPDATE_TRANSCRIPT_TEXT_ERROR] ? state.errorDict[UPDATE_TRANSCRIPT_TEXT_ERROR] : {};

  return {
    paragraphIndex: {
      ...paragraphIndex,
      paragraph:paragraph,
      saving:state.editTranscript.saving,
      error: !errorDict.error ? {error: false} : {
          error: true,
          errorCode: errorDict.errorCode,
      }
    },
  }
}

export default editParagraphMapper
